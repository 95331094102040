import { IFormController } from 'angular';
import { ApplicationService } from '../../../api/applicationService';
import { BondTypeService } from '../../../api/bondTypeService';
import { FieldOption } from '../../../components/fields/fieldOption';
import { Injectables } from '../../../configuration/injectables';
import { EProducerRootScope } from '../../../eProducerRootScope';
import app from "../../../main";
import { Application, PrincipalType } from '../application';
import ApplicationSettings, { BondAmountType } from '../applicationSettings';
import ApplicationStepManager from './applicationStepManager';
import { UiError } from '../../../components/fullPageError/uiError';

export class ProductSelectionFormController {

    public static $inject = [
        Injectables.ApplicationService,
        Injectables.BondTypeService,
        Injectables.$q,
        Injectables.$rootScope
    ];

    public applicationSettings: ApplicationSettings;
    public application: Application;
    public applicationStepManager: ApplicationStepManager;
    public bondAmountType: BondAmountType;
    public bondTypeFieldDisabled: boolean = true;
    public bondTypeOptions: any[];
    public state: string;
    public variableBondAmountOptions: FieldOption<number>[];
    public onApplicationSettingsChanged: ({applicationSettings}: {applicationSettings: ApplicationSettings}) => void;
    public onChangesSubmitted: ({hasChanges}: {hasChanges: boolean}) => void;
    public onError: ({error}: {error: UiError}) => void;
    public initialLoadComplete: boolean;

    constructor(
        private readonly applicationService: ApplicationService,
        private readonly bondTypeService: BondTypeService,
        private readonly $q: ng.IQService,
        private readonly $rootScope: EProducerRootScope) {
    }

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.setApplicationSummary();
        this.onChangesSubmitted({hasChanges: form.$dirty});
        this.applicationStepManager.moveNextStep();

        form.$setPristine();
    }

    private setApplicationSummary = () => {
        this.$rootScope.application = {
            ...this.$rootScope.application,
            applicationNumber: this.application?.applicationNumber,
            bondAmount: this.application.bondAmount as number,
            bondType: this.applicationSettings.bondTypeName
        };
    }

    public getBondTypeOptionsByState() {
        return this.bondTypeService.getBondTypesByState(this.state)
            .then(bondTypes => {
                this.bondTypeOptions = bondTypes;
            })
            .catch(() => {
                if (this.onError) {
                    this.onError({
                        error: {
                            errorDisplayType: 'fullpage',
                            heading: 'Something went wrong',
                            errorType: 'UnableToLoadBondTypesByState',
                            message: 'An error occurred while trying to load bond types by state. Please try again. Please contact us for assistance if you continue to experience problems.'
                        }
                    });
                }
            })
    }

    public stateSelectionChanged() {
        return this.getBondTypeOptionsByState()
            .then(() => {
                this.application.bondTypeId = null;
                this.application.bondAmount = null;
                this.bondAmountType = null;
                this.bondTypeFieldDisabled = false;
            });
    }

    public bondTypeSelectionChanged() {
        if (this.application.bondTypeId < 0) {
            return this.$q.when();
        }

        return this.getApplicationSettings();
    }

    private getApplicationSettings = () => {
        return this.applicationService.getApplicationSettings(this.application.bondTypeId)
            .then(applicationSettings => {
                this.applicationSettings = applicationSettings;
                this.applicationStepManager.setPaneOptions(this.application, this.applicationSettings);
                this.state = applicationSettings.state;
                this.bondTypeFieldDisabled = false;
                this.variableBondAmountOptions = [];

                if (applicationSettings.fixedBondAmount) {
                    this.bondAmountType = BondAmountType.Fixed;
                    this.application.bondAmount = applicationSettings.fixedBondAmount;
                } else if (applicationSettings.variableBondAmounts?.length) {
                    this.bondAmountType = BondAmountType.Variable;

                    // if application.bondAmount is set but not one of the variable amounts, clear it
                    if (!this.applicationSettings.variableBondAmounts.find(variableBondAmount => variableBondAmount.amount == this.application.bondAmount)) {
                        this.application.bondAmount = null;
                    }

                    this.variableBondAmountOptions = this.applicationSettings.variableBondAmounts.map(variableBondAmount => {
                        return {
                            label: variableBondAmount.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
                            value: variableBondAmount.amount
                        };
                    });
                } else {
                    this.bondAmountType = BondAmountType.UserDefined;
                }

                if (!applicationSettings.companyInformationIsApplicable) {
                    this.application.principalType = PrincipalType.Individual;
                }

                this.onApplicationSettingsChanged({ applicationSettings: this.applicationSettings });
            })
            .catch(() => {
                if (this.onError) {
                    this.onError({
                        error: {
                            errorDisplayType: 'fullpage',
                            heading: 'Something went wrong',
                            errorType: 'UnableToLoadApplicationSettings',
                            message: 'An error occurred while trying to load information needed for this application. Please try again. Please contact us for assistance if you continue to experience problems.'
                        }
                    });
                }
            });;
    }

    public principalTypeChanged() {
        this.applicationStepManager.setPaneOptions(this.application, this.applicationSettings);

        if (this.application.principalType == PrincipalType.Company) {
            this.application.people = [this.application.people[0]];
        }
    }

    public $onInit() {
        if (this.application.bondTypeId && this.application.bondTypeId != this.applicationSettings?.bondTypeId) {
            this.getApplicationSettings()
                .then(() => {
                    this.getBondTypeOptionsByState();
                    this.setApplicationSummary();
                    this.initialLoadComplete = true;
                });
        } else {
            this.initialLoadComplete = true;
        }
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        bondTypeId: '<',
        bondAmount: '<',
        onApplicationSettingsChanged: '&',
        onChangesSubmitted: '&',
        onError: '&'
    },
    controller: ProductSelectionFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/productSelectionForm.html'
}

app.component('productSelectionForm', component);