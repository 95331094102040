import { IPromise } from "angular";
import { EProducerAccountService } from "../../api/eProducerAccountService";
import { UiError } from '../../components/fullPageError/uiError';
import { SuccessMessage } from '../../components/inlineAlert/inlineAlert';
import { Injectables } from "../../configuration/injectables";
import addressesMatched from '../application/addressesMatched';
import copyPhysicalAddress from '../application/copyPhysicalAddress';

export class AgentProfileController {
    public static $inject = [
        Injectables.EProducerAccountService
    ];

    constructor(
        private readonly eProducerAccountService: EProducerAccountService
    ) {}

    public error: UiError;
    public successMessage: SuccessMessage;
    public account: BrokerProfile;
    public addressesMatched: boolean;
    public loadingPromise: IPromise<any>;
    public isSaving: boolean;

    public save = (invalid: boolean) => {
        if (invalid) {
            return;
        }

        this.isSaving = true;

        this.eProducerAccountService.updateEProducerAccount(this.account)
            .then(() => {
                this.successMessage = {
                    heading: 'Information Saved',
                    message: 'Your companies profile information has been saved.'
                };
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while trying to update your information. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    private loadAccount = () => {
        this.loadingPromise = this.eProducerAccountService.getEProducerAccount()
            .then(account => {
                this.account = account;
                this.checkAddressesMatched();

                if (!this.account.isApproved) {
                    this.error = {
                        errorDisplayType: 'inline',
                        errorType: 'Unknown',
                        heading: 'Account Pending',
                        message: 'We are reviewing your request for a broker account. Once we have approved your account we\'ll contact you to let you know that you\'re all set up.'
                    };
                }
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while trying to load your company information. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            });
    }

    public physicalAddressChanged() {
        if (this.addressesMatched) {
            copyPhysicalAddress(this.account);
        }
    }

    public addressSameClicked() {
        if (this.addressSameClicked) {
            copyPhysicalAddress(this.account);
        }
    }

    private checkAddressesMatched() {
        if (this.account?.physicalAddress || this.account?.mailAddress) {
            this.addressesMatched = addressesMatched(this.account);
        }
    }

    public $onInit() {
        this.loadAccount();
    }
}

export type BrokerProfile = {
    id: number;
    isApproved: boolean;
    name: string;
    companyType: string;
    phone: string;
    physicalAddress: string;
    physicalSuiteApt: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    mailAddress: string;
    mailSuiteApt: string;
    mailCity: string;
    mailState: string;
    mailZip: string;
}
