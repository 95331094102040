import { IStateProvider, IUrlRouterProvider } from "../../typeDefinitions/uiRouter/index";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { StateDefinition } from "./stateDefinition";
import { AgentProfileController } from "./agentProfile/agentProfileController";
import { DocumentsController } from "./documents/documentsController";
import { IndemnitySignatureListController } from "./indemnity/indemnitySignatureListController";
import { IndemnitySignatureSignController } from "./indemnity/indemnitySignatureSignController";
import { LoginController } from "./login/loginController";
import { OpenApplicationsController } from "./openApplications/openApplicationsController";
import { PaymentFormController } from "./paymentForm/paymentFormController";
import { ApplicationController } from "./application/applicationController";
import { PurchasedBondsController } from "./purchasedBonds/purchasedBondsController";
import { ResetPasswordController } from "./resetPassword/resetPasswordController";
import { TermsAndConditionsController } from "./termsAndConditions/termsAndConditionsController";
import { UserDefaultLandingController } from "./userDefaultLanding/userDefaultLandingController";
import { UsersDetailController } from "./users/usersDetailController";
import { UsersGridController } from "./users/usersGridController";
import { FooterController } from "../components/footer/footer";
import { HeaderController } from "../components/header/header";
import { ProductSelectorController } from "../components/productSelector/productSelector";
import { SearchableProductSelector } from "../components/productSelector/searchableProductSelector";
import { MainController } from "./mainController";
import { ConfirmedQuotesController } from "./quotes/confirmedQuotesController";
import { PurchaseController } from "./application/purchaseController";
import { DownloadController } from "./application/downloadController";

const stateConfiguration = ($urlRouterProvider: IUrlRouterProvider, $stateProvider: IStateProvider) => {
    $urlRouterProvider.otherwise("/");

    const states: StateDefinition[] = [
        {
            name: "main",
            abstract: true,
            views: {
                "" : {
                    controller: MainController,
                    controllerAs: 'mainVm',
                },
                "headerView@main": {
                    templateUrl: 'app/components/header/header.html',
                    controller: HeaderController,
                    controllerAs: 'vm'
                },
                "footerView@main": {
                    templateUrl: 'app/components/footer/footer.html',
                    controller: FooterController,
                    controllerAs: 'vm'
                }
            },
        },
        {
            name: 'main.paymentForm',
            url: '/paymentForm',
            templateUrl: 'app/states/paymentForm/paymentForm.html',
            controller: PaymentFormController,
            controllerAs: 'vm',
            params: {errorMessage: null}
        },
        {
            name: "main.usersGrid",
            url:'/users?successMessage',
            templateUrl: 'app/states/users/usersGrid.html',
            controller: UsersGridController,
            controllerAs: 'vm',
            params: {successMessage: null}
        },
        {
            name: 'main.usersDetail',
            url: '/users/detail?id',
            templateUrl: 'app/states/users/usersDetail.html',
            controller: UsersDetailController,
            controllerAs: 'vm',
            params: {id: null}
        },
        {
            name: 'main.openApplications',
            url: '/applications',
            templateUrl: 'app/states/openApplications/openApplications.html',
            controller: OpenApplicationsController,
            controllerAs: 'vm'
        },
        {
            name: 'main.purchasedBonds',
            url: '/bonds',
            templateUrl: 'app/states/purchasedBonds/purchasedBonds.html',
            controller: PurchasedBondsController,
            controllerAs: 'vm'
        },
        {
            name: 'main.termsAndConditions',
            url: '/terms',
            templateUrl: 'app/states/termsAndConditions/termsAndConditions.html',
            controller: TermsAndConditionsController,
            controllerAs: 'vm'
        },
        {
            name: 'main.documents',
            url: '/documents',
            templateUrl: 'app/states/documents/documents.html',
            controller: DocumentsController,
            controllerAs: 'vm'
        },
        {
            name: 'main.agencyProfile',
            url: '/profile',
            templateUrl: 'app/states/agentProfile/agentProfile.html',
            controller: AgentProfileController,
            controllerAs: 'vm'
        },
        {
            name: 'main.login',
            url: '/login/?returnState',
            templateUrl: 'app/states/login/login.html',
            controller: LoginController,
            controllerAs: 'vm',
            params: {
                returnState: null
            }
        },
        {
            name: 'main.userDefaultLanding',
            url: '/landing',
            templateUrl: 'app/states/userDefaultLanding/userDefaultLanding.html',
            controller: UserDefaultLandingController,
            controllerAs: 'vm'
        },
        {
            name: 'main.resetPassword',
            url: '/resetPassword/:eProducerPasswordResetKey',
            params: {
                eProducerPasswordResetKey: null
            },
            templateUrl: 'app/states/resetPassword/resetPassword.html',
            controller: ResetPasswordController,
            controllerAs: 'vm'
        },
        {
            name: 'main.productSelector',
            templateUrl: 'app/components/productSelector/productSelector.html',
            controller: ProductSelectorController,
            controllerAs: 'vm'
        },
        {
            name: 'main.searchableProductSelector',
            url: '/productSelector/searchable',
            templateUrl: 'app/components/productSelector/searchableProductSelector.html',
            controller: SearchableProductSelector,
            controllerAs: 'vm'
        },
        {
            name: 'main.application',
            url: '/application?uniqueId&bondTypeId&bondAmount&tags&eProducerAccountId&applicationType',
            templateUrl: 'app/states/application/application.html',
            controller: ApplicationController,
            controllerAs: 'vm',
            params: {
                uniqueId: null,
                bondTypeId: null,
                bondAmount: null,
                tags: null,
                eProducerAccountId: null,
                applicationType: null
            },
        },
        {
            name: 'main.confirmedQuotes',
            url: '/quotes/:uniqueId',
            params: {
                uniqueId: null
            },
            templateUrl: 'app/states/quotes/confirmedQuotes.html',
            controller: ConfirmedQuotesController,
            controllerAs: 'vm'
        },
        {
            name: 'main.payment',
            url: '/payment?quoteId',
            params: {
                quoteId: null
            },
            templateUrl: 'app/states/application/purchase.html',
            controller: PurchaseController,
            controllerAs: 'vm'
        },
        {
            name: 'main.indemnitySignatureList',
            url: '/indemnitySignatureList?quoteId',
            params: {
                quoteId: null
            },
            templateUrl: 'app/states/indemnity/indemnitySignatureList.html',
            controller: IndemnitySignatureListController,
            controllerAs: 'vm'
        },
        {
            name: 'main.indemnitySignatureSign',
            url: '/indemnitySignatureSign?iuid&quoteId',
            params: {
                iuid: null,
                quoteId: null
            },
            templateUrl: 'app/states/indemnity/indemnitySignatureSign.html',
            controller: IndemnitySignatureSignController,
            controllerAs: 'vm'
        },
        {
            name: 'main.download',
            url: '/download?quoteId',
            params: {
                quoteId: null,
            },
            templateUrl: 'app/states/application/download.html',
            controller: DownloadController,
            controllerAs: 'vm'
        }
    ];

    for (let i = 0; i < states.length; i++) {
        $stateProvider.state(states[i].name, states[i]);
    }
};

stateConfiguration.$inject = [
    Injectables.$urlRouterProvider,
    Injectables.$stateProvider
];

app.config(stateConfiguration);
