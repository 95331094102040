import app from "../../main";

export class ProductTypeController {
    public static $inject = [];

    constructor() {}

    public isPolicy: boolean;
    public capitalizeFirstLetter: boolean;
    public cssClass: string;

    public $onInit() {
    }
}

const component = {
    bindings: {
        isPolicy: '<',
        capitalizeFirstLetter: '<',
        cssClass: '<',
    },
    controller: ProductTypeController,
    controllerAs: 'vm',
    templateUrl: 'app/components/productType/productType.html'
}

app.component('productType', component);