import { IStateService } from "../../../typeDefinitions/uiRouter";
import { Injectables } from "../../configuration/injectables";
import { EProducerRootScope } from "../../eProducerRootScope";
import app from "../../main";
import isUndefinedOrNull from "../../utilities/isUndefinedOrNull";

export class NavigationItem {
    public static $inject = [
        Injectables.$rootScope,
        Injectables.$state
    ]

    constructor(
        private readonly $rootScope: EProducerRootScope,
        private readonly $state: IStateService
    ) {}

    public toState: string;
    public text: string;
    public isVisible: boolean;
    public onClick: () => {}

    public get currentState(): string {
        return this.$rootScope.currentState;
    };

    public handleClick = () => {
        if (this.onClick) {
            this.onClick();
        }

        if (this.toState) {
            this.$state.go(this.toState);
        }
    }

    public $onInit() {
        if(isUndefinedOrNull(this.isVisible)) {
            this.isVisible = true;
        }
    }
}

const component = {
    bindings: {
        toState: '@',
        isVisible: '<?',
        text: '@',
        onClick: '&'
    },
    controllerAs: 'vm',
    controller: NavigationItem,
    templateUrl: 'app/components/navigation/navigationItem.html'
}

app.component('navigationItem', component);