import app from "../../main";

class TablePaginationFooterController {
    public id: string;
    public cleanedId: string;

    public $onInit = () => {
    };
}

const tablePaginationFooterComponent = {
    bindings: {
        tablePager: "<"
    },
    templateUrl: "app/components/tablePager/tablePaginationFooter.html",
    controllerAs: "vm",
    controller: TablePaginationFooterController
};

app.component("tablePaginationFooter", tablePaginationFooterComponent);
