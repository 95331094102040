import { IAttributes, IController, IDirectiveFactory, IScope } from "angular"
import app from "../../main";
import { PhoneFieldController } from '../fields/phoneField';

const isPhoneDirective: IDirectiveFactory = () => {

    const link = (
        scope: IScope,
        elm: JQuery<HTMLInputElement>,
        attrs: IAttributes,
        controllers: [IController, PhoneFieldController]
    ) => {
        const [ctrl, phoneFieldCtrl] = controllers;

        const getCursorPosition = (numbersOnly: string, formattedValue: string): number => {
            let numericIndex = 0;
            let formattedIndex = 0;

            while (numericIndex < numbersOnly.length && formattedIndex < formattedValue.length) {
                if (/\d/.test(formattedValue[formattedIndex])) {
                    if (formattedValue[formattedIndex] === numbersOnly[numericIndex]) {
                        numericIndex++;
                    }
                }

                formattedIndex++;
            }

            if (["(", ")", " ", "-"].includes(phoneFieldCtrl.lastKeyPressed)) {
                formattedIndex++;
            }

            return formattedIndex;
        };

        const isValidPhoneNumber = (value: string): boolean => {
            const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
            return phonePattern.test(value);
        };

        ctrl.$parsers.unshift((viewValue) => {
            if (!viewValue) {
                return null;
            }

            if (phoneFieldCtrl.lastKeyPressed === 'Backspace' || phoneFieldCtrl.lastKeyPressed === 'Delete') {
                return null;
            }

            const cursorPosition = elm[0].selectionStart;
            const precedingNumericChars = viewValue.slice(0, cursorPosition).replace(/\D/g, '');

            const formattingCharacters = [
                '(',
                precedingNumericChars.length > 3 ? ')' : '',
                precedingNumericChars.length > 3 ? ' ' : '',
                precedingNumericChars.length > 6 ? '-' : ''
            ];

            let formattedValue = viewValue;

            // update formattedValue with new characters to meet phone format (###) ###-####
            if (precedingNumericChars.length > 0) {
                formattedValue = `${formattingCharacters[0]}${precedingNumericChars.slice(0, 3)}${formattingCharacters[1]}${formattingCharacters[2]}${precedingNumericChars.slice(3, 6)}${formattingCharacters[3]}${precedingNumericChars.slice(6, 10)}`;
            }

            elm.val(formattedValue);

            if (elm.is(':focus')) {
                elm[0].setSelectionRange(getCursorPosition(precedingNumericChars, formattedValue), getCursorPosition(precedingNumericChars, formattedValue));
            }

            if (isValidPhoneNumber(formattedValue)) {
                return formattedValue;
            }

            return null;
        });

        ctrl.$validators.invalid = (modelValue) => {
            if (!modelValue) {
                return true;
            }

            return isValidPhoneNumber(modelValue);
        };
    };

    return {
        require: ['ngModel', '^phoneField'],
        link: link
    };
};

app.directive('isPhone', isPhoneDirective);