import app from "../../main";

export class FullPageErrorController {
    public static $inject = [];

    public $onInit() {
    }
}

const component = {
    bindings: {
        error: '<',
    },
    controller: FullPageErrorController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fullPageError/fullPageError.html',
    transclude: true
}

app.component('fullPageError', component);