import { IDocumentService, IRootElementService, IScope, ITimeoutService, IWindowService } from 'angular';
import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { FieldOption } from './fieldOption';
import { BaseField } from './baseField';

export class SelectFieldController extends BaseField {

    public static $inject = [
        Injectables.$timeout,
        Injectables.$scope,
        Injectables.$element,
        Injectables.$document
    ];

    constructor(
        $timeout: ITimeoutService,
        private readonly $scope: IScope,
        private readonly $element: IRootElementService,
        private readonly $document: IDocumentService
    ) {
        super($timeout);
    }

    public optionLabel: string = 'label';
    public optionValue: string = 'value';
    public options: FieldOption<any>[] = [];
    public isDropdownOpen: boolean;
    public isFocused: boolean;
    public highlightedIndex: number;
    public selectedOption: FieldOption<any>;

    public $onInit() {
        this.modelOptions = { updateOn: 'default' };

        this.isDropdownOpen = false;
        this.isFocused = false;
        this.highlightedIndex = 0;

        // watch for model change
        this.$scope.$watch(() => this.model, (newValue, oldValue) => {
            if (!this.options) {
                return;
            }

            if (newValue !== oldValue || !this.selectedOption) {
                try {
                    this.setFromModel();
                } catch (err) {
                    console.error(err);
                }
            }

            if (newValue !== oldValue) {
                this.handleChange();
            }
        });

        // watch for options to change
        this.$scope.$watch(() => this.options, (newValue, oldValue) => {
            if (!this.options) {
                return;
            }

            try {
                this.setFromModel();
            } catch (err) {
                console.error(err);
            }
        });
    }

    private setFromModel = () => {
        const matchingOption = this.options.find(option => option[this.optionValue] == this.model);
        this.selectedOption = matchingOption;
    }

    public fieldClicked($event: MouseEvent) {
        this.toggleDropdown();
        this.$element[0]?.querySelector('select')?.focus();
        $event.stopPropagation();
    }

    public toggleDropdown() {
        if (!this.isDisabled) {
            this.setDropdownOpen(!this.isDropdownOpen);
        }
    }

    public setDropdownOpen = (isOpen: boolean) => {
        this.isDropdownOpen = isOpen;

        if (this.isDropdownOpen) {
            this.highlightedIndex = this.options.findIndex(option => option[this.optionValue] === this.model);

            this.$document.on('click', this.clickedAway);
        } else {
            this.$document.off('click', this.clickedAway);
        }
    }

    private clickedAway = (event: any) => {
        // if click was away from this component
        if (!this.$element[0].contains(event.target)) {
            this.isFocused = false;
            this.setDropdownOpen(false);

            // required because this function is called from a non-angularJS event handler
            this.$scope.$apply();
        }
    }

    public selectOption(option: FieldOption<any>) {
        this.setDropdownOpen(false);

        if (this.model == option[this.optionValue]) {
            return;
        }

        if (!option) {
            this.model = null;
            this.selectedOption = null;
        } else {
            this.model = option[this.optionValue];        
            this.selectedOption = option;
        }
    }

    public handleKeydown(event: KeyboardEvent) {
        if (this.isDisabled) {
            return;
        }

        switch (event.key) {
            case ' ':
                event.preventDefault();
                this.setDropdownOpen(true);
                break;
            case 'ArrowDown':
                event.preventDefault();
                this.highlightNextOption();
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.highlightPreviousOption();
                break;
            case 'Enter':
                event.preventDefault();
                if (this.isDropdownOpen && this.highlightedIndex >= 0) {
                    this.selectOption(this.options[this.highlightedIndex]);
                } else {
                    this.toggleDropdown();
                }
                break;
            case 'Escape':
                event.preventDefault();
                this.setDropdownOpen(false);
                break;
            case 'Backspace':
                event.preventDefault();
                this.clearSelection();
                break;
            case 'Tab':
                this.setDropdownOpen(false);
                this.isFocused = false;
                break;
            default:
                this.handleTyping(event.key);
                break;
        }
    }

    public optionClicked = ($event: MouseEvent, option) => {
        this.selectOption(option);        
        this.$element[0]?.querySelector('select')?.focus();
        $event.stopPropagation();
    }

    public handleBlur() {
        // this.isDropdownOpen = false;
        // this.isFocused = false;
    }

    public handleFocus() {
        this.isFocused = true;
    }

    private highlightNextOption() {
        if (!this.isDropdownOpen) {
            this.toggleDropdown();
        } else {
            this.highlightedIndex = (this.highlightedIndex + 1) % this.options.length;
        }
    }

    private highlightPreviousOption() {
        if (!this.isDropdownOpen) {
            this.toggleDropdown();
        } else {
            this.highlightedIndex = (this.highlightedIndex - 1 + this.options.length) % this.options.length;
        }
    }

    private clearSelection() {
        this.selectOption(null);
    }

    private handleTyping(key: string) {
        // const searchKey = key.toLowerCase();
        // const matchingOption = this.options.find(option => option[this.optionLabel].toLowerCase().startsWith(searchKey));
        // if (matchingOption) {
        //     this.selectOption(matchingOption);
        // }
    }
}

const component = {
    bindings: {
        ...BaseField.bindings,
        options: '<',
        optionLabel: '@?',
        optionValue: '@?',
        unselectedLabel: '@?',
        hideUnselectedLabel: '<?',
        hideDropdownIndicator: '<?'
    },
    controller: SelectFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/selectField.html',
    require: {
        form: '^form'
    }
}

app.component('selectField', component);

// app.directive('compareNumeric', () => {
//     return {
//         require: 'ngModel',
//         link: (
//             scope: IScope,
//             element: JQuery,
//             attrs: IAttributes,
//             ctrl: IController
//         ) => {
//             ctrl.$parsers.push((viewValue) => {
//                 if (isStringNumber(viewValue)) {
//                     return parseInt(viewValue, 10);
//                 }

//                 return viewValue;
//             });

//             ctrl.$formatters.push((modelValue) => {
//                 return modelValue?.toString();
//             });
//         }
//     }
// });