import app from "../../main";

const component = {
    bindings: {
        isValid: '&',
        message: '@'
    },
    controllerAs: 'vm',
    templateUrl: 'app/components/passwordRequirementIndicator/passwordRequirementIndicatorItem.html'
}

app.component('passwordRequirementIndicatorItem', component);