import * as angular from "angular";
import "angular-animate";
import "angular-sanitize";
import "@uirouter/angularjs/release/angular-ui-router.js";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Injectables } from "./configuration/injectables";
import { ILocationProvider } from "angular";

angular.module('eProducerTemplateCache', []);

const app = angular
    .module('app', [
        Injectables.eProducerTemplateCache,
        Injectables.UiRouter,
        Injectables.ngAnimate
    ]);
/* 
    Upgrade Patches
    ----------------------------------
    The following are workaround solutions
    to allow for an easier transition to 
    newer versions of AngularJs.
*/

/* 
    1.6 defaults the hashPrefix with "!".
    The following clears that default to 
    ensure "!" is not included in the URL
*/
app.config([Injectables.$locationProvider, ($locationProvider: ILocationProvider) => { $locationProvider.hashPrefix("") }]);

/*
    1.5 and legacy versions of UI router cause an error to be thrown for each state change.
    This mutes the error from being sent to the console in this case
*/
const provideDecorator = ($provide) => {
    $provide.decorator("$exceptionHandler", ["$delegate", function ($delegate) {
        return function(exception, cause) {
            if (!cause) {
                return; //do nothing (ignore) exception in this case === 'Possibly unhandled rejection: undefined'
            }

            $delegate(exception, cause);
        };
    }]);
}

provideDecorator.$inject = [Injectables.$provide];

app.config(provideDecorator);

export default app;