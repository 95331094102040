import { ITimeoutService } from 'angular';
import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { BaseField } from './baseField';

export class DateFieldController extends BaseField {

    public static $inject = [
        Injectables.$timeout,
    ];

    constructor(private readonly $timeout: ITimeoutService) {
        super($timeout);

        this.invalidMessage = 'must be MM/DD/YYYY format';
        this.minLengthMessage = this.invalidMessage;
        this.maxLengthMessage = this.invalidMessage;
    }

    public isProgrammaticChange: boolean = false;
    public lastKeyPressed: string;

    public handleChange = () => {
        if (this.isProgrammaticChange) {
            return;
        }

        super.handleChange();
    }

    public handleKeydown = (event: KeyboardEvent) => {

        const allowedKeys = [
            'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Escape', 'Shift', 'Home', 'End',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'
        ];

        // if not an allowed key
        if (!allowedKeys.includes(event.key) && !(event.ctrlKey || event.metaKey)) {
            event.preventDefault();
        }

        this.lastKeyPressed = event.key;

        if (event.key === '/') {
            const inputValue = (event.target as HTMLInputElement)?.value;

            // prevent first character from being "/"
            if (!inputValue || inputValue.length === 0) {
                event.preventDefault();
            }

            // prevent 2 "//" in a row
            if (inputValue && inputValue.slice(-1) === '/') {
                event.preventDefault();
            }

            // prevent more than 2 "/"
            if ((inputValue.match(/\//g) || []).length >= 2) {
                event.preventDefault();
            }
        }
    }

    public $onInit() {
        this.modelOptions = { updateOn: 'default' };
    }
}

const component = {
    bindings: {
        ...BaseField.bindings
    },
    controller: DateFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/dateField.html',
    require: {
        form: '^form'
    }
}

app.component('dateField', component);