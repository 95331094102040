import { IPromise, ITimeoutService } from 'angular';
import { ApplicationService } from "../../api/applicationService";
import { UiError } from '../../components/fullPageError/uiError';
import { Injectables } from "../../configuration/injectables";
import { Table } from '../../utilities/tables/table';

export class OpenApplicationsController {
    public static $inject = [
        Injectables.ApplicationService,
        Injectables.$timeout
    ];

    constructor(
        private readonly applicationService: ApplicationService,
        $timeout: ITimeoutService
    ) {
        this.table = new Table($timeout);
        this.table.onChange = this.loadApplications;
    }

    public error: UiError;
    public table: Table<ApplicationListItem>;
    public loadingPromise: IPromise<any>;

    public loadApplications = () => {
        this.loadingPromise = this.applicationService.getOpenApplications(this.table.queryOptions)
            .then(response => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while loading the applications. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            });
    }

    public $onInit() {
        this.loadApplications();
    }
}

export type ApplicationListItem = {
    id: number;
    applicationNumber: number;
    nameOnBond: string;
    uniqueId: string;
    bondAmount: number;
    bondType: string;
    desiredEffectiveDate: string;
}