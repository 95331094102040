import app from "../../main";

export class PageSlideController {
    public static $inject = [];

    constructor() {}

    public isOpen: boolean;
    public onClose: () => void;
    public slideFrom: 'left' | 'right';
    public width: string;

    public $onInit() {
        if (!this.slideFrom) {
            this.slideFrom = 'right';
        }

        if (!this.width) {
            this.width = '20rem';
        }
    }
}

const component = {
    bindings: {
        isOpen: '<',
        onClose: '&',
        slideFrom: '@',
        width: '@'
    },
    controller: PageSlideController,
    controllerAs: 'vm',
    templateUrl: 'app/components/pageSlide/pageSlide.html',
    transclude: true
};

app.component('pageSlide', component);

