import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { QuoteForEProducerPurchase } from "../../states/quotes/quoteForEProducerPurchase";
import { State } from "../../states/state";

export class QuoteController {
    public static $inject = [
        Injectables.$state
    ];

    constructor(
        private readonly $state: State
    ) {}

    public quote: QuoteForEProducerPurchase;
    public isPolicy: boolean;
    public isPurchasable: boolean;

    public purchaseQuote(quote) {
        if (quote.indemnityRequirements === 'None') {
            this.$state.go('main.payment', { quoteId: quote.id });
        } else {
            this.$state.go('main.indemnitySignatureList', { quoteId: quote.id });
        }
    }

    public totalFees(quote: QuoteForEProducerPurchase) {
        if (quote.fees.length || quote.expeditedProcessingFee) {
            return quote.fees.reduce((totalFees, currentFee) => {
                return totalFees + currentFee.amount;
            }, quote.expeditedProcessingFee);
        } else {
            return 0;
        }
    }

    public $onInit() {
    }
}

const component = {
    bindings: {
        quote: '<',
        isPolicy: '<?',
        isPurchasable: '<?'
    },
    controller: QuoteController,
    controllerAs: 'vm',
    templateUrl: 'app/components/quote/quote.html'
};

app.component('quote', component);