import { IHttpService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import app from "../main";
import A3ApiResponse from "./a3ApiResponse";

export class PaymentService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ) {}

    
    public getFees = (dto) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetGatewayTransactionFee`;

        return this.$http.post<A3ApiResponse<any>>(url, dto)
            .then(function (response) {
                return response.data.value;
            });
    }

    public submitPayment = (payment) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SubmitPortalPayment`;

        return this.$http.post<A3ApiResponse<any>>(url, payment)
            .then(function (response) {
                return response.data.value;
            });
    }
}

app.service(Injectables.PaymentService, PaymentService);