import { IHttpService, IPromise } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";
import { SystemSettings } from "../configuration/systemSettings";
import A3ApiResponse from "./a3ApiResponse";
import ApplicationSettings from '../states/application/applicationSettings';
import { Application, PrincipalType } from '../states/application/application';
import { NewLogin } from '../states/application/panes/reviewAndSubmit';
import { ApplicationListItem } from '../states/openApplications/openApplicationsController';
import { PageResponse } from '../utilities/tables/pageResponse';
import { TableQueryOptions } from '../utilities/tables/tableQueryOptions';
import buildTableQueryString from '../utilities/tables/buildTableQueryString';

export class ApplicationService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ){}

    public getApplicationSettings = (bondTypeId: number): IPromise<ApplicationSettings> => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetApplicationSettings?bondTypeId=${bondTypeId}`

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }

    public submitApplication = (application: Application, newLogin: NewLogin) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SubmitApplication`;

        const request = {
            application: {
                ...application,
                companies: application.principalType == PrincipalType.Company ? [application.company] : [],
                applicationQuestionResponses: application.questionResponses ?? [],
                company: undefined,
                obligee: !!application.obligee?.name ? application.obligee : undefined
            },
            newEProducerUserEmail: newLogin?.email,
            newEProducerUserPassword: newLogin?.password,
            leadId: application.leadId,
        };

        return this.$http.post<A3ApiResponse<number>>(url, request)
            .then(response => response.data.value);
    }

    public loadApplication = (uniqueId: string) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/LoadApplication?uniqueId=${uniqueId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }

    public getPrequalifiedQuotes = (systemAccountId: number, bondTypeId: number, bondAmount: number) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetPreQualificationQuotes?bondTypeId=${bondTypeId}&bondAmount=${bondAmount}&systemAccountId=${systemAccountId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }

    public purchase = (quote, payment, shipping) => {
        const url = `${this.systemSettings.apiBaseUrl}QuoteActions/Purchase`;

        var request = {
            quoteId: quote.id,
            quoteType: quote.quoteType,
            payment: payment,
            shippingInfo: shipping
        };

        return this.$http.post<A3ApiResponse<any>>(url, request)
            .then(response => response.data.value);
    }

    public getOpenApplications = (tableQueryOptions: TableQueryOptions) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetApplications${buildTableQueryString(tableQueryOptions)}`;

        return this.$http.get<A3ApiResponse<PageResponse<ApplicationListItem>>>(url)
            .then(response => response.data.value);
    }

    public getQuoteForEProducerPurchase = (quoteId) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetQuoteForPurchase?quoteId=${quoteId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }

    public getApplicationForConfirmedQuotes = (uniqueId: string) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetApplicationForConfirmedQuotes?uniqueId=${uniqueId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }

    public getQuotePurchaseReceipt = (quoteId: number) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetPurchasedQuoteReceipt?quoteId=${quoteId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }
}

app.service(Injectables.ApplicationService, ApplicationService);
