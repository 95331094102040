angular.module('eProducerTemplateCache').run(['$templateCache', function($templateCache) {
  'use strict';

  $templateCache.put('app/components/busyIndicator/busyIndicator.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.isVisible\"\r" +
    "\n" +
    "    class=\"fade busy-indicator flex flex-col text-center absolute top-1/2 left-0 right-0 z-[31]\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"relative h-[64px] mb-2\">\r" +
    "\n" +
    "        <div class=\"indicator mx-auto absolute top-1/2 bg-slate-700 rounded-sm\"></div>\r" +
    "\n" +
    "        <div class=\"indicator mx-auto absolute top-1/2 bg-slate-700 rounded-sm\"></div>\r" +
    "\n" +
    "        <div class=\"indicator mx-auto absolute top-1/2 bg-slate-700 rounded-sm\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        class=\"text-slate-600\" \r" +
    "\n" +
    "        ng-show=\"!vm.hideMessage\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        {{vm.message}}\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.isVisible && !vm.hideBackdrop\"\r" +
    "\n" +
    "    class=\"top-0 left-0 right-0 bottom-0 absolute z-30 bg-slate-50 fade\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/checkList/checkList.html',
    "<ul class=\"{{vm.wrapperClass}}\">\r" +
    "\n" +
    "    <ng-transclude></ng-transclude>\r" +
    "\n" +
    "</ul>"
  );


  $templateCache.put('app/components/checkList/checkListItem.html',
    "<li class=\"text-sm\">\r" +
    "\n" +
    "    <span class=\"flex\">\r" +
    "\n" +
    "        <i class=\"icon-check text-eproducer-blue mt-1\"></i>\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            ng-transclude\r" +
    "\n" +
    "            class=\"pl-2 flex-1\">\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</li>"
  );


  $templateCache.put('app/components/dropzone/dropzone.html',
    "<div\r" +
    "\n" +
    "    id=\"{{vm.dropzoneId}}\"\r" +
    "\n" +
    "    class=\"text-center cursor-pointer relative min-h-12 border border-dashed border-eproducer-blue text-slate-700 bg-white hover:border-solid p-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mb-1\">Drop file here to upload</div>\r" +
    "\n" +
    "    <div class=\"text-sm\">or click to select file(s)</div>\r" +
    "\n" +
    "    <div class=\"dz-click-area w-full h-full absolute top-0 left-0\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <progress-bar\r" +
    "\n" +
    "        ng-if=\"vm.uploadProgress\"\r" +
    "\n" +
    "        progress=\"vm.uploadProgress\"\r" +
    "\n" +
    "        class=\"block absolute top-0 left-0 w-full h-full\">\r" +
    "\n" +
    "    </progress-bar>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/emptyState/emptyState.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.visible\"\r" +
    "\n" +
    "    ng-class=\"vm.mergedClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <b>{{vm.heading}}</b>\r" +
    "\n" +
    "    <p>{{vm.message}}</p>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/fields/checkField.html',
    "<div \r" +
    "\n" +
    "    class=\"{{vm.mergeClasses('relative mb-2', vm.wrapperClass)}}\"\r" +
    "\n" +
    "    ng-class=\"{'shake': vm.invalidShake && vm.invalid}\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        id=\"{{vm.name}}\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        type=\"checkbox\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-focus=\"vm.isFocused = true\"\r" +
    "\n" +
    "        ng-blur=\"vm.isFocused = false\"\r" +
    "\n" +
    "        class=\"block w-0 h-0 m-0\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <label\r" +
    "\n" +
    "            class=\"flex select-none\" \r" +
    "\n" +
    "            for=\"{{vm.name}}\">\r" +
    "\n" +
    "            <i \r" +
    "\n" +
    "                class=\"fa fa-check my-auto text-[12px] w-4 h-4 border-2 border-solid rounded-[3px] text-center focus:border-slate-600\" \r" +
    "\n" +
    "                ng-class=\"{\r" +
    "\n" +
    "                    'bg-eproducer-blue text-white': vm.model,\r" +
    "\n" +
    "                    'text-transparent': !vm.model,\r" +
    "\n" +
    "                    'opacity-6': vm.isDisabled,\r" +
    "\n" +
    "                    'border-slate-200': !vm.model && !vm.invalid,\r" +
    "\n" +
    "                    'border-eproducer-blue': vm.model && !vm.invalid,\r" +
    "\n" +
    "                    'border-red-800': vm.invalid\r" +
    "\n" +
    "                }\">\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"my-auto ml-2\"\r" +
    "\n" +
    "                ng-class=\"{'text-red-800': vm.invalid}\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span ng-show=\"vm.isRequired\">\r" +
    "\n" +
    "                    *\r" +
    "\n" +
    "                </span> \r" +
    "\n" +
    "                {{ vm.label }}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </label>\r" +
    "\n" +
    "    </label>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/fields/currencyField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        ng-min=\"vm.min\"\r" +
    "\n" +
    "        ng-max=\"vm.max\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        is-currency\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/dateField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        min=\"{{vm.min}}\"\r" +
    "\n" +
    "        max=\"{{vm.max}}\"\r" +
    "\n" +
    "        minlength=\"10\"\r" +
    "\n" +
    "        maxlength=\"10\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        is-date\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/fieldLabel.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.parent.model && vm.parent.label\"\r" +
    "\n" +
    "    class=\"{{vm.parent.mergeClasses('absolute top-0 left-[13px] bg-white px-1 text-xs text-slate-600', vm.parent.labelClass)}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ng-transclude></ng-transclude>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span ng-show=\"vm.parent.isRequired\">\r" +
    "\n" +
    "        *\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/fields/fieldValidationMessage.html',
    "<div\r" +
    "\n" +
    "    class=\"text-right text-red-800 bg-white px-1 text-xs absolute right-1 bottom-0\"\r" +
    "\n" +
    "    ng-show=\"vm.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    {{vm.errorMessage}}\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/fields/fieldWrapper.html',
    "<div class=\"{{vm.parent.mergeClasses('relative w-full pt-2 pb-1.5', vm.parent.wrapperClass)}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <ng-transclude></ng-transclude>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/fields/integerField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/phoneField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        min=\"{{vm.min}}\"\r" +
    "\n" +
    "        max=\"{{vm.max}}\"\r" +
    "\n" +
    "        minlength=\"14\"\r" +
    "\n" +
    "        maxlength=\"14\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        is-phone\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "    </field-wrapper>"
  );


  $templateCache.put('app/components/fields/radioField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"flex\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'w-full p-4 border-2 border-solid border-red-800 rounded' : vm.invalid\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            ng-show=\"vm.label\"\r" +
    "\n" +
    "            class=\"mr-4\"\r" +
    "\n" +
    "            ng-if=\"vm.label\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ vm.label }}\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span\r" +
    "\n" +
    "                ng-show=\"vm.isRequired\"\r" +
    "\n" +
    "                class=\"validation-indicator fade\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                *\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"whitespace-nowrap\" \r" +
    "\n" +
    "                ng-transclude>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <field-validation-message></field-validation-message>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/radioOption.html',
    "<label>\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.radioField.name}}\"\r" +
    "\n" +
    "        value=\"{{vm.value}}\"\r" +
    "\n" +
    "        type=\"radio\"\r" +
    "\n" +
    "        ng-model=\"vm.radioField.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-disabled=\"vm.radioField.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.radioField.isRequired\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span ng-transclude></span>\r" +
    "\n" +
    "</label>"
  );


  $templateCache.put('app/components/fields/routingNumberField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        minlength=\"9\"\r" +
    "\n" +
    "        maxlength=\"9\"\r" +
    "\n" +
    "        pattern=\"[0-9]{9}\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/selectField.html',
    "<field-wrapper ng-keydown=\"vm.handleKeydown($event)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- invisible select to help with focus, blur and <form> interaction -->\r" +
    "\n" +
    "    <select\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-options=\"('' + option[vm.optionValue]) as option[vm.optionLabel] for option in vm.options\"\r" +
    "\n" +
    "        ng-blur=\"vm.handleBlur($event)\"\r" +
    "\n" +
    "        ng-focus=\"vm.handleFocus($event)\"\r" +
    "\n" +
    "        compare-numeric\r" +
    "\n" +
    "        class=\"w-0 h-0 absolute t-0 l-0 opacity-0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <option\r" +
    "\n" +
    "            value=\"\"\r" +
    "\n" +
    "            ng-disabled=\"!vm.unselectedLabel\"\r" +
    "\n" +
    "            ng-if=\"!vm.hideUnselectedLabel\"\r" +
    "\n" +
    "            class=\"\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ vm.unselectedLabel }}\r" +
    "\n" +
    "        </option> \r" +
    "\n" +
    "    </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-click=\"vm.fieldClicked()\"\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus-visible:outline-none rounded appearance-none focus:outline-hidden', vm.selectClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-100': !vm.invalid && vm.isDisabled,\r" +
    "\n" +
    "            'border-eproducer-blue': !vm.invalid && !vm.isDisabled && vm.isFocused,\r" +
    "\n" +
    "            'border-slate-200': !vm.invalid && !vm.isDisabled && !vm.isFocused,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled,\r" +
    "\n" +
    "            'cursor-pointer': !vm.isDisabled\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <!-- no value selected label -->\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            ng-if=\"!vm.model\" \r" +
    "\n" +
    "            class=\"italic whitespace-nowrap flex overflow-hidden\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'text-slate-500': !vm.isDisabled,\r" +
    "\n" +
    "                'text-slate-300': vm.isDisabled,\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            {{ vm.unselectedLabel || vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <!-- selected option label -->\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            ng-if=\"vm.model\"\r" +
    "\n" +
    "            class=\"whitespace-nowrap flex overflow-hidden\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'text-slate-300': vm.isDisabled,\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ vm.selectedOption[vm.optionLabel] }}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <!-- dropdown indicator -->\r" +
    "\n" +
    "        <i \r" +
    "\n" +
    "            class=\"fa fa-chevron-down absolute right-4 top-1/2 transform -translate-y-1/2\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'text-slate-400': !vm.isDisabled,\r" +
    "\n" +
    "                'text-slate-200': vm.isDisabled,\r" +
    "\n" +
    "                'hidden': vm.hideDropdownIndicator\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "        </i>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- dropdown -->\r" +
    "\n" +
    "    <ul\r" +
    "\n" +
    "        ng-show=\"vm.isDropdownOpen\"\r" +
    "\n" +
    "        class=\"absolute w-full bg-white border border-slate-200 rounded mt-1 z-10 max-h-[300px] overflow-auto\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <li\r" +
    "\n" +
    "            ng-repeat=\"option in vm.options\"\r" +
    "\n" +
    "            ng-click=\"vm.optionClicked($event, option)\"\r" +
    "\n" +
    "            ng-class=\"{'bg-slate-100': vm.highlightedIndex == $index}\"\r" +
    "\n" +
    "            class=\"py-2 px-4 cursor-pointer hover:bg-slate-100\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{ option[vm.optionLabel] }}\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "    </ul>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/ssnField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        minlength=\"11\"\r" +
    "\n" +
    "        maxlength=\"11\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        is-ssn\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/stateField.html',
    "<select-field\r" +
    "\n" +
    "    name=\"{{vm.name}}\"\r" +
    "\n" +
    "    label=\"{{vm.label}}\"\r" +
    "\n" +
    "    model=\"vm.model\"\r" +
    "\n" +
    "    ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "    is-required=\"vm.isRequired\"\r" +
    "\n" +
    "    is-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "    on-change=\"vm.onChange()\"\r" +
    "\n" +
    "    wrapper-class=\"{{vm.wrapperClass}}\"\r" +
    "\n" +
    "    required-message=\"{{vm.requiredMessage}}\"\r" +
    "\n" +
    "    options=\"vm.stateOptions\">\r" +
    "\n" +
    "</select-field>"
  );


  $templateCache.put('app/components/fields/textAreaField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <textarea\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"{{vm.type}}\"\r" +
    "\n" +
    "        ng-change=\"vm.onChange()\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-attr-maxlength=\"{{vm.maxLength}}\"\r" +
    "\n" +
    "        ng-attr-minlength=\"{{vm.minLength}}\"\r" +
    "\n" +
    "        rows=\"{{vm.rows}}\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.textAreaClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "    </textarea>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/textField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"{{vm.type}}\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        maxlength=\"{{vm.maxLength}}\"\r" +
    "\n" +
    "        minlength=\"{{vm.minLength}}\"\r" +
    "\n" +
    "        ng-pattern=\"vm.pattern\"\r" +
    "\n" +
    "        ng-min=\"vm.min\"\r" +
    "\n" +
    "        ng-max=\"vm.max\"\r" +
    "\n" +
    "        step=\"{{vm.step}}\"\r" +
    "\n" +
    "        inputmode=\"{{vm.inputmode}}\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        model-matches=\"{{vm.matches}}\"\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/fields/zipField.html',
    "<field-wrapper>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <input\r" +
    "\n" +
    "        name=\"{{vm.name}}\"\r" +
    "\n" +
    "        type=\"text\"\r" +
    "\n" +
    "        ng-model=\"vm.model\"\r" +
    "\n" +
    "        ng-model-options=\"vm.modelOptions\"\r" +
    "\n" +
    "        ng-change=\"vm.handleChange()\"\r" +
    "\n" +
    "        ng-keydown=\"vm.handleKeydown($event)\"\r" +
    "\n" +
    "        ng-disabled=\"vm.isDisabled\"\r" +
    "\n" +
    "        ng-required=\"vm.isRequired\"\r" +
    "\n" +
    "        minlength=\"5\"\r" +
    "\n" +
    "        maxlength=\"5\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        ng-attr-placeholder=\"{{ vm.placeholder || vm.label }} {{ vm.isRequired ? '*' : '' }}\"\r" +
    "\n" +
    "        class=\"{{vm.mergeClasses('w-full py-2 px-4 border-2 border-solid focus:border-eproducer-blue focus-visible:outline-none rounded appearance-none placeholder:italic placeholder:text-slate-500 disabled:text-slate-300 disabled:placeholder:text-slate-300', vm.inputClass)}}\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'border-red-800': vm.invalid,\r" +
    "\n" +
    "            'border-slate-200 disabled:border-slate-100 focus:border-eproducer-blue focus:outline-hidden': !vm.invalid,\r" +
    "\n" +
    "            'bg-gray-50': !vm.model && !vm.isDisabled,\r" +
    "\n" +
    "            'bg-white': !!vm.model || vm.isDisabled\r" +
    "\n" +
    "        }\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <field-validation-message></field-validation-message>\r" +
    "\n" +
    "    <field-label>{{vm.label}}</field-label>\r" +
    "\n" +
    "</field-wrapper>"
  );


  $templateCache.put('app/components/footer/footer.html',
    "<div class=\"bg-gray-200 h-11 px-4 text-sm flex\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"!['main.application', 'main.confirmedQuotes'].includes(mainVm.state)\"\r" +
    "\n" +
    "        class=\"flex my-auto w-full\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"text-eproducer-orange my-auto mr-1 fa fa-lock\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span class=\"hidden sm:inline my-auto\">\r" +
    "\n" +
    "            Your information is always secure.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span class=\"sm:hidden my-auto\">\r" +
    "\n" +
    "            Always secure\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"ml-2 my-auto text-eproducer-blue hover:decoration-1\"\r" +
    "\n" +
    "            ui-sref=\"main.termsAndConditions\"\r" +
    "\n" +
    "            target=\"termsAndConditions\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Terms &amp; Conditions\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"ml-auto my-auto bg-right a3-logo\"></i>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"mainVm.state === 'main.application' || mainVm.state === 'main.confirmedQuotes'\"\r" +
    "\n" +
    "        class=\"flex my-auto w-full\"\r" +
    "\n" +
    "        ng-class=\"{'ml-[calc(50%_+_1rem)]': mainVm.state === 'main.application'}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-show=\"mainVm.application.filteredQuoteCount > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Total of <b class=\"text-eproducer-blue\">{{ mainVm.application.filteredQuoteCount }}</b> estimate<span ng-show=\"mainVm.application.filteredQuoteCount > 1\">s</span>.\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"hidden md:inline\">\r" +
    "\n" +
    "                <b class=\"text-eproducer-blue\">{{ mainVm.application.lowestQuoteCost | currency:'$':0 }}</b> - <b class=\"text-eproducer-blue\">{{ mainVm.application.mostExpensiveQuoteCost | currency:'$':0 }}</b> total price.\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-show=\"mainVm.application.filteredQuoteCount == 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Our <b class=\"text-eproducer-blue\">best quotes</b> are coming your way!\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"ml-auto my-auto bg-right w-[84px] h-[20px] inline-block a3-logo\"></i>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <!-- <div \r" +
    "\n" +
    "        ng-show=\"mainVm.state === 'main.payment'\"\r" +
    "\n" +
    "        class=\"flex my-auto w-full\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <img\r" +
    "\n" +
    "            ng-src=\"/img/cc.png\"\r" +
    "\n" +
    "            class=\"h-5\"\r" +
    "\n" +
    "            alt=\"Accepted Credit Cards\"\r" +
    "\n" +
    "            ng-show=\"mainVm.allowCCPayments\" />\r" +
    "\n" +
    "    </div> -->\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/fullPageError/fullPageError.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType == 'fullpage'\"\r" +
    "\n" +
    "    class=\"md:max-w-[800px] md:mx-auto md:mt-10 p-10 border border-solid border-slate-300 bg-white rounded\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h1 class=\"text-red-800\">{{vm.error.heading}}</h1>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"my-2\">{{vm.error.message}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-transclude></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"text-sm mt-2\">\r" +
    "\n" +
    "        Error: {{ vm.error.errorType }}\r" +
    "\n" +
    "        <div class=\"mt-1\">\r" +
    "\n" +
    "            {{ vm.error.technicalMessage }}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/header/header.html',
    "<header class=\"text-sm text-slate-700 py-4 px-5 flex border-b border-b-solid border-b-slate-300\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex min-[830px]:hidden\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            ng-click=\"vm.toggleMobleNav()\"\r" +
    "\n" +
    "            class=\"font-bold flex text-gray-600\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"icon-menu text-3xl my-auto\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <page-slide\r" +
    "\n" +
    "            is-open=\"vm.mobileNavOpen\"\r" +
    "\n" +
    "            on-close=\"vm.mobileNavOpen = false\"\r" +
    "\n" +
    "            slide-from=\"left\"\r" +
    "\n" +
    "            width=\"20rem\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h2>Menu</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"pt-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.application\"\r" +
    "\n" +
    "                    ng-show=\"vm.isAuthorized_portal\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    New Bond\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.openApplications\"\r" +
    "\n" +
    "                    ng-show=\"vm.isAuthorized_openApplications\"\r" +
    "\n" +
    "                    ng-class=\"{'bg-eproducer-blue text-white': vm.state === 'main.openApplications'}\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Applications\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.purchasedBonds\"\r" +
    "\n" +
    "                    ng-show=\"vm.isAuthorized_purchasedBonds\"\r" +
    "\n" +
    "                    ng-class=\"{'bg-eproducer-blue text-white': vm.state === 'main.purchasedBonds'}\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Bonds\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.documents\"\r" +
    "\n" +
    "                    ng-show=\"vm.isAuthorized_documents\"\r" +
    "\n" +
    "                    ng-class=\"{'bg-eproducer-blue text-white': vm.state === 'main.documents'}\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Document Share\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.agencyProfile\"\r" +
    "\n" +
    "                    ng-show=\"vm.isAuthorized_agencyProfile\"\r" +
    "\n" +
    "                    ng-class=\"{'bg-eproducer-blue text-white': vm.state === 'main.agencyProfile'}\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Our Company\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.usersGrid\"\r" +
    "\n" +
    "                    ng-show=\"vm.isAuthorized_usersGrid\"\r" +
    "\n" +
    "                    ng-class=\"{'bg-eproducer-blue text-white': vm.state === 'main.usersGrid'}\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Users\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.paymentForm\"\r" +
    "\n" +
    "                    ng-class=\"{'bg-eproducer-blue text-white': vm.state === 'main.paymentForm'}\"\r" +
    "\n" +
    "                    ng-click=\"vm.mobileNavOpen = false\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Make a Payment\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    ng-show=\"mainVm.currentUser\"\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ng-click=\"vm.logout()\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Logout\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a \r" +
    "\n" +
    "                    ng-show=\"!mainVm.currentUser\"\r" +
    "\n" +
    "                    class=\"block p-3\"\r" +
    "\n" +
    "                    ui-sref=\"main.login\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Login\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </page-slide>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"hidden min-[830px]:block my-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"mainVm.currentUser && !['main.application', 'main.confirmedQuotes', 'main.paymentForm'].includes(mainVm.state)\">\r" +
    "\n" +
    "            <b>Welcome \r" +
    "\n" +
    "                <span ng-show=\"mainVm.currentUser.user.lastLoginDate !== null\">\r" +
    "\n" +
    "                    Back\r" +
    "\n" +
    "                </span> \r" +
    "\n" +
    "                {{mainVm.currentUser.user.firstName}}!\r" +
    "\n" +
    "            </b>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <span ng-show=\"mainVm.currentUser.user.lastLoginDate === null\">\r" +
    "\n" +
    "                    Thank you for using our online client portal\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"mainVm.currentUser.user.lastLoginDate !== null\">\r" +
    "\n" +
    "                    Last logged in {{mainVm.currentUser.user.lastLoginDate | date: 'MM/dd/yyyy'}} at\r" +
    "\n" +
    "                    {{mainVm.currentUser.user.lastLoginDate | date:'hh:mm a'}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"!mainVm.currentUser && ['main.agencyProfile', 'main.termsAndConditions'].includes(mainVm.state)\">\r" +
    "\n" +
    "            <b>Let&apos;s get you all setup...</b>\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                Already have a login? <a ng-click=\"vm.showLoginModal = true\">Sign In</a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"mainVm.state === 'main.paymentForm'\">\r" +
    "\n" +
    "            <b>Online Payment Portal</b>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div ng-show=\"mainVm.currentUser\">\r" +
    "\n" +
    "                Complete the form below to submit a payment.\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"!mainVm.currentUser\">\r" +
    "\n" +
    "                Already have a login? <a ng-click=\"vm.showLoginModal = true\">Sign In</a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"['main.application', 'main.confirmedQuotes'].includes(mainVm.state) && mainVm.application\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-show=\"mainVm.application.heading\"\r" +
    "\n" +
    "                ng-bind-html=\"mainVm.application.heading\">    \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-show=\"mainVm.application.subHeading\"\r" +
    "\n" +
    "                ng-bind-html=\"mainVm.application.subHeading\">    \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span ng-show=\"mainVm.application.bondType\">\r" +
    "\n" +
    "                <b>{{mainVm.application.bondType}}<span ng-show=\"!!mainVm.application.bondAmount\">:</span> </b>\r" +
    "\n" +
    "                {{mainVm.application.bondAmount | currency:'$':0}}\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"mainVm.application.applicationNumber\">\r" +
    "\n" +
    "                Application #{{mainVm.application.applicationNumber}}\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"!mainVm.application.applicationNumber && mainVm.currentUser\">\r" +
    "\n" +
    "                <i>New Application</i>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"!mainVm.currentUser\">\r" +
    "\n" +
    "                Already have a login? <a ng-click=\"vm.showLoginModal = true\">Sign In</a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"my-auto ml-auto flex\">\r" +
    "\n" +
    "        <system-account-logo\r" +
    "\n" +
    "            class=\"block w-11 h-11 mr-1\"\r" +
    "\n" +
    "            ng-if=\"mainVm.systemAccount\"\r" +
    "\n" +
    "            system-account-id=\"mainVm.branding.systemAccountId\"\r" +
    "\n" +
    "            eproducer-account-id=\"mainVm.branding.eProducerAccountId\">\r" +
    "\n" +
    "        </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div>\r" +
    "\n" +
    "            <span>{{mainVm.branding.companyName}}</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"hidden md:block\">\r" +
    "\n" +
    "                <b>Need Help?</b> Call us at <a>{{mainVm.branding.phone}}</a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"md:hidden\">\r" +
    "\n" +
    "                <a>{{mainVm.branding.phone}}</a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <login-modal\r" +
    "\n" +
    "        is-open=\"vm.showLoginModal\"\r" +
    "\n" +
    "        on-login=\"vm.showLoginModal = false\">\r" +
    "\n" +
    "    </login-modal>\r" +
    "\n" +
    "</header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<navigation></navigation>"
  );


  $templateCache.put('app/components/inlineAlert/inlineAlert.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.successMessage && vm.error.errorDisplayType != 'inline'\"\r" +
    "\n" +
    "    class=\"p-3 rounded border border-solid border-lime-900 text-lime-900 bg-lime-50\"\r" +
    "\n" +
    "    ng-class=\"vm.wrapperClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <strong>{{vm.successMessage.heading}}</strong>\r" +
    "\n" +
    "    <div class=\"vm.successMessage.message\">{{vm.successMessage.message}}</div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.error.errorDisplayType == 'inline'\"\r" +
    "\n" +
    "    class=\"p-3 rounded border border-solid border-red-800 text-red-800 bg-red-50 shake\"\r" +
    "\n" +
    "    ng-class=\"vm.wrapperClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <strong>{{vm.error.heading}}</strong>\r" +
    "\n" +
    "    <div class=\"vm.error.message\">{{vm.error.message}}</div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/loginForm/loginForm.html',
    "<form\r" +
    "\n" +
    "    ng-show=\"!vm.requestPasswordReset\"\r" +
    "\n" +
    "    name=\"loginForm\"\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    ng-submit=\"vm.loginClick(loginForm)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <inline-alert\r" +
    "\n" +
    "        error=\"vm.error\"\r" +
    "\n" +
    "        class=\"mb-4 block\">\r" +
    "\n" +
    "    </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-field\r" +
    "\n" +
    "        placeholder=\"Email Address\"\r" +
    "\n" +
    "        name=\"email\"\r" +
    "\n" +
    "        model=\"vm.email\"\r" +
    "\n" +
    "        type=\"email\"\r" +
    "\n" +
    "        is-required=\"true\">\r" +
    "\n" +
    "    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-field\r" +
    "\n" +
    "        placeholder=\"Password\"\r" +
    "\n" +
    "        name=\"password\"\r" +
    "\n" +
    "        type=\"password\"\r" +
    "\n" +
    "        model=\"vm.password\"\r" +
    "\n" +
    "        is-required=\"true\">\r" +
    "\n" +
    "    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        class=\"button mt-4 w-full\"\r" +
    "\n" +
    "        type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Login\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-2 flex\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            ng-click=\"vm.showForgotPassword()\"\r" +
    "\n" +
    "            class=\"ml-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Forgot Password?\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-2 text-sm\">\r" +
    "\n" +
    "        <i class=\"fa fa-lock text-eproducer-orange\"></i> Your information is always secure.\r" +
    "\n" +
    "        <br />\r" +
    "\n" +
    "        <b>\r" +
    "\n" +
    "            By using this tool, you agree to the \r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                target=\"termsAndConditions\" \r" +
    "\n" +
    "                ui-sref=\"main.termsAndConditions\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Terms &amp; Conditions\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form\r" +
    "\n" +
    "    ng-show=\"vm.requestPasswordReset\"\r" +
    "\n" +
    "    name=\"forgotPasswordForm\"\r" +
    "\n" +
    "    ng-submit=\"vm.passwordResetRequestClick()\"\r" +
    "\n" +
    "    novalidate>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <inline-alert\r" +
    "\n" +
    "        success-message=\"vm.successMessage\"\r" +
    "\n" +
    "        error=\"vm.error\"\r" +
    "\n" +
    "        class=\"mb-4 block\">\r" +
    "\n" +
    "    </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-field\r" +
    "\n" +
    "        placeholder=\"Email Address\"\r" +
    "\n" +
    "        name=\"email\"\r" +
    "\n" +
    "        model=\"vm.email\"\r" +
    "\n" +
    "        type=\"email\"\r" +
    "\n" +
    "        is-required=\"true\">\r" +
    "\n" +
    "    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        class=\"button mt-4 w-full\"\r" +
    "\n" +
    "        type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Send Password Reset Email\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-2 flex\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"ml-auto\"\r" +
    "\n" +
    "            ng-click=\"vm.showLogin()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Back to Login\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-4 text-sm\">\r" +
    "\n" +
    "        <i class=\"fa fa-lock text-eproducer-orange\"></i> Your information is always secure.\r" +
    "\n" +
    "        <br />\r" +
    "\n" +
    "        <b>\r" +
    "\n" +
    "            By using this tool, you agree to the \r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <a \r" +
    "\n" +
    "                target=\"termsAndConditions\" \r" +
    "\n" +
    "                ui-sref=\"main.termsAndConditions\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Terms &amp; Conditions\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </b>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/components/loginModal/loginModal.html',
    "<modal is-open=\"vm.isOpen\">\r" +
    "\n" +
    "    <modal-header>\r" +
    "\n" +
    "        <span ng-show=\"!vm.requestPasswordReset\">Login</span>\r" +
    "\n" +
    "        <span ng-show=\"vm.requestPasswordReset\">Forgot Password</span>\r" +
    "\n" +
    "    </modal-header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <modal-content>\r" +
    "\n" +
    "        <login-form\r" +
    "\n" +
    "            on-login=\"vm.onLogin()\"\r" +
    "\n" +
    "            on-back-to-login-click=\"vm.requestPasswordReset = false\"\r" +
    "\n" +
    "            on-forgot-password-click=\"vm.requestPasswordReset = true\">\r" +
    "\n" +
    "        </login-form>\r" +
    "\n" +
    "    </modal-content>\r" +
    "\n" +
    "</modal>"
  );


  $templateCache.put('app/components/modal/modal.html',
    "<div ng-if=\"vm.isOpen\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"max-w-[900px] m-auto drop-shadow-lg rounded z-[51] fixed top-24 left-5 right-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <header class=\"bg-slate-100 text-slate-800 rounded-t p-4 border-b border-solid border-b-slate-300 min-h-11 flex relative\">\r" +
    "\n" +
    "            <h4 \r" +
    "\n" +
    "                class=\"my-auto\"\r" +
    "\n" +
    "                ng-transclude=\"modalHeader\">\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                ng-click=\"vm.handleClose($event)\" \r" +
    "\n" +
    "                class=\"absolute right-0 top-[-5px] p-2 bg-transparent text-slate-400 hover:text-slate-800\"\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                ng-hide=\"vm.hideCloseButton\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"fa fa-times\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            class=\"p-5 rounded-b bg-white\"\r" +
    "\n" +
    "            ng-transclude=\"modalContent\">\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"fixed top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-40\"></div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/navigation/navigation.html',
    "<div ng-show=\"vm.showNav\">\r" +
    "\n" +
    "    <div class=\"hidden border-b border-b-solid border-b-slate-300 bg-slate-200 sm:text-sm min-[830px]:flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"p-3 inline-block text-slate-600 hover:bg-eproducer-blue hover:text-white\"\r" +
    "\n" +
    "            ui-sref=\"main.application\"\r" +
    "\n" +
    "            ng-show=\"vm.isAuthorized_portal\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"icon-plus\"></i>\r" +
    "\n" +
    "            New Bond\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"ml-auto\">\r" +
    "\n" +
    "            <navigation-item\r" +
    "\n" +
    "                text=\"Applications\"\r" +
    "\n" +
    "                to-state=\"main.openApplications\"\r" +
    "\n" +
    "                is-visible=\"vm.isAuthorized_openApplications\">\r" +
    "\n" +
    "            </navigation-item><navigation-item\r" +
    "\n" +
    "                text=\"Bonds\"\r" +
    "\n" +
    "                to-state=\"main.purchasedBonds\"\r" +
    "\n" +
    "                is-visible=\"vm.isAuthorized_purchasedBonds\">\r" +
    "\n" +
    "            </navigation-item><navigation-item\r" +
    "\n" +
    "                text=\"Document Share\"\r" +
    "\n" +
    "                to-state=\"main.documents\">\r" +
    "\n" +
    "            </navigation-item><navigation-item\r" +
    "\n" +
    "                text=\"Our Company\"\r" +
    "\n" +
    "                to-state=\"main.agencyProfile\"\r" +
    "\n" +
    "                is-visible=\"vm.isAuthorized_agencyProfile\">\r" +
    "\n" +
    "            </navigation-item><navigation-item\r" +
    "\n" +
    "                text=\"Users\"\r" +
    "\n" +
    "                to-state=\"main.usersGrid\"\r" +
    "\n" +
    "                is-visible=\"vm.isAuthorized_usersGrid\">\r" +
    "\n" +
    "            </navigation-item><navigation-item\r" +
    "\n" +
    "                text=\"Payment\"\r" +
    "\n" +
    "                to-state=\"main.paymentForm\">\r" +
    "\n" +
    "            </navigation-item><navigation-item\r" +
    "\n" +
    "                text=\"Logout\"\r" +
    "\n" +
    "                on-click=\"vm.logout()\">\r" +
    "\n" +
    "            </navigation-item>       \r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/navigation/navigationItem.html',
    "<a\r" +
    "\n" +
    "    class=\"py-3 px-5 inline-block border-l border-slate-100 hover:bg-eproducer-blue hover:text-white\"\r" +
    "\n" +
    "    ng-click=\"vm.handleClick()\"\r" +
    "\n" +
    "    ng-if=\"vm.isVisible\"\r" +
    "\n" +
    "    ng-class=\"{\r" +
    "\n" +
    "        'bg-eproducer-blue text-white': vm.toState === vm.currentState,\r" +
    "\n" +
    "        'text-slate-600': vm.toState !== vm.currentState,\r" +
    "\n" +
    "    }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    {{vm.text}}\r" +
    "\n" +
    "</a>"
  );


  $templateCache.put('app/components/newPassword/newPassword.html',
    "<text-field\r" +
    "\n" +
    "    name=\"password\"\r" +
    "\n" +
    "    model=\"vm.model\"\r" +
    "\n" +
    "    label=\"Password\"\r" +
    "\n" +
    "    type=\"password\"\r" +
    "\n" +
    "    pattern=\"vm.passwordPattern\"\r" +
    "\n" +
    "    pattern-message=\"Must meet requirements below\"\r" +
    "\n" +
    "    is-required=\"vm.isRequired\"\r" +
    "\n" +
    "    model-options=\"{updateOn: 'default', allowInvalid: true}\"\r" +
    "\n" +
    "    on-change=\"vm.handleChange()\">\r" +
    "\n" +
    "</text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<text-field\r" +
    "\n" +
    "    name=\"confirmPassword\"\r" +
    "\n" +
    "    model=\"vm.confirmPassword\"\r" +
    "\n" +
    "    label=\"Confirm Password\"\r" +
    "\n" +
    "    type=\"password\"\r" +
    "\n" +
    "    is-required=\"vm.isRequired\"\r" +
    "\n" +
    "    is-disabled=\"!vm.model\"\r" +
    "\n" +
    "    matches=\"vm.model\"\r" +
    "\n" +
    "    matches-message=\"Passwords do not match\"\r" +
    "\n" +
    "    on-change=\"vm.handleChange()\">\r" +
    "\n" +
    "</text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<password-requirement-indicator password=\"vm.model\"></password-requirement-indicator>"
  );


  $templateCache.put('app/components/pageSlide/pageSlide.html',
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"flex absolute top-0 bottom-0 z-[31] ease-in-out duration-500 bg-transparent\"\r" +
    "\n" +
    "    ng-style=\"{\r" +
    "\n" +
    "        'right': vm.slideFrom == 'right' ? '-' + vm.width : 'unset',\r" +
    "\n" +
    "        'left': vm.slideFrom == 'left' ? '-' + vm.width : 'unset',\r" +
    "\n" +
    "        'transform': vm.isOpen ? (vm.slideFrom == 'right' ? 'translateX(-' + vm.width + ')' : 'translateX(' + vm.width + ')') : 'unset'\r" +
    "\n" +
    "    }\" \r" +
    "\n" +
    "    ng-form=\"quoteFilterForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ng-if=\"vm.slideFrom == 'right'\"\r" +
    "\n" +
    "        class=\"bg-eproducer-blue text-white rounded-bl w-11 h-11 text-lg p-3 flex mb-auto\"\r" +
    "\n" +
    "        ng-click=\"vm.onClose()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"icon-close my-auto\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"bg-white py-3 px-5\"\r" +
    "\n" +
    "        ng-style=\"{'width': 'calc(' + vm.width + ' - 2.75rem)'}\"\r" +
    "\n" +
    "        ng-transclude>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <button\r" +
    "\n" +
    "        ng-if=\"vm.slideFrom == 'left'\"\r" +
    "\n" +
    "        class=\"bg-eproducer-blue text-white rounded-bl w-11 h-11 text-lg p-3 flex mb-auto\"\r" +
    "\n" +
    "        ng-click=\"vm.onClose()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"icon-close my-auto\"></i>\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.isOpen\"\r" +
    "\n" +
    "    ng-click=\"vm.onClose()\"\r" +
    "\n" +
    "    class=\"fade-40 z-30 bg-black fixed top-0 right-0 bottom-0 left-0\">\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/passwordRequirementIndicator/passwordRequirementIndicator.html',
    "<password-requirement-indicator-item\r" +
    "\n" +
    "    message=\"Contains at least one lowercase letter\"\r" +
    "\n" +
    "    is-valid=\"vm.hasLowerCase()\">\r" +
    "\n" +
    "</password-requirement-indicator-item>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<password-requirement-indicator-item\r" +
    "\n" +
    "    message=\"Contains at least one uppercase letter\"\r" +
    "\n" +
    "    is-valid=\"vm.hasUpperCase()\">\r" +
    "\n" +
    "</password-requirement-indicator-item>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<password-requirement-indicator-item\r" +
    "\n" +
    "    message=\"Contains at least one number\"\r" +
    "\n" +
    "    is-valid=\"vm.hasNumber()\">\r" +
    "\n" +
    "</password-requirement-indicator-item>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<password-requirement-indicator-item\r" +
    "\n" +
    "    message=\"At least 8 characters long\"\r" +
    "\n" +
    "    is-valid=\"vm.hasMinLength()\">\r" +
    "\n" +
    "</password-requirement-indicator-item>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<password-requirement-indicator-item\r" +
    "\n" +
    "    message=\"Contains only letters, numbers, and allowed special characters (!@#$%^&*()_-+=)\"\r" +
    "\n" +
    "    is-valid=\"vm.hasValidCharacters()\">\r" +
    "\n" +
    "</password-requirement-indicator-item>\r" +
    "\n"
  );


  $templateCache.put('app/components/passwordRequirementIndicator/passwordRequirementIndicatorItem.html',
    "<div ng-class=\"{ \r" +
    "\n" +
    "        'text-slate-900': vm.isValid(), \r" +
    "\n" +
    "        'text-slate-500': !vm.isValid() \r" +
    "\n" +
    "    }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i \r" +
    "\n" +
    "        class=\"icon-check font-bold w-15 text-eproducer-green text-center\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'inline': vm.isValid(),\r" +
    "\n" +
    "            'hidden': !vm.isValid()\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i \r" +
    "\n" +
    "        class=\"fa fa-times font-bold w-15 text-red-800 text-center\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'inline': !vm.isValid(),\r" +
    "\n" +
    "            'hidden': vm.isValid()\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    {{vm.message}}\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/pdfDownload/pdfDownload.html',
    "<button\r" +
    "\n" +
    "    ng-click=\"vm.downloadPdf()\"\r" +
    "\n" +
    "    class=\"button {{vm.buttonClass}}\"\r" +
    "\n" +
    "    ng-disabled=\"vm.downloading\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span ng-if=\"vm.showIcon\">\r" +
    "\n" +
    "        <i class=\"fa fa-download\"></i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"hidden-xs hidden-sm hidden-md\"\r" +
    "\n" +
    "            ng-bind=\"vm.buttonText\">\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span\r" +
    "\n" +
    "        ng-if=\"!vm.showIcon\"\r" +
    "\n" +
    "        ng-bind=\"vm.buttonText\">\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</button>"
  );


  $templateCache.put('app/components/productSelector/productSelector.html',
    "<div ng-class=\"vm.orientation\" class=\"product-selector\">\r" +
    "\n" +
    "    <form name=\"bondTypeSelectionForm\" novalidate ng-submit=\"vm.redirectToApplication(bondTypeSelectionForm.$invalid)\">\r" +
    "\n" +
    "        <!-- State -->\r" +
    "\n" +
    "        <application-field model=\"vm.selection.state\" form=\"bondTypeSelectionForm\" field=\"{type:'state', label:'State Requiring Bond', isRequired: true}\" change=\"vm.stateSelectionChanged()\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- Bond Type -->\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondTypeId\" form=\"bondTypeSelectionForm\" field=\"vm.bondTypeField\" change=\"vm.bondTypeSelectionChanged()\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- Bond Amount -->\r" +
    "\n" +
    "        <application-field model=\"vm.selection.spacer\" form=\"bondTypeSelectionForm\" field=\"{label: 'Bond Amount', isDisabled: true, type:'text' }\" ng-show=\"vm.bondAmountType === 'spacer'\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondAmount\" form=\"bondTypeSelectionForm\" field=\"{label: 'Bond Amount', type: 'money', isRequired: true}\" ng-if=\"vm.bondAmountType === 'userdefined'\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondAmount\" form=\"bondTypeSelectionForm\" ng-if=\"vm.bondAmountType === 'variable'\" field=\"vm.variableBondAmountField\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondAmount\" form=\"bondTypeSelectionForm\" field=\"{type: 'money', label: 'Bond Amount', isDisabled: true, isRequired: true}\" ng-if=\"vm.bondAmountType === 'fixed'\" ng-cloak></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button type=\"submit\">Build Quotes</button>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"clear-fix\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p class=\"small\">\r" +
    "\n" +
    "        <b class=\"right-margin\">Already have an account? <a ng-click=\"vm.redirectToLogin()\">Login</a></b>\r" +
    "\n" +
    "        <span class=\"right-margin\">By using this tool you agree to the <a ng-click=\"vm.redirectToTermsAndConditions()\">Terms & Conditions</a></span>\r" +
    "\n" +
    "        <span class=\"right-margin-big\"><i class=\"fa fa-lock orange\"></i> Your information is secure</span>\r" +
    "\n" +
    "        <i class=\"a3-logo\"></i>\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/productSelector/searchableProductSelector.html',
    "<div ng-class=\"vm.orientation\" class=\"product-selector\">\r" +
    "\n" +
    "    <form name=\"bondTypeSelectionForm\" novalidate ng-submit=\"vm.redirectToApplication(bondTypeSelectionForm.$invalid)\">\r" +
    "\n" +
    "        <!-- State -->\r" +
    "\n" +
    "        <application-field model=\"vm.selection.state\" form=\"bondTypeSelectionForm\" field=\"{type:'state', label:'State Requiring Bond', isRequired: true}\" change=\"vm.stateSelectionChanged()\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- Bond Type -->\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field ng-if=\"vm.bondTypeField\" model=\"vm.selection.bondTypeId\" form=\"bondTypeSelectionForm\" field=\"vm.bondTypeField\" change=\"vm.bondTypeSelectionChanged()\"></application-field>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <!-- Bond Amount -->\r" +
    "\n" +
    "        <application-field model=\"vm.selection.spacer\" form=\"bondTypeSelectionForm\" field=\"{label: 'Bond Amount', isDisabled: true, type:'' }\" ng-if=\"vm.bondAmountType === 'spacer'\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondAmount\" form=\"bondTypeSelectionForm\" field=\"{label: 'Bond Amount', type: 'money', isRequired: true}\" ng-if=\"vm.bondAmountType === 'userdefined'\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondAmount\" form=\"bondTypeSelectionForm\" ng-if=\"vm.bondAmountType === 'variable'\" field=\"vm.variableBondAmountField\"></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <application-field model=\"vm.selection.bondAmount\" form=\"bondTypeSelectionForm\" field=\"{type: 'money', label: 'Bond Amount', isDisabled: true, isRequired: true}\" ng-if=\"vm.bondAmountType === 'fixed'\" ng-cloak></application-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button type=\"submit\">Build Quotes</button>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"clear-fix\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p class=\"small\">\r" +
    "\n" +
    "        <b class=\"right-margin\">Already have an account? <a ng-click=\"vm.redirectToLogin()\">Login</a></b>\r" +
    "\n" +
    "        <span class=\"right-margin\">By using this tool you agree to the <a ng-click=\"vm.redirectToTermsAndConditions()\">Terms & Conditions</a></span>\r" +
    "\n" +
    "        <span class=\"right-margin-big\"><i class=\"fa fa-lock orange\"></i> Your information is secure</span>\r" +
    "\n" +
    "        <i class=\"a3-logo\"></i>\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/productType/productType.html',
    "<span\r" +
    "\n" +
    "    ng-if=\"vm.isPolicy && vm.capitalizeFirstLetter\"\r" +
    "\n" +
    "    class=\"vm.cssClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    Policy\r" +
    "\n" +
    "</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<span\r" +
    "\n" +
    "    ng-if=\"vm.isPolicy && !vm.capitalizeFirstLetter\"\r" +
    "\n" +
    "    class=\"vm.cssClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    policy\r" +
    "\n" +
    "</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<span\r" +
    "\n" +
    "    ng-if=\"!vm.isPolicy && vm.capitalizeFirstLetter\"\r" +
    "\n" +
    "    class=\"vm.cssClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    Bond\r" +
    "\n" +
    "</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<span\r" +
    "\n" +
    "    ng-if=\"!vm.isPolicy && !vm.capitalizeFirstLetter\"\r" +
    "\n" +
    "    class=\"vm.cssClass\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    bond\r" +
    "\n" +
    "</span>\r" +
    "\n"
  );


  $templateCache.put('app/components/progressBar/progressBar.html',
    "<div class=\"h-2 bg-slate-100 rounded\">\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"h-2 bg-eproducer-blue rounded transition-all ease-linear duration-700\"\r" +
    "\n" +
    "        ng-style=\"{'width': vm.progress + '%'}\">\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/quote/quote.html',
    "<div\r" +
    "\n" +
    "    class=\"card\"\r" +
    "\n" +
    "    ng-class=\"{'relative border border-eproducer-orange mt-7 rounded-tl-none': vm.quote.isSponsored }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        ng-show=\"vm.quote.isSponsored\"\r" +
    "\n" +
    "        class=\"w-36 h-5 rounded-t bg-[url(../img/tab-perferred-carrier.png)] bg-eproducer-orange text-white text-center absolute text-sm bg-no-repeat left-[-1px] top-[-20px] rounded-none\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"icon-star\"></i> \r" +
    "\n" +
    "        <span class=\"hidden sm:inline\">Preferred Carrier</span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"grid grid-cols-12 text-center\">\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            class=\"col-span-4 my-auto text-sm\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'md:col-span-2': vm.isPurchasable,\r" +
    "\n" +
    "                'md:col-span-3': !vm.isPurchasable\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <system-account-logo\r" +
    "\n" +
    "                system-account-id=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                class=\"block mx-auto my-auto mb-1 h-11 w-11\"\r" +
    "\n" +
    "                variant=\"small\">\r" +
    "\n" +
    "            </system-account-logo>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{vm.quote.carrierName}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"col-span-4 md:col-span-2 my-auto\">\r" +
    "\n" +
    "            {{vm.quote.term}} year<span ng-show=\"vm.quote.term > 1\">s</span>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"md:col-span-3 hidden md:block text-lg my-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i\r" +
    "\n" +
    "                class=\"icon-credit-check\"\r" +
    "\n" +
    "                ng-class=\"{'text-slate-300': !vm.quote.requiresCredit }\"\r" +
    "\n" +
    "                ng-attrs-title=\"{{vm.quote.requiresCredit ? 'Credit Check Required' : 'No Credit Check Required'}}\">\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i\r" +
    "\n" +
    "                class=\"icon-indemnity\"\r" +
    "\n" +
    "                ng-class=\"{'text-slate-300': vm.quote.indemnityRequirements === 'None'}\"\r" +
    "\n" +
    "                ng-attrs-title=\"{{vm.quote.indemnityRequirements ? 'Indemnity Required' : 'No Indemnity Required'}}\">\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i\r" +
    "\n" +
    "                class=\"icon-review\"\r" +
    "\n" +
    "                ng-class=\"{'text-slate-300': !vm.quote.requiresManualReview }\"\r" +
    "\n" +
    "                ng-attrs-title=\"{{vm.quote.requiresManualReview ? 'Subject to Final Review' : 'No Review Required'}}\">\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i\r" +
    "\n" +
    "                class=\"icon-documents\"\r" +
    "\n" +
    "                ng-class=\"{'text-slate-300': !vm.quote.requiresAdditionalDocuments }\"\r" +
    "\n" +
    "                ng-attrs-title=\"{{vm.quote.requiresAdditionalDocuments ? 'Documentation Required' : 'No Additional Documentation Required'}}\">\r" +
    "\n" +
    "            </i>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"col-span-3 my-auto\">\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-show=\"!vm.isPurchasable\"\r" +
    "\n" +
    "                class=\"text-sm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                As low as\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <b>{{ vm.quote.totalAmountDue | currency:'$':2 }}</b>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-if=\"vm.isPurchasable\"\r" +
    "\n" +
    "            class=\"hidden md:flex my-auto md:col-span-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"button bg-eproducer-green ml-auto\"\r" +
    "\n" +
    "                ng-click=\"vm.purchaseQuote(vm.quote)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Purchase\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <hr class=\"mt-4 mb-6 hidden md:block\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-4\">\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ng-show=\"vm.isPurchasable\"\r" +
    "\n" +
    "            class=\"button bg-eproducer-green md:hidden mt-2 w-full mb-4\"\r" +
    "\n" +
    "            ng-click=\"vm.purchaseQuote(vm.quote)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Purchase\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"md:flex justify-between gap-4 md:px-4\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <b>Estimate Details</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-sm\">\r" +
    "\n" +
    "                    <em>Term:</em> {{vm.quote.term}} year<span ng-show=\"vm.quote.term > 1\">s</span>\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <em>Premium:</em> {{vm.quote.totalPremium | currency:'$':2}}\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <em>Fees:</em> {{vm.totalFees | currency:'$':2}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"mt-4 md:mt-0\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <b>Terms & Conditions</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-sm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"icon-credit-check\"\r" +
    "\n" +
    "                        ng-class=\"{ on: vm.quote.requiresCredit }\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Credit Check\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <em>\r" +
    "\n" +
    "                        <span ng-show=\"!vm.quote.requiresCredit\">Not </span>\r" +
    "\n" +
    "                        Required\r" +
    "\n" +
    "                    </em>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"icon-indemnity\"\r" +
    "\n" +
    "                        ng-class=\"{ on: vm.quote.indemnityRequirements !== 'None' }\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Indemnity Required:\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <em ng-show=\"vm.quote.indemnityRequirements === 'None'\">None</em>\r" +
    "\n" +
    "                    <em ng-show=\"vm.quote.indemnityRequirements === 'ApplicantOnly'\">Applicant/Business Owners</em>\r" +
    "\n" +
    "                    <em ng-show=\"vm.quote.indemnityRequirements === 'ApplicantSpouse'\">Applicant/Business Owners &amp; Spouses</em>\r" +
    "\n" +
    "                    <em ng-show=\"vm.quote.indemnityRequirements === 'ApplicantSpouseCorporate'\">Applicant/Business Owners, Spouses &amp; One Corporate Signature </em>\r" +
    "\n" +
    "                    <em ng-show=\"vm.quote.indemnityRequirements === 'ApplicantCorporate'\">Applicant/Business Owners &amp; One Corporate</em>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"icon-review\"\r" +
    "\n" +
    "                        ng-class=\"{ on: vm.quote.requiresManualReview }\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <em>\r" +
    "\n" +
    "                        <span ng-show=\"!vm.quote.requiresManualReview\">Not </span>\r" +
    "\n" +
    "                        Subject\r" +
    "\n" +
    "                    </em>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    to Final Review\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <i\r" +
    "\n" +
    "                        class=\"icon-documents\"\r" +
    "\n" +
    "                        ng-class=\"{ on: vm.quote.requiresAdditionalDocuments }\">\r" +
    "\n" +
    "                    </i>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <em>\r" +
    "\n" +
    "                        <span ng-show=\"!vm.quote.requiresAdditionalDocuments\">No </span>\r" +
    "\n" +
    "                        Additional\r" +
    "\n" +
    "                    </em>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Docs Required\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"mt-4 md:mt-0\">            \r" +
    "\n" +
    "                <div class=\"my-1\">\r" +
    "\n" +
    "                    <b>Why {{vm.quote.carrierName}}?</b>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"my-1 text-sm\">\r" +
    "\n" +
    "                    {{vm.quote.carrierDescription}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"my-1 text-sm\">\r" +
    "\n" +
    "                    AM Best Rating: {{vm.quote.carrierAmBestRating}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"my-2 text-sm\" \r" +
    "\n" +
    "                    ng-show=\"vm.quote.highlightComments\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b>Comments:</b>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"my-2 text-sm\">{{vm.quote.highlightComments}}</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/quoteAccuracyIndicator/quoteAccuracyIndicator.html',
    "<div class=\"hidden sm:block px-3\">\r" +
    "\n" +
    "    Estimated Accuracy:\r" +
    "\n" +
    "    <b ng-show=\"vm.strengthValue < 2\">Low</b>\r" +
    "\n" +
    "    <b ng-show=\"vm.strengthValue == 2\">Medium</b>\r" +
    "\n" +
    "    <b ng-show=\"vm.strengthValue == 3\">High</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span class=\"inline-block py-1\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            class=\"w-1 h-2 ml-1 inline-block rounded\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'bg-slate-400': !vm.strengthValue,\r" +
    "\n" +
    "                'bg-eproducer-blue': vm.strengthValue >= 1\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            class=\"w-1 h-3 inline-block rounded\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'bg-slate-400': vm.strengthValue < 2,\r" +
    "\n" +
    "                'bg-eproducer-blue': vm.strengthValue >= 2\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            class=\"w-1 h-4 inline-block rounded\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'bg-slate-400': vm.strengthValue < 3,\r" +
    "\n" +
    "                'bg-eproducer-blue': vm.strengthValue >= 3\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/components/quoteList/quoteList.html',
    "<div>\r" +
    "\n" +
    "    <quote\r" +
    "\n" +
    "        ng-repeat=\"quote in vm.filteredQuotes\"\r" +
    "\n" +
    "        quote=\"quote\"\r" +
    "\n" +
    "        is-policy=\"vm.isPolicy\"\r" +
    "\n" +
    "        is-purchasable=\"vm.isPurchasable\"\r" +
    "\n" +
    "        class=\"block mb-6\">\r" +
    "\n" +
    "    </quote>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"text-center mt-8\"\r" +
    "\n" +
    "        ng-show=\"!vm.filteredQuotes.length && vm.filterCount\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p class=\"text-xl\">\r" +
    "\n" +
    "            There aren't any quotes matching the filters you've selected.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"button bg-slate-200 text-slate-700 mt-2\"\r" +
    "\n" +
    "            ng-click=\"vm.resetFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Remove filters\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<page-slide \r" +
    "\n" +
    "    is-open=\"vm.filtersVisible\"\r" +
    "\n" +
    "    on-close=\"vm.filtersVisible = false\"\r" +
    "\n" +
    "    slide-from=\"right\"\r" +
    "\n" +
    "    width=\"20rem\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2 class=\"mb-4\">Filter By</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <span class=\"my-auto\">Term</span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select-field\r" +
    "\n" +
    "            name=\"term\"\r" +
    "\n" +
    "            model=\"vm.filters.term\"\r" +
    "\n" +
    "            on-change=\"vm.applyQuoteFilters()\"\r" +
    "\n" +
    "            class=\"flex-1 my-auto ml-1\"\r" +
    "\n" +
    "            unselected-label=\"Any Term\"\r" +
    "\n" +
    "            options=\"[\r" +
    "\n" +
    "                {label: '1 year', value: '1'},\r" +
    "\n" +
    "                {label: '2 year', value: '2'},\r" +
    "\n" +
    "                {label: '3 year', value: '3'},\r" +
    "\n" +
    "                {label: '4 year', value: '4'},\r" +
    "\n" +
    "                {label: '5 years or more', value: '5'}\r" +
    "\n" +
    "            ]\">\r" +
    "\n" +
    "        </select-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2 mt-4 flex\">\r" +
    "\n" +
    "        <i class=\"icon-credit-check my-auto mr-1\"></i> \r" +
    "\n" +
    "        <span class=\"my-auto\">Credit Check Required</span>\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-field\r" +
    "\n" +
    "        name=\"noCredit\"\r" +
    "\n" +
    "        model=\"vm.filters.noCredit\"\r" +
    "\n" +
    "        label=\"Quotes without credit check\"\r" +
    "\n" +
    "        on-change=\"vm.applyQuoteFilters()\">\r" +
    "\n" +
    "    </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2 mt-4 flex\">\r" +
    "\n" +
    "        <i class=\"icon-indemnity my-auto mr-1\"></i>\r" +
    "\n" +
    "        <span class=\"my-auto\">Indemnity Required</span>\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-field\r" +
    "\n" +
    "        name=\"noIndemnity\"\r" +
    "\n" +
    "        model=\"vm.filters.noIndemnity\"\r" +
    "\n" +
    "        label=\"No indemnity at all\"\r" +
    "\n" +
    "        on-change=\"vm.applyQuoteFilters()\">\r" +
    "\n" +
    "    </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-field\r" +
    "\n" +
    "        name=\"noSpouseIndemnity\"\r" +
    "\n" +
    "        model=\"vm.filters.noSpouseIndemnity\"\r" +
    "\n" +
    "        label=\"No spouse indemnity\"\r" +
    "\n" +
    "        on-change=\"vm.applyQuoteFilters()\">\r" +
    "\n" +
    "    </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-field\r" +
    "\n" +
    "        name=\"noCorporateIndemnity\"\r" +
    "\n" +
    "        model=\"vm.filters.noCorporateIndemnity\"\r" +
    "\n" +
    "        label=\"No corporate indemnity\"\r" +
    "\n" +
    "        on-change=\"vm.applyQuoteFilters()\">\r" +
    "\n" +
    "    </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2 mt-4 flex\">\r" +
    "\n" +
    "        <i class=\"icon-review my-auto mr-1\"></i> \r" +
    "\n" +
    "        <span class=\"my-auto\">Subject to Final Review</span>\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-field\r" +
    "\n" +
    "        name=\"noReview\"\r" +
    "\n" +
    "        model=\"vm.filters.noReview\"\r" +
    "\n" +
    "        label=\"Does not require review\"\r" +
    "\n" +
    "        on-change=\"vm.applyQuoteFilters()\">\r" +
    "\n" +
    "    </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2 mt-4 flex\">\r" +
    "\n" +
    "        <i class=\"icon-documents my-auto mr-1\"></i> \r" +
    "\n" +
    "        <span class=\"my-auto\">Documentation Required</span>\r" +
    "\n" +
    "    </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-field\r" +
    "\n" +
    "        name=\"noDocumuents\"\r" +
    "\n" +
    "        model=\"vm.filters.noDocuments\"\r" +
    "\n" +
    "        label=\"No documentation required\"\r" +
    "\n" +
    "        on-change=\"vm.applyQuoteFilters()\">\r" +
    "\n" +
    "    </check-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <button \r" +
    "\n" +
    "        ng-show=\"vm.filterCount\"\r" +
    "\n" +
    "        class=\"mt-6 button bg-slate-200 text-slate-700 w-full\"\r" +
    "\n" +
    "        ng-click=\"vm.resetFilters()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        Clear Filters\r" +
    "\n" +
    "    </button>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "</page-slide>"
  );


  $templateCache.put('app/components/referringEProducerBrokerSelection/referringEProducerBrokerSelection.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"!vm.broker\"\r" +
    "\n" +
    "    class=\"flex\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <radio-field\r" +
    "\n" +
    "        name=\"isBrokerReferralRadioValue\"\r" +
    "\n" +
    "        model=\"vm.isBrokerReferralRadioValue\"\r" +
    "\n" +
    "        on-change=\"vm.radioValueChanged()\"\r" +
    "\n" +
    "        label=\"Were you referred by another insurance agency or organization? This is NOT the party requiring the bond or policy.\"\r" +
    "\n" +
    "        is-required=\"vm.isRequired\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <radio-option value=\"true\">Yes</radio-option>\r" +
    "\n" +
    "        <radio-option value=\"false\">No</radio-option>\r" +
    "\n" +
    "    </radio-field>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    class=\"mt-2\" \r" +
    "\n" +
    "    ng-show=\"vm.isBrokerReferral && !vm.broker\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-field\r" +
    "\n" +
    "        name=\"brokerReferralSearchPhrase\"\r" +
    "\n" +
    "        on-change=\"vm.searchEProducerBrokers()\"\r" +
    "\n" +
    "        model=\"vm.searchPhrase\"\r" +
    "\n" +
    "        model-options=\"{debounce: 300, updateOn: 'default'}\"\r" +
    "\n" +
    "        label=\"Search for referring agency\"\r" +
    "\n" +
    "        placeholder=\"Search by name, lookup code or location\"\r" +
    "\n" +
    "        is-required=\"vm.isBrokerReferral && !vm.broker\">\r" +
    "\n" +
    "    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"text-center mt-2\"\r" +
    "\n" +
    "        ng-show=\"vm.isSearching\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-search\"></i>\r" +
    "\n" +
    "        <p>Searching...</p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"text-center mt-2 text-slate-700\"\r" +
    "\n" +
    "        ng-show=\"vm.searchPhrase && !vm.isSearching && !vm.matchingEProducerBrokers.length\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <i class=\"fa fa-question mt-2 text-3xl\"></i>\r" +
    "\n" +
    "        <p>We couldn't find any matches based on your search</p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-repeat=\"broker in vm.matchingEProducerBrokers\"\r" +
    "\n" +
    "        class=\"flex\"\r" +
    "\n" +
    "        ng-class=\"{'mt-4': $first, 'bg-slate-100': $alt}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex-1 mr-2 my-auto\">\r" +
    "\n" +
    "            <b>{{broker.name}}</b> <br />\r" +
    "\n" +
    "            {{broker.physicalAddress}} {{broker.physicalSuiteAptNumber}}<br />\r" +
    "\n" +
    "            {{broker.physicalCity}}, {{broker.physicalState}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"button my-auto\"\r" +
    "\n" +
    "            type=\"button\"\r" +
    "\n" +
    "            ng-click=\"vm.setSelection(broker)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Select\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-show=\"vm.isBrokerReferral && vm.broker\">\r" +
    "\n" +
    "    <div>\r" +
    "\n" +
    "        According to our records, this account is associated to the agent or referral source seen below.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <hr class=\"my-2\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"flex-1\">\r" +
    "\n" +
    "            {{vm.broker.name}}<br/>\r" +
    "\n" +
    "            {{vm.broker.physicalAddress}} <br/>\r" +
    "\n" +
    "            {{vm.broker.physicalCity}}, {{vm.broker.physicalState}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"ml-2 my-auto\"\r" +
    "\n" +
    "            ng-click=\"vm.clearSelection()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Change this\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<!--This input is only used for validation of the entire component.-->\r" +
    "\n" +
    "<input\r" +
    "\n" +
    "    name=\"brokerId\"\r" +
    "\n" +
    "    ng-model=\"vm.broker.id\"\r" +
    "\n" +
    "    ng-required=\"vm.isBrokerReferral !== false && vm.isRequired\"\r" +
    "\n" +
    "    class=\"hidden\"\r" +
    "\n" +
    "    name=\"referringEProducerBrokerSelectionValidity\" />\r" +
    "\n"
  );


  $templateCache.put('app/components/systemAccountLogo/systemAccountLogo.html',
    "<img\r" +
    "\n" +
    "    class=\"{{vm.imageClass}}\"\r" +
    "\n" +
    "    ng-src=\"{{vm.url}}\" />"
  );


  $templateCache.put('app/components/tablePager/tablePaginationFooter.html',
    "<div\r" +
    "\n" +
    "    ng-form=\"tablePaginationFooterForm\"\r" +
    "\n" +
    "    ng-show=\"vm.tablePager.isVisible\"\r" +
    "\n" +
    "    class=\"bg-gray-100 flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"w-4\">&nbsp;</div>\r" +
    "\n" +
    "    <div class=\"flex flex-1 p-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select-field\r" +
    "\n" +
    "            name=\"applicationListRecordsPerPage\"\r" +
    "\n" +
    "            label=\"per page\"\r" +
    "\n" +
    "            options=\"vm.tablePager.recordsPerPageOptions\"\r" +
    "\n" +
    "            model=\"vm.tablePager.recordsPerPage\"\r" +
    "\n" +
    "            on-change=\"vm.tablePager.recordsPerPageChanged()\"\r" +
    "\n" +
    "            hide-unselected-label=\"true\"\r" +
    "\n" +
    "            class=\"my-auto hidden md:inline-block\"\r" +
    "\n" +
    "            wrapper-class=\"flex\"\r" +
    "\n" +
    "            select-class=\"mb-0 w-auto mr-1 my-auto px-1.5 py-0\"\r" +
    "\n" +
    "            label-class=\"inline bg-transparent text-sm my-auto text-slate-900 static\"\r" +
    "\n" +
    "            hide-dropdown-indicator=\"true\">\r" +
    "\n" +
    "        </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span class=\"my-auto text-sm ml-auto mr-auto hidden md:inline\">\r" +
    "\n" +
    "            Page {{ vm.tablePager.currentPage }} of {{vm.tablePager.totalPages}}\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex my-auto mx-auto md:mx-0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                ng-disabled=\"vm.tablePager.currentPage == 1\"\r" +
    "\n" +
    "                ng-click=\"vm.tablePager.goToPreviousPage()\"\r" +
    "\n" +
    "                class=\"button-outline flex my-auto mr-3 py-1 px-2 text-sm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-left my-auto\"></i>\r" +
    "\n" +
    "                <span class=\"my-auto ml-1\">Prev</span>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"my-auto fill\">\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    ng-show=\"vm.tablePager.pageButtonOptions[0] != 1\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.tablePager.currentPage == 1\"\r" +
    "\n" +
    "                    ng-click=\"vm.tablePager.goToFirstPage()\"\r" +
    "\n" +
    "                    class=\"mx-1 text-center text-eproducer-blue px-1 rounded\"\r" +
    "\n" +
    "                    ng-class=\"{'text-white bg-eproducer-blue': 1 === vm.tablePager.currentPage}\">1</a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"![1,2].includes(vm.tablePager.pageButtonOptions[0])\">\r" +
    "\n" +
    "                    ...\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    ng-repeat=\"page in vm.tablePager.pageButtonOptions\"\r" +
    "\n" +
    "                    ng-click=\"vm.tablePager.goToPage(page)\"\r" +
    "\n" +
    "                    class=\"mx-1 text-center text-eproducer-blue px-1 rounded\"\r" +
    "\n" +
    "                    ng-class=\"{'text-white bg-eproducer-blue': page === vm.tablePager.currentPage }\">{{ page }}</a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span ng-show=\"vm.tablePager.pageButtonOptions[vm.tablePager.pageButtonOptions.length - 1] < vm.tablePager.totalPages - 1\">\r" +
    "\n" +
    "                    ...\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    ng-show=\"vm.tablePager.pageButtonOptions[vm.tablePager.pageButtonOptions.length - 1] < vm.tablePager.totalPages\"\r" +
    "\n" +
    "                    ng-disabled=\"vm.tablePager.currentPage == vm.tablePager.totalPages\"\r" +
    "\n" +
    "                    ng-click=\"vm.tablePager.goToLastPage()\"\r" +
    "\n" +
    "                    class=\"mx-1 text-center text-eproducer-blue px-1 rounded\"\r" +
    "\n" +
    "                    ng-class=\"{'text-white bg-eproducer-blue': vm.tablePager.totalPages === vm.tablePager.currentPage }\">{{ vm.tablePager.totalPages }}</a>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                type=\"button\"\r" +
    "\n" +
    "                class=\"button-outline flex my-auto ml-3 py-1 px-2 text-sm\"\r" +
    "\n" +
    "                ng-disabled=\"vm.tablePager.currentPage == vm.tablePager.totalPages\"\r" +
    "\n" +
    "                ng-click=\"vm.tablePager.goToNextPage()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"my-auto\">Next</span>\r" +
    "\n" +
    "                <i class=\"fa fa-chevron-right my-auto ml-1\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"w-4\">&nbsp;</div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/components/tableSorter/tableSortButton.html',
    "<button\r" +
    "\n" +
    "    ng-show=\"vm.sortExpression\"\r" +
    "\n" +
    "    ng-click=\"vm.tableSorter.sort(vm.sortExpression)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    {{vm.text}}\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <i\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'fa fa-caret-up' : vm.tableSorter.isSortedBy(vm.sortExpression),\r" +
    "\n" +
    "            'fa fa-caret-down': vm.tableSorter.isSortedByDesc(vm.sortExpression)\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "    </i>\r" +
    "\n" +
    "</button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<span ng-show=\"!vm.sortExpression\">\r" +
    "\n" +
    "    {{vm.text}}\r" +
    "\n" +
    "</span>"
  );


  $templateCache.put('app/components/timeRemaining/timeRemaining.html',
    "<div class=\"flex\">\r" +
    "\n" +
    "    <span class=\"w-8 my-auto inline-block h-[6px] mr-2 rounded bg-slate-400 overflow-hidden\">\r" +
    "\n" +
    "        <span \r" +
    "\n" +
    "            class=\"block my-auto bg-eproducer-blue rounded h-[6px]\"\r" +
    "\n" +
    "            ng-style=\"{width: vm.percent.toString() + '%'}\">\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <span class=\"my-auto\"> \r" +
    "\n" +
    "        <b>{{vm.minutesRemaining}}</b> mins remianing\r" +
    "\n" +
    "    </span>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/index.html',
    "<!DOCTYPE html>\r" +
    "\n" +
    "<html lang=\"en\">\r" +
    "\n" +
    "    <head>\r" +
    "\n" +
    "        <title>A3 by Agency Multiplied</title>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <meta charset=\"UTF-8\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <meta\r" +
    "\n" +
    "            content=\"IE=edge, chrome=1\"\r" +
    "\n" +
    "            http-equiv=\"X-UA-Compatible\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <meta\r" +
    "\n" +
    "            name=\"viewport\"\r" +
    "\n" +
    "            content=\"width=device-width, initial-scale=1\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/font-awesome.min.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\"\r" +
    "\n" +
    "            type=\"text/css\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/eproducer-font.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\"\r" +
    "\n" +
    "            type=\"text/css\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"css/eProducerTailwind.css\"\r" +
    "\n" +
    "            rel=\"stylesheet\"\r" +
    "\n" +
    "            type=\"text/css\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <script type=\"text/javascript\" src=\"scripts/jquery/jquery.min.js\"></script>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- Google Tag Manager -->\r" +
    "\n" +
    "        <script type=\"text/javascript\">\r" +
    "\n" +
    "            window.dataLayer = [];\r" +
    "\n" +
    "\r" +
    "\n" +
    "            // (function (w, d, s, l, i) {\r" +
    "\n" +
    "            //     w[l] = w[l] || []; w[l].push({\r" +
    "\n" +
    "            //         'gtm.start':\r" +
    "\n" +
    "            //             new Date().getTime(), event: 'gtm.js'\r" +
    "\n" +
    "            //     }); var f = d.getElementsByTagName(s)[0],\r" +
    "\n" +
    "            //         j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =\r" +
    "\n" +
    "            //             'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);\r" +
    "\n" +
    "            // })(window, document, 'script', 'dataLayer', 'GTM-WZ2PPW9');\r" +
    "\n" +
    "\r" +
    "\n" +
    "            // (function () {\r" +
    "\n" +
    "            //     function getFromCookie(cname) {\r" +
    "\n" +
    "            //         var name = cname + \"=\";\r" +
    "\n" +
    "            //         var decodedCookie = decodeURIComponent(document.cookie);\r" +
    "\n" +
    "            //         var ca = decodedCookie.split(';');\r" +
    "\n" +
    "            //         for (var i = 0; i < ca.length; i++) {\r" +
    "\n" +
    "            //             var c = ca[i];\r" +
    "\n" +
    "            //             while (c.charAt(0) == ' ') {\r" +
    "\n" +
    "            //                 c = c.substring(1);\r" +
    "\n" +
    "            //             }\r" +
    "\n" +
    "            //             if (c.indexOf(name) == 0) {\r" +
    "\n" +
    "            //                 return c.substring(name.length, c.length);\r" +
    "\n" +
    "            //             }\r" +
    "\n" +
    "            //         }\r" +
    "\n" +
    "            //         return null;\r" +
    "\n" +
    "            //     }\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //     function getFromUrl(analyticsParameterName) {\r" +
    "\n" +
    "            //         try {\r" +
    "\n" +
    "            //             var url = window.location.hash;\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //             if (url.indexOf(analyticsParameterName) !== -1) {\r" +
    "\n" +
    "            //                 var startingPosition = url.indexOf(analyticsParameterName);\r" +
    "\n" +
    "            //                 var endingPosition = url.indexOf('&', startingPosition);\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //                 if (endingPosition === -1) {\r" +
    "\n" +
    "            //                     endingPosition = url.length;\r" +
    "\n" +
    "            //                 }\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //                 var analyticsTrackingIdLength = endingPosition - startingPosition + 1;\r" +
    "\n" +
    "            //                 return url.substr(startingPosition + 4, analyticsTrackingIdLength);\r" +
    "\n" +
    "            //             }\r" +
    "\n" +
    "            //             else {\r" +
    "\n" +
    "            //                 return null;\r" +
    "\n" +
    "            //             }\r" +
    "\n" +
    "            //         } catch (error) {\r" +
    "\n" +
    "            //             constole.log(error);\r" +
    "\n" +
    "            //             return null;\r" +
    "\n" +
    "            //         }\r" +
    "\n" +
    "            //     }\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //     try {\r" +
    "\n" +
    "            //         var analyticsParameterName = '_ga';\r" +
    "\n" +
    "            //         var analyticsCookieParameterName = '_gac'\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //         var analyticsTrackingId = getFromUrl(analyticsParameterName) || getFromCookie(analyticsParameterName);\r" +
    "\n" +
    "            //         var analyticsCookieTrackingId = getFromUrl(analyticsCookieParameterName);\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //         if (window.dataLayer) {\r" +
    "\n" +
    "            //             window.dataLayer.push({\r" +
    "\n" +
    "            //                 'attributes': {\r" +
    "\n" +
    "            //                     'analyticsTrackingId': analyticsTrackingId,\r" +
    "\n" +
    "            //                     'analyticsCookieTrackingId': analyticsCookieTrackingId\r" +
    "\n" +
    "            //                 }\r" +
    "\n" +
    "            //             });\r" +
    "\n" +
    "            //         }\r" +
    "\n" +
    "\r" +
    "\n" +
    "            //     } catch (e) {\r" +
    "\n" +
    "            //         console.log(e);\r" +
    "\n" +
    "            //     }\r" +
    "\n" +
    "            // })();\r" +
    "\n" +
    "        </script>\r" +
    "\n" +
    "        <!-- End Google Tag Manager -->\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <link\r" +
    "\n" +
    "            href=\"https://fonts.googleapis.com/css?family=Open+Sans:400,600,700\"\r" +
    "\n" +
    "            rel=\"stylesheet\"\r" +
    "\n" +
    "            type=\"text/css\" />\r" +
    "\n" +
    "    </head>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <body\r" +
    "\n" +
    "        ng-app=\"app\"\r" +
    "\n" +
    "        class=\"h-screen w-full text-slate-900\"\r" +
    "\n" +
    "        ng-strict-di>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <ui-view class=\"block\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"flex flex-col w-full h-screen\"\r" +
    "\n" +
    "                ng-if=\"mainVm.systemAccount\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ui-view=\"headerView\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ui-view\r" +
    "\n" +
    "                    class=\"bg-gray-100 flex-1 overflow-auto relative\">\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ui-view=\"footerView\"></div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"fixed top-0 left-0 right-0 bottom-0 z-10 bg-gray-50 text-center pt-20 hide\"\r" +
    "\n" +
    "                ng-show=\"!mainVm.systemAccount\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div>Loading...</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </ui-view>\r" +
    "\n" +
    "    </body>\r" +
    "\n" +
    "</html>"
  );


  $templateCache.put('app/states/agentProfile/agentProfile.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <form\r" +
    "\n" +
    "        name=\"profileForm\"\r" +
    "\n" +
    "        novalidate\r" +
    "\n" +
    "        ng-submit=\"vm.save(profileForm.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <inline-alert\r" +
    "\n" +
    "            success-message=\"vm.successMessage\"\r" +
    "\n" +
    "            error=\"vm.error\"\r" +
    "\n" +
    "            class=\"block mb-4\">\r" +
    "\n" +
    "        </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"card\">\r" +
    "\n" +
    "            <h4 class=\"mb-2\">Company Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"companyName\"\r" +
    "\n" +
    "                model=\"vm.account.name\"\r" +
    "\n" +
    "                label=\"Company Name\"\r" +
    "\n" +
    "                is-required=\"true\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select-field\r" +
    "\n" +
    "                name=\"companyType\"\r" +
    "\n" +
    "                model=\"vm.account.companyType\"\r" +
    "\n" +
    "                label=\"Company Type\"\r" +
    "\n" +
    "                options=\"[{label: 'Proprietorship', value:  'Proprietorship'}, {label: 'SCorp', value: 'SCorp'}, {label: 'CCorp', value: 'CCorp'}, {label: 'LLC', value: 'LLC'}, {label: 'Partnership', value: 'Partnership'}]\"\r" +
    "\n" +
    "                is-required=\"true\" >\r" +
    "\n" +
    "            </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <phone-field\r" +
    "\n" +
    "                name=\"phone\"\r" +
    "\n" +
    "                model=\"vm.account.phone\"\r" +
    "\n" +
    "                is-required=\"true\"\r" +
    "\n" +
    "                label=\"Phone\">\r" +
    "\n" +
    "            </phone-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"card mt-4\">\r" +
    "\n" +
    "            <h4 class=\"mb-2\">\r" +
    "\n" +
    "                <span ng-show=\"!vm.addressesMatched\">Physical </span>\r" +
    "\n" +
    "                Address\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalAddress\"\r" +
    "\n" +
    "                    model=\"vm.account.physicalAddress\"\r" +
    "\n" +
    "                    label=\"Address\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-8\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalSuiteAptNumber\"\r" +
    "\n" +
    "                    model=\"vm.account.physicalSuiteAptNumber\"\r" +
    "\n" +
    "                    label=\"Suite #\"\r" +
    "\n" +
    "                    is-required=\"false\"\r" +
    "\n" +
    "                    class=\"col-span-4\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalCity\"\r" +
    "\n" +
    "                    model=\"vm.account.physicalCity\"\r" +
    "\n" +
    "                    label=\"City\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-6\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <state-field\r" +
    "\n" +
    "                    name=\"physicalState\"\r" +
    "\n" +
    "                    model=\"vm.account.physicalState\"\r" +
    "\n" +
    "                    label=\"State\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <zip-field\r" +
    "\n" +
    "                    name=\"physicalZip\"\r" +
    "\n" +
    "                    model=\"vm.account.physicalZip\"\r" +
    "\n" +
    "                    label=\"Zip\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "                </zip-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <check-field\r" +
    "\n" +
    "                name=\"addressesMatched\"\r" +
    "\n" +
    "                model=\"vm.addressesMatched\"\r" +
    "\n" +
    "                label=\"Mailing address is the same\"\r" +
    "\n" +
    "                wrapper-class=\"text-sm mt-auto ml-auto\"\r" +
    "\n" +
    "                on-change=\"vm.addressSameClicked()\">\r" +
    "\n" +
    "            </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 \r" +
    "\n" +
    "                ng-show=\"!vm.addressesMatched\"\r" +
    "\n" +
    "                class=\"mt-4 mb-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Mail Address\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-show=\"!vm.addressesMatched\"\r" +
    "\n" +
    "                class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailAddress\"\r" +
    "\n" +
    "                    model=\"vm.account.mailAddress\"\r" +
    "\n" +
    "                    label=\"Address\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-8\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailSuiteAptNumber\"\r" +
    "\n" +
    "                    model=\"vm.account.mailSuiteAptNumber\"\r" +
    "\n" +
    "                    label=\"Suite #\"\r" +
    "\n" +
    "                    is-required=\"false\"\r" +
    "\n" +
    "                    class=\"col-span-4\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailCity\"\r" +
    "\n" +
    "                    model=\"vm.account.mailCity\"\r" +
    "\n" +
    "                    label=\"City\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-6\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <state-field\r" +
    "\n" +
    "                    name=\"mailState\"\r" +
    "\n" +
    "                    model=\"vm.account.mailState\"\r" +
    "\n" +
    "                    label=\"State\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-3\">\r" +
    "\n" +
    "                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <zip-field\r" +
    "\n" +
    "                    name=\"mailZip\"\r" +
    "\n" +
    "                    model=\"vm.account.mailZip\"\r" +
    "\n" +
    "                    label=\"Zip\"\r" +
    "\n" +
    "                    is-required=\"true\"\r" +
    "\n" +
    "                    class=\"col-span-3\">\r" +
    "\n" +
    "                </zip-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex mt-4 gap-x-2\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"button w-full md:w-auto ml-auto\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                ng-disabled=\"vm.isSaving\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"!vm.isSaving\"\r" +
    "\n" +
    "                    class=\"my-auto\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Save\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    ng-show=\"vm.isSaving\"\r" +
    "\n" +
    "                    class=\"my-auto\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Saving...\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<busy-indicator \r" +
    "\n" +
    "    promise=\"vm.loadingPromise\"\r" +
    "\n" +
    "    message=\"Loading...\">\r" +
    "\n" +
    "</busy-indicator>"
  );


  $templateCache.put('app/states/application/application.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.application\"\r" +
    "\n" +
    "    ng-show=\"!vm.error || vm.error.errorDisplayType == 'inline'\"\r" +
    "\n" +
    "    class=\"flex absolute top-0 bottom-0 right-0 left-0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"bg-white flex flex-col w-full relative overflow-auto\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'md:w-1/2 md:shadow-[5px_0_5px_rgba(0,0,0,0.25)]': vm.prequalifiedQuotes.length && vm.applicationStepManager.showStepProgress,\r" +
    "\n" +
    "            'md:mx-auto md:max-w-[750px] md:shadow-2xl': !vm.prequalifiedQuotes.length || !vm.applicationStepManager.showStepProgress\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <erisa-application-form\r" +
    "\n" +
    "            ng-if=\"vm.customApplicationType === 'erisa'\"\r" +
    "\n" +
    "            application=\"vm.application\"\r" +
    "\n" +
    "            on-changes-submitted=\"vm.sumbitApplication()\" >\r" +
    "\n" +
    "        </erisa-application-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"!vm.customApplicationType\">\r" +
    "\n" +
    "            <application-form-header\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                class=\"mb-6 block\">\r" +
    "\n" +
    "            </application-form-header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <product-selection-form\r" +
    "\n" +
    "                ng-show=\"vm.applicationStepManager.activePane == 'ProductSelectionStep' || !vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-application-settings-changed=\"vm.applicationSettingsChanged(applicationSettings)\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.productSelectionFormSubmitted(hasChanges)\"\r" +
    "\n" +
    "                on-error=\"vm.handleProductSelectionFormError(error)\">\r" +
    "\n" +
    "            </product-selection-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <company-form\r" +
    "\n" +
    "                ng-if=\"vm.applicationStepManager.activePane == 'CompanyInfo'\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.save()\">\r" +
    "\n" +
    "            </company-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <person-form\r" +
    "\n" +
    "                ng-if=\"vm.applicationStepManager.activePane == 'PersonalInfo'\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.save()\">\r" +
    "\n" +
    "            </person-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <additional-questions-form\r" +
    "\n" +
    "                ng-if=\"vm.applicationStepManager.activePane == 'AdditionalQuestions'\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.save()\">\r" +
    "\n" +
    "            </additional-questions-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <obligee-form\r" +
    "\n" +
    "                ng-if=\"vm.applicationStepManager.activePane == 'Obligee'\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.save()\">\r" +
    "\n" +
    "            </obligee-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <name-on-bond-form\r" +
    "\n" +
    "                ng-if=\"vm.applicationStepManager.activePane == 'NameOnBond'\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.save()\">\r" +
    "\n" +
    "            </name-on-bond-form>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <review-and-submit\r" +
    "\n" +
    "                ng-if=\"vm.applicationStepManager.activePane == 'Review'\"\r" +
    "\n" +
    "                application-settings=\"vm.applicationSettings\"\r" +
    "\n" +
    "                application=\"vm.application\"\r" +
    "\n" +
    "                application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "                on-changes-submitted=\"vm.sumbitApplication()\"\r" +
    "\n" +
    "                new-login=\"vm.newLogin\"\r" +
    "\n" +
    "                login-exists-error=\"vm.error.errorType == 'EProducerUserEmailExists' ? vm.error : null\">\r" +
    "\n" +
    "            </review-and-submit>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-show=\"vm.prequalifiedQuotes.length && vm.applicationStepManager.showStepProgress\"\r" +
    "\n" +
    "        class=\"fade delay-200 hidden md:block w-1/2 relative top-0 right-0 bottom-0 overflow-y-auto overflow-x-hidden\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"text-sm px-4 flex h-11\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <time-remaining\r" +
    "\n" +
    "                field-count=\"vm.fieldCount\"\r" +
    "\n" +
    "                completed-field-count=\"vm.completeFields\"\r" +
    "\n" +
    "                class=\"my-auto\">\r" +
    "\n" +
    "            </time-remaining>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"ml-auto my-auto flex\"\r" +
    "\n" +
    "                ng-click=\"vm.filtersVisible = !vm.filtersVisible\"\r" +
    "\n" +
    "                ng-class=\"{\r" +
    "\n" +
    "                    'text-eproducer-blue font-bold': vm.filterCount,\r" +
    "\n" +
    "                    'text-slate-800': !vm.filterCount\r" +
    "\n" +
    "                }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"my-auto\">\r" +
    "\n" +
    "                    Filter \r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <span \r" +
    "\n" +
    "                    class=\"my-auto\"\r" +
    "\n" +
    "                    ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    ({{ vm.filterCount }})\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <i class=\"icon-filter ml-1 my-auto\"></i>\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"py-4 px-5\">\r" +
    "\n" +
    "            <quote-list\r" +
    "\n" +
    "                ng-if=\"vm.prequalifiedQuotes\"\r" +
    "\n" +
    "                quotes=\"vm.prequalifiedQuotes\"\r" +
    "\n" +
    "                is-policy=\"vm.applicationSettings.isPolicy\"\r" +
    "\n" +
    "                filtered-quote-count=\"vm.filteredQuoteCount\"\r" +
    "\n" +
    "                lowest-cost=\"vm.lowestCost\"\r" +
    "\n" +
    "                most-expensive-cost=\"vm.mostExpensiveCost\"\r" +
    "\n" +
    "                filter-count=\"vm.filterCount\"\r" +
    "\n" +
    "                filters-visible=\"vm.filtersVisible\">\r" +
    "\n" +
    "            </quote-list>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\">\r" +
    "\n" +
    "    <div class=\"text-right\">\r" +
    "\n" +
    "        <a ui-sref=\"main.application({ uniqueId: null, bondTypeId: null, bondAmount: null, applicationType: vm.customApplicationType })\">Start New Application</a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div ng-show=\"vm.isSubmitting\">\r" +
    "\n" +
    "    <div class=\"bg-black opacity-40 fixed top-0 left-0 right-0 bottom-0 z-40\"></div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"fixed top-1/2 left-1/2 min-w-[500px] translate-x-[-50%] translate-y-[-50%] z-[41] opacity-100 rounded\">\r" +
    "\n" +
    "        <header class=\"bg-slate-100 rounded-t p-4 border-b border-solid border-slate-400\">\r" +
    "\n" +
    "            <h2>Processing your application</h2>\r" +
    "\n" +
    "            Our system is reviewing your application in order to confirm your quotes.\r" +
    "\n" +
    "        </header>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <main class=\"bg-white p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h3\r" +
    "\n" +
    "                ng-show=\"mainVm.application.totalQuoteCount\"\r" +
    "\n" +
    "                class=\"mb-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                We are confirming your quotes\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <span class=\"hidden md:inline\">\r" +
    "\n" +
    "                    for <br />\r" +
    "\n" +
    "                    {{mainVm.application.bondType}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                starting at {{mainVm.application.lowestQuoteCost | currency:'$':0 }}\r" +
    "\n" +
    "            </h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p class=\"mb-2\">\r" +
    "\n" +
    "                We are processing your information to provide you with the best confirmed quotes.\r" +
    "\n" +
    "                You may receive an email with a link to return to your application.\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"mb-2\">\r" +
    "\n" +
    "                <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex-1 my-auto text-center\">\r" +
    "\n" +
    "                        <div class=\"font-bold text-eproducer-blue text-[50px]\">\r" +
    "\n" +
    "                            {{mainVm.systemAccount.carrierCount}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <b>Carriers</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"mainVm.application.totalQuoteCount\"\r" +
    "\n" +
    "                        class=\"flex-1 my-auto text-center\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"font-bold text-eproducer-blue text-[50px]\">\r" +
    "\n" +
    "                            {{mainVm.application.totalQuoteCount}}\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                        <b>Quotes</b>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-show=\"!mainVm.application.totalQuoteCount\"\r" +
    "\n" +
    "                        class=\"flex-1 my-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        Multiple carriers are being evaluated in order to provide you our best quotes.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <progress-bar\r" +
    "\n" +
    "                    class=\"block mt-4\" \r" +
    "\n" +
    "                    progress=\"vm.submissionProgressPercent\">\r" +
    "\n" +
    "                </progress-bar>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </main>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <footer class=\"flex bg-slate-100 border-t border-solid border-slate-400 p-4 rounded-b\">\r" +
    "\n" +
    "            <div>\r" +
    "\n" +
    "                <b>Need Help?</b> Call us at <span class=\"text-eproducer-blue\">{{mainVm.branding.phone}}</span>.\r" +
    "\n" +
    "                <br />\r" +
    "\n" +
    "                <i class=\"fa fa-lock text-eproducer-orange\"></i> Your information is always secure\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"a3-logo bg-right w-[84px] h-[20px] ml-auto my-auto\"></div>\r" +
    "\n" +
    "        </footer>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/application/applicationFormFooter.html',
    "<footer \r" +
    "\n" +
    "    class=\"fixed left-0 w-full bottom-0 flex bg-slate-700 text-white pl-5 h-[44px] z-30\"\r" +
    "\n" +
    "    ng-class=\"{\r" +
    "\n" +
    "        'md:w-1/2': vm.hasQuotes,\r" +
    "\n" +
    "        'md:left-1/2 md:translate-x-[-50%] md:max-w-[750px]': !vm.hasQuotes\r" +
    "\n" +
    "    }\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"text-sm hidden sm:block my-auto\">\r" +
    "\n" +
    "        <i class=\"fa fa-refresh mr-2\"></i>\r" +
    "\n" +
    "        Changes save automatically\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"ml-auto\">\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"text-white\"\r" +
    "\n" +
    "            ng-click=\"vm.onBackClick()\"\r" +
    "\n" +
    "            ng-hide=\"vm.hideBackButton\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"icon-arrow-left\"></i> Back\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"ml-2 uppercase bg-eproducer-blue h-[44px] px-4\"\r" +
    "\n" +
    "            type=\"submit\"\r" +
    "\n" +
    "            ng-click=\"vm.onNextClick()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            {{vm.nextButtonText}} <i class=\"icon-arrow-right\"></i>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</footer>"
  );


  $templateCache.put('app/states/application/applicationFormHeader.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"!vm.applicationStepManager.showStepProgress\"\r" +
    "\n" +
    "    class=\"pt-3 px-5 mt-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <h4 class=\"flex-1 my-auto\">\r" +
    "\n" +
    "            Getting Started\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"my-auto ml-2\"></div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div>\r" +
    "\n" +
    "        Start by providing some basic information the bond you need.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.applicationStepManager.showStepProgress\"\r" +
    "\n" +
    "    class=\"pt-3 px-5 md:hidden\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <h4\r" +
    "\n" +
    "            class=\"flex-1 my-auto\"\r" +
    "\n" +
    "            ng-switch=\"vm.applicationStepManager.activePane\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-switch-when=\"ProductSelectionStep\">Getting Started</span>\r" +
    "\n" +
    "            <span ng-switch-when=\"CompanyInfo\">Company Info</span>\r" +
    "\n" +
    "            <span ng-switch-when=\"PersonalInfo\">\r" +
    "\n" +
    "                <span ng-if=\"vm.application.principalType == 'Company'\">Company Owner(s)</span>\r" +
    "\n" +
    "                <span ng-if=\"vm.application.principalType != 'Company'\">Personal Information</span>\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            <span ng-switch-when=\"AdditionalQuestions\">Additional Questions</span>\r" +
    "\n" +
    "            <span ng-switch-when=\"Obligee\">Obligee</span>\r" +
    "\n" +
    "            <span ng-switch-when=\"NameOnBond\">Name on Bond</span>\r" +
    "\n" +
    "            <span ng-switch-when=\"Review\">Review &amp; Submit</span>\r" +
    "\n" +
    "        </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"my-auto ml-2\">\r" +
    "\n" +
    "            Step {{vm.applicationStepManager.currentPaneNumber}} of {{vm.applicationStepManager.totalPaneCount}}\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-switch=\"vm.applicationStepManager.activePane\">\r" +
    "\n" +
    "        <span ng-switch-when=\"ProductSelectionStep\">\r" +
    "\n" +
    "            Start by providing some basic information the bond you need.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span ng-switch-when=\"CompanyInfo\">\r" +
    "\n" +
    "            Tell us about your company.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span ng-switch-when=\"PersonalInfo\">\r" +
    "\n" +
    "            <span ng-if=\"vm.application.principalType == 'Company'\">Tell us about the owner(s) of your company.</span>\r" +
    "\n" +
    "            <span ng-if=\"vm.application.principalType != 'Company'\">Tell us about yourself.</span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span ng-switch-when=\"AdditionalQuestions\">\r" +
    "\n" +
    "            Answer a few additional questions to help us prepare your bond.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span ng-switch-when=\"Obligee\">\r" +
    "\n" +
    "            Tell us about the obligee. This is the entity that requires the bond.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span ng-switch-when=\"NameOnBond\">\r" +
    "\n" +
    "            Because the bond is a legal contract, provide the name to be printed on the form.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "        <span ng-switch-when=\"Review\">\r" +
    "\n" +
    "            You are ready to submit your bond application for review.\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <progress-bar\r" +
    "\n" +
    "        class=\"block mt-2\"\r" +
    "\n" +
    "        progress=\"100 * vm.applicationStepManager.currentPaneNumber / vm.applicationStepManager.totalPaneCount\">\r" +
    "\n" +
    "    </progress-bar>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.applicationStepManager.showStepProgress\"\r" +
    "\n" +
    "    class=\"hidden md:flex justify-around border-b border-b-solid border-b-slate-300\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-repeat=\"pane in vm.applicationStepManager.panes\"\r" +
    "\n" +
    "        class=\"text-center px-2 my-auto h-11 font-bold text-sm flex\"\r" +
    "\n" +
    "        ng-class=\"{'bg-eproducer-blue text-white': pane == vm.applicationStepManager.activePane}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'ProductSelectionStep'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Getting Started\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'CompanyInfo'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Company Info\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'PersonalInfo'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-if=\"vm.application.principalType == 'Company'\">\r" +
    "\n" +
    "                Owner(s)\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span ng-if=\"vm.application.principalType != 'Company'\">\r" +
    "\n" +
    "                Personal Info\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'AdditionalQuestions'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Additional Questions\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'Obligee'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Obligee\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'NameOnBond'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Name on Bond\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"my-auto\"\r" +
    "\n" +
    "            ng-if=\"pane == 'Review'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Review &amp; Submit\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/application/customApplicationForms/erisa.html',
    "<form\r" +
    "\n" +
    "    name=\"erisaForm\"\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    ng-submit=\"vm.formSubmitted(erisaForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h1 class=\"text-slate-700 mt-8 mb-2\">Get Your ERISA Bond Quickly &amp; Easily</h1>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"text-slate-600 text-sm\">\r" +
    "\n" +
    "        Getting your ERISA Surety Bond is simple and hassle-free. Just fill out our quick application, get an instant quote, and pay securely with a credit card. Once you are done, you'll be able to download your bond documents right away. \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"text-slate-700 mt-8 mb-2\">Tell Us About Your Plan</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mb-2\">\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"nameOnBond\"\r" +
    "\n" +
    "            model=\"vm.application.nameOnBond\"\r" +
    "\n" +
    "            label=\"What is plan name?\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"text-sm text-slate-500\">\r" +
    "\n" +
    "            <b>Important:</b> Be sure to enter your plan name exactly as it appears on IRS Form 5500.\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mb-2\">\r" +
    "\n" +
    "        <state-field\r" +
    "\n" +
    "            name=\"ERISAState\"\r" +
    "\n" +
    "            model=\"vm.erisaState\"\r" +
    "\n" +
    "            label=\"In what state are the funds located?\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </state-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mb-2\">\r" +
    "\n" +
    "        <integer-field\r" +
    "\n" +
    "            name=\"ERISAtrustees\"\r" +
    "\n" +
    "            model=\"vm.erisaTrustees\"\r" +
    "\n" +
    "            label=\"How many trustees are there for the plan?\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </integer-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mb-2\">\r" +
    "\n" +
    "        <date-field\r" +
    "\n" +
    "            name=\"desiredEffectiveDate\"\r" +
    "\n" +
    "            model=\"vm.application.desiredEffectiveDate\"\r" +
    "\n" +
    "            label=\"What is the bond's effective date?\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            min=\"vm.minimumEffectiveDate\"\r" +
    "\n" +
    "            max=\"vm.maximumEffectiveDate\">\r" +
    "\n" +
    "        </date-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mb-2\">\r" +
    "\n" +
    "        <currency-field\r" +
    "\n" +
    "            name=\"bondAmount\"\r" +
    "\n" +
    "            inputmode=\"numeric\"\r" +
    "\n" +
    "            model=\"vm.application.bondAmount\"\r" +
    "\n" +
    "            label=\"Bond Amount\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </currency-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"text-sm text-slate-500\">\r" +
    "\n" +
    "            Your ERISA Surety Bond must be at least 10% of the plan's total assets from the previous year.\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"text-slate-700 mt-8 mb-2\">Your Contact Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"text-slate-700\">\r" +
    "\n" +
    "        Provide us with your contact information so that we can record your bond correctly.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"firstName\"\r" +
    "\n" +
    "            model=\"vm.person.firstName\"\r" +
    "\n" +
    "            label=\"First Name\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            class=\"col-span-12 md:col-span-6\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"lastName\"\r" +
    "\n" +
    "            model=\"vm.person.lastName\"\r" +
    "\n" +
    "            label=\"Last Name\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            class=\"col-span-12 md:col-span-6\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"email\"\r" +
    "\n" +
    "            model=\"vm.person.email\"\r" +
    "\n" +
    "            label=\"Email\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            type=\"email\"\r" +
    "\n" +
    "            class=\"col-span-12 md:col-span-6\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <phone-field\r" +
    "\n" +
    "            name=\"phone\"\r" +
    "\n" +
    "            model=\"vm.person.cellPhone\"\r" +
    "\n" +
    "            label=\"Phone Number\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            class=\"col-span-12 md:col-span-6\">\r" +
    "\n" +
    "        </phone-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"physicalAddress\"\r" +
    "\n" +
    "            model=\"vm.person.physicalAddress\"\r" +
    "\n" +
    "            label=\"Address\"\r" +
    "\n" +
    "            class=\"col-span-8\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"physicalSuiteAptNumber\"\r" +
    "\n" +
    "            model=\"vm.person.physicalSuiteAptNumber\"\r" +
    "\n" +
    "            label=\"Suite #\"\r" +
    "\n" +
    "            is-required=\"false\"\r" +
    "\n" +
    "            class=\"col-span-4\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"physicalCity\"\r" +
    "\n" +
    "            model=\"vm.person.physicalCity\"\r" +
    "\n" +
    "            label=\"City\"\r" +
    "\n" +
    "            class=\"col-span-6\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <state-field\r" +
    "\n" +
    "            name=\"physicalState\"\r" +
    "\n" +
    "            model=\"vm.person.physicalState\"\r" +
    "\n" +
    "            label=\"State\"\r" +
    "\n" +
    "            class=\"col-span-3\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <zip-field\r" +
    "\n" +
    "            name=\"physicalZip\"\r" +
    "\n" +
    "            model=\"vm.person.physicalZip\"\r" +
    "\n" +
    "            label=\"Zip\"\r" +
    "\n" +
    "            class=\"col-span-3\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </zip-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <footer \r" +
    "\n" +
    "        class=\"fixed left-0 w-full bottom-0 flex bg-slate-700 text-white pl-5 h-[44px] z-30\"\r" +
    "\n" +
    "        ng-class=\"{\r" +
    "\n" +
    "            'md:w-1/2': vm.hasQuotes,\r" +
    "\n" +
    "            'md:left-1/2 md:translate-x-[-50%] md:max-w-[750px]': !vm.hasQuotes\r" +
    "\n" +
    "        }\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"ml-auto\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"ml-2 uppercase bg-eproducer-blue h-[44px] px-4\"\r" +
    "\n" +
    "                type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Next <i class=\"icon-arrow-right\"></i>\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/application/download.html',
    "<div \r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5 text-slate-700\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mx-auto max-w-[1000px]\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h2 class=\"mb-1\">Thank you for your business!</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex mb-2.5\">\r" +
    "\n" +
    "            <div ng-if=\"!vm.hasOpenBalance && !vm.canDownloadPacket\">\r" +
    "\n" +
    "                <div class=\"my-2\">\r" +
    "\n" +
    "                    We appreciate you using our online system to purchase your <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type>. \r" +
    "\n" +
    "                    Regulations require that this type of <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type> be created and signed in our office. \r" +
    "\n" +
    "                    Don't worry, we'll get to work processing your <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type> right away. \r" +
    "\n" +
    "                    In the mean time you can download your invoice by clicking the link below.\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"my-2\">Your finished <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type> packet will be mailed to you as soon as we get it completed.</div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-if=\"!vm.hasOpenBalance && vm.canDownloadPacket\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"my-2\">\r" +
    "\n" +
    "                    We appreciate you using our online system to purchase your <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type>. \r" +
    "\n" +
    "                    This type of <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type> allows for us to print our electronic signatures which means you can download your finished <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type> today!\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div class=\"mt-2\">\r" +
    "\n" +
    "                    Click the button below to download your completed <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type> packet that's ready for you to sign and submit.\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-if=\"vm.hasOpenBalance\">\r" +
    "\n" +
    "                <div class=\"my-2\">\r" +
    "\n" +
    "                    We appreciate you using our online system to purchase your <product-type is-policy=\"vm.quotePurchaseReceipt.isPolicy\"></product-type>. \r" +
    "\n" +
    "                    We've received the information required to process your bond and we'll get on that right away. \r" +
    "\n" +
    "                    If you have any questions please feel free to contact us at <span class=\"whitespace-nowrap\">{{mainVm.branding.phone}}.</span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"mt-5\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h2 class=\"my-2\">\r" +
    "\n" +
    "                <product-type \r" +
    "\n" +
    "                    capitalize-first-letter=\"true\"\r" +
    "\n" +
    "                    is-policy=\"vm.quotePurchaseReceipt.isPolicy\">\r" +
    "\n" +
    "                </product-type> \r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Information\r" +
    "\n" +
    "            </h2>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"card sm:flex my-2\">\r" +
    "\n" +
    "                <div class=\"sm:flex-1\">\r" +
    "\n" +
    "                    <div>{{vm.quotePurchaseReceipt.bondType}}</div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <product-type \r" +
    "\n" +
    "                            capitalize-first-letter=\"true\"\r" +
    "\n" +
    "                            is-policy=\"vm.quotePurchaseReceipt.isPolicy\">\r" +
    "\n" +
    "                        </product-type> \r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                        Amount: {{vm.quotePurchaseReceipt.bondAmount | currency}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div>Effective Date: {{vm.quotePurchaseReceipt.effectiveDate | dateOnly}}</div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <product-type \r" +
    "\n" +
    "                            capitalize-first-letter=\"true\"\r" +
    "\n" +
    "                            is-policy=\"vm.quotePurchaseReceipt.isPolicy\">\r" +
    "\n" +
    "                        </product-type>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        #: {{vm.quotePurchaseReceipt.bondNumber}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                <pdf-download\r" +
    "\n" +
    "                    ng-if=\"!vm.hasOpenBalance && vm.canDownloadPacket\"\r" +
    "\n" +
    "                    processing-promise=\"vm.processingPromise\"\r" +
    "\n" +
    "                    url=\"vm.documentPacketUrl\"\r" +
    "\n" +
    "                    filename=\"bond_packet.pdf\"\r" +
    "\n" +
    "                    system-account=\"vm.systemAccount\"\r" +
    "\n" +
    "                    virtual-page-name=\"EProducer-DownloadPacket\"\r" +
    "\n" +
    "                    tooltip=\"Click Here to Download Your Bond\"\r" +
    "\n" +
    "                    button-text=\"Click Here to Download Your Bond\"\r" +
    "\n" +
    "                    class=\"block mt-4 sm:my-auto\"\r" +
    "\n" +
    "                    button-class=\"w-full sm:w-auto bg-eproducer-green\">\r" +
    "\n" +
    "                </pdf-download> \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>        \r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-if=\"vm.quotePurchaseReceipt.payments.length\"\r" +
    "\n" +
    "            class=\"mt-5\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <h2 class=\"my-2.5\">Payment Details</h2>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div class=\"card my-2.5\">\r" +
    "\n" +
    "                <div>The following payment(s) have been applied to this your quote purchase.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"hidden mt-2 lg:flex\">\r" +
    "\n" +
    "                    <div class=\"grow basis-1/4 border-b border-solid border-gray-200\"><h4>Paid By:</h4></div>\r" +
    "\n" +
    "                    <div class=\"grow basis-1/4 border-b border-solid border-gray-200\"><h4>Date/Time:</h4></div>\r" +
    "\n" +
    "                    <div class=\"grow basis-1/4 border-b border-solid border-gray-200\"><h4>Reference #:</h4></div>\r" +
    "\n" +
    "                    <div class=\"basis-36 border-b border-solid border-gray-200\"><h4>Amount:</h4></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"lg:flex lg:flex-col lg:gap-y-2.5\">\r" +
    "\n" +
    "                    <div ng-repeat=\"payment in vm.quotePurchaseReceipt.payments\">\r" +
    "\n" +
    "                        <h3 class=\"mt-2.5 border-b border-solid border-gray-200 lg:hidden\">Payment: <span class=\"font-normal text-base\">{{payment.createdDateTime | localDateTime}}</span></h2>\r" +
    "\n" +
    "                        <div class=\"sm:flex\">\r" +
    "\n" +
    "                            <div class=\"sm:grow sm:basis-1/4\">\r" +
    "\n" +
    "                                <h4 class=\"pl-2.5 lg:hidden\">Paid By:</h4>\r" +
    "\n" +
    "                                <div class=\"pl-5 sm:pl-2.5 lg:pl-0\">\r" +
    "\n" +
    "                                    <span ng-if=\"payment.companyName\">{{payment.companyName}}<br/></span>\r" +
    "\n" +
    "                                    {{payment.firstName}} {{payment.lastName}}<br/>\r" +
    "\n" +
    "                                    {{payment.streetAddress}} {{payment.suiteAptNumber}}<br/>\r" +
    "\n" +
    "                                    {{payment.city}}, {{payment.state}}, {{payment.zipCode}}<br/>\r" +
    "\n" +
    "                                    {{payment.phone}}<br/>\r" +
    "\n" +
    "                                    {{payment.email}}\r" +
    "\n" +
    "                                </div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"hidden lg:block lg:grow lg:basis-1/4\">\r" +
    "\n" +
    "                                <h4 class=\"pl-2.5 lg:hidden\">Date/Time:</h4>\r" +
    "\n" +
    "                                <div class=\"my-2\">{{payment.createdDateTime | localDateTime}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"sm:grow sm:basis-1/4\">\r" +
    "\n" +
    "                                <h4 class=\"pl-2.5 lg:hidden\">Reference #:</h4>\r" +
    "\n" +
    "                                <div class=\"pl-5 sm:pl-2.5 lg:pl-0\">{{payment.transactionId}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"sm:basis-36 sm:text-right\">\r" +
    "\n" +
    "                                <h4 class=\"pl-2.5 lg:hidden\">Amount:</h4>\r" +
    "\n" +
    "                                <div class=\"pl-5 sm:pl-2.5 lg:pl-0\">{{(payment.amount) + (payment.transactionFee ? payment.transactionFee : 0) | currency:\"$\":2}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <div class=\"mt-5\">\r" +
    "\n" +
    "            <h2 class=\"my-2.5\">Invoice(s)</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"card my-2.5\">\r" +
    "\n" +
    "                <div class=\"hidden mb-1 sm:flex\">\r" +
    "\n" +
    "                    <div class=\"grow basis-1/4 border-b border-solid border-gray-200\">\r" +
    "\n" +
    "                        <h4>Invoice #</h4>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"grow basis-1/4 border-b border-solid border-gray-200\">\r" +
    "\n" +
    "                        <h4>Status</h4>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"grow basis-1/4 border-b border-solid border-gray-200\">\r" +
    "\n" +
    "                        <h4>Amount Due</h4>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    <div class=\"basis-28 border-b border-solid border-gray-200\"></div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"sm:flex\" \r" +
    "\n" +
    "                    ng-repeat=\"invoice in vm.quotePurchaseReceipt.invoices\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"sm:grow sm:basis-1/4 sm:my-2\">\r" +
    "\n" +
    "                        <span class=\"font-semibold sm:hidden\">Invoice #:</span> \r" +
    "\n" +
    "                        {{invoice.invoiceNumber}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"sm:grow sm:basis-1/4 sm:my-2\">\r" +
    "\n" +
    "                        <span class=\"font-semibold sm:hidden\">Status:</span> \r" +
    "\n" +
    "                        {{invoice.status}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <div class=\"sm:grow sm:basis-1/4 sm:my-2\">\r" +
    "\n" +
    "                        <span class=\"font-semibold sm:hidden\">Amount Due:</span> \r" +
    "\n" +
    "                        {{invoice.totalAmountDue | currency:\"$\":2}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <pdf-download\r" +
    "\n" +
    "                        processing-promise=\"vm.processingPromise\"\r" +
    "\n" +
    "                        url=\"vm.getInvoiceURL(invoice.id)\"\r" +
    "\n" +
    "                        filename=\"bond_invoice.pdf\"\r" +
    "\n" +
    "                        system-account=\"vm.systemAccount\"\r" +
    "\n" +
    "                        virtual-page-name=\"EProducer-DownloadInvoice\"\r" +
    "\n" +
    "                        button-text=\"Download\"\r" +
    "\n" +
    "                        tooltip=\"Download your Invoice\"\r" +
    "\n" +
    "                        class=\"block mt-4 sm:mt-0 sm:basis-28\"\r" +
    "\n" +
    "                        button-class=\"w-full sm:w-auto\">\r" +
    "\n" +
    "                    </pdf-download>\r" +
    "\n" +
    "                </div>             \r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>"
  );


  $templateCache.put('app/states/application/panes/additionalQuestionsForm.html',
    "<form\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    name=\"additionalQuestionsForm\"\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(additionalQuestionsForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2\">Additional Questions</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-repeat=\"question in vm.applicationSettings.additionalApplicationQuestions\"\r" +
    "\n" +
    "        ng-switch=\"question.type\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-switch-when=\"TextArea\">\r" +
    "\n" +
    "            <textarea-field\r" +
    "\n" +
    "                name=\"{{question.name}}\"\r" +
    "\n" +
    "                model=\"vm.questionResponses[question.id].responseText\"\r" +
    "\n" +
    "                label=\"{{question.question}}\"\r" +
    "\n" +
    "                is-required=\"question.isRequired\">\r" +
    "\n" +
    "            </textarea-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-switch-when=\"DropDown\">\r" +
    "\n" +
    "            <select-field\r" +
    "\n" +
    "                name=\"{{question.name}}\"\r" +
    "\n" +
    "                model=\"vm.questionResponses[question.id].responseText\"\r" +
    "\n" +
    "                label=\"{{question.question}}\"\r" +
    "\n" +
    "                is-required=\"question.isRequired\"\r" +
    "\n" +
    "                options=\"question.masterApplicationQuestionChoices\"\r" +
    "\n" +
    "                option-label=\"text\"\r" +
    "\n" +
    "                option-value=\"text\">\r" +
    "\n" +
    "            </select-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-switch-when=\"Radio\">\r" +
    "\n" +
    "            <radio-field\r" +
    "\n" +
    "                name=\"{{question.name}}\"\r" +
    "\n" +
    "                model=\"vm.questionResponses[question.id].responseText\"\r" +
    "\n" +
    "                label=\"{{question.question}}\"\r" +
    "\n" +
    "                is-required=\"question.isRequired\"\r" +
    "\n" +
    "                options=\"question.masterApplicationQuestionChoices\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <radio-option\r" +
    "\n" +
    "                    ng-repeat=\"option in question.masterApplicationQuestionChoices\"\r" +
    "\n" +
    "                    value=\"{{option.text}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{option.text}}\r" +
    "\n" +
    "                </radio-option>\r" +
    "\n" +
    "            </radio-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-switch-default>\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"{{question.name}}\"\r" +
    "\n" +
    "                model=\"vm.questionResponses[question.id].responseText\"\r" +
    "\n" +
    "                label=\"{{question.question}}\"\r" +
    "\n" +
    "                is-required=\"question.isRequired\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-repeat=\"subQuestion in question.subQuestions\"\r" +
    "\n" +
    "            ng-if=\"vm.questionResponses[question.id].responseText == subQuestion.showWhenParentValueEquals\"\r" +
    "\n" +
    "            ng-switch=\"subQuestion.type\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-switch-when=\"TextArea\">\r" +
    "\n" +
    "                <textarea-field\r" +
    "\n" +
    "                    name=\"{{subQuestion.name}}\"\r" +
    "\n" +
    "                    model=\"vm.questionResponses[subQuestion.id].responseText\"\r" +
    "\n" +
    "                    label=\"{{subQuestion.question}}\"\r" +
    "\n" +
    "                    is-required=\"subQuestion.isRequired\">\r" +
    "\n" +
    "                </textarea-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-switch-when=\"DropDown\">\r" +
    "\n" +
    "                <select-field\r" +
    "\n" +
    "                    name=\"{{subQuestion.name}}\"\r" +
    "\n" +
    "                    model=\"vm.questionResponses[subQuestion.id].responseText\"\r" +
    "\n" +
    "                    label=\"{{subQuestion.question}}\"\r" +
    "\n" +
    "                    is-required=\"subQuestion.isRequired\"\r" +
    "\n" +
    "                    options=\"subQuestion.masterApplicationQuestionChoices\"\r" +
    "\n" +
    "                    option-label=\"text\"\r" +
    "\n" +
    "                    option-value=\"text\">\r" +
    "\n" +
    "                </select-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-switch-when=\"Radio\">\r" +
    "\n" +
    "                <radio-field\r" +
    "\n" +
    "                    name=\"{{subQuestion.name}}\"\r" +
    "\n" +
    "                    model=\"vm.questionResponses[subQuestion.id].responseText\"\r" +
    "\n" +
    "                    label=\"{{subQuestion.question}}\"\r" +
    "\n" +
    "                    is-required=\"subQuestion.isRequired\"\r" +
    "\n" +
    "                    options=\"subQuestion.masterApplicationQuestionChoices\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <radio-option\r" +
    "\n" +
    "                        ng-repeat=\"option in subQuestion.masterApplicationQuestionChoices\"\r" +
    "\n" +
    "                        value=\"{{option.text}}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        {{option.text}}\r" +
    "\n" +
    "                    </radio-option>\r" +
    "\n" +
    "                </radio-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-switch-default>\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"{{subQuestion.name}}\"\r" +
    "\n" +
    "                    model=\"vm.questionResponses[subQuestion.id].responseText\"\r" +
    "\n" +
    "                    label=\"{{subQuestion.question}}\"\r" +
    "\n" +
    "                    is-required=\"subQuestion.isRequired\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <application-form-footer on-back-click=\"vm.applicationStepManager.movePrevStep()\"></application-form-footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/application/panes/companyForm.html',
    "<form\r" +
    "\n" +
    "    name=\"companyForm\"\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(companyForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2\">Company Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"name\"\r" +
    "\n" +
    "            model=\"vm.application.company.name\"\r" +
    "\n" +
    "            label=\"Company Name\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-9\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"numberOfOwners\"\r" +
    "\n" +
    "            model=\"vm.application.company.numberOfOwners\"\r" +
    "\n" +
    "            label=\"Number of Owners\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            type=\"number\"\r" +
    "\n" +
    "            step=\"1\"\r" +
    "\n" +
    "            max=\"9\"\r" +
    "\n" +
    "            min=\"1\"\r" +
    "\n" +
    "            on-change=\"vm.ownerCountChanged()\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"dba\"\r" +
    "\n" +
    "            model=\"vm.application.company.dba\"\r" +
    "\n" +
    "            label=\"DBA\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.companyDbaFieldStatus == 'Required'\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.companyDbaFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <select-field\r" +
    "\n" +
    "            name=\"companyType\"\r" +
    "\n" +
    "            model=\"vm.application.company.companyType\"\r" +
    "\n" +
    "            label=\"Company Type\"\r" +
    "\n" +
    "            options=\"[{label: 'Proprietorship', value:  'Proprietorship'}, {label: 'SCorp', value: 'SCorp'}, {label: 'CCorp', value: 'CCorp'}, {label: 'LLC', value: 'LLC'}, {label: 'Partnership', value: 'Partnership'}]\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.companyTypeFieldStatus == 'Required'\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.companyTypeFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-6\" >\r" +
    "\n" +
    "        </select-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.applicationSettings.companyPhoneFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyFaxFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyEmailFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Contact Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <phone-field\r" +
    "\n" +
    "                name=\"phone\"\r" +
    "\n" +
    "                model=\"vm.application.company.phone\"\r" +
    "\n" +
    "                label=\"Phone\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyPhoneFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyPhoneFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <phone-field\r" +
    "\n" +
    "                name=\"fax\"\r" +
    "\n" +
    "                model=\"vm.application.company.fax\"\r" +
    "\n" +
    "                label=\"Fax\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyFaxFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyFaxFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"email\"\r" +
    "\n" +
    "                model=\"vm.application.company.email\"\r" +
    "\n" +
    "                label=\"Email\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyEmailFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyEmailFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden' || vm.applicationSettings.companyCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4 class=\"mt-4 mb-2\">Address</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalAddress\"\r" +
    "\n" +
    "                model=\"vm.application.company.physicalAddress\"\r" +
    "\n" +
    "                label=\"Address\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-8\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalSuiteAptNumber\"\r" +
    "\n" +
    "                model=\"vm.application.company.physicalSuiteAptNumber\"\r" +
    "\n" +
    "                label=\"Suite #\"\r" +
    "\n" +
    "                is-required=\"false\"\r" +
    "\n" +
    "                class=\"col-span-4\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalCity\"\r" +
    "\n" +
    "                model=\"vm.application.company.physicalCity\"\r" +
    "\n" +
    "                label=\"City\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <state-field\r" +
    "\n" +
    "                name=\"physicalState\"\r" +
    "\n" +
    "                model=\"vm.application.company.physicalState\"\r" +
    "\n" +
    "                label=\"State\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <zip-field\r" +
    "\n" +
    "                name=\"physicalZip\"\r" +
    "\n" +
    "                model=\"vm.application.company.physicalZip\"\r" +
    "\n" +
    "                label=\"Zip\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalCounty\"\r" +
    "\n" +
    "                model=\"vm.application.company.physicalCounty\"\r" +
    "\n" +
    "                label=\"County\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-12\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyCountyFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <check-field\r" +
    "\n" +
    "            name=\"addressesMatched\"\r" +
    "\n" +
    "            model=\"vm.addressesMatched\"\r" +
    "\n" +
    "            label=\"Mailing address is the same\"\r" +
    "\n" +
    "            wrapper-class=\"text-sm mt-auto ml-auto\"\r" +
    "\n" +
    "            on-change=\"vm.addressSameClicked()\">\r" +
    "\n" +
    "        </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"!vm.addressesMatched\"\r" +
    "\n" +
    "            class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailAddress\"\r" +
    "\n" +
    "                model=\"vm.application.company.mailAddress\"\r" +
    "\n" +
    "                label=\"Address\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-8\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailSuiteAptNumber\"\r" +
    "\n" +
    "                model=\"vm.application.company.mailSuiteAptNumber\"\r" +
    "\n" +
    "                label=\"Suite #\"\r" +
    "\n" +
    "                is-required=\"false\"\r" +
    "\n" +
    "                class=\"col-span-4\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailCity\"\r" +
    "\n" +
    "                model=\"vm.application.company.mailCity\"\r" +
    "\n" +
    "                label=\"City\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <state-field\r" +
    "\n" +
    "                name=\"mailState\"\r" +
    "\n" +
    "                model=\"vm.application.company.mailState\"\r" +
    "\n" +
    "                label=\"State\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <zip-field\r" +
    "\n" +
    "                name=\"mailZip\"\r" +
    "\n" +
    "                model=\"vm.application.company.mailZip\"\r" +
    "\n" +
    "                label=\"Zip\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailCounty\"\r" +
    "\n" +
    "                model=\"vm.application.company.mailCounty\"\r" +
    "\n" +
    "                label=\"County\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-12\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.applicationSettings.companyDateFormedFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyStateOfIncorporationFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyFEINFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyNumberOfEmployeesFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyFiscalYearEndFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyNmlsNumberFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "        vm.applicationSettings.companyWebsiteUrlFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4>Additional Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <date-field\r" +
    "\n" +
    "                name=\"dateFormed\"\r" +
    "\n" +
    "                model=\"vm.application.company.dateFormed\"\r" +
    "\n" +
    "                label=\"Date Company was Established\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyDateFormedFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyDateFormedFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </date-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <state-field\r" +
    "\n" +
    "                name=\"stateOfIncorporation\"\r" +
    "\n" +
    "                model=\"vm.application.company.stateOfIncorporation\"\r" +
    "\n" +
    "                label=\"State of Incorporation\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyStateOfIncorporationFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyStateOfIncorporationFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"fein\"\r" +
    "\n" +
    "                model=\"vm.application.company.fein\"\r" +
    "\n" +
    "                label=\"FEIN\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyFEINFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyFEINFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"numberOfEmployees\"\r" +
    "\n" +
    "                model=\"vm.application.company.numberOfEmployees\"\r" +
    "\n" +
    "                label=\"Number of Employees\"\r" +
    "\n" +
    "                type=\"number\"\r" +
    "\n" +
    "                step=\"1\"\r" +
    "\n" +
    "                max-length=\"4\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyNumberOfEmployeesFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyNumberOfEmployeesFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <date-field\r" +
    "\n" +
    "                name=\"fiscalYearEnd\"\r" +
    "\n" +
    "                model=\"vm.application.company.fiscalYearEnd\"\r" +
    "\n" +
    "                label=\"Fiscal Year End\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyFiscalYearEndFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyFiscalYearEndFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </date-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"nmlsNumber\"\r" +
    "\n" +
    "                model=\"vm.application.company.nmlsNumber\"\r" +
    "\n" +
    "                label=\"NMLS Number\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyNmlsNumberFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyNmlsNumberFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"websiteUrl\"\r" +
    "\n" +
    "                model=\"vm.application.company.websiteUrl\"\r" +
    "\n" +
    "                label=\"Website Url\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.companyWebsiteUrlFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.companyWebsiteUrlFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <application-form-footer on-back-click=\"vm.applicationStepManager.movePrevStep()\"></application-form-footer>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/application/panes/nameOnBondForm.html',
    "<form\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    name=\"nameOnBondForm\"\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(nameOnBondForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2\">{{vm.heading}}</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.applicationSettings.nameOnBondInstructions\">\r" +
    "\n" +
    "        {{vm.applicationSettings.nameOnBondInstructions}}\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-2\">\r" +
    "\n" +
    "        <select-field\r" +
    "\n" +
    "            name=\"nameOnBondDropdown\"\r" +
    "\n" +
    "            model=\"vm.nameOnBondDropdown\"\r" +
    "\n" +
    "            label=\"{{vm.dropdownFieldLabel}}\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            options=\"vm.nameOnBondOptions\">\r" +
    "\n" +
    "        </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"nameOnBondOther\"\r" +
    "\n" +
    "            model=\"vm.nameOnBondOther\"\r" +
    "\n" +
    "            ng-if=\"vm.nameOnBondDropdown == 'other'\"\r" +
    "\n" +
    "            label=\"{{vm.otherFieldLabel}}\"\r" +
    "\n" +
    "            is-required=\"true\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <application-form-footer on-back-click=\"vm.applicationStepManager.movePrevStep()\"></application-form-footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/application/panes/obligeeForm.html',
    "<form\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    name=\"obligeeForm\"\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(obligeeForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2\">Obligee Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-field\r" +
    "\n" +
    "        name=\"name\"\r" +
    "\n" +
    "        model=\"vm.application.obligee.name\"\r" +
    "\n" +
    "        label=\"Name\"\r" +
    "\n" +
    "        is-required=\"true\">\r" +
    "\n" +
    "    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.applicationSettings.obligeeAddressFieldStatus != 'Hidden' || vm.applicationSettings.obligeeCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h4 class=\"mt-4 mb-2\">Address</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalAddress\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.physicalAddress\"\r" +
    "\n" +
    "                label=\"Address\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-8\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalSuiteAptNumber\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.physicalSuiteAptNumber\"\r" +
    "\n" +
    "                label=\"Suite #\"\r" +
    "\n" +
    "                is-required=\"false\"\r" +
    "\n" +
    "                class=\"col-span-4\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalCity\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.physicalCity\"\r" +
    "\n" +
    "                label=\"City\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <state-field\r" +
    "\n" +
    "                name=\"physicalState\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.physicalState\"\r" +
    "\n" +
    "                label=\"State\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <zip-field\r" +
    "\n" +
    "                name=\"physicalZip\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.physicalZip\"\r" +
    "\n" +
    "                label=\"Zip\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"physicalCounty\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.physicalCounty\"\r" +
    "\n" +
    "                label=\"County\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-12\"\r" +
    "\n" +
    "                on-change=\"vm.physicalAddressChanged()\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <check-field\r" +
    "\n" +
    "            name=\"addressesMatched\"\r" +
    "\n" +
    "            model=\"vm.addressesMatched\"\r" +
    "\n" +
    "            label=\"Mailing address is the same\"\r" +
    "\n" +
    "            wrapper-class=\"text-sm mt-auto ml-auto\"\r" +
    "\n" +
    "            on-change=\"vm.addressSameClicked()\">\r" +
    "\n" +
    "        </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"!vm.addressesMatched\"\r" +
    "\n" +
    "            class=\"grid grid-cols-12 grid-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailAddress\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.mailAddress\"\r" +
    "\n" +
    "                label=\"Mailing Address\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-8\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.obligeeAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailSuiteAptNumber\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.mailSuiteAptNumber\"\r" +
    "\n" +
    "                label=\"Mailing Suite #\"\r" +
    "\n" +
    "                is-required=\"false\"\r" +
    "\n" +
    "                class=\"col-span-4\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.obligeeAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailCity\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.mailCity\"\r" +
    "\n" +
    "                label=\"Mailing City\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.obligeeAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <state-field\r" +
    "\n" +
    "                name=\"mailState\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.mailState\"\r" +
    "\n" +
    "                label=\"Mailing State\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.obligeeAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <zip-field\r" +
    "\n" +
    "                name=\"mailZip\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.mailZip\"\r" +
    "\n" +
    "                label=\"Mailing Zip\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-3\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.obligeeAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"mailCounty\"\r" +
    "\n" +
    "                model=\"vm.application.obligee.mailCounty\"\r" +
    "\n" +
    "                label=\"Mailing County\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.obligeeCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-12\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.obligeeCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mt-4 mb-2\">Contact Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <phone-field\r" +
    "\n" +
    "            name=\"phone\"\r" +
    "\n" +
    "            model=\"vm.application.obligee.phone\"\r" +
    "\n" +
    "            label=\"Phone\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.obligeePhoneFieldStatus == 'Required'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.obligeePhoneFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <phone-field\r" +
    "\n" +
    "            name=\"fax\"\r" +
    "\n" +
    "            model=\"vm.application.obligee.fax\"\r" +
    "\n" +
    "            label=\"Fax\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.obligeeFaxFieldStatus == 'Required'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.obligeeFaxFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"email\"\r" +
    "\n" +
    "            model=\"vm.application.obligee.email\"\r" +
    "\n" +
    "            label=\"Email\"\r" +
    "\n" +
    "            type=\"email\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.obligeeEmailFieldStatus == 'Required'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.obligeeEmailFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"contact1\"\r" +
    "\n" +
    "            model=\"vm.application.obligee.contact1\"\r" +
    "\n" +
    "            label=\"Contact 1\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.obligeeContact1FieldStatus == 'Required'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.obligeeContact1FieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"contact2\"\r" +
    "\n" +
    "            model=\"vm.application.obligee.contact2\"\r" +
    "\n" +
    "            label=\"Contact 2\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.obligeeContact2FieldStatus == 'Required'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.obligeeContact2FieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"contact3\"\r" +
    "\n" +
    "            model=\"vm.application.obligee.contact3\"\r" +
    "\n" +
    "            label=\"Contact 3\"\r" +
    "\n" +
    "            is-required=\"vm.applicationSettings.obligeeContact3FieldStatus == 'Required'\"\r" +
    "\n" +
    "            class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "            ng-if=\"vm.applicationSettings.obligeeContact3FieldStatus != 'Hidden'\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <text-field\r" +
    "\n" +
    "        name=\"websiteUrl\"\r" +
    "\n" +
    "        model=\"vm.application.obligee.websiteUrl\"\r" +
    "\n" +
    "        label=\"Website URL (Address)\"\r" +
    "\n" +
    "        is-required=\"vm.applicationSettings.obligeeWebsiteUrlFieldStatus == 'Required'\"\r" +
    "\n" +
    "        class=\"col-span-12 sm:col-span-3\"\r" +
    "\n" +
    "        ng-if=\"vm.applicationSettings.obligeeWebsiteUrlFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <application-form-footer on-back-click=\"vm.applicationStepManager.movePrevStep()\"></application-form-footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/application/panes/personForm.html',
    "<form\r" +
    "\n" +
    "    name=\"personForm\"\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(personForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-repeat=\"person in vm.application.people\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <h4 class=\"flex-1 mb-2\">\r" +
    "\n" +
    "                <span ng-show=\"vm.application.principalType != 'Company'\">\r" +
    "\n" +
    "                    Personal Information\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "                <span ng-show=\"vm.application.principalType == 'Company'\">\r" +
    "\n" +
    "                    Owner {{$index + 1}}\r" +
    "\n" +
    "                </span>\r" +
    "\n" +
    "            </h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <check-field\r" +
    "\n" +
    "                name=\"noMiddleName{{$index}}\"\r" +
    "\n" +
    "                model=\"person.noMiddleName\"\r" +
    "\n" +
    "                label=\"No Middle Name\"\r" +
    "\n" +
    "                class=\"text-sm mt-auto ml-auto font-normal\">\r" +
    "\n" +
    "            </check-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"firstName{{$index}}\"\r" +
    "\n" +
    "                model=\"person.firstName\"\r" +
    "\n" +
    "                label=\"First Name\"\r" +
    "\n" +
    "                is-required=\"true\"\r" +
    "\n" +
    "                class=\"col-span-12 md:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"middleName{{$index}}\"\r" +
    "\n" +
    "                model=\"person.middleName\"\r" +
    "\n" +
    "                label=\"Middle Name\"\r" +
    "\n" +
    "                is-required=\"!person.noMiddleName\"\r" +
    "\n" +
    "                class=\"col-span-12 md:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"lastName{{$index}}\"\r" +
    "\n" +
    "                model=\"person.lastName\"\r" +
    "\n" +
    "                label=\"Last Name\"\r" +
    "\n" +
    "                is-required=\"true\"\r" +
    "\n" +
    "                class=\"col-span-12 md:col-span-4\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"title{{$index}}\"\r" +
    "\n" +
    "                model=\"person.title\"\r" +
    "\n" +
    "                label=\"Title\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personPrefixFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.personPrefixFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"suffix{{$index}}\"\r" +
    "\n" +
    "                model=\"person.suffix\"\r" +
    "\n" +
    "                label=\"Suffix\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personSuffixFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.personSuffixFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"ownershipPercent{{$index}}\"\r" +
    "\n" +
    "                model=\"person.ownershipPercent\"\r" +
    "\n" +
    "                label=\"Ownership Percentage\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personOwnershipPercentFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.application.principalType == 'Company' && vm.applicationSettings.personOwnershipPercentFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                type=\"number\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <check-field\r" +
    "\n" +
    "                name=\"isUSCitizen{{$index}}\"\r" +
    "\n" +
    "                model=\"person.isUSCitizen\"\r" +
    "\n" +
    "                label=\"US Citizen\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personIsUSCitizenFieldStatus == 'Required'\"\r" +
    "\n" +
    "                class=\"col-span-6\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.personIsUSCitizenFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </check-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.applicationSettings.personEmailFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                vm.applicationSettings.personPhoneFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                vm.applicationSettings.personFaxFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 class=\"mt-4 mb-2\">Contact Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"email{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.email\"\r" +
    "\n" +
    "                    label=\"Email\"\r" +
    "\n" +
    "                    type=\"email\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personEmailFieldStatus == 'Required' || vm.application.principalType == 'Individual'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personEmailFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <phone-field\r" +
    "\n" +
    "                    name=\"phone{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.phone\"\r" +
    "\n" +
    "                    label=\"Phone\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personPhoneFieldStatus === 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personPhoneFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <phone-field\r" +
    "\n" +
    "                    name=\"fax{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.fax\"\r" +
    "\n" +
    "                    label=\"Fax\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personFaxFieldStatus === 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personFaxFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </phone-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.applicationSettings.personAddressFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                vm.applicationSettings.personCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 class=\"mt-4 mb-2\">Address</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-if=\"applicationSettings.personAddressFieldStatus != 'Hidden'\"\r" +
    "\n" +
    "                class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalAddress{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.physicalAddress\"\r" +
    "\n" +
    "                    label=\"Address\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-8\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged($index)\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalSuiteAptNumber{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.physicalSuiteAptNumber\"\r" +
    "\n" +
    "                    label=\"Suite #\"\r" +
    "\n" +
    "                    is-required=\"false\"\r" +
    "\n" +
    "                    class=\"col-span-4\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged($index)\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalCity{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.physicalCity\"\r" +
    "\n" +
    "                    label=\"City\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-6\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged($index)\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <state-field\r" +
    "\n" +
    "                    name=\"physicalState{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.physicalState\"\r" +
    "\n" +
    "                    label=\"State\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged($index)\">\r" +
    "\n" +
    "                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <zip-field\r" +
    "\n" +
    "                    name=\"physicalZip{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.physicalZip\"\r" +
    "\n" +
    "                    label=\"Zip\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged($index)\">\r" +
    "\n" +
    "                </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"physicalCounty{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.physicalCounty\"\r" +
    "\n" +
    "                    label=\"County\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12\"\r" +
    "\n" +
    "                    on-change=\"vm.physicalAddressChanged($index)\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <check-field\r" +
    "\n" +
    "                name=\"addressesMatched{{$index}}\"\r" +
    "\n" +
    "                model=\"vm.addressesMatched[$index]\"\r" +
    "\n" +
    "                label=\"Mailing address is the same\"\r" +
    "\n" +
    "                wrapper-class=\"text-sm mt-auto ml-auto\"\r" +
    "\n" +
    "                on-change=\"vm.addressSameClicked($index)\">\r" +
    "\n" +
    "            </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-show=\"!vm.addressesMatched[$index]\"\r" +
    "\n" +
    "                class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailAddress{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.mailAddress\"\r" +
    "\n" +
    "                    label=\"Mailing Address\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-8\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailSuiteAptNumber{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.mailSuiteAptNumber\"\r" +
    "\n" +
    "                    label=\"Mailing Suite #\"\r" +
    "\n" +
    "                    is-required=\"false\"\r" +
    "\n" +
    "                    class=\"col-span-4\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailCity{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.mailCity\"\r" +
    "\n" +
    "                    label=\"Mailing City\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <state-field\r" +
    "\n" +
    "                    name=\"mailState{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.mailState\"\r" +
    "\n" +
    "                    label=\"Mailing State\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <zip-field\r" +
    "\n" +
    "                    name=\"mailZip{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.mailZip\"\r" +
    "\n" +
    "                    label=\"Mailing Zip\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"mailCounty{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.mailCounty\"\r" +
    "\n" +
    "                    label=\"Mailing County\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.applicationSettings.personEmployerNameFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personEmployerAddressFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personEmployerCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 class=\"mt-4 mb-2\">Employer Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"employerName{{$index}}\"\r" +
    "\n" +
    "                model=\"person.employerName\"\r" +
    "\n" +
    "                label=\"Employer Name\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personEmployerNameFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.personEmployerNameFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"employerAddress{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.employerAddress\"\r" +
    "\n" +
    "                    label=\"Employer Address\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personEmployerAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-8\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personEmployerAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"employerSuiteAptNumber{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.employerSuiteAptNumber\"\r" +
    "\n" +
    "                    label=\"Employer Suite #\"\r" +
    "\n" +
    "                    is-required=\"false\"\r" +
    "\n" +
    "                    class=\"col-span-4\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personEmployerAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"employerCity{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.employerCity\"\r" +
    "\n" +
    "                    label=\"Employer City\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personEmployerAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personEmployerAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <state-field\r" +
    "\n" +
    "                    name=\"employerState{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.employerState\"\r" +
    "\n" +
    "                    label=\"Employer State\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personEmployerAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personEmployerAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <zip-field\r" +
    "\n" +
    "                    name=\"employerZip{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.employerZip\"\r" +
    "\n" +
    "                    label=\"Employer Zip\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personEmployerAddressFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-3\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personEmployerAddressFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </zip-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <text-field\r" +
    "\n" +
    "                name=\"employerCounty{{$index}}\"\r" +
    "\n" +
    "                model=\"person.employerCounty\"\r" +
    "\n" +
    "                label=\"Employer County\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personEmployerCountyFieldStatus == 'Required'\"\r" +
    "\n" +
    "                ng-if=\"vm.applicationSettings.personEmployerCountyFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "            </text-field>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.applicationSettings.personMaritalStatusFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personSpouseNameFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personSpouseCellPhoneFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personSpouseDateOfBirthFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personSpouseEmailFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personSpouseSsnFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 class=\"mt-4 mb-2\">Marital Status</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <select-field\r" +
    "\n" +
    "                name=\"maritalStatus{{$index}}\"\r" +
    "\n" +
    "                model=\"person.maritalStatus\"\r" +
    "\n" +
    "                label=\"Marital Status\"\r" +
    "\n" +
    "                options=\"[{label: 'Single', value: 'Single'}, {label: 'Married', value: 'Married'}]\"\r" +
    "\n" +
    "                is-required=\"vm.applicationSettings.personMaritalStatusFieldStatus == 'Required'\">\r" +
    "\n" +
    "            </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-show=\"person.maritalStatus == 'Married' &&\r" +
    "\n" +
    "                    (vm.applicationSettings.personSpouseNameFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                    vm.applicationSettings.personSpouseCellPhoneFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                    vm.applicationSettings.personSpouseDateOfBirthFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                    vm.applicationSettings.personSpouseEmailFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "                    vm.applicationSettings.personSpouseSsnFieldStatus != 'Hidden')\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"grid grid-cols-12 gap-x-2\"\r" +
    "\n" +
    "                    ng-show=\"vm.applicationSettings.personSpouseNameFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"spouseFirstName{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseFirstName\"\r" +
    "\n" +
    "                        label=\"Spouse First Name\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseNameFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"spouseMiddleName{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseMiddleName\"\r" +
    "\n" +
    "                        label=\"Spouse Middle Name\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseNameFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"spouseLastName{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseLastName\"\r" +
    "\n" +
    "                        label=\"Spouse Last Name\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseNameFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"spouseSsn{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseSsn\"\r" +
    "\n" +
    "                        label=\"Spouse SSN\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseSsnFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                        ng-if=\"vm.applicationSettings.personSpouseSsnFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <phone-field\r" +
    "\n" +
    "                        name=\"spouseCellPhone{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseCellPhone\"\r" +
    "\n" +
    "                        label=\"Spouse Mobile Phone\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseCellPhoneFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                        ng-if=\"vm.applicationSettings.personSpouseCellPhoneFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                    </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"spouseEmail{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseEmail\"\r" +
    "\n" +
    "                        label=\"Spouse Email\"\r" +
    "\n" +
    "                        type=\"email\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseEmailFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                        ng-if=\"vm.applicationSettings.personSpouseEmailFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <date-field\r" +
    "\n" +
    "                        name=\"spouseDob{{$index}}\"\r" +
    "\n" +
    "                        model=\"person.spouseDob\"\r" +
    "\n" +
    "                        label=\"Spouse Date of Birth\"\r" +
    "\n" +
    "                        is-required=\"vm.applicationSettings.personSpouseDateOfBirthFieldStatus == 'Required' && person.maritalStatus == 'Married'\"\r" +
    "\n" +
    "                        class=\"col-span-12 sm:col-span-4\"\r" +
    "\n" +
    "                        ng-if=\"vm.applicationSettings.personSpouseDateOfBirthFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                    </date-field>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-if=\"vm.applicationSettings.personYearsExperienceFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personDateOfBirthFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personSsnFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personDriversLicenseStateFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personDriversLicenseNumberFieldStatus != 'Hidden' ||\r" +
    "\n" +
    "            vm.applicationSettings.personResidenceTypeFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h4 class=\"mt-4 mb-2\">Additional Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"yearsExperience{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.yearsExperience\"\r" +
    "\n" +
    "                    label=\"Years of Experience\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personYearsExperienceFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personYearsExperienceFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <date-field\r" +
    "\n" +
    "                    name=\"dateOfBirth{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.dateOfBirth\"\r" +
    "\n" +
    "                    label=\"Date of Birth\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personDateOfBirthFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personDateOfBirthFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </date-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <ssn-field\r" +
    "\n" +
    "                    name=\"ssn{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.ssn\"\r" +
    "\n" +
    "                    label=\"SSN\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personSsnFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personSsnFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </ssn-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <state-field\r" +
    "\n" +
    "                    name=\"driversLicenseState{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.driversLicenseState\"\r" +
    "\n" +
    "                    label=\"Drivers License State\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personDriversLicenseStateFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personDriversLicenseStateFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"driversLicenseNumber{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.driversLicenseNumber\"\r" +
    "\n" +
    "                    label=\"Drivers License Number\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personDriversLicenseNumberFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personDriversLicenseNumberFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <select-field\r" +
    "\n" +
    "                    name=\"residenceType{{$index}}\"\r" +
    "\n" +
    "                    model=\"person.residenceType\"\r" +
    "\n" +
    "                    label=\"Is Residence Owned or Rented\"\r" +
    "\n" +
    "                    is-required=\"vm.applicationSettings.personResidenceTypeFieldStatus == 'Required'\"\r" +
    "\n" +
    "                    options=\"[{label: 'Owned', value: 'Owned'}, {label: 'Rented', value: 'Rented'}]\"\r" +
    "\n" +
    "                    class=\"col-span-12 sm:col-span-6\"\r" +
    "\n" +
    "                    ng-if=\"vm.applicationSettings.personResidenceTypeFieldStatus != 'Hidden'\">\r" +
    "\n" +
    "                </select-field>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <application-form-footer \r" +
    "\n" +
    "        on-back-click=\"vm.applicationStepManager.movePrevStep()\">\r" +
    "\n" +
    "    </application-form-footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/application/panes/productSelectionForm.html',
    "<form\r" +
    "\n" +
    "    class=\"px-5 pb-12\"\r" +
    "\n" +
    "    name=\"productSelectionStepForm\"\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(productSelectionStepForm)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mb-2\">Bond Type</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <state-field\r" +
    "\n" +
    "        ng-if=\"vm.initialLoadComplete\"\r" +
    "\n" +
    "        name=\"state\"\r" +
    "\n" +
    "        model=\"vm.state\"\r" +
    "\n" +
    "        label=\"State Requiring Bond\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        on-change=\"vm.stateSelectionChanged()\">\r" +
    "\n" +
    "    </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <select-field\r" +
    "\n" +
    "        name=\"bondTypeId\"\r" +
    "\n" +
    "        model=\"vm.application.bondTypeId\"\r" +
    "\n" +
    "        options=\"vm.bondTypeOptions\"\r" +
    "\n" +
    "        option-label=\"name\"\r" +
    "\n" +
    "        option-value=\"id\"\r" +
    "\n" +
    "        label=\"Type of bond required\"\r" +
    "\n" +
    "        on-change=\"vm.bondTypeSelectionChanged()\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        is-disabled=\"vm.bondTypeFieldDisabled\">\r" +
    "\n" +
    "    </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <currency-field\r" +
    "\n" +
    "        name=\"bondAmount\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        model=\"vm.application.bondAmount\"\r" +
    "\n" +
    "        label=\"Bond Amount\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        ng-if=\"vm.bondAmountType === 'UserDefined' || !vm.bondAmountType\"\r" +
    "\n" +
    "        is-disabled=\"!vm.bondAmountType\"\r" +
    "\n" +
    "        min=\"vm.applicationSettings.minimumBondAmount\"\r" +
    "\n" +
    "        max=\"vm.applicationSettings.maximumBondAmount\"\r" +
    "\n" +
    "        min-message=\"Must be between {{vm.applicationSettings.minimumBondAmount | currency : '$'}} and {{vm.applicationSettings.maximumBondAmount | currency : '$'}}\"\r" +
    "\n" +
    "        max-message=\"Must be between {{vm.applicationSettings.minimumBondAmount | currency : '$'}} and {{vm.applicationSettings.maximumBondAmount | currency : '$'}}\">\r" +
    "\n" +
    "    </currency-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <currency-field\r" +
    "\n" +
    "        name=\"bondAmount\"\r" +
    "\n" +
    "        inputmode=\"numeric\"\r" +
    "\n" +
    "        model=\"vm.application.bondAmount\"\r" +
    "\n" +
    "        label=\"Bond Amount\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        ng-if=\"vm.bondAmountType === 'Fixed'\"\r" +
    "\n" +
    "        is-disabled=\"vm.bondAmountType === 'Fixed'\">\r" +
    "\n" +
    "    </currency-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <select-field\r" +
    "\n" +
    "        name=\"bondAmount\"\r" +
    "\n" +
    "        model=\"vm.application.bondAmount\"\r" +
    "\n" +
    "        label=\"Bond Amount\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        ng-if=\"vm.bondAmountType === 'Variable'\"\r" +
    "\n" +
    "        options=\"vm.variableBondAmountOptions\">\r" +
    "\n" +
    "    </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <select-field\r" +
    "\n" +
    "        name=\"principalType\"\r" +
    "\n" +
    "        model=\"vm.application.principalType\"\r" +
    "\n" +
    "        label=\"Is your bond for a company or individual?\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        options=\"[{label: 'Individual', value: 'Individual'}, {label: 'Company', value: 'Company'}]\"\r" +
    "\n" +
    "        on-change=\"vm.principalTypeChanged()\"\r" +
    "\n" +
    "        is-disabled=\"vm.bondTypeFieldDisabled\">\r" +
    "\n" +
    "    </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4 class=\"mt-6\">Effective Date</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p class=\"mb-2\">\r" +
    "\n" +
    "        Most carriers require the effective date to be recent or in the very near future. When backdating is not allowed, the earliest effective date is the date of purchase.\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <date-field\r" +
    "\n" +
    "        model=\"vm.application.desiredEffectiveDate\"\r" +
    "\n" +
    "        name=\"desiredEffectiveDate\"\r" +
    "\n" +
    "        label=\"Desired Effective Date\"\r" +
    "\n" +
    "        min=\"vm.applicationSettings.minimumEffectiveDate\"\r" +
    "\n" +
    "        max=\"vm.applicationSettings.maximumEffectiveDate\"\r" +
    "\n" +
    "        is-required=\"true\"\r" +
    "\n" +
    "        is-disabled=\"vm.bondTypeFieldDisabled\">\r" +
    "\n" +
    "    </date-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"!vm.currentUser && !mainVm.eproducerAccountId\">\r" +
    "\n" +
    "        <h4 class=\"mt-6\">Referring Agent</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <referring-eproducer-broker-selection\r" +
    "\n" +
    "            name=\"isBrokerReferral\"\r" +
    "\n" +
    "            broker=\"vm.application.broker\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            is-broker-referral=\"vm.application.isBrokerReferral\"\r" +
    "\n" +
    "            class=\"mt-2\">\r" +
    "\n" +
    "        </referring-eproducer-broker-selection>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <application-form-footer\r" +
    "\n" +
    "        application-step-manager=\"vm.applicationStepManager\"\r" +
    "\n" +
    "        hide-back-button=\"true\">\r" +
    "\n" +
    "    </application-form-footer>\r" +
    "\n" +
    "</form>\r" +
    "\n"
  );


  $templateCache.put('app/states/application/panes/reviewAndSubmit.html',
    "<login-modal\r" +
    "\n" +
    "    is-open=\"vm.showLoginModal\"\r" +
    "\n" +
    "    on-login=\"vm.showLoginModal = false\">\r" +
    "\n" +
    "</login-modal>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<form\r" +
    "\n" +
    "    name=\"reviewAndSubmitForm\"\r" +
    "\n" +
    "    novalidate\r" +
    "\n" +
    "    ng-submit=\"vm.nextClicked(reviewAndSubmitForm)\"\r" +
    "\n" +
    "    class=\"px-5 pb-12\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        ng-if=\"!vm.currentUser\"\r" +
    "\n" +
    "        class=\"mb-4\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <h4 class=\"mt-4 mb-2\">Create Login</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <inline-alert error=\"vm.loginExistsError\"></inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex\">\r" +
    "\n" +
    "            <radio-field\r" +
    "\n" +
    "                name=\"createLogin\"\r" +
    "\n" +
    "                model=\"vm.createLogin\"\r" +
    "\n" +
    "                on-change=\"vm.createLoginChanged()\"\r" +
    "\n" +
    "                label=\"\"\r" +
    "\n" +
    "                class=\"flex-1 my-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <radio-option value=\"true\">Create Login</radio-option>\r" +
    "\n" +
    "                <radio-option value=\"false\">Continue as Guest</radio-option>\r" +
    "\n" +
    "            </radio-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <a\r" +
    "\n" +
    "                class=\"ml-auto my-auto\"\r" +
    "\n" +
    "                ng-click=\"vm.showLoginModal = !vm.showLoginModal\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Already have a login?\r" +
    "\n" +
    "            </a>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <text-field\r" +
    "\n" +
    "            name=\"loginEmail\"\r" +
    "\n" +
    "            model=\"vm.newLogin.email\"\r" +
    "\n" +
    "            label=\"Email\"\r" +
    "\n" +
    "            type=\"email\"\r" +
    "\n" +
    "            is-required=\"vm.createLogin === 'true'\"\r" +
    "\n" +
    "            on-change=\"vm.emailChanged()\"\r" +
    "\n" +
    "            ng-show=\"vm.createLogin === 'true'\">\r" +
    "\n" +
    "        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <new-password\r" +
    "\n" +
    "            model=\"vm.newLogin.password\"\r" +
    "\n" +
    "            is-required=\"vm.createLogin === 'true'\"\r" +
    "\n" +
    "            on-change=\"vm.passwordChanged()\"\r" +
    "\n" +
    "            ng-show=\"vm.createLogin === 'true'\">\r" +
    "\n" +
    "        </new-password>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-if=\"vm.applicationSettings.requiresCreditReport\">\r" +
    "\n" +
    "        <h4>CONSENT FOR CREDIT REPORT</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            In order to quote your <product-type is-policy=\"vm.applicationSettings.isPolicy\"></product-type>,\r" +
    "\n" +
    "            we will need to pull credit report(s). This is a soft credit check, which means that this inquiry\r" +
    "\n" +
    "            won't negatively affect your credit rating, score, or other inquiries.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>\r" +
    "\n" +
    "            You understand that by agreeing to the terms and conditions, you are providing\r" +
    "\n" +
    "            written instructions to {{vm.systemAccountContext?.companyName}} under the Fair Credit Reporting Act authorizing\r" +
    "\n" +
    "            {{vm.systemAccountContext?.companyName}} to obtain information from your personal credit profile or other\r" +
    "\n" +
    "            information from Experian, Equifax, and/or Transunion. You authorize us to obtain such information solely to\r" +
    "\n" +
    "            prequalify you for surety and/or insurance products.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h4>TERMS AND CONDITIONS</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <b class=\"text-sm\">I/We hereby acknowledge the following:</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <check-list>\r" +
    "\n" +
    "        <check-list-item>I understand that changes made after purchase can result in additional cost.</check-list-item>\r" +
    "\n" +
    "        <check-list-item>I have reviewed the printed name on bond it is correct as required for my bond.</check-list-item>\r" +
    "\n" +
    "        <check-list-item>I have reviewed all of the information provided and it is correct</check-list-item>\r" +
    "\n" +
    "    </check-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mt-2\">\r" +
    "\n" +
    "        <check-field\r" +
    "\n" +
    "            name=\"agreeToTerms\"\r" +
    "\n" +
    "            model=\"vm.agreeToTerms\"\r" +
    "\n" +
    "            label=\"By checking this box you are agreeing to terms and conditions\"\r" +
    "\n" +
    "            is-required=\"true\"\r" +
    "\n" +
    "            invalid-shake=\"true\">\r" +
    "\n" +
    "        </check-field>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <application-form-footer\r" +
    "\n" +
    "        on-back-click=\"vm.applicationStepManager.movePrevStep()\"\r" +
    "\n" +
    "        next-button-text=\"Submit\">\r" +
    "\n" +
    "    </application-form-footer>\r" +
    "\n" +
    "</form>"
  );


  $templateCache.put('app/states/application/purchase.html',
    "<div \r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <form\r" +
    "\n" +
    "        novalidate\r" +
    "\n" +
    "        name=\"purchaseForm\"\r" +
    "\n" +
    "        ng-submit=\"vm.submit(purchaseForm)\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <inline-alert\r" +
    "\n" +
    "            class=\"max-w[1400px] mb-4 block\"\r" +
    "\n" +
    "            error=\"vm.error\">\r" +
    "\n" +
    "        </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"max-w-[1400px] mx-auto lg:flex\">\r" +
    "\n" +
    "            <div class=\"lg:ml-auto lg:flex-[4]\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div ng-if=\"!vm.quote.allowsElectronicSignature\">\r" +
    "\n" +
    "                    <h2 class=\"mb-4\">Shipping Information</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"card mb-6\">\r" +
    "\n" +
    "                        <div class=\"mb-2 text-sm\">\r" +
    "\n" +
    "                            We are required to sign this <product-type is-policy=\"vm.quote.isPolicy\"></product-type> and ship it to you. Please let us know where you'd like to have it shipped to.\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <check-field\r" +
    "\n" +
    "                            name=\"shippingExpedited\"\r" +
    "\n" +
    "                            model=\"vm.shipping.expedited\"\r" +
    "\n" +
    "                            label=\"Expedited Shipping and Processing\"\r" +
    "\n" +
    "                            on-change=\"vm.expediteChanged()\"\r" +
    "\n" +
    "                            class=\"block mt-4\">\r" +
    "\n" +
    "                        </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            class=\"red text-sm\"\r" +
    "\n" +
    "                            ng-show=\"vm.shipping.expedited && vm.systemAccount.expeditedProcessingFee > 0\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            An addtional fee of <b>{{vm.systemAccount.expeditedProcessingFee | currency:'$'}}</b> will be applied for expedited shipping and processing.\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"grid grid-cols-12 gap-x-2 mt-4\">\r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"shippingMailTo\"\r" +
    "\n" +
    "                                model=\"vm.shipping.mailTo\"\r" +
    "\n" +
    "                                label=\"Mail To\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-12\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"shippingMailAddress\"\r" +
    "\n" +
    "                                model=\"vm.shipping.mailAddress\"\r" +
    "\n" +
    "                                label=\"Address\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-8\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"shippingMailSuiteAptNumber\"\r" +
    "\n" +
    "                                model=\"vm.shipping.mailSuiteAptNumber\"\r" +
    "\n" +
    "                                label=\"Suite/Apt #\"\r" +
    "\n" +
    "                                class=\"col-span-4\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"shippingMailCity\"\r" +
    "\n" +
    "                                model=\"vm.shipping.mailCity\"\r" +
    "\n" +
    "                                label=\"City\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-6\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <state-field\r" +
    "\n" +
    "                                name=\"shippingMailSate\"\r" +
    "\n" +
    "                                model=\"vm.shipping.mailState\"\r" +
    "\n" +
    "                                label=\"State\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-3\">\r" +
    "\n" +
    "                            </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <zip-field\r" +
    "\n" +
    "                                name=\"shippingMailZip\"\r" +
    "\n" +
    "                                model=\"vm.shipping.mailZip\"\r" +
    "\n" +
    "                                label=\"Zip\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-3\">\r" +
    "\n" +
    "                            </zip-field>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    <h2 class=\"mb-4\">Payment Method</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-if=\"vm.allowDeferredPayment\"\r" +
    "\n" +
    "                        class=\"card mb-4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <label>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                type=\"radio\"\r" +
    "\n" +
    "                                name=\"DeferredPaymentType\"\r" +
    "\n" +
    "                                value=\"DeferredPayment\"\r" +
    "\n" +
    "                                ng-model=\"vm.payment.paymentMethod\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Invoice and Pay Later\r" +
    "\n" +
    "                        </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div ng-if=\"vm.payment.paymentMethod == 'DeferredPayment'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"mt-4 text-sm\">Please review the information below carefully. If you have any questions, please contact us at <span class=\"whitespace-nowrap\">{{mainVm.branding.phone}}</span>.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <h4 class=\"mt-6\">Terms &amp; Conditions</h4>\r" +
    "\n" +
    "                            <hr class=\"my-1\" />\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <b class=\"block mt-4 text-sm\">I/We hereby acknowledge the following:</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <check-list class=\"block mt-2\">\r" +
    "\n" +
    "                                <check-list-item>I/We will receive an invoice for the amount specified.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I/We will be responsible to pay the invoice in order for the <product-type is-policy=\"vm.quote.isPolicy\"></product-type> to be executed</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I have entered all application information correct to the best of my knowledge</check-list-item>\r" +
    "\n" +
    "                            </check-list>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <check-field\r" +
    "\n" +
    "                                name=\"deferredPaymentAgreeToTerms\"\r" +
    "\n" +
    "                                model=\"vm.agreeToTermsDeferredPayment\"\r" +
    "\n" +
    "                                label=\"I agree to the terms and conditions above\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"block mt-6\"\r" +
    "\n" +
    "                                invalid-shake=\"true\">\r" +
    "\n" +
    "                            </check-field>                \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div \r" +
    "\n" +
    "                        ng-if=\"vm.systemAccount.allowACHPaymentsThroughEProducer\"\r" +
    "\n" +
    "                        class=\"card mb-4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <label>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                type=\"radio\"\r" +
    "\n" +
    "                                name=\"AchPaymentType\"\r" +
    "\n" +
    "                                value=\"ACH\"\r" +
    "\n" +
    "                                ng-model=\"vm.payment.paymentMethod\"\r" +
    "\n" +
    "                                required />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Electonic Check (ACH)\r" +
    "\n" +
    "                        </label>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-if=\"vm.payment.paymentMethod == 'ACH'\"\r" +
    "\n" +
    "                            class=\"mt-4\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"accountNumber\"\r" +
    "\n" +
    "                                type=\"numeric\"\r" +
    "\n" +
    "                                model=\"vm.payment.accountNumber\"\r" +
    "\n" +
    "                                label=\"Account Number\"\r" +
    "\n" +
    "                                is-required=\"true\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"confirmAccountNumber\"\r" +
    "\n" +
    "                                type=\"numeric\"\r" +
    "\n" +
    "                                model=\"vm.confirmAccountNumber\"\r" +
    "\n" +
    "                                label=\"Account Number\"\r" +
    "\n" +
    "                                matches=\"vm.payment.accountNumber\"\r" +
    "\n" +
    "                                is-required=\"true\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <routing-number-field\r" +
    "\n" +
    "                                name=\"routingNumberField\"\r" +
    "\n" +
    "                                model=\"vm.payment.routingNumber\"\r" +
    "\n" +
    "                                label=\"Routing Number\"\r" +
    "\n" +
    "                                is-required=\"true\">\r" +
    "\n" +
    "                            </routing-number-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <select-field\r" +
    "\n" +
    "                                name=\"paymentACHAccountType\"\r" +
    "\n" +
    "                                model=\"vm.payment.accountType\"\r" +
    "\n" +
    "                                label=\"Account Type\"\r" +
    "\n" +
    "                                options=\"vm.achAccountTypeOptions\"\r" +
    "\n" +
    "                                is-required=\"true\">\r" +
    "\n" +
    "                            </select-field>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <h4 class=\"mt-4\">Bank Account Owner</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"md:grid md:grid-cols-2 md:gap-x-2\">\r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentFirstName\"\r" +
    "\n" +
    "                                    model=\"vm.payment.firstName\"\r" +
    "\n" +
    "                                    label=\"First Name\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentLastName\"\r" +
    "\n" +
    "                                    model=\"vm.payment.lastName\"\r" +
    "\n" +
    "                                    label=\"Last Name\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <phone-field\r" +
    "\n" +
    "                                    name=\"paymentPhone\"\r" +
    "\n" +
    "                                    model=\"vm.payment.phone\"\r" +
    "\n" +
    "                                    label=\"Phone\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </phone-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentEmail\"\r" +
    "\n" +
    "                                    model=\"vm.payment.email\"\r" +
    "\n" +
    "                                    label=\"Email\"\r" +
    "\n" +
    "                                    type=\"email\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentAddress\"\r" +
    "\n" +
    "                                    model=\"vm.payment.address\"\r" +
    "\n" +
    "                                    label=\"Address\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-8\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentSuiteAptNumber\"\r" +
    "\n" +
    "                                    model=\"vm.payment.suiteAptNumber\"\r" +
    "\n" +
    "                                    label=\"Suite Number\"\r" +
    "\n" +
    "                                    class=\"col-span-4\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentCity\"\r" +
    "\n" +
    "                                    model=\"vm.payment.city\"\r" +
    "\n" +
    "                                    label=\"City\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-6\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <state-field\r" +
    "\n" +
    "                                    name=\"paymentState\"\r" +
    "\n" +
    "                                    model=\"vm.payment.state\"\r" +
    "\n" +
    "                                    label=\"State\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-3\">\r" +
    "\n" +
    "                                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <zip-field\r" +
    "\n" +
    "                                    name=\"paymentZip\"\r" +
    "\n" +
    "                                    model=\"vm.payment.zip\"\r" +
    "\n" +
    "                                    label=\"Zip\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-3\">\r" +
    "\n" +
    "                                </zip-field>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <h4 class=\"mt-6\">Terms &amp; Conditions</h4>\r" +
    "\n" +
    "                            <hr class=\"my-1\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <b class=\"block mt-4 text-sm\">I/We hereby acknowledge the following:</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <check-list class=\"block mt-2\">\r" +
    "\n" +
    "                                <check-list-item>Due to the legal nature of the product(s) all purchases are not refundable even if you no longer need your <product-type is-policy=\"vm.quote.isPolicy\"></product-type>.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>Any changes after purchase will result in additional fees.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>Any fee is separate from the Premium for the product we are purchasing.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>Were made aware of any fees PRIOR to purchase of the item(s) mentioned above.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I/We will be responsible to pay the invoice in order for the <product-type is-policy=\"vm.quote.isPolicy\"></product-type> to be executed</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I have entered all application information correct to the best of my knowledge</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I authorize {{mainVm.branding.companyName}} to charge my selected account for the total amount listed above plus the appropriate fee.</check-list-item>\r" +
    "\n" +
    "                            </check-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <check-field\r" +
    "\n" +
    "                                name=\"achTerms\"\r" +
    "\n" +
    "                                model=\"vm.agreeToTermsACH\"\r" +
    "\n" +
    "                                label=\"I agree to the terms and conditions\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                invalid-shake=\"true\"\r" +
    "\n" +
    "                                class=\"block mt-6\">\r" +
    "\n" +
    "                            </check-field>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        ng-if=\"vm.systemAccount.allowCCPaymentsThroughEProducer\" \r" +
    "\n" +
    "                        class=\"card mb-4\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <label>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <input \r" +
    "\n" +
    "                                type=\"radio\"\r" +
    "\n" +
    "                                name=\"CCPaymentType\"\r" +
    "\n" +
    "                                value=\"CreditCard\"\r" +
    "\n" +
    "                                ng-model=\"vm.payment.paymentMethod\"\r" +
    "\n" +
    "                                required />    \r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                Credit Card\r" +
    "\n" +
    "                        </label>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-if=\"vm.payment.paymentMethod == 'CreditCard'\"\r" +
    "\n" +
    "                            class=\"mt-4\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"paymentCardNumber\"\r" +
    "\n" +
    "                                model=\"vm.payment.cardNumber\"\r" +
    "\n" +
    "                                label=\"Credit Card Number\"\r" +
    "\n" +
    "                                maxLength=\"25\"\r" +
    "\n" +
    "                                minLength=\"13\"\r" +
    "\n" +
    "                                is-required=\"true\">\r" +
    "\n" +
    "                            </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"grid grid-cols-2 gap-x-2\">\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <select-field\r" +
    "\n" +
    "                                    name=\"paymentExpirationMonth\"\r" +
    "\n" +
    "                                    model=\"vm.payment.expirationMonth\"\r" +
    "\n" +
    "                                    label=\"Exp. Month\"\r" +
    "\n" +
    "                                    options=\"vm.creditCardExpirationMonthOptions\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-1\">\r" +
    "\n" +
    "                                </select-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <select-field\r" +
    "\n" +
    "                                    name=\"paymentExpirationYear\"\r" +
    "\n" +
    "                                    model=\"vm.payment.expirationYear\"\r" +
    "\n" +
    "                                    label=\"Exp Year\"\r" +
    "\n" +
    "                                    options=\"vm.creditCardExpirationYearOptions\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-1\">\r" +
    "\n" +
    "                                </select-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentSecurityCode\"\r" +
    "\n" +
    "                                    model=\"vm.payment.securityCode\"\r" +
    "\n" +
    "                                    label=\"Security Code\"\r" +
    "\n" +
    "                                    maxLength=\"4\"\r" +
    "\n" +
    "                                    minLength=\"3\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-1\">\r" +
    "\n" +
    "                                </text-field>                        \r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <h4 class=\"my-4\">Card Holder Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"md:grid md:grid-cols-2 md:gap-x-2\">\r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentFirstName\"\r" +
    "\n" +
    "                                    model=\"vm.payment.firstName\"\r" +
    "\n" +
    "                                    label=\"First Name\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentLastName\"\r" +
    "\n" +
    "                                    model=\"vm.payment.lastName\"\r" +
    "\n" +
    "                                    label=\"Last Name\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                <phone-field\r" +
    "\n" +
    "                                    name=\"paymentPhone\"\r" +
    "\n" +
    "                                    model=\"vm.payment.phone\"\r" +
    "\n" +
    "                                    label=\"Phone\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </phone-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentEmail\"\r" +
    "\n" +
    "                                    model=\"vm.payment.email\"\r" +
    "\n" +
    "                                    label=\"Email\"\r" +
    "\n" +
    "                                    type=\"email\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"md:col-span-1\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentAddress\"\r" +
    "\n" +
    "                                    model=\"vm.payment.address\"\r" +
    "\n" +
    "                                    label=\"Address\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-8\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentSuiteAptNumber\"\r" +
    "\n" +
    "                                    model=\"vm.payment.suiteAptNumber\"\r" +
    "\n" +
    "                                    label=\"Suite Number\"\r" +
    "\n" +
    "                                    class=\"col-span-4\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <text-field\r" +
    "\n" +
    "                                    name=\"paymentCity\"\r" +
    "\n" +
    "                                    model=\"vm.payment.city\"\r" +
    "\n" +
    "                                    label=\"City\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-6\">\r" +
    "\n" +
    "                                </text-field>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                                <state-field\r" +
    "\n" +
    "                                    name=\"paymentState\"\r" +
    "\n" +
    "                                    model=\"vm.payment.state\"\r" +
    "\n" +
    "                                    label=\"State\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-3\">\r" +
    "\n" +
    "                                </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <zip-field\r" +
    "\n" +
    "                                    name=\"paymentZip\"\r" +
    "\n" +
    "                                    model=\"vm.payment.zip\"\r" +
    "\n" +
    "                                    label=\"Zip\"\r" +
    "\n" +
    "                                    is-required=\"true\"\r" +
    "\n" +
    "                                    class=\"col-span-3\">\r" +
    "\n" +
    "                                </zip-field>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <h4 class=\"mt-6\">Terms &amp; Conditions</h4>\r" +
    "\n" +
    "                            <hr class=\"my-1\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <b class=\"block mt-4 text-sm\">I/We hereby acknowledge the following:</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <check-list class=\"block mt-2\">\r" +
    "\n" +
    "                                <check-list-item>Due to the legal nature of the product(s) all purchases are not refundable even if you no longer need your <product-type is-policy=\"vm.quote.isPolicy\"></product-type>.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>Any changes after purchase will result in additional fees.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>Any fee is separate from the Premium for the product we are purchasing.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>Were made aware of any fees PRIOR to purchase of the item(s) mentioned above.</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I/We will be responsible to pay the invoice in order for the <product-type is-policy=\"vm.quote.isPolicy\"></product-type> to be executed</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I have entered all application information correct to the best of my knowledge</check-list-item>\r" +
    "\n" +
    "                                <check-list-item>I authorize {{mainVm.branding.companyName}} to charge my selected account for the total amount listed above plus the appropriate fee.</check-list-item>\r" +
    "\n" +
    "                            </check-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <check-field\r" +
    "\n" +
    "                                name=\"creditCardTerms\"\r" +
    "\n" +
    "                                model=\"vm.agreeToTermsCreditCard\"\r" +
    "\n" +
    "                                label=\"I agree to the terms and conditions\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                invalid-shake=\"true\"\r" +
    "\n" +
    "                                class=\"block mt-6\">\r" +
    "\n" +
    "                            </check-field>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"lg:sticky lg:top-5 lg:flex-[3] lg:mb-auto lg:ml-8\">           \r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <b>\r" +
    "\n" +
    "                            <product-type \r" +
    "\n" +
    "                                capitalize-first-letter=\"true\"\r" +
    "\n" +
    "                                is-policy=\"vm.quote.isPolicy\">\r" +
    "\n" +
    "                            </product-type> \r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Type\r" +
    "\n" +
    "                        </b>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <hr class=\"my-2\" />\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                        <div>\r" +
    "\n" +
    "                            <div>{{vm.quote.bondType}}</div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"text-sm\">\r" +
    "\n" +
    "                                Name on \r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <product-type \r" +
    "\n" +
    "                                    capitalize-first-letter=\"true\"\r" +
    "\n" +
    "                                    is-policy=\"vm.quote.isPolicy\">\r" +
    "\n" +
    "                                </product-type>\r" +
    "\n" +
    "                                \r" +
    "\n" +
    "                                : {{vm.quote.nameOnBond}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"text-sm\">\r" +
    "\n" +
    "                                <product-type \r" +
    "\n" +
    "                                    capitalize-first-letter=\"true\"\r" +
    "\n" +
    "                                    is-policy=\"vm.quote.isPolicy\">\r" +
    "\n" +
    "                                </product-type>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                                <span> Amount: </span>\r" +
    "\n" +
    "                                {{vm.quote.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"text-sm\">\r" +
    "\n" +
    "                                Effective Date:\r" +
    "\n" +
    "                                {{vm.quote.desiredEffectiveDate | dateOnly}}\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"text-sm\">Term: {{vm.quote.term}} year(s)</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                    <div class=\"mt-4\">\r" +
    "\n" +
    "                        <b>Carrier</b>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <hr class=\"my-2\" />\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <system-account-logo\r" +
    "\n" +
    "                                ng-if=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                                system-account-id=\"vm.quote.carrierSystemAccountId\"\r" +
    "\n" +
    "                                class=\"my-auto block h-14 w-14\">\r" +
    "\n" +
    "                            </system-account-logo>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "                            <div class=\"ml-4 my-auto\">\r" +
    "\n" +
    "                                <div>{{vm.quote.carrierName}}</div>\r" +
    "\n" +
    "                                <div class=\"text-sm\">{{vm.quote.writingCompany}}</div>\r" +
    "\n" +
    "                                <div class=\"text-sm\">AM Best Rating: {{vm.quote.carrierAmBestRating}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>  \r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"mt-6\">\r" +
    "\n" +
    "                        <b>Payment Detail</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <hr class=\"my-2\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex mt-2 text-sm\">\r" +
    "\n" +
    "                            <div class=\"flex-1\">Premium</div>\r" +
    "\n" +
    "                            <div class=\"ml-2\">{{vm.quote.totalPremium | currency:'$'}}</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div\r" +
    "\n" +
    "                            ng-repeat=\"fee in vm.quote.fees\" \r" +
    "\n" +
    "                            class=\"flex mt-2 text-sm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            <div class=\"flex-1\">{{fee.description}}</div>\r" +
    "\n" +
    "                            <div class=\"ml-2\">{{fee.amount | currency:'$'}}</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            ng-show=\"vm.payment.processingFee\"\r" +
    "\n" +
    "                            class=\"flex mt-2 text-sm\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"flex-1\">Payment Processing Fee</div>\r" +
    "\n" +
    "                            <div class=\"ml-2\">{{vm.processingFeeFormatted}}</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div \r" +
    "\n" +
    "                            ng-show=\"vm.expediteFee\"\r" +
    "\n" +
    "                            class=\"flex mt-2 text-sm\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <div class=\"flex-1\">Expedited Processing Fee</div>\r" +
    "\n" +
    "                            <div class=\"ml-2\">{{vm.expediteFee | currency:'$'}}</div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <hr class=\"my-2\" />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex mt-2\">\r" +
    "\n" +
    "                            <div class=\"flex-1 text-sm my-auto\">Total Payment Amount</div>\r" +
    "\n" +
    "                            <b class=\"my-auto ml-2 text-eproducer-blue\">{{ vm.quote.totalAmountDue + vm.expediteFee + vm.payment.processingFee | currency:'$' }}</b>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex gap-x-4 mt-6\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a\r" +
    "\n" +
    "                        ui-sref=\"main.confirmedQuotes({uniqueId: vm.quote.applicationUniqueId})\"\r" +
    "\n" +
    "                        class=\"my-auto flex-1\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "                        Back to Quotes\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        type=\"submit\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.submitting\"\r" +
    "\n" +
    "                        class=\"button flex-1 md:flex-none md:ml-auto my-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"!vm.submitting\">Purchase</span>\r" +
    "\n" +
    "                        <span ng-show=\"vm.submitting\">Processing...</span> \r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-right\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </form>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>"
  );


  $templateCache.put('app/states/documents/documents.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <inline-alert\r" +
    "\n" +
    "        success-message=\"vm.successMessage\"\r" +
    "\n" +
    "        error=\"vm.error\"\r" +
    "\n" +
    "        class=\"block mb-4\">\r" +
    "\n" +
    "    </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <dropzone\r" +
    "\n" +
    "        on-success=\"vm.uploadSuccess()\"\r" +
    "\n" +
    "        on-error=\"vm.uploadError(error)\"\r" +
    "\n" +
    "        class=\"block mb-4\">\r" +
    "\n" +
    "    </dropzone>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2 class=\"mb-2\">Documents</h2>\r" +
    "\n" +
    "        <div class=\"mb-4\">\r" +
    "\n" +
    "            Sharing documents with us is easy. Click the box below to add documents directly to your account.\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"hidden md:grid md:grid-cols-12 font-bold py-2 px-6 rounded bg-gray-200 shadow\">\r" +
    "\n" +
    "            <div class=\"col-span-5\">Filename</div>\r" +
    "\n" +
    "            <div class=\"col-span-2\">File Size</div>\r" +
    "\n" +
    "            <div class=\"col-span-5\">Upload Date</div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-repeat=\"attachment in vm.table.data\"\r" +
    "\n" +
    "            class=\"card mt-4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"hidden md:grid md:grid-cols-12\">\r" +
    "\n" +
    "                <div class=\"col-span-5\">{{attachment.filename}}</div>\r" +
    "\n" +
    "                <div class=\"col-span-2\">{{attachment.fileSize | fileSize:2 }}</div>\r" +
    "\n" +
    "                <div class=\"col-span-2\">{{attachment.createdDateTime | date: 'MM/dd/yyyy'}}</div>\r" +
    "\n" +
    "                <div class=\"col-span-3 flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <pdf-download\r" +
    "\n" +
    "                        url=\"attachment.downloadUrl\"\r" +
    "\n" +
    "                        processing-promise=\"vm.processingPromise\"\r" +
    "\n" +
    "                        filename=\"attachment.filename\"\r" +
    "\n" +
    "                        button-text=\"Download\"\r" +
    "\n" +
    "                        button-class=\"\"\r" +
    "\n" +
    "                        class=\"ml-auto my-auto\">\r" +
    "\n" +
    "                    </pdf-download>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"md:hidden\">\r" +
    "\n" +
    "                {{attachment.filename}}\r" +
    "\n" +
    "                <div class=\"text-sm\">\r" +
    "\n" +
    "                    File size: {{attachment.fileSize | fileSize:2}} <br/>\r" +
    "\n" +
    "                    Uploaded Date: {{attachment.createdDateTime | date: 'MM/dd/yyyy'}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <pdf-download\r" +
    "\n" +
    "                    processiong-promise=\"vm.processingPromise\"\r" +
    "\n" +
    "                    url=\"attachment.downloadUrl\"\r" +
    "\n" +
    "                    filename=\"attachment.filename\"\r" +
    "\n" +
    "                    button-text=\"Download\"\r" +
    "\n" +
    "                    button-class=\"\">\r" +
    "\n" +
    "                </pdf-download>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        visible=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        heading=\"No documents found...\"\r" +
    "\n" +
    "        message=\"It looks like you don't have any documents uploaded yet.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage' && vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"sticky bottom-0 block\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<busy-indicator \r" +
    "\n" +
    "    promise=\"vm.loadingPromise\"\r" +
    "\n" +
    "    message=\"Loading...\">\r" +
    "\n" +
    "</busy-indicator>"
  );


  $templateCache.put('app/states/indemnity/indemnitySignatureList.html',
    "<div\r" +
    "\n" +
    "    ng-show=\"vm.pane === 'send'\"\r" +
    "\n" +
    "    cg-busy=\"{promise:vm.processingPromise}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"bg-slate-200 h-11\">\r" +
    "\n" +
    "        <span class=\"text-black px-3 leading-11\">Looks like we need to get some signatures</span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"py-3 px-5 max-w-4xl mx-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2 class=\"mb-3\">Don't worry it's easy</h2>\r" +
    "\n" +
    "        <p class=\"mb-3\">We'll send emails to each individual that is required to sign. The emails will contain a link that will take you to a secure signature portal. Once all signatures are collected you'll be able to proceed to purchase. <b>Click the button below to send signature request emails.</b></p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <form\r" +
    "\n" +
    "            name=\"form\"\r" +
    "\n" +
    "            ng-submit=\"vm.sendIndemnityRequests(form.$valid)\"\r" +
    "\n" +
    "            novalidate>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                class=\"p-2.5 mb-5 rounded border border-solid border-red-600 bg-red-100\"\r" +
    "\n" +
    "                ng-show=\"(form.$submitted || form.$dirty) && form.$invalid\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                Not all of the email addresses are valid\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"flex flex-col gap-y-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <main\r" +
    "\n" +
    "                    class=\"card p-3\"\r" +
    "\n" +
    "                    ng-repeat=\"signature in vm.indemnitySignatures\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <b ng-show=\"signature.indemnityType === 'Spouse'\">\r" +
    "\n" +
    "                            Spousal Indemnity\r" +
    "\n" +
    "                        </b>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <b ng-show=\"signature.indemnityType === 'Corporate'\">\r" +
    "\n" +
    "                            Corporate Indemnity\r" +
    "\n" +
    "                        </b>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <b ng-show=\"signature.indemnityType !== 'Spouse' && signature.indemnityType !== 'Corporate'\">\r" +
    "\n" +
    "                            Individual Indemnity\r" +
    "\n" +
    "                        </b>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <br/> \r" +
    "\n" +
    "                        {{signature.printedName}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"emailedToAddress{{signature.id}}\"\r" +
    "\n" +
    "                            model=\"signature.emailedToAddress\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            placeholder=\"Signee Email Addresss\"\r" +
    "\n" +
    "                            type=\"email\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </main>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    type=\"submit\"\r" +
    "\n" +
    "                    class=\"button font-bold\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    Send Signature Requests\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<div \r" +
    "\n" +
    "    class=\"eProducer\"\r" +
    "\n" +
    "    ng-show=\"vm.pane === 'review'\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"bg-slate-200 h-11\">\r" +
    "\n" +
    "        <span class=\"text-black px-3 leading-11\">Electronic Signature Status</span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"py-3 px-5 max-w-4xl mx-auto\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignaturesUnsignedCount > 0\"\r" +
    "\n" +
    "            class=\"mb-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Waiting on Electronic Signatures\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignaturesUnsignedCount == 0\"\r" +
    "\n" +
    "            class=\"mb-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            Ready for purchase\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignaturesUnsignedCount == 0\"\r" +
    "\n" +
    "            class=\"mb-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            It looks like we have all of the indemnity signatures on file. Thanks for getting that taken care of! You can use the button below to proceed with the purchase\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "        <p\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignaturesUnsignedCount > 0\"\r" +
    "\n" +
    "            class=\"mb-3\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            It looks like we are still waiting on <b>{{vm.indemnitySignaturesUnsignedCount}}</b> signatures. As soon as we have collected all signatures you'll be able to proceed with your purchase.\r" +
    "\n" +
    "        </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"flex flex-col gap-y-3\">\r" +
    "\n" +
    "            <main\r" +
    "\n" +
    "                ng-repeat=\"signature in vm.indemnitySignatures\"\r" +
    "\n" +
    "                class=\"card p-3 flex gap-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"text-center\"\r" +
    "\n" +
    "                    ng-show=\"signature.status === 'Signed'\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <i class=\"fa fa-check text-5xl text-lime-400\"></i>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <span class=\"text-gray-600 text-xs\">Signed</span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    class=\"text-center\"\r" +
    "\n" +
    "                    ng-show=\"signature.status !== 'Signed'\">\r" +
    "\n" +
    "                    <i class=\"fa fa-clock-o text-5xl text-amber-500\"></i>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <span class=\"text-gray-600 text-xs\">Unsigned</span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"flex-grow text-gray-700 text-sm\"\r" +
    "\n" +
    "                    ng-show=\"signature.status === 'Signed'\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <b ng-show=\"signature.indemnityType === 'Spouse'\">\r" +
    "\n" +
    "                        Spousal Indemnity\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b ng-show=\"signature.indemnityType === 'Corporate'\">\r" +
    "\n" +
    "                        Corporate Indemnity\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b ng-show=\"signature.indemnityType !== 'Spouse' && signature.indemnityType !== 'Corporate'\">\r" +
    "\n" +
    "                        Individual Indemnity\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "                                        \r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <span>Signed As:</span> {{signature.signature}}\r" +
    "\n" +
    "      \r" +
    "\n" +
    "                    <br /> \r" +
    "\n" +
    "                    {{signature.signatureDateTime | date: 'MM/dd/yyyy'}} at {{signature.signatureDateTime | date: 'hh:mm a'}}\r" +
    "\n" +
    "      \r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <span>IP Address</span> {{signature.clientIp}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"flex-grow text-gray-700 text-sm\"\r" +
    "\n" +
    "                    ng-show=\"signature.status !== 'Signed'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b ng-show=\"signature.indemnityType === 'Spouse'\">\r" +
    "\n" +
    "                        Spousal Indemnity\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b ng-show=\"signature.indemnityType === 'Corporate'\">\r" +
    "\n" +
    "                        Corporate Indemnity\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b ng-show=\"signature.indemnityType !== 'Spouse' && signature.indemnityType !== 'Corporate'\">\r" +
    "\n" +
    "                        Individual Indemnity\r" +
    "\n" +
    "                    </b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <span>Signee :</span> {{signature.printedName}}\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <span>Email: </span> {{signature.emailedToAddress}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"clear-fix\"></div>\r" +
    "\n" +
    "            </main>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            class=\"button font-bold mt-3 w-full\"\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignaturesUnsignedCount === 0\"\r" +
    "\n" +
    "            ui-sref=\"main.payment({quoteId: vm.quoteId})\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            Proceed To Purchase\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/indemnity/indemnitySignatureSign.html',
    "<div ng-form=\"indemnityForm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"bg-slate-200 h-11\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"text-black px-3 leading-11 sm:hidden\"\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            We just need you to sign something real quick\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"hidden text-black px-3 leading-11 sm:inline\"\r" +
    "\n" +
    "            ng-show=\"vm.indemnitySignature.status != 'Signed'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <b>You're almost done! Before we finish up, we need you to sign some things</b>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"p-2.5 mb-5 rounded border border-solid border-red-600 bg-red-100 m-3\"\r" +
    "\n" +
    "        ng-show=\"!vm.indemnitySignature\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        We couldn't find a matching indemnity agreement.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div\r" +
    "\n" +
    "        class=\"py-2 px-3 max-w-4xl mx-auto\"\r" +
    "\n" +
    "        ng-show=\"vm.indemnitySignature\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2>Indemnity Agreement</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <form\r" +
    "\n" +
    "            name=\"form\"\r" +
    "\n" +
    "            novalidate\r" +
    "\n" +
    "            ng-submit=\"vm.signIndemnity(form.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div ng-bind-html=\"vm.indemnityLanguage\"></div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p class=\"font-semibold\">\r" +
    "\n" +
    "                Electronic Signature\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <p class=\"mt-2\">\r" +
    "\n" +
    "                Before issuing your {{ main.productType ? main.productType : 'Bond/Policy' }} all applicants and their spouses (if applicable) must sign the following indemnity Agreement. Each of the individuals specified must review and e-sign this agreement by typing their full legal name\r" +
    "\n" +
    "                in the corresponging signature box.\r" +
    "\n" +
    "            </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"text-center mt-2\">\r" +
    "\n" +
    "                <b>\r" +
    "\n" +
    "                    Printed Name: {{vm.indemnitySignature.printedName}} \r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <span ng-show=\"vm.indemnitySignature.indemnityType == 'Corporate'\">\r" +
    "\n" +
    "                        (Corporate)\r" +
    "\n" +
    "                    </span>\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "                <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <text-field\r" +
    "\n" +
    "                    name=\"signature\"\r" +
    "\n" +
    "                    model=\"vm.indemnitySignature.signature\"\r" +
    "\n" +
    "                    placeholder=\"Signature\"\r" +
    "\n" +
    "                    is-required=\"true\">\r" +
    "\n" +
    "                </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <b\r" +
    "\n" +
    "                    ng-show=\"vm.invalidSignature\"\r" +
    "\n" +
    "                    class=\"text-red-800\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    You must sign your name exactly as printed above.\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"mt-3\">\r" +
    "\n" +
    "                <button\r" +
    "\n" +
    "                    class=\"button font-bold  w-full\"\r" +
    "\n" +
    "                    type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    E-Sign and Continue\r" +
    "\n" +
    "                </button>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/login/login.html',
    "<div class=\"p-5 flex\">\r" +
    "\n" +
    "    <div class=\"card mx-auto md:w-[500px] mt-16\">\r" +
    "\n" +
    "        <h2 class=\"mb-4\">\r" +
    "\n" +
    "            <span ng-show=\"!vm.requestPasswordReset\">Login</span>\r" +
    "\n" +
    "            <span ng-show=\"vm.requestPasswordReset\">Forgot Password</span>\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <login-form\r" +
    "\n" +
    "            on-login=\"vm.loggedIn()\"\r" +
    "\n" +
    "            on-back-to-login-click=\"vm.requestPasswordReset = false\"\r" +
    "\n" +
    "            on-forgot-password-click=\"vm.requestPasswordReset = true\">\r" +
    "\n" +
    "        </login-form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/openApplications/openApplications.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"!vm.error\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        <h2 class=\"mb-4\">Open Applications</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"hidden md:flex font-bold py-2 px-6 rounded bg-gray-200 shadow\">\r" +
    "\n" +
    "            <div class=\"text-center w-20\">#</div>\r" +
    "\n" +
    "            <div class=\"w-52\">Name on Bond</div>\r" +
    "\n" +
    "            <div class=\"text-center w-40\">Effective</div>\r" +
    "\n" +
    "            <div class=\"flex-1\">Bond Type</div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-repeat=\"application in vm.table.data\"\r" +
    "\n" +
    "            class=\"card mt-4 md:flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"hidden md:flex\">\r" +
    "\n" +
    "                <div class=\"my-auto text-center w-20\">{{application.applicationNumber}}</div>\r" +
    "\n" +
    "                <div class=\"my-auto w-52\">{{application.nameOnBond}}</div>\r" +
    "\n" +
    "                <div class=\"my-auto text-center w-40\">{{application.desiredEffectiveDate | date}}</div>\r" +
    "\n" +
    "                <div class=\"my-auto flex-1\">\r" +
    "\n" +
    "                    {{application.bondType}}\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <em>Bond Amount: </em><span class=\"text-sm\">{{application.bondAmount | currency:'$'}}</span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"md:hidden\">\r" +
    "\n" +
    "                <b>#{{application.applicationNumber}}</b>\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    {{application.bondType}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-sm\">\r" +
    "\n" +
    "                    <em>Bond Amount:</em> {{application.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <em>Name on Bond:</em> {{application.nameOnBond}}\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <em>Bond ammount:</em> {{application.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <em>Effective:</em> {{application.desiredEffectiveDate | date}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"button mt-4 md:my-auto ml-auto w-full md:w-auto\"\r" +
    "\n" +
    "                ui-sref=\"main.application({uniqueId: application.uniqueId})\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Resume Application\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        visible=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        heading=\"No applications found...\"\r" +
    "\n" +
    "        message=\"It looks like you don't have any open applications currently.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage' && vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"sticky bottom-0 block\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<busy-indicator \r" +
    "\n" +
    "    promise=\"vm.loadingPromise\"\r" +
    "\n" +
    "    message=\"Loading...\">\r" +
    "\n" +
    "</busy-indicator>"
  );


  $templateCache.put('app/states/paymentForm/paymentForm.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"md:mx-auto md:max-w-[950px]\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <inline-alert \r" +
    "\n" +
    "            error=\"vm.error\"\r" +
    "\n" +
    "            wrapper-class=\"mb-4\">\r" +
    "\n" +
    "        </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- CUSTOMER_LOOKUP_FORM -->\r" +
    "\n" +
    "        <div ng-show=\"vm.activePane == vm.CUSTOMER_LOOKUP_FORM\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h2 class=\"mb-2\">Account Lookup</h2>\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"lookupForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.searchCustomerPayables()\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "                    <div class=\"mb-4\">\r" +
    "\n" +
    "                        Thank you for using our online payment portal. To get started, help us find your information by providing your lookup code and your zip code.\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        <b>Don't know your account number?</b>\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        No problem, <a ng-click=\"vm.showLookupCodeSearch()\">Click Here</a> to search for it.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"lookupCode\"\r" +
    "\n" +
    "                        model=\"vm.lookupCode\"\r" +
    "\n" +
    "                        label=\"Account #\"\r" +
    "\n" +
    "                        is-required=\"true\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <zip-field\r" +
    "\n" +
    "                        name=\"lookupZip\"\r" +
    "\n" +
    "                        model=\"vm.lookupZip\"\r" +
    "\n" +
    "                        label=\"Zip Code\"\r" +
    "\n" +
    "                        is-required=\"true\">\r" +
    "\n" +
    "                    </zip-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <check-field\r" +
    "\n" +
    "                        name=\"saveLookupCode\"\r" +
    "\n" +
    "                        model=\"vm.saveLookupCode\"\r" +
    "\n" +
    "                        label=\"Remember my lookup number\">\r" +
    "\n" +
    "                    </check-field>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex mt-4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        type=\"submit\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.searchingLookup\"\r" +
    "\n" +
    "                        class=\"button ml-auto flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"!vm.searchingLookup\"\r" +
    "\n" +
    "                            class=\"my-auto\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Search\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            ng-show=\"vm.searchingLookup\"\r" +
    "\n" +
    "                            class=\"my-auto\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            Searching...\r" +
    "\n" +
    "                        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-right my-auto ml-2\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- CUSTOMER_SEARCH_FORM -->\r" +
    "\n" +
    "        <div ng-show=\"vm.activePane == vm.CUSTOMER_SEARCH_FORM\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h2 class=\"mb-2\">Account Number Lookup</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"customerSearchForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.submitCustomerSearch(customerSearchForm.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"mb-4\">\r" +
    "\n" +
    "                        To help us find your account, please provide one of the following pieces of information.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"customerSearchBondNumber\"\r" +
    "\n" +
    "                        model=\"vm.customerSearchBondNumber\"\r" +
    "\n" +
    "                        label=\"Bond Number\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h3 class=\"mb-2 text-center md:text-left\">OR</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <phone-field\r" +
    "\n" +
    "                        name=\"customerSearchPhone\"\r" +
    "\n" +
    "                        model=\"vm.customerSearchPhone\"\r" +
    "\n" +
    "                        label=\"Phone Number\">\r" +
    "\n" +
    "                    </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h3 class=\"mb-2 text-center md:text-left\">OR</h3>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <text-field\r" +
    "\n" +
    "                        name=\"customerSearchEmail\"\r" +
    "\n" +
    "                        model=\"vm.customerSearchEmail\"\r" +
    "\n" +
    "                        label=\"Email Address\">\r" +
    "\n" +
    "                    </text-field>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex mt-4 gap-x-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        type=\"button\"\r" +
    "\n" +
    "                        class=\"button bg-slate-200 text-slate-600 flex-1 md:flex-none md:ml-auto\"\r" +
    "\n" +
    "                        ng-click=\"vm.activePane = vm.CUSTOMER_LOOKUP_FORM\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "                        <span>Back</span>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        type=\"submit\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.customerSearchFormSubmitting\"\r" +
    "\n" +
    "                        class=\"button flex-1 md:flex-none\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"!vm.customerSearchFormSubmitting\">Search</span>\r" +
    "\n" +
    "                        <span ng-show=\"vm.customerSearchFormSubmitting\">Searching...</span>\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-right\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- CUSTOMER_FOUND_FORM -->\r" +
    "\n" +
    "        <div\r" +
    "\n" +
    "            ng-show=\"vm.activePane == vm.CUSTOMER_FOUND_FORM\"\r" +
    "\n" +
    "            class=\"card\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h2 class=\"mb-2\">Great News!</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"customerFoundForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.customerFoundSubmitted(customerFoundForm.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "                    <div class=\"mb-4\">\r" +
    "\n" +
    "                        We found a customer record that matches the information you provided. Please have review the information below to ensure that we have the correct account.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <b>Account #: {{vm.searchMatch.lookupCode}}</b>\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <b>{{vm.searchMatch.name}}</b>\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    {{vm.searchMatch.address}} {{vm.searchMatch.suiteAptNumber}}\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    {{vm.searchMatch.city}}, {{vm.searchMatch.state}}, {{vm.searchMatch.zip}}\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    {{vm.searchMatch.phone}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                <div class=\"flex mt-4 gap-x-2\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        type=\"button\"\r" +
    "\n" +
    "                        class=\"button bg-slate-200 text-slate-600 flex-1 md:flex-none md:ml-auto\"\r" +
    "\n" +
    "                        ng-click=\"vm.activePane = vm.CUSTOMER_SEARCH_FORM\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "                        <span>Back</span>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        type=\"submit\"\r" +
    "\n" +
    "                        class=\"button flex-1 md:flex-none\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span>Continue</span>\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-right\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>                \r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- PAYMENT_FORM -->\r" +
    "\n" +
    "        <div ng-show=\"vm.activePane == vm.PAYMENT_FORM\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <h2 class=\"mb-2\">Online Payment Portal</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"paymentForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.submitPaymentForm(paymentForm.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "                    <div\r" +
    "\n" +
    "                        class=\"mb-4\" \r" +
    "\n" +
    "                        ng-if=\"!mainVm.currentUser\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <b>{{vm.name}}</b>\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        Account #: {{vm.lookupCode}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        {{vm.address}} {{vm.suiteAptNumber}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        {{vm.city}}, {{vm.state}}, {{vm.zip}}\r" +
    "\n" +
    "                        <br/>\r" +
    "\n" +
    "                        {{vm.phone}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"my-2\">\r" +
    "\n" +
    "                        Please provide an invoice number or bond number as a reference to your payment.\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"sm:grid sm:grid-cols-2 sm:gap-x-2\">\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            ng-show=\"vm.otherPayment\"\r" +
    "\n" +
    "                            name=\"otherPaymentReferenceNumber\"\r" +
    "\n" +
    "                            model=\"vm.otherPaymentReferenceNumber\"\r" +
    "\n" +
    "                            label=\"Reference #\"\r" +
    "\n" +
    "                            is-required=\"vm.otherPaymentReferenceNumberIsRequired\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <currency-field\r" +
    "\n" +
    "                            ng-show=\"vm.otherPayment\"\r" +
    "\n" +
    "                            name=\"otherPaymentAmount\"\r" +
    "\n" +
    "                            model=\"vm.otherPaymentAmount\"\r" +
    "\n" +
    "                            label=\"Payment Amount\"\r" +
    "\n" +
    "                            on-change=\"vm.calculatePaymentAmount()\"\r" +
    "\n" +
    "                            min=\".01\"\r" +
    "\n" +
    "                            min-message=\"please enter a valid payment amount\"\r" +
    "\n" +
    "                            is-required=\"vm.otherPaymentAmountIsRequired\">\r" +
    "\n" +
    "                        </currency-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h4 class=\"my-4\">Billing Information</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"md:grid md:grid-cols-2 md:gap-x-2\">\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentCompanyName\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.companyName\"\r" +
    "\n" +
    "                            label=\"Company Name\"\r" +
    "\n" +
    "                            class=\"md:col-span-2\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentFirstName\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.firstName\"\r" +
    "\n" +
    "                            label=\"First Name\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"md:col-span-1\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentLastName\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.lastName\"\r" +
    "\n" +
    "                            label=\"Last Name\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"md:col-span-1\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                        <phone-field\r" +
    "\n" +
    "                            name=\"dtoPaymentPhone\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.phone\"\r" +
    "\n" +
    "                            label=\"Phone\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"md:col-span-1\">\r" +
    "\n" +
    "                        </phone-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentEmail\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.email\"\r" +
    "\n" +
    "                            label=\"Email\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"md:col-span-1\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h4 class=\"my-2\">Billing Address</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"grid grid-cols-12 gap-x-2\">\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentAddress\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.address\"\r" +
    "\n" +
    "                            label=\"Address\"\r" +
    "\n" +
    "                            class=\"col-span-8\"\r" +
    "\n" +
    "                            is-required=\"true\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentSuiteAptNumber\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.suiteAptNumber\"\r" +
    "\n" +
    "                            class=\"col-span-4\"\r" +
    "\n" +
    "                            label=\"Suite Number\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentCity\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.city\"\r" +
    "\n" +
    "                            label=\"City\"\r" +
    "\n" +
    "                            class=\"col-span-6\"\r" +
    "\n" +
    "                            is-required=\"true\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <state-field\r" +
    "\n" +
    "                            name=\"dtoPaymentState\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.state\"\r" +
    "\n" +
    "                            label=\"State\"\r" +
    "\n" +
    "                            class=\"col-span-3\"\r" +
    "\n" +
    "                            is-required=\"true\">\r" +
    "\n" +
    "                        </state-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <zip-field\r" +
    "\n" +
    "                            name=\"dtoPaymentZip\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.zip\"\r" +
    "\n" +
    "                            label=\"Zip\"\r" +
    "\n" +
    "                            class=\"col-span-3\"\r" +
    "\n" +
    "                            is-required=\"true\">\r" +
    "\n" +
    "                        </zip-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <check-field\r" +
    "\n" +
    "                        name=\"saveBillingInformation\"\r" +
    "\n" +
    "                        model=\"vm.saveBillingInformation\"\r" +
    "\n" +
    "                        label=\"Remember my billing information\">\r" +
    "\n" +
    "                    </check-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <h4 class=\"my-4\">Payment Method</h4>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <select-field\r" +
    "\n" +
    "                        name=\"dtoPaymentPaymentMethod\"\r" +
    "\n" +
    "                        model=\"vm.dto.payment.paymentMethod\"\r" +
    "\n" +
    "                        label=\"Payment Method\"\r" +
    "\n" +
    "                        options=\"vm.paymentMethodOptions\"\r" +
    "\n" +
    "                        is-required=\"true\">\r" +
    "\n" +
    "                    </select-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"vm.dto.payment.paymentMethod === 'ACH' && vm.allowACHPayments\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"accountNumber\"\r" +
    "\n" +
    "                            type=\"numeric\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.accountNumber\"\r" +
    "\n" +
    "                            label=\"Account Number\"\r" +
    "\n" +
    "                            is-required=\"vm.dto.paymentMethod == 'ACH'\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"confirmAccountNumber\"\r" +
    "\n" +
    "                            type=\"numeric\"\r" +
    "\n" +
    "                            model=\"vm.confirmAccountNumber\"\r" +
    "\n" +
    "                            label=\"Account Number\"\r" +
    "\n" +
    "                            matches=\"vm.dto.payment.accountNumber\"\r" +
    "\n" +
    "                            is-required=\"vm.dto.paymentMethod == 'ACH'\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <routing-number-field\r" +
    "\n" +
    "                            name=\"routingNumberField\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.routingNumber\"\r" +
    "\n" +
    "                            label=\"Routing Number\"\r" +
    "\n" +
    "                            is-required=\"vm.dto.paymentMethod == 'ACH'\">\r" +
    "\n" +
    "                        </routing-number-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <select-field\r" +
    "\n" +
    "                            name=\"dtoPaymentACHAccountType\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.achAccountType\"\r" +
    "\n" +
    "                            label=\"Account Type\"\r" +
    "\n" +
    "                            options=\"vm.achAccountTypeOptions\"\r" +
    "\n" +
    "                            is-required=\"true\">\r" +
    "\n" +
    "                        </select-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-if=\"vm.dto.payment.paymentMethod === 'CreditCard' && vm.allowCCPayments\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"dtoPaymentCardNumber\"\r" +
    "\n" +
    "                            model=\"vm.dto.payment.cardNumber\"\r" +
    "\n" +
    "                            label=\"Credit Card Number\"\r" +
    "\n" +
    "                            maxLength=\"25\"\r" +
    "\n" +
    "                            minLength=\"13\"\r" +
    "\n" +
    "                            is-required=\"true\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"grid grid-cols-2 gap-x-2\">\r" +
    "\n" +
    "                            \r" +
    "\n" +
    "                            <select-field\r" +
    "\n" +
    "                                name=\"dtoPaymentExpirationMonth\"\r" +
    "\n" +
    "                                model=\"vm.dto.payment.expirationMonth\"\r" +
    "\n" +
    "                                label=\"Exp. Month\"\r" +
    "\n" +
    "                                options=\"vm.creditCardExpirationMonthOptions\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-1\">\r" +
    "\n" +
    "                            </select-field>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <select-field\r" +
    "\n" +
    "                                name=\"dtoPaymentExpirationYear\"\r" +
    "\n" +
    "                                model=\"vm.dto.payment.expirationYear\"\r" +
    "\n" +
    "                                label=\"Exp Year\"\r" +
    "\n" +
    "                                options=\"vm.creditCardExpirationYearOptions\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-1\">\r" +
    "\n" +
    "                            </select-field>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                            <text-field\r" +
    "\n" +
    "                                name=\"dtoPaymentSecurityCode\"\r" +
    "\n" +
    "                                model=\"vm.dto.payment.securityCode\"\r" +
    "\n" +
    "                                label=\"Security Code\"\r" +
    "\n" +
    "                                maxLength=\"4\"\r" +
    "\n" +
    "                                minLength=\"3\"\r" +
    "\n" +
    "                                is-required=\"true\"\r" +
    "\n" +
    "                                class=\"col-span-1\">\r" +
    "\n" +
    "                            </text-field>                        \r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex mt-4 gap-x-2\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button ml-auto\"\r" +
    "\n" +
    "                        type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span>Next</span>\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-right\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- REVIEW_PAYMENT -->\r" +
    "\n" +
    "        <div ng-show=\"vm.activePane == vm.REVIEW_PAYMENT\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h2 class=\"mb-2\">Almost finished</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"reviewPaymentForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.submitReviewPaymentForm(reviewPaymentForm)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <span>Please review the information below before confirming your payment. After you have confirmed that all this information is correct click the</span>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span class=\"pl-1 whitespace-nowrap text-sm bg-eproducer-blue text-white rounded\">\r" +
    "\n" +
    "                            <span>Pay</span> \r" +
    "\n" +
    "                            <i class=\"fa fa-chevron-right text-xs\"></i>\r" +
    "\n" +
    "                        </span> \r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span>&nbsp;button to submit your payment.</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"mt-8\">\r" +
    "\n" +
    "                        <h4>Billing Information</h4>\r" +
    "\n" +
    "                        <hr />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <div class=\"flex-1\">\r" +
    "\n" +
    "                                <div ng-show=\"vm.dto.payment.companyName\">{{vm.dto.payment.companyName}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.firstName}} {{vm.dto.payment.lastName}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.address}} {{vm.dto.payment.suiteAptNumber}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.city}}, {{vm.dto.payment.state}} {{vm.dto.payment.zip}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"flex-1 ml-1\">\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.phone}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.email}}</div>\r" +
    "\n" +
    "                                <div>{{vm.paymentDetails}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table class=\"mt-8 w-full\">\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th class=\"text-left border-b border-slate-200\">Payment Details</th>\r" +
    "\n" +
    "                                <th class=\"text-right whitespace-nowrap border-b border-slate-200\">Amount Due</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-repeat=\"payable in vm.dto.payables\">\r" +
    "\n" +
    "                                <td class=\"text-left align-top py-0.5\">\r" +
    "\n" +
    "                                    <div\r" +
    "\n" +
    "                                        ng-class=\"{'mt-1': !$first}\"\r" +
    "\n" +
    "                                        ng-repeat=\"lineItem in payable.lineItems track by $index\"\r" +
    "\n" +
    "                                        ng-bind-html=\"lineItem.parsedDescription\">\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <td class=\"whitespace-nowrap text-right align-top py-0.5\">\r" +
    "\n" +
    "                                    {{payable.totalAmountDue | currency:'$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.otherPayment\">\r" +
    "\n" +
    "                                <td class=\"align-top py-0.5\">Reference #: {{vm.otherPaymentReferenceNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"whitespace-nowrap text-right align-top py-0.5\">{{vm.otherPaymentAmount | currency:'$'}}</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.dto.payment.processingFee\">\r" +
    "\n" +
    "                                <td class=\"text-left align-top py-0.5\">\r" +
    "\n" +
    "                                    Payment processing fee\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"whitespace-nowrap text-right align-top py-0.5\">\r" +
    "\n" +
    "                                    {{vm.dto.payment.processingFee | currency:'$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                        <tfoot>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    colspan=\"3\"\r" +
    "\n" +
    "                                    class=\"border-t border-slate-200 text-right\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    Payment Amount: <b class=\"ml-2 inline-block\">{{ vm.totalPaymentAmount | currency:'$' }}</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td></td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tfoot>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div>\r" +
    "\n" +
    "                        <h4 class=\"mt-6\">Terms &amp; Conditions</h4>\r" +
    "\n" +
    "                        <hr class=\"my-1\" />\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <b class=\"block mt-4 text-sm\">I/We hereby acknowledge the following:</b>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <check-list class=\"block mt-2\">\r" +
    "\n" +
    "                            <check-list-item>Due to the unique nature of the product(s) that all premiums and/or fees are considered fully earned and are not refundable even if your bond is canceled mid-term.</check-list-item>\r" +
    "\n" +
    "                            <check-list-item>This agency fee is separate from the Premium for the product we are purchasing.</check-list-item>\r" +
    "\n" +
    "                            <check-list-item>You are aware of this fee PRIOR to purchase of the item(s) mentioned above.</check-list-item>\r" +
    "\n" +
    "                            <check-list-item>You Authorize {{mainVm.branding.companyName}} to charge my selected account for the total amount listed above plus the appropriate fee. <em>For more information please contact us at {{mainVm.branding.phone}}</em></check-list-item>\r" +
    "\n" +
    "                            <check-list-item>Any returned electronic check is subject to fees and penalties.</check-list-item>\r" +
    "\n" +
    "                            <check-list-item>You have reviewed <b>all</b> of the information <b>above</b> and it is correct</check-list-item>\r" +
    "\n" +
    "                        </check-list>\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <check-field\r" +
    "\n" +
    "                            name=\"agreeToTerms\"\r" +
    "\n" +
    "                            model=\"vm.agreeToTerms\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            label=\"I agree to the terms and conditions above\"\r" +
    "\n" +
    "                            class=\"block mt-2\"\r" +
    "\n" +
    "                            invalid-shake=\"true\">\r" +
    "\n" +
    "                        </check-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex mt-4 gap-x-2\">\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button bg-slate-200 text-slate-600 flex-1 md:flex-none md:ml-auto\"\r" +
    "\n" +
    "                        type=\"button\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.paymentSubmitting\"\r" +
    "\n" +
    "                        ng-click=\"vm.activePane = vm.PAYMENT_FORM\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "                        <span>Back</span>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button flex-1 md:flex-none\"\r" +
    "\n" +
    "                        ng-disabled=\"vm.paymentSubmitting\"\r" +
    "\n" +
    "                        type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <span ng-show=\"!vm.paymentSubmitting\">Pay {{ vm.totalPaymentAmount | currency:'$'}}</span>\r" +
    "\n" +
    "                        <span ng-show=\"vm.paymentSubmitting\">Processing...</span>\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-right\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- PAYMENT_CONFIRMATION -->\r" +
    "\n" +
    "        <div ng-show=\"vm.activePane == vm.PAYMENT_CONFIRMATION\">\r" +
    "\n" +
    "        \r" +
    "\n" +
    "            <h2 class=\"mb-2\">Thank you for your payment</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"paymentConfirmationForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.submitPaymentConfirmationForm(paymentConfirmationForm.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"card\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button bg-white text-eproducer-blue\"\r" +
    "\n" +
    "                        type=\"submit\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-chevron-left\"></i>\r" +
    "\n" +
    "                        Make another payment\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"text-center mt-4\">\r" +
    "\n" +
    "                        <i class=\"fa fa-check text-eproducer-green text-8xl\"></i>\r" +
    "\n" +
    "                        <div class=\"text-eproducer-green text-xl\">Payment Received!</div>\r" +
    "\n" +
    "                        <div>Confirmation number: <b>{{vm.transaction}}</b></div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <p>\r" +
    "\n" +
    "                        Your payment was successfully processed. Please print this page for your records.\r" +
    "\n" +
    "                        A copy of your receipt has been emailed to: <b>{{vm.dto.payment.email}}</b>\r" +
    "\n" +
    "                    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"mt-8\">\r" +
    "\n" +
    "                        <h4>Billing Information</h4>\r" +
    "\n" +
    "                        <hr />\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <div class=\"flex\">\r" +
    "\n" +
    "                            <div class=\"flex-1\">\r" +
    "\n" +
    "                                <div ng-show=\"vm.dto.payment.companyName\">{{vm.dto.payment.companyName}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.firstName}} {{vm.dto.payment.lastName}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.address}} {{vm.dto.payment.suiteAptNumber}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.city}}, {{vm.dto.payment.state}} {{vm.dto.payment.zip}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                            <div class=\"flex-1 ml-1\">\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.phone}}</div>\r" +
    "\n" +
    "                                <div>{{vm.dto.payment.email}}</div>\r" +
    "\n" +
    "                            </div>\r" +
    "\n" +
    "                        </div>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <table class=\"mt-8 w-full\">\r" +
    "\n" +
    "                        <thead>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <th class=\"text-left border-b border-slate-200\">Payment Details</th>\r" +
    "\n" +
    "                                <th class=\"text-right whitespace-nowrap border-b border-slate-200\">Amount Due</th>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </thead>\r" +
    "\n" +
    "                        <tbody>\r" +
    "\n" +
    "                            <tr ng-repeat=\"payable in vm.dto.payables\">\r" +
    "\n" +
    "                                <td class=\"text-left align-top py-0.5\">\r" +
    "\n" +
    "                                    <div\r" +
    "\n" +
    "                                        ng-class=\"{'mt-1': !$first}\"\r" +
    "\n" +
    "                                        ng-repeat=\"lineItem in payable.lineItems track by $index\"\r" +
    "\n" +
    "                                        ng-bind-html=\"lineItem.parsedDescription\">\r" +
    "\n" +
    "                                    </div>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                                <td class=\"whitespace-nowrap text-right align-top py-0.5\">\r" +
    "\n" +
    "                                    {{payable.totalAmountDue | currency:'$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.otherPayment\">\r" +
    "\n" +
    "                                <td class=\"align-top py-0.5\">Reference #: {{vm.otherPaymentReferenceNumber}}</td>\r" +
    "\n" +
    "                                <td class=\"whitespace-nowrap text-right align-top py-0.5\">{{vm.otherPaymentAmount | currency:'$'}}</td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                            <tr ng-show=\"vm.dto.payment.processingFee\">\r" +
    "\n" +
    "                                <td class=\"text-left align-top py-0.5\">\r" +
    "\n" +
    "                                    Payment processing fee\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td class=\"whitespace-nowrap text-right align-top py-0.5\">\r" +
    "\n" +
    "                                    {{vm.dto.payment.processingFee | currency:'$'}}\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tbody>\r" +
    "\n" +
    "                        <tfoot>\r" +
    "\n" +
    "                            <tr>\r" +
    "\n" +
    "                                <td \r" +
    "\n" +
    "                                    colspan=\"3\"\r" +
    "\n" +
    "                                    class=\"border-t border-slate-200 text-right\">\r" +
    "\n" +
    "                                    \r" +
    "\n" +
    "                                    Payment Amount: <b class=\"ml-2 inline-block\">{{ vm.totalPaymentAmount | currency:'$' }}</b>\r" +
    "\n" +
    "                                </td>\r" +
    "\n" +
    "                                <td></td>\r" +
    "\n" +
    "                            </tr>\r" +
    "\n" +
    "                        </tfoot>\r" +
    "\n" +
    "                    </table>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>"
  );


  $templateCache.put('app/states/purchasedBonds/purchasedBonds.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"!vm.error\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div ng-show=\"vm.table.isVisible\">\r" +
    "\n" +
    "        <h2 class=\"mb-4\">Purchased Bonds</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"hidden md:flex font-bold py-2 px-6 rounded bg-gray-200 shadow\">\r" +
    "\n" +
    "            <div class=\"text-center w-40\">Bond #</div>\r" +
    "\n" +
    "            <div class=\"w-52\">Name on Bond</div>\r" +
    "\n" +
    "            <div class=\"text-center w-52\">Effective</div>\r" +
    "\n" +
    "            <div class=\"flex-1\">Bond Type</div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-repeat=\"bond in vm.table.data\"\r" +
    "\n" +
    "            class=\"card mt-4 md:flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"hidden md:flex\">\r" +
    "\n" +
    "                <div class=\"my-auto text-center w-40\">{{bond.bondNumber}}</div>\r" +
    "\n" +
    "                <div class=\"my-auto w-52\">{{bond.nameOnBond}}</div>\r" +
    "\n" +
    "                <div class=\"my-auto text-center w-52\">\r" +
    "\n" +
    "                    <em>Eff:</em> {{bond.effectiveDate | date: 'MM/dd/yyyy'}}\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <em>Exp:</em> {{bond.expirationDate | date: 'MM/dd/yyyy'}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "                <div class=\"my-auto flex-1\">\r" +
    "\n" +
    "                    {{bond.bondType}}\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <em>Bond Amount: </em><span class=\"text-sm\">{{bond.bondAmount | currency:'$'}}</span>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"md:hidden\">\r" +
    "\n" +
    "                <b>#{{bond.bondNumber}}</b>\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "                    {{bond.bondType}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-sm\">\r" +
    "\n" +
    "                    <em>Bond Amount:</em> {{bond.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    <br/>\r" +
    "\n" +
    "                    <em>Name on Bond:</em> {{bond.nameOnBond}}\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <em>Bond ammount:</em> {{bond.bondAmount | currency:'$'}}\r" +
    "\n" +
    "                    <br />\r" +
    "\n" +
    "                    <em>Effective:</em> {{bond.effectiveDate | date: 'MM/dd/yyyy'}} - {{bond.expirationDate | date: 'MM/dd/yyyy'}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        visible=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        heading=\"No bonds found...\"\r" +
    "\n" +
    "        message=\"It looks like you don't have any bonds to display.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage' && vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"sticky bottom-0 block\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<busy-indicator \r" +
    "\n" +
    "    promise=\"vm.loadingPromise\"\r" +
    "\n" +
    "    message=\"Loading...\">\r" +
    "\n" +
    "</busy-indicator>"
  );


  $templateCache.put('app/states/quotes/confirmedQuotes.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"absolute top-0 right-0 bottom-0 left-0 overflow-x-hidden overflow-y-auto\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div \r" +
    "\n" +
    "        class=\"text-slate-800 text-sm px-4 flex h-11\"\r" +
    "\n" +
    "        ng-class=\"{'bg-slate-200': !mainVm.currentUser}\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"hidden md:inline my-auto\"\r" +
    "\n" +
    "            ng-show=\"vm.applicationInformation.approvedQuotes.length > 0\">\r" +
    "\n" +
    "            Check out your <b>confirmed</b> quotes!\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <span\r" +
    "\n" +
    "            class=\"hidden md:inline my-auto\"\r" +
    "\n" +
    "            ng-show=\"vm.applicationInformation.approvedQuotes.length === 0\">\r" +
    "\n" +
    "            We're working to prepare quotes. We'll contact you <b>very soon</b>\r" +
    "\n" +
    "        </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"ml-auto my-auto flex\"\r" +
    "\n" +
    "            ng-click=\"vm.filtersVisible = true\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'text-eproducer-blue font-bold': vm.filterCount,\r" +
    "\n" +
    "                'text-slate-800 hover:text-eproducer-blue': !vm.filterCount\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span class=\"my-auto\">\r" +
    "\n" +
    "                Filter \r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"my-auto\"\r" +
    "\n" +
    "                ng-show=\"vm.filterCount\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                ({{ vm.filterCount }})\r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <i class=\"icon-filter ml-1 my-auto\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <a\r" +
    "\n" +
    "            class=\"ml-4 flex my-auto\"\r" +
    "\n" +
    "            ng-show=\"mainVm.currentUser\"\r" +
    "\n" +
    "            ng-click=\"vm.attachmentsVisible = true\"\r" +
    "\n" +
    "            ng-class=\"{\r" +
    "\n" +
    "                'text-eproducer-blue font-bold': vm.attachments.length > 0,\r" +
    "\n" +
    "                'text-slate-800 hover:text-eproducer-blue': !vm.attachments.length\r" +
    "\n" +
    "            }\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <span class=\"my-auto\">\r" +
    "\n" +
    "                Attachments \r" +
    "\n" +
    "            </span>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <span \r" +
    "\n" +
    "                class=\"my-auto\"\r" +
    "\n" +
    "                ng-show=\"vm.attachments.length > 0\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                ({{vm.attachments.length}})\r" +
    "\n" +
    "            </span> \r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"fa fa-paperclip ml-1 my-auto\"></i>\r" +
    "\n" +
    "        </a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"py-4 px-5 overflow-x-hidden position\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <quote-list\r" +
    "\n" +
    "            ng-if=\"vm.applicationInformation.status === 'Submitted' && vm.applicationInformation.approvedQuotes\"\r" +
    "\n" +
    "            quotes=\"vm.applicationInformation.approvedQuotes\"\r" +
    "\n" +
    "            is-policy=\"vm.applicationInformation.isPolicy\"\r" +
    "\n" +
    "            filtered-quote-count=\"vm.filteredQuoteCount\"\r" +
    "\n" +
    "            lowest-cost=\"vm.lowestCost\"\r" +
    "\n" +
    "            most-expensive-cost=\"vm.mostExpensiveCost\"\r" +
    "\n" +
    "            filter-count=\"vm.filterCount\"\r" +
    "\n" +
    "            filters-visible=\"vm.filtersVisible\"\r" +
    "\n" +
    "            is-purchasable=\"true\">\r" +
    "\n" +
    "        </quote-list>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <!-- Attachmnent Slide -->\r" +
    "\n" +
    "        <page-slide \r" +
    "\n" +
    "            is-open=\"vm.attachmentsVisible\"\r" +
    "\n" +
    "            on-close=\"vm.attachmentsVisible = false\"\r" +
    "\n" +
    "            slide-from=\"right\"\r" +
    "\n" +
    "            width=\"20rem\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <inline-alert \r" +
    "\n" +
    "                class=\"block mt-2 mb-2\"\r" +
    "\n" +
    "                success-message=\"vm.attachmentSuccessMessage\"\r" +
    "\n" +
    "                error=\"vm.attachmentError\">\r" +
    "\n" +
    "            </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <h2 class=\"mb-4\">Application Attachments</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <dropzone\r" +
    "\n" +
    "                application-id=\"vm.applicationInformation.id\"\r" +
    "\n" +
    "                on-success=\"vm.uploadSuccess()\"\r" +
    "\n" +
    "                on-error=\"vm.uploadError(error)\">\r" +
    "\n" +
    "            </dropzone>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-show=\"!vm.attachments.length\"\r" +
    "\n" +
    "                class=\"text-center mt-8\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Use the area above to upload documents to be attached to your application.\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div\r" +
    "\n" +
    "                ng-show=\"vm.attachments.length\"\r" +
    "\n" +
    "                class=\"mt-4\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <h4 class=\"mb-4\">Uploaded Attachments</h4>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div\r" +
    "\n" +
    "                    ng-repeat=\"attachment in vm.attachments\"\r" +
    "\n" +
    "                    class=\"text-sm flex mb-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex-1 mr-0.5 my-auto\">\r" +
    "\n" +
    "                        {{ attachment.filename | limitTo:25 }}\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        <span \r" +
    "\n" +
    "                            class=\"text-xs\"\r" +
    "\n" +
    "                            ng-show=\"attachment.filename.length > 25\">...</span>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <pdf-download\r" +
    "\n" +
    "                        url=\"attachment.downloadUrl\"\r" +
    "\n" +
    "                        filename=\"{{attachment.filename}}\"\r" +
    "\n" +
    "                        show-icon=\"true\"\r" +
    "\n" +
    "                        button-text=\"\"\r" +
    "\n" +
    "                        class=\"inline-block my-auto\">\r" +
    "\n" +
    "                    </pdf-download>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </page-slide>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\">\r" +
    "\n" +
    "    <div class=\"text-right\">\r" +
    "\n" +
    "        <a ui-sref=\"main.application\">Start New Application</a>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<busy-indicator \r" +
    "\n" +
    "    promise=\"vm.loadingPromise\"\r" +
    "\n" +
    "    message=\"Loading...\">\r" +
    "\n" +
    "</busy-indicator>"
  );


  $templateCache.put('app/states/resetPassword/resetPassword.html',
    "<div class=\"p-5 flex\">\r" +
    "\n" +
    "    <div class=\"card mx-auto md:w-[650px] mt-16\">\r" +
    "\n" +
    "        <h2 class=\"mb-4\">Reset Password</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <inline-alert\r" +
    "\n" +
    "            error=\"vm.error\" \r" +
    "\n" +
    "            success-message=\"vm.successMessage\"\r" +
    "\n" +
    "            class=\"mb-4 block\">\r" +
    "\n" +
    "        </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div ng-show=\"vm.successMessage\">\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                class=\"button mt-6 w-full\"\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                ng-click=\"vm.goToLanding()\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Continue\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <form\r" +
    "\n" +
    "            ng-show=\"!vm.successMessage\"\r" +
    "\n" +
    "            name=\"passwordResetForm\"\r" +
    "\n" +
    "            ng-submit=\"vm.resetPasswordClick(passwordResetForm.$invalid)\"\r" +
    "\n" +
    "            novalidate>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <new-password\r" +
    "\n" +
    "                model=\"vm.password\"\r" +
    "\n" +
    "                is-required=\"true\">\r" +
    "\n" +
    "            </new-password>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <button\r" +
    "\n" +
    "                type=\"submit\"\r" +
    "\n" +
    "                class=\"button mt-6 w-full\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                Reset Password\r" +
    "\n" +
    "            </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"mt-2 flex\">\r" +
    "\n" +
    "                <a\r" +
    "\n" +
    "                    class=\"ml-auto\"\r" +
    "\n" +
    "                    ui-sref=\"main.login\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Back to Login\r" +
    "\n" +
    "                </a>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"mt-4 text-sm\">\r" +
    "\n" +
    "                <i class=\"fa fa-lock text-eproducer-orange\"></i> \r" +
    "\n" +
    "                Your information is always secure.\r" +
    "\n" +
    "                <br />\r" +
    "\n" +
    "                <b>\r" +
    "\n" +
    "                    By using this tool, you agree to the \r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <a \r" +
    "\n" +
    "                        target=\"termsAndConditions\" \r" +
    "\n" +
    "                        ui-sref=\"main.termsAndConditions\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Terms &amp; Conditions\r" +
    "\n" +
    "                    </a>\r" +
    "\n" +
    "                </b>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </form>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/termsAndConditions/termsAndConditions.html',
    "<div class=\"p-10 text-sm\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2 class=\"text-center mb-4\">TERMS AND CONDITIONS AGREEMENT</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mx-2\">\r" +
    "\n" +
    "        The following Terms and Conditions constitute a binding legal contract between you and Agency Multiplied, Inc. (“A3”).  Please carefully read these terms as they affect your legal rights.  You (\"User\") are invited to consult with counsel prior to accepting the terms of this agreement.  Before you use the services provided by A3 you must read and accept all of the terms and conditions in this agreement.\r" +
    "\n" +
    "        This agreement outlines the terms that govern your use of A3 software, specifically the dashboard and widget, together with all updates, additional terms, software licenses, and all of the A3 rules and policies referred to in this agreement.  Collectively these constitute the Terms and Conditions between you and A3.  By using A3 software you agree to these terms.  If you do not agree to these terms just click on the ‘Decline' button, and do not use the A3 services. You must accept and abide by these terms of use as presented to you in this agreement; changes, additions, or deletions are not acceptable, and A3 reserves the right to refuse access to the dashboard services for non-compliance with any part of this agreement.\r" +
    "\n" +
    "        By entering and using the A3 dashboard, you agree to abide by the following terms and conditions:\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2 class=\"text-center mb-4\">PRIVACY</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mx-2\">\r" +
    "\n" +
    "        A3 protects User privacy and is dedicated to safeguarding your information. By using the A3's dashboard, you are agreeing that A3 may store the information you provide as part of the registration or surety bond request process and as described in the privacy policy below.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Privacy Policy</h3>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    Personal information is collected by A3, for the purposes of:\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">1)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">Obtaining quotes for User for surety bonds;</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">2)</div>\r" +
    "\n" +
    "        <div class=\"flex-1\">Sharing with parties as approved by the owner of the personal information; and</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">3)</div>\r" +
    "\n" +
    "        <div class=\"flex-1\">Responding to authorized requests related to information audits and legislative requirements.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"mx-2\">\r" +
    "\n" +
    "        In addition, where our A3 products or services are involved, we use personal information as an integral part of an ongoing process of maintenance of our accounts, including but not limited to: considering, establishing and maintaining an A3 User profile, assessing the online use of the application as it relates to performance and pricing policies and execution, investigating, evaluating and handling potential system security breach claims, providing evidentiary support for recovering losses under indemnities and guarantees provided in support of A3 profile guarantees. In using personal information for these purposes A3 sometimes discloses personal information to: A3 employees directly involved in handling A3 business and those responsible for corporate governance, regulators and auditors, courts of law, legal counsel retained to advise or assist A3 with respect to A3 matters, financial institutions for processing your online payment and companies related to A3.\r" +
    "\n" +
    "        Individual online usage patterns will not be disclosed to either advertisers or to third parties. A3 will not disclose the name of your organization, or the names, addresses, telephone numbers, email addresses, or other personal identifying information regarding document signers or Users, without your consent.  A3 does not process or store your credit card information on any of its servers. This information is managed by our payment gateway provider.\r" +
    "\n" +
    "        By using this service, you are agreeing that A3 will store the documents administered within its online servers and make them accessible to you and the Users you share these documents with.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div>By using A3 software you are agreeing that:</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">a)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">A3 has your consent to use the personal information you provide about yourself for the purposes described in this Privacy Policy.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">b)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">When you provide Personal Information about others you have that person's consent to disclose information about them to A3 for the purposes described in this Privacy Policy.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">c)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">The specific personal information you are authorized to access under your User ID and Password will be used only for purposes described in this Privacy Policy.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">d)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">A3 has your consent to send you, the other persons whose consent you have and your business associates information relevant to the A3 services via email or any other electronic or print method.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">e)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">A3 has your consent to make your Surety bond requests available to its partner surety bonding companies (“Partner Carriers”), for the purpose of presenting a no obligation offer to you.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">f)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">A3 will retain personal information for the same period of time it is requested by Users to retain the documents containing the personal information. By using A3 software, you are agreeing that A3 may store the information you provided as part of the registration process and as described in this Privacy Policy.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Sharing of Information Received</h3>\r" +
    "\n" +
    "    A3 shares Personal Information only as described below and with controls that either are subject to the A3 Privacy Policy or follow practices at least as protective as those described in this agreement.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">a)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">PARTNER BUSINESSES WE DO NOT CONTROL: We work closely with companies with which you have established a business relationship. In some cases, these businesses may invite you and in other cases, you may invite them to be part of your working group. Or other organisation users may request the verification of the authenticity of documents signed and sealed by you. We may also share the information related to the surety bond transactions with any party that you have submitted a request to receive an offer for surety bonds or wishes to verify of the authenticity of documents signed or sealed by you.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">b)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">THIRD-PARTY SERVICE PROVIDERS: We employ other companies and individuals to perform functions on our behalf. Examples include data centers, email, postal mail and e-mail service providers, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card and other electronic payments, credit report bureaus, electronic signature service providers and other providing customer service. They may have access to your personal information needed to perform their functions, but may not use it for other purposes.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">c)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">A3 PARTNER CARRIER OFFERS: Sometimes we may send surety bond related offers to selected Agency Users on behalf of our Partner Carriers. These Partner Carriers offers are intended to provide the Agency Users with alternative options to choose from. You are under no obligation to accept these offers.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">d)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">PROMOTIONAL OFFERS: Sometimes we send offers to selected groups of A3 customers on behalf of our businesses. If you do not want to receive such offers, please contact us to remove you from any marketing communication.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">e)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">BUSINESS TRANSFERS: As we continue to develop our business, we might sell or buy subsidiaries, or business units. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Statement (unless, of course, the customer consents otherwise). Also, in the unlikely event that A3 or substantially all of its assets are acquired, customer information will of course be one of the transferred assets.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">f)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">PROTECTION OF A3 AND OTHERS: We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our terms and services and other agreements; or protect the rights, property, or safety of A3, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments set forth in this Privacy Policy statement.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex\">\r" +
    "\n" +
    "        <div class=\"mr-2\">g)</div> \r" +
    "\n" +
    "        <div class=\"flex-1\">WITH YOUR CONSENT: Other than as set out above, you will receive notice when information about you might go to third parties, and you will have an opportunity to choose not to share the information.</div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "       \r" +
    "\n" +
    "    <div>Any questions or concerns about this Privacy Statement or about A3's policies or practices in dealing with personal information should be directed to A3: Attention: Privacy 4285 Commercial St. SE, Suite 110, Salem, OR 97302</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Security</h3>\r" +
    "\n" +
    "    A3 is committed to security. We use secured data centers with redundant power and internet connectivity. A3 employs commercially reasonable precautions to maintain the security of the system, including the use of industry standard encryption methods to prevent unauthorized access to customer documents and account information stored on our servers. The Service also protects information with the HTTPS security protocol during transmission to your web browser.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Cookies</h3>\r" +
    "\n" +
    "    Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your device and to provide features such as, personalized user interface and seamless navigation throughout the A3 Web sites, and session tracking.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2 class=\"text-center mx-4\">USER REPRESENTATIONS AND OBLIGATIONS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Amendment</h3>\r" +
    "\n" +
    "    A3 and the Websites retain the right to amend the terms of this agreement without prior consent of or notice to the User.  You can review the most current version of the Terms and Conditions at any time by clicking the ‘Terms of Conditions' link located at the bottom of the Website pages.  Additionally, when using particular A3 owned or operated services, you are subject to any posted guidelines or rules applicable to such services, which may be posted from time to time.\r" +
    "\n" +
    "    You are responsible for reviewing the current version of the Terms and Conditions periodically.  A3 may amend this agreement by (a) posting a revised Customer Terms and Conditions on the A3 Websites, or (b) sending information regarding the amendment at login or to the email address you provide to A3.  Continued use of the Service will be deemed acceptance of the amended Terms and Conditions.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Proper Use</h3>\r" +
    "\n" +
    "    You agree to comply with the terms and conditions of this agreement and with all applicable local, state, federal and international laws and regulations and all Internet regulations, policies and procedures, including all export and other laws regarding the transmission of technical data exported from any country through the Service. You agree that you will not use the Service for illegal purposes, to disrupt to the Service, or to distribute content that violates the privacy, intellectual property or other proprietary rights of any third party, or for purposes that A3 reasonably determines to be unlawful, obscene, defamatory, harassing, fraudulent, abusive, threatening, harmful, vulgar, or otherwise objectionable. You are wholly responsible for the contents of your transactions through the Service.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">No Resale of Services Permitted:</h3>\r" +
    "\n" +
    "    You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of A3's software, use of the service, or access to the service without the written consent of A3.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Eligibility</h3>\r" +
    "\n" +
    "    The A3 service is available only to persons who can form a legal binding contract. Website is not available for use by minors (under 18) without parental consent.  By entering the Website and entering this TERMS AND CONDITIONS agreement, you represent and warrant that you are at least 18 years of age (or, as applicable, the age of majority in the state in which you reside), and that you possess the legal right and ability to enter into this TERMS AND CONDITIONS and you certify that you are of legal age and not restricted to using the Website by any United States or International law, or otherwise by any other law, TERMS AND CONDITIONS, or regulation.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Account Registration</h3>\r" +
    "\n" +
    "    A3 may require you to register for a password-protected account. You represent and warrant that all of the information you provide to A3 is true, accurate and complete, and that you have legal right to use the e-mail address(es) you provide. You agree to keep your information current by updating your account information. You agree to maintain the confidentiality of your password and account. You are responsible for all activities that occur under your account. A3 reserves the right to deactivate or close your account if your account remains inactive for more than twelve months.\r" +
    "\n" +
    "    Account Cancellation\r" +
    "\n" +
    "    You may cancel your account and terminate this TERMS AND CONDITIONS at any time.  A3recommends printing or copying any important documents because you will lose access to documents, messages or other content contained in your account after cancellation.  A3 reserves the right to terminate any account that violates the terms of this TERMS AND CONDITIONS.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Communication</h3>\r" +
    "\n" +
    "    You agree that the official time for all transactions using the Service will be the timestamps recorded by the A3's servers. You also agree that all electronic communications or notices sent to the e-mail address you provide to A3, placed in your account, or posted on the Web Site will be considered “in writing” and received within five (5) business days of its dissemination.  A3 disclaims all responsibility for all failures in communication caused by failures of third parties to properly process or deliver A3's electronic communications.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2 class=\"text-center mx-4\">A3'S RIGHTS AND DISCLAIMERS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Relationship to Documents</h3>\r" +
    "\n" +
    "    Unless A3 is named as a direct party, A3's relationship to all documents and transactions completed using the Service is that of a trusted, disinterested third party. Accordingly, A3 disclaims any representations of any kind regarding the documents or transactions that pass through the Service. You are responsible for recognizing the parties of interest in all transactions you complete using the Service and assessing all associated risks.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Maintenance</h3>\r" +
    "\n" +
    "    The Service will occasionally be unavailable during system maintenance and upgrades. A3 will make commercially reasonable efforts to ensure that the Service will be available during any system maintenance period.  However, A3 disclaims all responsibility for any interruptions or problems with the Service caused by external forces affecting the reliability of the Internet or computer systems.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Limitation of Liability</h3>\r" +
    "\n" +
    "    YOU EXPRESSLY UNDERSTAND AND AGREE THAT A3 SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH A3 HAS BEEN ADVISED OF THE POSSIBILITY THEREOF), AND INCLUDING DAMAGES RESULTING FROM: (i) ANY DOCUMENT OR TRANSACTION SENT THROUGH THE SERVICE WHERE A3 WAS NOT A DIRECT PARTY; (ii) THE USE OR INABILITY TO USE THE SERVICE, INCLUDING ERRORS, INTERRUPTIONS OR DELAYS; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR DOCUMENTS OR TRANSMISSIONS; OR (iv) ANY OTHER MATTER RELATING TO THE SERVICE. A3's LIABILITY SHALL NOT EXCEED, IN THE AGGREGATE, A SUM EQUAL TO THE AMOUNT OF FEES (IF ANY) PAID TO A3 FOR USING THE SERVICE TO COMPLETE THE TRANSACTION UNDER THIS TERMS AND CONDITIONS.  YOU UNDERSTAND AND AGREE TO THAT YOU WILL AT ALL TIMES INDEMNIFY, AND KEEP INDEMNIFIED, A3, AND HOLD AND SAFE A3 HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY, LOSS, COSTS, DAMAGES, CHARGES AND EXPENSES OF WHATSOEVER KIND AND NATURE, INCLUDING EXPENSES, COLLECTIONS FEES AND ATTORNEYS' FEE, WHICH IT MAY, AT ANY TIME, SUSTAIN OR INCUR BY REASON OF USING A3 OR UNDERTAKING OR ENFORCING THIS AGREEMENT.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Disclaimer of Warranty</h3>\r" +
    "\n" +
    "    YOU EXPRESSLY UNDERSTAND AND AGREE THAT:\r" +
    "\n" +
    "    1. YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. A3 PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS. A3 EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.\r" +
    "\n" +
    "    2. A3 DOES NOT WARRANT AND EXPRESSLY DISCLAIMS THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS; (ii) THE SERVICE WILL BE UNINTERRUPTED, RELIABLE, TIMELY, SECURE, ERROR-FREE OR FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS, (iii) ANY ERRORS ON THE A3 WEB SITE OR SERVICE WILL BE CORRECTED; AND (iv) THAT DOCUMENTS OR MATERIALS THAT YOU STORE VIA THE SERVICE WILL REMAIN ACCESSIBLE\r" +
    "\n" +
    "    3. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR SOLE DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.\r" +
    "\n" +
    "    4. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM A3, OR THROUGH OR FROM THE A3 WEB SITE OR SERVICE, SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TERMS AND CONDITIONS.\r" +
    "\n" +
    "    5. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OF CERTAIN WARRANTIES OR THE LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Intellectual Property Rights</h3>\r" +
    "\n" +
    "    You acknowledge and agree that A3 owns all right, title and interest in the Service and the web site, including, without limitation, all intellectual property rights. You acknowledge and agree that the Service and the website contain proprietary and confidential information and trade secrets including, but not limited to text, graphics, logos, images, software, and icons, that are protected by United States and international intellectual property laws and other applicable laws.\r" +
    "\n" +
    "    You agree not to display or use A3 Marks in any manner without A3's prior written permission.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">A3's Bonding Services Terms</h3>\r" +
    "\n" +
    "    By using the services provided by A3 and its websites, you expressly understand and agree to the following:\r" +
    "\n" +
    "    a)	You shall provide correct and accurate answers to all questions asked by the A3 system on behalf of the member bonding companies or its agents.  You expressly understand and agree that intentional misinformation is considered insurance fraud.\r" +
    "\n" +
    "    b)	You understand false statements may result in forfeiture of benefits and possible prosecution by the US Attorney-General - (Any person who knowingly and with the intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing false, or misleading information is guilty of a felony of the third degree.\r" +
    "\n" +
    "    c)	Obtaining surety bond quotes is 100% free and there is no cost should you decline the offer.\r" +
    "\n" +
    "    d)	The quote you may receive is a conditional offer and is subject to change based on a multitude of considerations.\r" +
    "\n" +
    "    e)	The quote may include a broker fee. Any possible broker fees are part of the quote you may receive.\r" +
    "\n" +
    "    f)	You may or may not receive a quote immediately from an online surety bond request, as some applicants require further review by a human in order to obtain the lowest rate.\r" +
    "\n" +
    "    g)	Online applications are contracts between the principal applying for said bond and the bond agency. By agreeing to the TERMS AND CONDITIONS, you hereby understand and agree that the bond premium is fully earned upon issuance of the bond and is not refundable.\r" +
    "\n" +
    "    h)	If your bond is cancelled mid-term, you also understand and agree that any and all fees and premiums are also fully earned, and are not refundable. In addition, fees may earn interest. This undeclared income is non-refundable and fully earned as well as any fees charged.\r" +
    "\n" +
    "    i)	Completion of an online surety bond request form constitutes permission for the agency and their bonding companies to obtain consumer information from any source which will be used to determine bonding eligibility. This includes obtaining credit reports at the time of application, in any review, or renewal, at the time of any potential or actual claim, or for any other legitimate purposes as determined by the bonding company in its reasonable discretion. This information will be held in the strictest of confidence. Soft credit reports that do not count as inquires on your report will be used whenever possible.\r" +
    "\n" +
    "    j)	This agreement shall serve as the writing required pursuant to the rules set forth by your Department of Insurance.\r" +
    "\n" +
    "    k)	You expressly understand and consent that the information submitted using the A3 system may be shared with 3rd parties in order to assess risk and or approve your bond requests.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Electronic Signature Service: Policies</h3>\r" +
    "\n" +
    "    <div>A3 uses a third party electronic signature service.  If you use the Service and whenever applicable, you are agreeing to conduct business transactions with electronic documents and signatures instead of paper-based documents and signatures. You are under no obligation to transact business electronically. To withdraw your consent to conduct electronic transactions, simply stop using the Electronic Signature Service portion of the transaction and contact the sending party to explore other options. You may read and sign each document on a case-by-case basis. Each decision to view or sign a document electronically does not affect the legal effect of any transactions already completed using either electronic or paper-based documents or signatures. You hereby agree to read every document before electronically signing it. You also agree to communicate all issues regarding the content of a document directly with the sending party. Finally, you agree to notify the sending parties and the Service of any change in your e-mail address in order to prevent interruptions to your communications. You acknowledge and understand that electronic signatures are legally binding in the United States and other countries. You also understand that printed copies of electronic documents might not be considered legal originals, but rather copies of the digitally signed documents or held within the A3 Service.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Electronic Signature Service: Requirements</h3>\r" +
    "\n" +
    "    <div>Use of the Service requires a standards-compliant web-browser which supports the HTTPS protocol, HTML, Java Runtime Environment and cookies. Electronic signatures are void where prohibited by law.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Legal Document Service: Legal Information Disclaimer</h3>\r" +
    "\n" +
    "    <div>The Service offers documents and other information that may be relevant to the legal needs of our users. However, the legal information available on or through the Service does not constitute legal advice as it does not draw legal conclusions or apply the law to the specific circumstances of each user.  If you require legal advice, please consult a law services professional in your area.  Your use of the Service cannot replace the advice of a lawyer or create a lawyer-client relationship between you and A3.  Although A3 makes reasonable efforts to ensure the accuracy of the legal information it provides, the law is constantly changing, and A3 cannot guarantee the correctness or completeness of the legal information.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2 class=\"text-center mt-4\">GENERAL PROVISIONS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Entire Agreement</h3>\r" +
    "\n" +
    "    <div>This Agreement constitutes the entire agreement between you and A3 and its related Websites and governs your use of the A3's Service. This Agreement supersedes all prior agreements and understandings between you and A3 and its related Websites, whether written or oral.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Indemnification</h3>\r" +
    "\n" +
    "    <div>You agree to hold harmless and indemnify A3 and its subsidiaries, affiliates, officers, agents, directors, managers, employees, and successors from any claims, losses, damages, suits, demands, liabilities, judgments, litigation costs and attorneys' fees arising from or in any way related to your use of the Service, your breach of any of your obligations under this Agreement, or your violation of any applicable laws or regulations.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Severability and Waiver</h3>\r" +
    "\n" +
    "    <div>If a court of competent jurisdiction declares any term or provision in this Agreement invalid or unenforceable, you and A3 nevertheless agree that the court should construe that term or provision consistent with applicable law as nearly as possible to reflect the parties' original intentions, and that the remaining terms and provisions remain in full force and effect. Failure by A3 to exercise or enforce any provision of this Agreement shall not constitute a waiver of any such provision.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Choice of Law</h3>\r" +
    "\n" +
    "    <div>This Agreement shall be governed by the laws of the State of Oregon, USA without regard to its conflict of law provisions. You and A3 agree to submit to the personal and exclusive jurisdiction of the courts located within Marion County, Oregon, USA.</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3 class=\"mt-4\">Mediation</h3>\r" +
    "\n" +
    "    <div>All claims, disputes, and controversies arising out of or in relation to the performance, interpretation, application, or enforcement of this agreement, including but not limited to breach thereof, shall be referred to mediation before, and as a condition precedent to, the initiation of any adjudicative action or proceeding, including arbitration.</div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('app/states/userDefaultLanding/userDefaultLanding.html',
    "<div class=\"p-5\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "</div>\r" +
    "\n"
  );


  $templateCache.put('app/states/users/usersDetail.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"md:mx-auto md:w-[950px]\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <inline-alert \r" +
    "\n" +
    "            ng-if=\"vm.error.errorDisplayType == 'inline'\"\r" +
    "\n" +
    "            error=\"vm.error\"\r" +
    "\n" +
    "            wrapper-class=\"mb-4\">\r" +
    "\n" +
    "        </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <h2 class=\"mb-2\">\r" +
    "\n" +
    "            <span ng-show=\"vm.isEditMode\">Edit User</span>\r" +
    "\n" +
    "            <span ng-show=\"!vm.isEditMode\">New User</span>\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"card\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <form\r" +
    "\n" +
    "                name=\"userForm\"\r" +
    "\n" +
    "                novalidate\r" +
    "\n" +
    "                ng-submit=\"vm.save(userForm.$invalid)\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"w-full sm:grid sm:grid-cols-12 sm:gap-x-2\">\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"userFirstName\"\r" +
    "\n" +
    "                            model=\"vm.user.firstName\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"First Name\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"userLastName\"\r" +
    "\n" +
    "                            model=\"vm.user.lastName\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Last Name\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"userEmail\"\r" +
    "\n" +
    "                            model=\"vm.user.email\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Email\"\r" +
    "\n" +
    "                            is-required=\"true\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"userJobTitle\"\r" +
    "\n" +
    "                            model=\"vm.user.jobTitle\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Job Title\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <phone-field\r" +
    "\n" +
    "                            name=\"userPhone\"\r" +
    "\n" +
    "                            model=\"vm.user.phone\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Phone\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </phone-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"userPhoneExtension\"\r" +
    "\n" +
    "                            model=\"vm.user.phoneExtension\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Phone Extension\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <phone-field\r" +
    "\n" +
    "                            name=\"userSecondaryPhone\"\r" +
    "\n" +
    "                            model=\"vm.user.secondaryPhone\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Secondary Phone\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </phone-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <text-field\r" +
    "\n" +
    "                            name=\"userSecondaryPhoneExtension\"\r" +
    "\n" +
    "                            model=\"vm.user.secondaryPhoneExtension\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Secondary Phone Extension\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </text-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <phone-field\r" +
    "\n" +
    "                            name=\"userFax\"\r" +
    "\n" +
    "                            model=\"vm.user.fax\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Fax\"\r" +
    "\n" +
    "                            class=\"col-span-6\">\r" +
    "\n" +
    "                        </phone-field>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "                        <textarea-field\r" +
    "\n" +
    "                            name=\"userAdditionalComments\"\r" +
    "\n" +
    "                            model=\"vm.user.additionalComments\"\r" +
    "\n" +
    "                            form=\"userForm\"\r" +
    "\n" +
    "                            label=\"Additional Comments\"\r" +
    "\n" +
    "                            class=\"col-span-12\">\r" +
    "\n" +
    "                        </textarea-field>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <new-password\r" +
    "\n" +
    "                        model=\"vm.user.password\"\r" +
    "\n" +
    "                        is-required=\"!vm.isEditMode || vm.user.password\">\r" +
    "\n" +
    "                    </new-password>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div class=\"flex gap-4 mt-8\">\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            ui-sref=\"main.usersGrid\"\r" +
    "\n" +
    "                            type=\"button\"\r" +
    "\n" +
    "                            class=\"button flex-1 bg-slate-200 text-slate-600\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Cancel\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                        <button\r" +
    "\n" +
    "                            type=\"submit\"\r" +
    "\n" +
    "                            class=\"button flex-1\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                            Save\r" +
    "\n" +
    "                        </button>\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>"
  );


  $templateCache.put('app/states/users/usersGrid.html',
    "<div\r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage'\"\r" +
    "\n" +
    "    class=\"p-5\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <inline-alert\r" +
    "\n" +
    "        success-message=\"vm.successMessage\"\r" +
    "\n" +
    "        error=\"vm.error\"\r" +
    "\n" +
    "        class=\"block mb-4\">\r" +
    "\n" +
    "    </inline-alert>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"flex mb-4\">\r" +
    "\n" +
    "        <h2 class=\"flex-1 my-auto\">\r" +
    "\n" +
    "            Users\r" +
    "\n" +
    "        </h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <button\r" +
    "\n" +
    "            ui-sref=\"main.usersDetail\"\r" +
    "\n" +
    "            class=\"button bg-white px-2 py-1 text-eproducer-blue border border-solid border-eproducer-blue hover:text-white hover:bg-eproducer-blue ml-2 my-auto flex\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <i class=\"icon-plus my-auto\"></i> \r" +
    "\n" +
    "            <span class=\"my-auto ml-2\">Add User</span>\r" +
    "\n" +
    "        </button>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <div class=\"vm.table.isVisible\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div class=\"hidden md:grid grid-cols-12 font-bold py-2 px-6 rounded bg-gray-200 shadow\">\r" +
    "\n" +
    "            <div class=\"col-span-3\">Name</div>\r" +
    "\n" +
    "            <div class=\"col-span-3\">Email</div>\r" +
    "\n" +
    "            <div class=\"col-span-4\">Last Login</div>\r" +
    "\n" +
    "            <div class=\"col-span-2\"></div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <div \r" +
    "\n" +
    "            ng-repeat=\"user in vm.table.data\"\r" +
    "\n" +
    "            class=\"card mt-4\">\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                class=\"hidden md:grid grid-cols-12\"  \r" +
    "\n" +
    "                ng-show=\"!user.delete\">\r" +
    "\n" +
    "                \r" +
    "\n" +
    "                <div class=\"col-span-3 my-auto\">{{user.firstName}} {{user.lastName}}</div>\r" +
    "\n" +
    "                <div class=\"col-span-3 my-auto\">{{user.email}}</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"col-span-4 my-auto\" \r" +
    "\n" +
    "                    ng-show=\"user.lastLoginDate === '0001-01-01T00:00:00Z'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    Never Logged In\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div \r" +
    "\n" +
    "                    class=\"col-span-4 my-auto\" \r" +
    "\n" +
    "                    ng-show=\"user.lastLoginDate !== '0001-01-01T00:00:00Z'\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    {{user.lastLoginDate | date}} at {{user.lastLoginDate | date:'hh:mm a'}}\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"col-span-2 flex my-auto\">                    \r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button text-sm my-auto ml-auto\"\r" +
    "\n" +
    "                        ng-click=\"vm.promptDelete(user)\"\r" +
    "\n" +
    "                        ng-disabled=\"user.id === vm.currentUser.user.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button text-sm my-auto ml-2\"\r" +
    "\n" +
    "                        ui-sref=\"main.usersDetail({ id: user.id })\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"icon-pencil\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"block sm:flex md:hidden\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"sm:flex-1\">\r" +
    "\n" +
    "                    {{user.firstName}} {{user.lastName}} <br/>\r" +
    "\n" +
    "                    {{user.email}} <br/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-show=\"user.lastLoginDate === '0001-01-01T00:00:00Z'\">\r" +
    "\n" +
    "                        Never Logged In\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <div ng-show=\"user.lastLoginDate !== '0001-01-01T00:00:00Z'\">\r" +
    "\n" +
    "                        {{user.lastLoginDate | date}} at {{user.lastLoginDate | date:'hh:mm a'}}\r" +
    "\n" +
    "                    </div>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"mt-2 sm:my-auto sm:ml-2 flex\">\r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button text-sm sm:my-auto sm:ml-auto sm:w-auto flex-1\"\r" +
    "\n" +
    "                        ng-click=\"vm.promptDelete(user)\"\r" +
    "\n" +
    "                        ng-disabled=\"user.id === vm.currentUser.user.id\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"fa fa-trash\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                    \r" +
    "\n" +
    "                    <button\r" +
    "\n" +
    "                        class=\"button text-sm sm:my-auto ml-2 sm:w-auto flex-1\"\r" +
    "\n" +
    "                        ui-sref=\"main.usersDetail({ id: user.id })\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                        <i class=\"icon-pencil\"></i>\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            \r" +
    "\n" +
    "            <div \r" +
    "\n" +
    "                ng-show=\"user.delete\"\r" +
    "\n" +
    "                class=\"mt-2\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"text-center\">                       \r" +
    "\n" +
    "                    Are you sure you want to delete <strong>{{ user.firstName }} {{ user.lastName }}</strong>?\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                <div class=\"flex mt-2\">\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        class=\"button bg-slate-200 text-slate-600 text-sm ml-auto mr-2\"\r" +
    "\n" +
    "                        ng-click=\"vm.cancelDelete(user)\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Don't Delete\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "\r" +
    "\n" +
    "                    <button \r" +
    "\n" +
    "                        ng-click=\"vm.confirmDelete(user)\" \r" +
    "\n" +
    "                        class=\"button text-sm mr-auto\">\r" +
    "\n" +
    "                        \r" +
    "\n" +
    "                        Delete User\r" +
    "\n" +
    "                    </button>\r" +
    "\n" +
    "                </div>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <empty-state\r" +
    "\n" +
    "        visible=\"vm.table.isEmptyStateVisible\"\r" +
    "\n" +
    "        heading=\"No users found...\"\r" +
    "\n" +
    "        message=\"It looks like you don't have any users created.\">\r" +
    "\n" +
    "    </empty-state>\r" +
    "\n" +
    "</div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<table-pagination-footer \r" +
    "\n" +
    "    ng-if=\"vm.error.errorDisplayType != 'fullpage' && vm.table.isVisible\"\r" +
    "\n" +
    "    class=\"sticky bottom-0 block\"\r" +
    "\n" +
    "    table-pager=\"vm.table.pager\">\r" +
    "\n" +
    "</table-pagination-footer>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<full-page-error error=\"vm.error\"></full-page-error>\r" +
    "\n" +
    "\r" +
    "\n" +
    "<busy-indicator \r" +
    "\n" +
    "    promise=\"vm.loadingPromise\"\r" +
    "\n" +
    "    message=\"Loading...\">\r" +
    "\n" +
    "</busy-indicator>"
  );


  $templateCache.put('app/termsAndConditions.html',
    "<div class=\"terms-and-conditions\">\r" +
    "\n" +
    "    <h2>TERMS AND CONDITIONS AGREEMENT</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p>\r" +
    "\n" +
    "        The following Terms and Conditions constitute a binding legal contract between you and Agency Multiplied, Inc. (“A3”).  Please carefully read these terms as they affect your legal rights.  You (\"User\") are invited to consult with counsel prior to accepting the terms of this agreement.  Before you use the services provided by A3 you must read and accept all of the terms and conditions in this agreement.\r" +
    "\n" +
    "        This agreement outlines the terms that govern your use of A3 software, specifically the dashboard and widget, together with all updates, additional terms, software licenses, and all of the A3 rules and policies referred to in this agreement.  Collectively these constitute the Terms and Conditions between you and A3.  By using A3 software you agree to these terms.  If you do not agree to these terms just click on the ‘Decline’ button, and do not use the A3 services. You must accept and abide by these terms of use as presented to you in this agreement; changes, additions, or deletions are not acceptable, and A3 reserves the right to refuse access to the dashboard services for non-compliance with any part of this agreement.\r" +
    "\n" +
    "        By entering and using the A3 dashboard, you agree to abide by the following terms and conditions:\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2>PRIVACY</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p>\r" +
    "\n" +
    "        A3 protects User privacy and is dedicated to safeguarding your information. By using the A3’s dashboard, you are agreeing that A3 may store the information you provide as part of the registration or surety bond request process and as described in the privacy policy below.\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Privacy Policy</h3>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    Personal information is collected by A3, for the purposes of:\r" +
    "\n" +
    "    <dl>\r" +
    "\n" +
    "        <dt>1)</dt> <dd>Obtaining quotes for User for surety bonds;</dd>\r" +
    "\n" +
    "        <dt>2)</dt> <dd>Sharing with parties as approved by the owner of the personal information; and</dd>\r" +
    "\n" +
    "        <dt>3)</dt> <dd>Responding to authorized requests related to information audits and legislative requirements.</dd>\r" +
    "\n" +
    "    </dl>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <p>\r" +
    "\n" +
    "        In addition, where our A3 products or services are involved, we use personal information as an integral part of an ongoing process of maintenance of our accounts, including but not limited to: considering, establishing and maintaining an A3 User profile, assessing the online use of the application as it relates to performance and pricing policies and execution, investigating, evaluating and handling potential system security breach claims, providing evidentiary support for recovering losses under indemnities and guarantees provided in support of A3 profile guarantees. In using personal information for these purposes A3 sometimes discloses personal information to: A3 employees directly involved in handling A3 business and those responsible for corporate governance, regulators and auditors, courts of law, legal counsel retained to advise or assist A3 with respect to A3 matters, financial institutions for processing your online payment and companies related to A3.\r" +
    "\n" +
    "        Individual online usage patterns will not be disclosed to either advertisers or to third parties. A3 will not disclose the name of your organization, or the names, addresses, telephone numbers, email addresses, or other personal identifying information regarding document signers or Users, without your consent.  A3 does not process or store your credit card information on any of its servers. This information is managed by our payment gateway provider.\r" +
    "\n" +
    "        By using this service, you are agreeing that A3 will store the documents administered within its online servers and make them accessible to you and the Users you share these documents with.\r" +
    "\n" +
    "    </p>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    By using A3 software you are agreeing that:\r" +
    "\n" +
    "    <dl>\r" +
    "\n" +
    "        <dt>a)</dt> <dd>A3 has your consent to use the personal information you provide about yourself for the purposes described in this Privacy Policy.</dd>\r" +
    "\n" +
    "        <dt>b)</dt> <dd>When you provide Personal Information about others you have that person’s consent to disclose information about them to A3 for the purposes described in this Privacy Policy.</dd>\r" +
    "\n" +
    "        <dt>c)</dt> <dd>The specific personal information you are authorized to access under your User ID and Password will be used only for purposes described in this Privacy Policy.</dd>\r" +
    "\n" +
    "        <dt>d)</dt> <dd>A3 has your consent to send you, the other persons whose consent you have and your business associates information relevant to the A3 services via email or any other electronic or print method.</dd>\r" +
    "\n" +
    "        <dt>e)</dt> <dd>A3 has your consent to make your Surety bond requests available to its partner surety bonding companies (“Partner Carriers”), for the purpose of presenting a no obligation offer to you.</dd>\r" +
    "\n" +
    "        <dt>f)</dt> <dd>A3 will retain personal information for the same period of time it is requested by Users to retain the documents containing the personal information. By using A3 software, you are agreeing that A3 may store the information you provided as part of the registration process and as described in this Privacy Policy.</dd>\r" +
    "\n" +
    "    </dl>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Sharing of Information Received</h3>\r" +
    "\n" +
    "    A3 shares Personal Information only as described below and with controls that either are subject to the A3 Privacy Policy or follow practices at least as protective as those described in this agreement.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <dl>\r" +
    "\n" +
    "        <dt>a)</dt> <dd>PARTNER BUSINESSES WE DO NOT CONTROL: We work closely with companies with which you have established a business relationship. In some cases, these businesses may invite you and in other cases, you may invite them to be part of your working group. Or other organisation users may request the verification of the authenticity of documents signed and sealed by you. We may also share the information related to the surety bond transactions with any party that you have submitted a request to receive an offer for surety bonds or wishes to verify of the authenticity of documents signed or sealed by you.</dd>\r" +
    "\n" +
    "        <dt>b)</dt> <dd>THIRD-PARTY SERVICE PROVIDERS: We employ other companies and individuals to perform functions on our behalf. Examples include data centers, email, postal mail and e-mail service providers, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card and other electronic payments, credit report bureaus, electronic signature service providers and other providing customer service. They may have access to your personal information needed to perform their functions, but may not use it for other purposes.</dd>\r" +
    "\n" +
    "        <dt>c)</dt> <dd>A3 PARTNER CARRIER OFFERS: Sometimes we may send surety bond related offers to selected Agency Users on behalf of our Partner Carriers. These Partner Carriers offers are intended to provide the Agency Users with alternative options to choose from. You are under no obligation to accept these offers.</dd>\r" +
    "\n" +
    "        <dt>d)</dt> <dd>PROMOTIONAL OFFERS: Sometimes we send offers to selected groups of A3 customers on behalf of our businesses. If you do not want to receive such offers, please contact us to remove you from any marketing communication.</dd>\r" +
    "\n" +
    "        <dt>e)</dt> <dd>BUSINESS TRANSFERS: As we continue to develop our business, we might sell or buy subsidiaries, or business units. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Statement (unless, of course, the customer consents otherwise). Also, in the unlikely event that A3 or substantially all of its assets are acquired, customer information will of course be one of the transferred assets.</dd>\r" +
    "\n" +
    "        <dt>f)</dt> <dd>PROTECTION OF A3 AND OTHERS: We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our terms and services and other agreements; or protect the rights, property, or safety of A3, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments set forth in this Privacy Policy statement.</dd>\r" +
    "\n" +
    "        <dt>g)</dt> <dd>WITH YOUR CONSENT: Other than as set out above, you will receive notice when information about you might go to third parties, and you will have an opportunity to choose not to share the information.</dd>\r" +
    "\n" +
    "    </dl>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    Any questions or concerns about this Privacy Statement or about A3’s policies or practices in dealing with personal information should be directed to A3: Attention: Privacy 4285 Commercial St. SE, Suite 110, Salem, OR 97302\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Security</h3>\r" +
    "\n" +
    "    A3 is committed to security. We use secured data centers with redundant power and internet connectivity. A3 employs commercially reasonable precautions to maintain the security of the system, including the use of industry standard encryption methods to prevent unauthorized access to customer documents and account information stored on our servers. The Service also protects information with the HTTPS security protocol during transmission to your web browser.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Cookies</h3>\r" +
    "\n" +
    "    Cookies are unique identifiers that we transfer to your device to enable our systems to recognize your device and to provide features such as, personalized user interface and seamless navigation throughout the A3 Web sites, and session tracking.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2>USER REPRESENTATIONS AND OBLIGATIONS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Amendment</h3>\r" +
    "\n" +
    "    A3 and the Websites retain the right to amend the terms of this agreement without prior consent of or notice to the User.  You can review the most current version of the Terms and Conditions at any time by clicking the ‘Terms of Conditions’ link located at the bottom of the Website pages.  Additionally, when using particular A3 owned or operated services, you are subject to any posted guidelines or rules applicable to such services, which may be posted from time to time.\r" +
    "\n" +
    "    You are responsible for reviewing the current version of the Terms and Conditions periodically.  A3 may amend this agreement by (a) posting a revised Customer Terms and Conditions on the A3 Websites, or (b) sending information regarding the amendment at login or to the email address you provide to A3.  Continued use of the Service will be deemed acceptance of the amended Terms and Conditions.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Proper Use</h3>\r" +
    "\n" +
    "    You agree to comply with the terms and conditions of this agreement and with all applicable local, state, federal and international laws and regulations and all Internet regulations, policies and procedures, including all export and other laws regarding the transmission of technical data exported from any country through the Service. You agree that you will not use the Service for illegal purposes, to disrupt to the Service, or to distribute content that violates the privacy, intellectual property or other proprietary rights of any third party, or for purposes that A3 reasonably determines to be unlawful, obscene, defamatory, harassing, fraudulent, abusive, threatening, harmful, vulgar, or otherwise objectionable. You are wholly responsible for the contents of your transactions through the Service.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>No Resale of Services Permitted:</h3>\r" +
    "\n" +
    "    You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of A3’s software, use of the service, or access to the service without the written consent of A3.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Eligibility</h3>\r" +
    "\n" +
    "    The A3 service is available only to persons who can form a legal binding contract. Website is not available for use by minors (under 18) without parental consent.  By entering the Website and entering this TERMS AND CONDITIONS agreement, you represent and warrant that you are at least 18 years of age (or, as applicable, the age of majority in the state in which you reside), and that you possess the legal right and ability to enter into this TERMS AND CONDITIONS and you certify that you are of legal age and not restricted to using the Website by any United States or International law, or otherwise by any other law, TERMS AND CONDITIONS, or regulation.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Account Registration</h3>\r" +
    "\n" +
    "    A3 may require you to register for a password-protected account. You represent and warrant that all of the information you provide to A3 is true, accurate and complete, and that you have legal right to use the e-mail address(es) you provide. You agree to keep your information current by updating your account information. You agree to maintain the confidentiality of your password and account. You are responsible for all activities that occur under your account. A3 reserves the right to deactivate or close your account if your account remains inactive for more than twelve months.\r" +
    "\n" +
    "    Account Cancellation\r" +
    "\n" +
    "    You may cancel your account and terminate this TERMS AND CONDITIONS at any time.  A3recommends printing or copying any important documents because you will lose access to documents, messages or other content contained in your account after cancellation.  A3 reserves the right to terminate any account that violates the terms of this TERMS AND CONDITIONS.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Communication</h3>\r" +
    "\n" +
    "    You agree that the official time for all transactions using the Service will be the timestamps recorded by the A3’s servers. You also agree that all electronic communications or notices sent to the e-mail address you provide to A3, placed in your account, or posted on the Web Site will be considered “in writing” and received within five (5) business days of its dissemination.  A3 disclaims all responsibility for all failures in communication caused by failures of third parties to properly process or deliver A3’s electronic communications.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2>A3'S RIGHTS AND DISCLAIMERS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Relationship to Documents</h3>\r" +
    "\n" +
    "    Unless A3 is named as a direct party, A3’s relationship to all documents and transactions completed using the Service is that of a trusted, disinterested third party. Accordingly, A3 disclaims any representations of any kind regarding the documents or transactions that pass through the Service. You are responsible for recognizing the parties of interest in all transactions you complete using the Service and assessing all associated risks.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Maintenance</h3>\r" +
    "\n" +
    "    The Service will occasionally be unavailable during system maintenance and upgrades. A3 will make commercially reasonable efforts to ensure that the Service will be available during any system maintenance period.  However, A3 disclaims all responsibility for any interruptions or problems with the Service caused by external forces affecting the reliability of the Internet or computer systems.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Limitation of Liability</h3>\r" +
    "\n" +
    "    YOU EXPRESSLY UNDERSTAND AND AGREE THAT A3 SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH A3 HAS BEEN ADVISED OF THE POSSIBILITY THEREOF), AND INCLUDING DAMAGES RESULTING FROM: (i) ANY DOCUMENT OR TRANSACTION SENT THROUGH THE SERVICE WHERE A3 WAS NOT A DIRECT PARTY; (ii) THE USE OR INABILITY TO USE THE SERVICE, INCLUDING ERRORS, INTERRUPTIONS OR DELAYS; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR DOCUMENTS OR TRANSMISSIONS; OR (iv) ANY OTHER MATTER RELATING TO THE SERVICE. A3’s LIABILITY SHALL NOT EXCEED, IN THE AGGREGATE, A SUM EQUAL TO THE AMOUNT OF FEES (IF ANY) PAID TO A3 FOR USING THE SERVICE TO COMPLETE THE TRANSACTION UNDER THIS TERMS AND CONDITIONS.  YOU UNDERSTAND AND AGREE TO THAT YOU WILL AT ALL TIMES INDEMNIFY, AND KEEP INDEMNIFIED, A3, AND HOLD AND SAFE A3 HARMLESS FROM AND AGAINST ANY AND ALL LIABILITY, LOSS, COSTS, DAMAGES, CHARGES AND EXPENSES OF WHATSOEVER KIND AND NATURE, INCLUDING EXPENSES, COLLECTIONS FEES AND ATTORNEYS’ FEE, WHICH IT MAY, AT ANY TIME, SUSTAIN OR INCUR BY REASON OF USING A3 OR UNDERTAKING OR ENFORCING THIS AGREEMENT.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Disclaimer of Warranty</h3>\r" +
    "\n" +
    "    YOU EXPRESSLY UNDERSTAND AND AGREE THAT:\r" +
    "\n" +
    "    1. YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. A3 PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS. A3 EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.\r" +
    "\n" +
    "    2. A3 DOES NOT WARRANT AND EXPRESSLY DISCLAIMS THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS; (ii) THE SERVICE WILL BE UNINTERRUPTED, RELIABLE, TIMELY, SECURE, ERROR-FREE OR FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS, (iii) ANY ERRORS ON THE A3 WEB SITE OR SERVICE WILL BE CORRECTED; AND (iv) THAT DOCUMENTS OR MATERIALS THAT YOU STORE VIA THE SERVICE WILL REMAIN ACCESSIBLE\r" +
    "\n" +
    "    3. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR SOLE DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.\r" +
    "\n" +
    "    4. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM A3, OR THROUGH OR FROM THE A3 WEB SITE OR SERVICE, SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TERMS AND CONDITIONS.\r" +
    "\n" +
    "    5. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSIONS OF CERTAIN WARRANTIES OR THE LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Intellectual Property Rights</h3>\r" +
    "\n" +
    "    You acknowledge and agree that A3 owns all right, title and interest in the Service and the web site, including, without limitation, all intellectual property rights. You acknowledge and agree that the Service and the website contain proprietary and confidential information and trade secrets including, but not limited to text, graphics, logos, images, software, and icons, that are protected by United States and international intellectual property laws and other applicable laws.\r" +
    "\n" +
    "    You agree not to display or use A3 Marks in any manner without A3’s prior written permission.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>A3’s Bonding Services Terms</h3>\r" +
    "\n" +
    "    By using the services provided by A3 and its websites, you expressly understand and agree to the following:\r" +
    "\n" +
    "    a)	You shall provide correct and accurate answers to all questions asked by the A3 system on behalf of the member bonding companies or its agents.  You expressly understand and agree that intentional misinformation is considered insurance fraud.\r" +
    "\n" +
    "    b)	You understand false statements may result in forfeiture of benefits and possible prosecution by the US Attorney-General - (Any person who knowingly and with the intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing false, or misleading information is guilty of a felony of the third degree.\r" +
    "\n" +
    "    c)	Obtaining surety bond quotes is 100% free and there is no cost should you decline the offer.\r" +
    "\n" +
    "    d)	The quote you may receive is a conditional offer and is subject to change based on a multitude of considerations.\r" +
    "\n" +
    "    e)	The quote may include a broker fee. Any possible broker fees are part of the quote you may receive.\r" +
    "\n" +
    "    f)	You may or may not receive a quote immediately from an online surety bond request, as some applicants require further review by a human in order to obtain the lowest rate.\r" +
    "\n" +
    "    g)	Online applications are contracts between the principal applying for said bond and the bond agency. By agreeing to the TERMS AND CONDITIONS, you hereby understand and agree that the bond premium is fully earned upon issuance of the bond and is not refundable.\r" +
    "\n" +
    "    h)	If your bond is cancelled mid-term, you also understand and agree that any and all fees and premiums are also fully earned, and are not refundable. In addition, fees may earn interest. This undeclared income is non-refundable and fully earned as well as any fees charged.\r" +
    "\n" +
    "    i)	Completion of an online surety bond request form constitutes permission for the agency and their bonding companies to obtain consumer information from any source which will be used to determine bonding eligibility. This includes obtaining credit reports at the time of application, in any review, or renewal, at the time of any potential or actual claim, or for any other legitimate purposes as determined by the bonding company in its reasonable discretion. This information will be held in the strictest of confidence. Soft credit reports that do not count as inquires on your report will be used whenever possible.\r" +
    "\n" +
    "    j)	This agreement shall serve as the writing required pursuant to the rules set forth by your Department of Insurance.\r" +
    "\n" +
    "    k)	You expressly understand and consent that the information submitted using the A3 system may be shared with 3rd parties in order to assess risk and or approve your bond requests.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Electronic Signature Service: Policies</h3>\r" +
    "\n" +
    "    A3 uses a third party electronic signature service.  If you use the Service and whenever applicable, you are agreeing to conduct business transactions with electronic documents and signatures instead of paper-based documents and signatures. You are under no obligation to transact business electronically. To withdraw your consent to conduct electronic transactions, simply stop using the Electronic Signature Service portion of the transaction and contact the sending party to explore other options. You may read and sign each document on a case-by-case basis. Each decision to view or sign a document electronically does not affect the legal effect of any transactions already completed using either electronic or paper-based documents or signatures. You hereby agree to read every document before electronically signing it. You also agree to communicate all issues regarding the content of a document directly with the sending party. Finally, you agree to notify the sending parties and the Service of any change in your e-mail address in order to prevent interruptions to your communications. You acknowledge and understand that electronic signatures are legally binding in the United States and other countries. You also understand that printed copies of electronic documents might not be considered legal originals, but rather copies of the digitally signed documents or held within the A3 Service.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Electronic Signature Service: Requirements</h3>\r" +
    "\n" +
    "    Use of the Service requires a standards-compliant web-browser which supports the HTTPS protocol, HTML, Java Runtime Environment and cookies. Electronic signatures are void where prohibited by law.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Legal Document Service: Legal Information Disclaimer</h3>\r" +
    "\n" +
    "    The Service offers documents and other information that may be relevant to the legal needs of our users. However, the legal information available on or through the Service does not constitute legal advice as it does not draw legal conclusions or apply the law to the specific circumstances of each user.  If you require legal advice, please consult a law services professional in your area.  Your use of the Service cannot replace the advice of a lawyer or create a lawyer-client relationship between you and A3.  Although A3 makes reasonable efforts to ensure the accuracy of the legal information it provides, the law is constantly changing, and A3 cannot guarantee the correctness or completeness of the legal information.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h2>GENERAL PROVISIONS</h2>\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Entire Agreement</h3>\r" +
    "\n" +
    "    This Agreement constitutes the entire agreement between you and A3 and its related Websites and governs your use of the A3’s Service. This Agreement supersedes all prior agreements and understandings between you and A3 and its related Websites, whether written or oral.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Indemnification</h3>\r" +
    "\n" +
    "    You agree to hold harmless and indemnify A3 and its subsidiaries, affiliates, officers, agents, directors, managers, employees, and successors from any claims, losses, damages, suits, demands, liabilities, judgments, litigation costs and attorneys’ fees arising from or in any way related to your use of the Service, your breach of any of your obligations under this Agreement, or your violation of any applicable laws or regulations.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Severability and Waiver</h3>\r" +
    "\n" +
    "    If a court of competent jurisdiction declares any term or provision in this Agreement invalid or unenforceable, you and A3 nevertheless agree that the court should construe that term or provision consistent with applicable law as nearly as possible to reflect the parties’ original intentions, and that the remaining terms and provisions remain in full force and effect. Failure by A3 to exercise or enforce any provision of this Agreement shall not constitute a waiver of any such provision.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Choice of Law</h3>\r" +
    "\n" +
    "    This Agreement shall be governed by the laws of the State of Oregon, USA without regard to its conflict of law provisions. You and A3 agree to submit to the personal and exclusive jurisdiction of the courts located within Marion County, Oregon, USA.\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <h3>Mediation</h3>\r" +
    "\n" +
    "    All claims, disputes, and controversies arising out of or in relation to the performance, interpretation, application, or enforcement of this agreement, including but not limited to breach thereof, shall be referred to mediation before, and as a condition precedent to, the initiation of any adjudicative action or proceeding, including arbitration.\r" +
    "\n" +
    "</div>"
  );

}]);
