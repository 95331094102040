import { Injectables } from "../configuration/injectables";
import app from "../main";
import { DataService } from "../api/dataSvc";

/*
 * -- This factory is used to communicate with the iframe parent.
 */
const apiFactory = (dataSvc: DataService) => {

	const listener = (event) => {
		if(event.data) {

			dataSvc.get('navigation', event.data, function(data){
				if(data.error){
					console.log('error');
				} else if(data.path === undefined || data.path === ''){
					console.log('no path');
				} else {
					window.parent.postMessage({ 
						path: data.path, 
						application_path: data.application_path 
					}, '*');
				}
			});
		}
	}

	if (window.addEventListener){
		window.addEventListener("message", listener, false);
	} else {
		(window as any).attachEvent("onmessage", listener);
	}

	return {
		routeIframe: (data) => {
			window.parent.postMessage({ 
				path: data.path, 
				application_path: data.application_path,
				application_number: data.application_number
			}, '*');
		}
	}
}

apiFactory.$inject = [
	Injectables.DataService
];

app.factory('api', apiFactory);
