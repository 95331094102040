import { IHttpService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import app from "../main";
import A3ApiResponse from "./a3ApiResponse";
import { TableQueryOptions } from '../utilities/tables/tableQueryOptions';
import buildTableQueryString from '../utilities/tables/buildTableQueryString';
import { DocumentListItem } from '../states/documents/documentsController';
import { PageResponse } from '../utilities/tables/pageResponse';

export class AttachmentService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ) {}

    public getApplicationAttachments = (applicationId: number) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetApplicationAttachments?applicationId=${applicationId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => {
                for(let i = 0; i < response.data.value.length; i++) {
                    this.setDownloadUrl(response.data.value[i]);
                }

                return response.data.value;
            });
    }

    public getAllAttachments = (tableQueryOptions: TableQueryOptions) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerAttachments${buildTableQueryString(tableQueryOptions)}`;

        return this.$http.get<A3ApiResponse<PageResponse<DocumentListItem>>>(url)
            .then(response => {
                for(let i = 0; i < response.data.value.items.length; i++) {
                    this.setDownloadUrl(response.data.value.items[i]);
                }

                return response.data.value;
            });
    }

    public uploadAttachment = (attachment) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/AddAttachment`;

        return this.$http.post(url, attachment);
    }

    public setDownloadUrl = (attachment) => {
        attachment.downloadUrl = `${this.systemSettings.apiBaseUrl}Download/Attachment?id=${attachment.id}`;
    }
}

app.service(Injectables.AttachmentService, AttachmentService);
