import { IHttpService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import A3ApiResponse from "./a3ApiResponse";
import app from "../main";
import { TableQueryOptions } from "../utilities/tables/tableQueryOptions";
import buildTableQueryString from "../utilities/tables/buildTableQueryString";
import { PageResponse } from "../utilities/tables/pageResponse";
import { EProducerAccountUser } from "../states/users/eProducerAccountUser";

export class EProducerUserService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ) {}

    public getEProducerUsers = (queryOptions: TableQueryOptions) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerUsers${buildTableQueryString(queryOptions)}`;
        
        return this.$http.get<A3ApiResponse<PageResponse<EProducerAccountUser>>>(url)
            .then(response => response.data.value);
    }

    public getEProducerUser = (id: number) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerUser?userId=${id}`;

        return this.$http.get<A3ApiResponse<EProducerAccountUser>>(url)
            .then(response => response.data.value);
    }

    public deleteEProducerUser = (userId: number) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/DeleteEProducerUser`;
        
        return this.$http.post(url, {userId})
            .then(() => {});
    }

    public insertEProducerUser = (user: EProducerAccountUser) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/AddEProducerUser`;

        return this.$http.post<A3ApiResponse<any>>(url, user)
            .then(response => response.data.value);
    }

    public updateEProducerUser = (user: EProducerAccountUser) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/EditEProducerUser`;

        return this.$http.post<A3ApiResponse<any>>(url, user)
            .then(response => response.data.value);
    }
}

app.service(Injectables.EProducerUserService, EProducerUserService);

