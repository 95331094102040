import isUndefinedOrNull from "./isUndefinedOrNull";

export default (value?: string | number): number => {

    if (isUndefinedOrNull(value)) {
        return null;
    }

    if (typeof value !== 'string' && typeof value !== 'number') {
        return null;
    }

    if (value === '') {
        return null;
    }

    return parseFloat(value.toString().replace(/[^0-9.-]+/g, ''));
}