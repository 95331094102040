import { IPromise, ITimeoutService } from "angular";
import { EProducerUserService } from "../../api/eProducerUserService";
import { UiError } from '../../components/fullPageError/uiError';
import { SuccessMessage } from "../../components/inlineAlert/inlineAlert";
import { Injectables } from "../../configuration/injectables";
import { EProducerAccountType, EProducerRootScope } from "../../eProducerRootScope";
import { IEProducerStateParams } from "../../eProducerStateParams";
import { State } from "../state";
import { EProducerAccountUser } from "./eProducerAccountUser";
import { Table } from "../../utilities/tables/table";

export class UsersGridController {
    public static $inject = [
        Injectables.EProducerUserService,
        Injectables.$rootScope,
        Injectables.$stateParams,
        Injectables.$state,
        Injectables.$timeout
    ];

    constructor(
        private readonly eProducerUserService: EProducerUserService,
        private readonly $rootScope: EProducerRootScope,
        private readonly $stateParams: IEProducerStateParams,
        private readonly $state: State,
        private readonly $timeout: ITimeoutService
    ) {
        this.table = new Table(this.$timeout);
        this.table.onChange = this.loadUsers;
    }

    public error: UiError;
    public successMessage: SuccessMessage;
    public table: Table<EProducerAccountUser>;
    public loadingPromise: IPromise<any>;

    public promptDelete(user: EProducerAccountUser) {
        user.delete = true;
        this.error = null;
    }

    public cancelDelete(user: EProducerAccountUser) {
        user.delete = false;
        this.error = null;
    }

    public confirmDelete(user: EProducerAccountUser) {
        this.error = null;
        
        return this.eProducerUserService.deleteEProducerUser(user.id)
            .then(() => {
                this.loadUsers();
                this.successMessage = {
                    heading: 'User Deleted',
                    message: 'User was successfully deleted from your account'
                };
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while trying to delete this user. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            });
    }

    public loadUsers = () => {
        this.loadingPromise = this.eProducerUserService.getEProducerUsers(this.table.queryOptions)
            .then(response => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while loading users. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            });
    }

    public $onInit() {
        if (!this.$rootScope.currentUser) {
            this.$state.go('main.login', 'main.usersGrid');
        }

        if (this.$rootScope.currentUser.eProducerAccount.eProducerAccountType != EProducerAccountType.Broker) {
            this.$state.go('main.userDefaultLanding');
        }
        
        if (this.$stateParams.successMessage) {
            this.successMessage = {
                message: this.$stateParams.successMessage
            };
        }
        
        this.loadUsers();
    }
}