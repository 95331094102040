import { IFormController } from 'angular';
import { AuthenticationService } from '../../api/authenticationService';
import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { UiError } from '../fullPageError/uiError';
import { SuccessMessage } from '../inlineAlert/inlineAlert';

export class LoginFormController {
    public static $inject = [Injectables.AuthenticationService];

    constructor(private authenticationService: AuthenticationService) { }

    public onLogin: () => void;
    public error: UiError;
    public email: string;
    public password: string;
    public requestPasswordReset: boolean;
    public successMessage: SuccessMessage;
    public onForgotPasswordClick: () => void;
    public onBackToLoginClick: () => void;

    private clearMessages() {
        this.error = null;
        this.successMessage = null;
    }

    private showErrorMessage(message: string) {
        this.error = {
            errorDisplayType: 'inline',
            message: message
        };
        this.successMessage = null;
    };

    private showSuccessMessage(message: string) {
        this.successMessage = {
            message: message
        };
        this.error = null;
    }

    public loginClick(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.authenticationService.login(this.email, this.password)
            .then(() => {
                this.onLogin();
            })
            .catch(() => {
                this.showErrorMessage("Your username or password are incorrect.");
            });
    }

    public passwordResetRequestClick() {
        return this.authenticationService.requestPasswordReset(this.email)
            .then(() => {
                this.showSuccessMessage('Check your email for instructions to reset your password');
            })
            .catch(err => {
                this.showErrorMessage(err);
            });
    }

    public showForgotPassword() {
        this.requestPasswordReset = true;
        this.clearMessages();

        if (this.onForgotPasswordClick) {
            this.onForgotPasswordClick();
        }
    }

    public showLogin() {
        this.requestPasswordReset = false;
        this.clearMessages();

        if (this.onBackToLoginClick) {
            this.onBackToLoginClick();
        }
    }

    public $onInit() {
    }
}

const component = {
    bindings: {
        onLogin: '&',
        onForgotPasswordClick: '&?',
        onBackToLoginClick: '&?'
    },
    controller: LoginFormController,
    controllerAs: 'vm',
    templateUrl: 'app/components/loginForm/loginForm.html'
};

app.component('loginForm', component);