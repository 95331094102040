import { IFormController, IQService } from "angular";
import { ApplicationService } from "../../api/applicationService";
import { Injectables } from "../../configuration/injectables";
import { EProducerRootScope, SystemAccount } from "../../eProducerRootScope";
import { IEProducerStateParams } from "../../eProducerStateParams";
import { QuoteForEProducerPurchase } from "../quotes/quoteForEProducerPurchase";
import { Payment, PaymentMethod } from "./payment";
import getCreditCardMonths from "../../utilities/getCreditCardMonths";
import getCreditCardYears from "../../utilities/getCreditCardYears";
import angular from "angular";
import { ShippingInfo } from "./shippingInfo";
import { PaymentService } from "../../api/paymentService";
import { IEProducerFilters } from "../../filters/eProducerFilters";
import { State } from "../state";
import { ApplicationStatus } from "./application";
import { FieldOption } from "../../components/fields/fieldOption";
import { UiError } from "../../components/fullPageError/uiError";

export class PurchaseController {
    public static $inject = [
        Injectables.ApplicationService,
        Injectables.$stateParams,
        Injectables.$rootScope,
        Injectables.PaymentService,
        Injectables.$filter,
        Injectables.$state
    ];

    constructor(
        private readonly applicationService: ApplicationService,
        private readonly $stateParams: IEProducerStateParams,
        private readonly $rootScope: EProducerRootScope,
        private readonly paymentService: PaymentService,
        private readonly $filter: IEProducerFilters,
        private readonly $state: State
    ) {}

    public achAccountTypeOptions: FieldOption<string>[];
    public creditCardExpirationMonthOptions: FieldOption<string>[];
    public creditCardExpirationYearOptions: FieldOption<string>[];
    public error: UiError;
    public expediteFee: number;
    public payment: Partial<Payment>;
    public processingFeeFormatted: string;
    public processPayment: boolean;
    public quote: QuoteForEProducerPurchase;
    public quoteId: number;
    public shipping: ShippingInfo;
    public submitting: boolean;    

    public get allowPayment() {
        return this.$rootScope.systemAccount.allowACHPaymentsThroughEProducer || this.$rootScope.systemAccount.allowCCPaymentsThroughEProducer;
    }

    public get allowDeferredPayment() {
        return this.currentUser?.eProducerAccount?.allowDeferredPayment;
    }

    public get systemAccount() {
        return this.$rootScope.systemAccount;
    }

    public get currentUser() {
        return this.$rootScope.currentUser;
    }

    public initPaymentForm() {
        this.payment = {
            amount: this.getPaymentAmount(),
            systemAccountId: this.systemAccount.id,
            processingFee: 0,
            paymentOrigin: 'EProducerQuotePurchase'
        };

        if (this.systemAccount.allowACHPaymentsThroughEProducer) {
            
            this.achAccountTypeOptions = [
                { label: 'Checking', value: '0' },
                { label: 'Savings', value: '1' },
                { label: 'Corporate Checking', value: '2' },
                { label: 'Corporate Savings', value: '3' }
            ];
        }

        if (this.systemAccount.allowCCPaymentsThroughEProducer) {
            this.creditCardExpirationMonthOptions = getCreditCardMonths();
            this.creditCardExpirationYearOptions = getCreditCardYears();
        }

        this.processPayment = true;

        this.checkForTransactionFee();
    }

    public submit(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        if (this.payment.paymentMethod === PaymentMethod.DeferredPayment) {
            delete this.payment;
        }

        this.submitting = true;

        this.applicationService.purchase(this.quote, this.payment, this.shipping)
            .then((response) => {
                if (response.success) {
                    this.$state.go('main.download', {quoteId: this.quoteId});
                } else {
                    this.error = {
                        errorDisplayType: 'inline',
                        heading: 'Unable to purchase quote',
                        message: response.message,
                        errorType: 'Unknown'
                    };
                }
            })
            .catch((e) => {
                this.error = {
                    errorDisplayType: 'inline',
                    heading: 'Unable to purchase quote',
                    message: 'An error occurred while trying to complete your purchase.',
                    errorType: 'Unknown'
                };
            })
            .finally(() => {
                this.submitting = false;
            });
    };

    public expediteChanged() {
        this.expediteFee = this.shipping.expedited === true ? this.systemAccount.expeditedProcessingFee : 0;
        this.payment.amount = this.getPaymentAmount();
        this.checkForTransactionFee();
    }

    public checkForTransactionFee() {
        const request = {
             payment: angular.copy(this.payment)
        };

        this.paymentService.getFees(request)
            .then((response) => {
                this.payment.processingFee = response.fee;
                this.processingFeeFormatted = this.$filter('currency')(response.fee, "$");
            });
    }

    public getPaymentAmount() {
        return (this.quote.totalAmountDue + this.expediteFee) || null;
    }

    public goToNewApplication() {
        this.$state.go('main.application');
    }

    public $onInit() {
        this.quoteId = this.$stateParams.quoteId;

        if (!this.quoteId) {
            this.error = {
                errorDisplayType: 'fullpage',
                heading: 'Invalid URL',
                message: 'The url you used is not correct. Please be sure to use the link provided to you through email.',
                errorType: 'Quote Id Not Valid'
            };
            return;
        }

        this.creditCardExpirationMonthOptions = [];
        this.creditCardExpirationYearOptions = [];

        this.applicationService.getQuoteForEProducerPurchase(this.quoteId)
            .then((quote) => {

                if (quote) {
                    this.quote = quote;

                    if (this.quote.applicationStatus == ApplicationStatus.Completed) {
                        this.$state.go('main.download', {quoteId: this.quoteId});
                    }

                    if (this.quote.shippingInfo) {
                        this.shipping = this.quote.shippingInfo;
                    }
                } else {
                    this.error = {
                        errorDisplayType: 'fullpage',
                        heading: 'Something went wrong',
                        message: 'The quote could not not be found. Please be sure to use the link provided to you through email.',
                        errorType: 'QuoteNotFound'
                    };
                }
            })
            .then(() => {
                if (!this.allowDeferredPayment && this.allowPayment) {
                    this.initPaymentForm();
                }
            });

        this.expediteFee = 0;
    }
}