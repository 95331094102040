import isStringNumber from "./isStringNumber";
import isUndefinedOrNull from "./isUndefinedOrNull";

export default (value?: string | number) => {

    if (isUndefinedOrNull(value)) {
        return '';
    }

    if (typeof value !== 'string' && typeof value !== 'number') {
        return '';
    }

    if (value === '') {
        return '';
    }

    let numberValue: number;

    if (typeof value === 'string') {
        const cleanValue = value.replace(/[^0-9.]/g, '');

        if (!isStringNumber(cleanValue)) {
            return '';
        }

        numberValue = Number(cleanValue);
    } else {
        numberValue = value;
    }

    return new Intl.NumberFormat(
        'en-US', {
            style: 'currency',
            currency: 'USD'
        })
        .format(numberValue);
}