import { IPromise, ITimeoutService } from 'angular';
import { BondService } from "../../api/bondService";
import { Injectables } from "../../configuration/injectables";
import { Table } from '../../utilities/tables/table';
import { UiError } from '../../components/fullPageError/uiError';

export class PurchasedBondsController {
    public static $inject = [
        Injectables.BondService,
        Injectables.$timeout
    ]

    constructor(
        private readonly bondService: BondService,
        $timeout: ITimeoutService
    ) {
        this.table = new Table($timeout);
        this.table.onChange = this.loadBonds;
    }

    public table: Table<BondListItem>;
    public error: UiError;
    public loadingPromise: IPromise<any>;

    public loadBonds = () => {
        this.loadingPromise = this.bondService.getBonds(this.table.queryOptions)
            .then(response => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while loading bond records. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            });
    }

    public $onInit() {
        this.loadBonds();
    }
}

export type BondListItem = {
    id: number;
    bondType: string;
    nameOnBond: string;
    bondAmount: number;
    bondNumber: number;
    effectiveDate: string;
    expirationDate: string;
}