import app from '../../main';

export class PasswordRequirementIndicatorController {
    public password: string;

    public hasLowerCase(): boolean {
        return /[a-z]/.test(this.password || '');
    }

    public hasUpperCase(): boolean {
        return /[A-Z]/.test(this.password || '');
    }

    public hasNumber(): boolean {
        return /\d/.test(this.password || '');
    }

    public hasMinLength(): boolean {
        return (this.password || '').length >= 8;
    }

    public hasValidCharacters(): boolean {
        return /^[A-Za-z0-9!@#$%^&*()_\-+=]+$/.test(this.password || '');
    }
}

const component = {
    bindings: {
        password: '<'
    },
    controller: PasswordRequirementIndicatorController,
    controllerAs: 'vm',
    templateUrl: 'app/components/passwordRequirementIndicator/passwordRequirementIndicator.html'
};

app.component('passwordRequirementIndicator', component);
