import isUndefinedOrNull from "./isUndefinedOrNull";

export default (value?: string | Date): string => {

    if (isUndefinedOrNull(value)) {
        return null;
    }

    if (typeof value !== 'string' && !(value instanceof Date)) {
        return null;
    }

    if (value === '') {
        return null;
    }

    const date = typeof value === 'string' ? new Date(value + 'T00:00:00') : value; // Adjust for ISO format
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}