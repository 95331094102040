import { TableQueryOptions } from './tableQueryOptions';

export default (tableQueryOptions: TableQueryOptions) => {
    if (!tableQueryOptions) {
        tableQueryOptions = {
            pageNumber: 1,
            recordsPerPage: 10,
            orderBy: "",
            searchPhrase: ""
        };
    }

    if (!tableQueryOptions.searchPhrase) {
        tableQueryOptions.searchPhrase = "";
    }

    if (!tableQueryOptions.pageNumber) {
        tableQueryOptions.pageNumber = 1;
    }

    if (!tableQueryOptions.recordsPerPage) {
        tableQueryOptions.recordsPerPage = 10;
    }

    if (!tableQueryOptions.orderBy) {
        tableQueryOptions.orderBy = "";
    }

    let queryString = "?";

    queryString += `&pageNumber=${tableQueryOptions.pageNumber}`;
    queryString += `&recordsPerPage=${tableQueryOptions.recordsPerPage}`;
    queryString += `&orderBy=${tableQueryOptions.orderBy}`;
    queryString += `&searchPhrase=${tableQueryOptions.searchPhrase}`;

    return queryString;
}