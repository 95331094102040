import { AdditionalApplicationQuestion } from "./application";

export type ApplicationSettings = {
    bondTypeId: number;
    minimumEffectiveDate: string;
    maximumEffectiveDate: string;
    allowsBackdating: boolean;
    requiresCreditReport: boolean;
    bondTypeName: string;
    fixedBondAmount: number;
    nameOnBondInstructions: string;
    minimumBondAmount: number;
    maximumBondAmount: number;
    variableBondAmounts: VariableBondAmount[];
    state: string;
    isGenericObligee: boolean;
    isPolicy: boolean;
    companyInformationIsApplicable: boolean;
    multiplePeopleAllowed: boolean;
    nameOnBondAllowFirstMiddleLast: boolean;
    nameOnBondAllowCompanyDBA: boolean;
    nameOnBondAllowOtherA3: boolean;
    nameOnBondAllowCombinePrincipals: boolean;
    nameOnBondAllowFirstLast: boolean;
    nameOnBondAllowCompanyName: boolean;
    nameOnBondAllowOtherEProducer: boolean;

    additionalApplicationQuestions: AdditionalApplicationQuestion[];

    companyNameFieldStatus: FieldStatus;
    companyDbaFieldStatus: FieldStatus;
    companyTypeFieldStatus: FieldStatus;
    companyPhoneFieldStatus: FieldStatus;
    companyDateFormedFieldStatus: FieldStatus;
    companyNumberOfOwnersFieldStatus: FieldStatus;
    companyNumberOfEmployeesFieldStatus: FieldStatus;
    companyFaxFieldStatus: FieldStatus;
    companyEmailFieldStatus: FieldStatus;
    companyAddressFieldStatus: FieldStatus;
    companyCountyFieldStatus: FieldStatus;
    companyStateOfIncorporationFieldStatus: FieldStatus;
    companyFEINFieldStatus: FieldStatus;
    companyFiscalYearEndFieldStatus: FieldStatus;
    companyNmlsNumberFieldStatus: FieldStatus;
    companyWebsiteUrlFieldStatus: FieldStatus;

    personMiddleNameFieldStatus: FieldStatus;
    personPrefixFieldStatus: FieldStatus;
    personSuffixFieldStatus: FieldStatus;
    personSSNFieldStatus: FieldStatus;
    personDateOfBirthFieldStatus: FieldStatus;
    personDriversLicenseNumberFieldStatus: FieldStatus;
    personDriversLicenseStateFieldStatus: FieldStatus;
    personHomePhoneFieldStatus: FieldStatus;
    personCellPhoneFieldStatus: FieldStatus;
    personFaxFieldStatus: FieldStatus;
    personGenderFieldStatus: FieldStatus;
    personAddressFieldStatus: FieldStatus;
    personCountyFieldStatus: FieldStatus;
    personJobTitleFieldStatus: FieldStatus;
    personMaritalStatusFieldStatus: FieldStatus;
    personSpouseNameFieldStatus: FieldStatus;
    personSpouseSsnFieldStatus: FieldStatus;
    personSpouseEmailFieldStatus: FieldStatus;
    personSpouseCellPhoneFieldStatus: FieldStatus;
    personSpouseDateOfBirthFieldStatus: FieldStatus;
    personSpouseGenderFieldStatus: FieldStatus;
    personResidenceTypeFieldStatus: FieldStatus;
    personDateMovedToResidenceFieldStatus: FieldStatus;
    personEmployerNameFieldStatus: FieldStatus;
    personEmployerAddressFieldStatus: FieldStatus;
    personEmployerCountyFieldStatus: FieldStatus;
    personEmployerPhoneFieldStatus: FieldStatus;
    personYearsExperienceFieldStatus: FieldStatus;
    personOwnershipPercentFieldStatus: FieldStatus;
    personIsUSCitizenFieldStatus: FieldStatus;
}

export enum FieldStatus {
    Hidden = "Hidden",
    Optional = "Optional",
    Required = "Required"
}

export type VariableBondAmount = {
    id: number;
    amount: number;
}

export enum BondAmountType {
    Fixed = "Fixed",
    Variable = "Variable",
    UserDefined = "UserDefined"
}

export type BondAmountResponse = {
    bondAmountType?: BondAmountType,
    bondAmount?: number | string,
    minimumBondAmount?: number,
    maximumBondAmount?: number,
    variableAmounts?: string | null,
}

export default ApplicationSettings;