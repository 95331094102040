import { SystemAccountService } from '../../api/systemAccountService';
import { Injectables } from '../../configuration/injectables';
import app from "../../main";

export class SystemAccountLogoController {
    public static $inject = [Injectables.SystemAccountService];

    constructor(
        private readonly systemAccountService: SystemAccountService
    ) {}

    public systemAccountId: number;
    public eproducerAccountId: number;
    public url: string;
    public variant: 'small' | 'large' = 'small';

    public setLogoUrl() {
        this.systemAccountService.getBrandingLogo(this.systemAccountId, this.eproducerAccountId, this.variant)
            .then((dataUrl: string) => {
                this.url = dataUrl;
            });
    }

    public $onInit() {
        this.setLogoUrl();
    }
}

const component = {
    bindings: {
        systemAccountId: '<',
        eproducerAccountId: '<?',
        variant: '@?',
        imageClass: '@?',
    },
    controller: SystemAccountLogoController,
    controllerAs: 'vm',
    templateUrl: 'app/components/systemAccountLogo/systemAccountLogo.html'
}

app.component('systemAccountLogo', component);