import { IFilterService } from "angular";
import { IDateOnlyFilter } from "./dateOnly";
import { IFileSizeFilter } from "./fileSizeFilter";
import { IHighlightFilter } from "./highlight";
import { Injectables } from "../configuration/injectables";
import { ILocalDateTimeFilter } from "./localDateTime";

export interface IEProducerFilters extends IFilterService {
    (name: Injectables.DateOnly): IDateOnlyFilter;
    (name: Injectables.FileSize): IFileSizeFilter;
    (name: Injectables.Highlight): IHighlightFilter;
    (name: Injectables.LocalDateTime): ILocalDateTimeFilter;
}