import { ApplicationService } from "../../api/applicationService";
import { BondService } from "../../api/bondService";
import { UiError } from "../../components/fullPageError/uiError";
import { Injectables } from "../../configuration/injectables";
import { SystemSettings } from "../../configuration/systemSettings";
import { EProducerRootScope, SystemAccount } from "../../eProducerRootScope";
import { IEProducerStateParams } from "../../eProducerStateParams";
import { QuotePurchaseReceipt } from "./quotePurchaseReceipt";

export class DownloadController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.ApplicationService,
        Injectables.BondService,
        Injectables.SystemSettings,
        Injectables.$rootScope
    ]

    constructor(
        private readonly $stateParams: IEProducerStateParams,
        private readonly applicationService: ApplicationService,
        private readonly bondService: BondService,
        private readonly systemSettings: SystemSettings,
        private readonly $rootScope: EProducerRootScope
    ) {}

    public canDownloadPacket: boolean;
    public hasOpenBalance: boolean;
    public documentPacketUrl: string;
    public error: UiError;
    public quoteId: number;
    public quotePurchaseReceipt: QuotePurchaseReceipt;

    public get systemAccount(): SystemAccount {
        return this.$rootScope.systemAccount;
    }

    public getInvoiceURL = (invoiceId: number) => {
        return `${this.systemSettings.apiBaseUrl}DataDocument/GetInvoiceDocument?invoiceId=${invoiceId}`;
    }

    public getQuotePurchaseReceipt = () => {
        return this.applicationService.getQuotePurchaseReceipt(this.quoteId)
            .then((receipt) => {
                this.quotePurchaseReceipt = receipt;
                this.hasOpenBalance = this.quotePurchaseReceipt.hasBalance;
                this.documentPacketUrl = this.bondService.getPacketUrlByTransactionId(this.quotePurchaseReceipt.bondTransactionId);
                this.canDownloadPacket = this.quotePurchaseReceipt.allowsElectronicSignature;
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    heading: 'Something went wrong',
                    message: 'The record could not not be found. Please be sure to use the link provided to you through email.',
                    errorType: 'Record Not Found'
                };
            });
    }

    public $onInit() {
        this.quoteId = this.$stateParams.quoteId;

        if (!this.quoteId) {
            this.error = {
                errorDisplayType: 'fullpage',
                heading: 'Invalid URL',
                message: 'The url you used is not correct. Please be sure to use the link provided to you through email.',
                errorType: 'Quote Id Not Valid'
            };
            return;
        }

        this.getQuotePurchaseReceipt();
    }
}