import { IHttpService, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import { EProducerService } from "./eProducerService";
import app from "../main";
import angular from "angular";
import A3ApiResponse from "./a3ApiResponse";
import { BrokerProfile } from '../states/agentProfile/agentProfileController';

export class EProducerAccountService {
    public static $inject = [
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.EProducerService
    ];

    constructor(
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly eProducerService: EProducerService
    ) {}

    public getEProducerAccountFromUrlParam = () => {
        var urlParam = this.eProducerService.getEProducerAccountIdFromUrl();

        if (angular.isDefined(urlParam) === true) {
            return this.getEProducerAccountById(urlParam);
        }

        return this.$q.when();
    }

    public getEProducerAccountById = (eProducerAccountId) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetEProducerBroker?eProducerAccountId=${eProducerAccountId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => {
                return response.data.value;
            });
    }

    public searchEProducerBrokers = (search) => {
        if (!search){
            return this.$q.when([]);
        }

        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SearchBrokers?search=${search}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => {
                return response.data.value;
            });
    }

    public getEProducerAccount = () => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetBrokerInformation`;

        return this.$http.get<A3ApiResponse<BrokerProfile>>(url)
            .then(response => {
                    return response.data.value;
            });
    }

    public updateEProducerAccount = (account: BrokerProfile) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/UpdateBrokerInformation`;

        return this.$http.post(url, account)
            .then(() => {});
    }
}

app.service(Injectables.EProducerAccountService, EProducerAccountService);
