import { IHttpService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import app from "../main";

export class DataService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService) {}

    public get = (path: string, data: any, callBack: (r: any) => void) => {
        this.$http.delete(this.systemSettings.apiBaseUrl + path, data)
            .then((response) => {
                callBack(response);
            })
            .catch((response) => {
                console.log('error', response);
            });
    }

    public post = (path: string, data: any, callBack: (r: any) => void) => {
        this.$http.post(this.systemSettings.apiBaseUrl + path, data)
            .then((response) => {
                callBack(response);
            })
            .catch((response) => {
                console.log('error', response);
        });
    }

    public delete = (path: string, data: any, callBack: (r: any) => void) => {
        this.$http.delete(this.systemSettings.apiBaseUrl + path, data)
            .then((response) => {
                callBack(response);
            })
            .catch((response) => {
                console.log('error', response);
            });
    }
}

app.service(Injectables.DataService, DataService);
