import { IDirectiveFactory, IFilterService, IScope } from "angular"
import { Injectables } from "../../configuration/injectables"
import app from "../../main";

const isIntegerDirective: IDirectiveFactory = ($filter: IFilterService) => {
    const FLOAT_REGEXP_1 = /^\$?\d+.(\d{3})*(\,\d*)$/; //Numbers like: 1.123,56
    const FLOAT_REGEXP_2 = /^\$?\d+,(\d{3})*(\.\d*)$/; //Numbers like: 1,123.56
    const FLOAT_REGEXP_3 = /^\$?\d+(\.\d*)?$/; //Numbers like: 1123.56
    const FLOAT_REGEXP_4 = /^\$?\d+(\,\d*)?$/; //Numbers like: 1123,56

    const link = (scope: IScope, elm, attrs, ctrl) => {
        
        if ('isString' in attrs){
            return;
        }

        // remove all , % _ $ and spaces
        const clean = (value) => {
            return value.toString()
                .replace(/[,%\$ ]/g, '')
                .replace(/_/g, '0');
        };

        ctrl.$parsers.unshift((viewValue) => {
            viewValue = clean(viewValue);

            if (FLOAT_REGEXP_1.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace('.', '').replace(',', '.'));
            } else if (FLOAT_REGEXP_2.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace(',', ''));
            } else if (FLOAT_REGEXP_3.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue);
            } else if (FLOAT_REGEXP_4.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace(',', '.'));
            } else if (!viewValue) {
                ctrl.$setValidity('float', true);
                return undefined;
            } else {
                ctrl.$setValidity('float', false);
                return undefined;
            }
        });

        ctrl.$formatters.unshift((modelValue) => {
            return $filter('number')(parseInt(modelValue), 0);
        });
    };

    return {
        require: 'ngModel',
        link: link
    };
};

isIntegerDirective.$inject = [
    Injectables.$filter
];

app.directive('isInteger', isIntegerDirective);