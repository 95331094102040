import { AuthenticationService } from "../../api/authenticationService";
import { Injectables } from "../../configuration/injectables";
import { UiError } from '../../components/fullPageError/uiError';
import { SuccessMessage } from '../../components/inlineAlert/inlineAlert';
import { State } from "../state";

export class ResetPasswordController {
    public static $inject = [
        Injectables.AuthenticationService,
        Injectables.$stateParams,
        Injectables.$state
    ]

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly $stateParams: {eProducerPasswordResetKey: string},
        private readonly $state: State
    ) {}

    public confirmPassword: string;
    public error: UiError;
    public password: string;
    public successMessage: SuccessMessage;

    public resetPasswordClick(invalid: boolean) {
        if (invalid) {
            return;
        }

        return this.authenticationService.changePassword(this.$stateParams.eProducerPasswordResetKey, this.password)
            .then(() => {
                this.error = null;
                this.successMessage = {
                    heading: 'Password Updated',
                    message: 'Your password was updated successfully. Use the button below to continue'
                }
            })
            .catch((err) => {
                this.error = {
                    errorDisplayType: 'inline',
                    heading: 'Something\'s not quite right',
                    message: ' Be sure that you use the link in the email you received when requesting your password to be reset. If you\'re still having problems, try submitting a new password reset request.'
                };
            });
    }

    public goToLanding() {
        this.$state.go('main.userDefaultLanding');
    }

    public $onInit() {
        if (this.authenticationService.isLoggedIn()) {
            this.$state.go('main.userDefaultLanding');
        }
    }
}