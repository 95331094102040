import { Injectables } from "../configuration/injectables";
import app from "../main";

export const filter = () => {
    const units = [
        'bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB'
    ];

    return function (bytes: number, precision: number) {
        if (isNaN(bytes) || !isFinite(bytes)) {
            return '?';
        }

        let unit = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }

        return `${bytes.toFixed(precision)} ${units[unit]}`;
    };
};

app.filter(Injectables.FileSize, filter);

export interface IFileSizeFilter {
    (bytes: number, precision: number): string;
}