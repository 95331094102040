import { IFormController } from 'angular';
import app from "../../../main";
import addressesMatched from '../addressesMatched';
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";
import copyPhysicalAddress from '../copyPhysicalAddress';
import ApplicationStepManager from './applicationStepManager';

export class ObligeeFormController {
    public static $inject = [];

    constructor() {}

    public applicationSettings: ApplicationSettings;
    public application: Application;
    public applicationStepManager: ApplicationStepManager;
    public addressesMatched: boolean = true;
    public onChangesSubmitted: () => void;

    private checkAddressesMatched() {
        if (this.application?.obligee?.physicalAddress || this.application?.obligee?.mailAddress) {
            this.addressesMatched = addressesMatched(this.application.obligee);
        }
    }

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.applicationStepManager.moveNextStep();

        if (form.$dirty) {
            this.onChangesSubmitted();
        }
    }

    public physicalAddressChanged() {
        if (this.addressesMatched) {
            copyPhysicalAddress(this.application.obligee);
        }
    }

    public addressSameClicked() {
        if (this.addressesMatched) {
            copyPhysicalAddress(this.application.obligee);
        }
    }

    public $onInit() {
        this.checkAddressesMatched();
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        onChangesSubmitted: '&'
    },
    controller: ObligeeFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/obligeeForm.html'
}

app.component('obligeeForm', component);