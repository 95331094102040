import app from "../../main";
import { EProducerAccountService } from "../../api/eProducerAccountService";
import { Injectables } from "../../configuration/injectables";
import { IFormController } from 'angular';
import { ApplicationBroker } from '../../states/application/application';

export class ReferringEproducerBrokerSelectionController {

    public static $inject = [
        Injectables.EProducerAccountService
    ];

    constructor(
        private readonly eProducerAccountService: EProducerAccountService
    ) {
    }

    public broker: ApplicationBroker;
    public searchPhrase: string;
    public isSearching: boolean;
    public matchingEProducerBrokers: ApplicationBroker[];
    public form: IFormController;

    public isBrokerReferralRadioValue: string;
    public get isBrokerReferral(): boolean {
        return this.isBrokerReferralRadioValue === 'true';
    };
    public set isBrokerReferral(value: boolean) {
        if (value === true) {
            this.isBrokerReferralRadioValue = 'true';
        } else if (value === false) {
            this.isBrokerReferralRadioValue = 'false';
        } else {
            this.isBrokerReferralRadioValue = undefined;
        }
    }    

    public radioValueChanged() {
        if (!this.isBrokerReferral) {
            this.clearSelection();
        }
    }

    public setSelection(broker) {
        this.broker = broker;
    }

    public clearSelection() {
        this.broker = null;
        this.searchPhrase = null;
        this.matchingEProducerBrokers = null;
    }

    public searchEProducerBrokers() {
        if (!this.searchPhrase || this.searchPhrase.length < 3) {
            return;
        }

        this.isSearching = true;

        this.eProducerAccountService.searchEProducerBrokers(this.searchPhrase)
            .then((matches) => {
                this.isSearching = false;
                this.matchingEProducerBrokers = matches;
            });
    }

    public $onInit(): void {
        if (this.broker) {
            this.isBrokerReferral = true;
        }
    }
}

app.component('referringEproducerBrokerSelection', {
    bindings: {
        broker: '=',
        isBrokerReferral: '=',
        isRequired: '<?'
    },
    require: {
        form: '^form'
    },
    templateUrl: 'app/components/referringEProducerBrokerSelection/referringEProducerBrokerSelection.html',
    controller: ReferringEproducerBrokerSelectionController,
    controllerAs: 'vm'
});
