import { BaseField } from './baseField';
import { CheckFieldController } from './checkField';
import { CurrencyFieldController } from './currencyField';
import { RadioFieldController } from './radioField';
import { TextareaFieldController } from './textAreaField';
import { TextFieldController } from './textField';
import { DateFieldController } from './dateField';
import { PhoneFieldController } from './phoneField';
import { ZipFieldController } from './zipField';
import { SsnFieldController } from './ssnField';
import { RoutingNumberFieldController } from "./routingNumberField";
import { SelectFieldController } from "./selectField";
import { IntegerFieldController } from "./integerField";

export abstract class FieldChildBaseController {

    public textField: TextFieldController;
    public currencyField: CurrencyFieldController;
    public radioField: RadioFieldController;
    public checkboxField: CheckFieldController;
    public selectField: SelectFieldController;
    public textareaField: TextareaFieldController;
    public dateField: DateFieldController;
    public phoneField: PhoneFieldController;
    public zipField: ZipFieldController;
    public ssnField: SsnFieldController;
    public routingNumberField: RoutingNumberFieldController;
    public integerField: IntegerFieldController;

    public get parent(): BaseField {
        return this.textField ||
            this.currencyField ||
            this.radioField ||
            this.checkboxField ||
            this.selectField ||
            this.textareaField ||
            this.dateField ||
            this.phoneField ||
            this.zipField ||
            this.ssnField ||
            this.routingNumberField ||
            this.integerField;
    }

    public static require = {
        textField: '?^textField',
        currencyField: '?^currencyField',
        radioField: '?^radioField',
        checkboxField: '?^checkField',
        selectField: '?^selectField',
        textareaField: '?^textareaField',
        dateField: '?^dateField',
        phoneField: '?^phoneField',
        zipField: '?^zipField',
        ssnField: '?^ssnField',
        routingNumberField: '?^routingNumberField',
        customSelectField: '?^customSelectField',
        integerField: '?^integerField'
    };
}
