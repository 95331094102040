import { IHttpService, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import app from "../main";
import A3ApiResponse from "./a3ApiResponse";

export class IndemnityService {
    public static $inject = [
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ){}

    public getByUniqueId = (uid) => {
        const url = `${this.systemSettings.apiBaseUrl}IndemnitySignatureActions/GetIndemnitySignatureByUniqueId?uniqueId=${uid}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then((response) => {
                return response.data.value;
            });
    }

    public signIndemnity = (signatureId, signature) => {
        var request = { id: signatureId, signature: signature };
        const url = `${this.systemSettings.apiBaseUrl}IndemnitySignatureActions/SignIndemnity`;

        return this.$http.post<A3ApiResponse<any>>(url, request)
            .then((response) => {
                return response.data.value;
            });
    }

    public signGroupIndemnities = (indemnitySignatures) => {
        var request = [];

        for (var  i = 0; i < indemnitySignatures.length; i++) {
            request.push({ id: indemnitySignatures[i].id, signature: indemnitySignatures[i].signature });
        }

        const url = `${this.systemSettings.apiBaseUrl}QuoteActions/SignGroupIndemnities`;

        return this.$http.post<A3ApiResponse<any>>(url, request)
            .then((response) => {
                return response.data.value;
            });
    }

    public getIndemnityUniqueIdByQuoteId = (quoteId) => {
        const url = `${this.systemSettings.apiBaseUrl}IndemnitySignatureActions/GetUniqueIdByQuoteId?quoteId=${quoteId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => {
                return response.data.value;
            });
    }

    public sendSignatureRequests = (sendSignatureRequestDtos) => {
        const url = `${this.systemSettings.apiBaseUrl}IndemnitySignatureActions/SendSignatureRequests`;

        return this.$http.post(url, sendSignatureRequestDtos)
            .then(() => {});
    }

    public loadIndemnitySignatures = (quoteId) => {
        const url = `${this.systemSettings.apiBaseUrl}IndemnitySignatureActions/GetIndemnitySignaturesByQuoteId?quoteId=${quoteId}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => {
                return response.data.value;
            });
    }
}

app.service(Injectables.IndemnityService, IndemnityService);
