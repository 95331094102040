import app from "../../main";
import isUndefinedOrNull from "../../utilities/isUndefinedOrNull";

export class ModalController {
    public static $inject = [];

    public isOpen: boolean;
    public onClose: () => void;

    public handleClose($event) {
        $event.preventDefault();

        this.isOpen = false;
        
        if (this.onClose) {
            this.onClose();
        }
    }

    public $onInit() {
        if (isUndefinedOrNull(this.isOpen)) {
            this.isOpen = false;
        }
    }
}

const component = {
    bindings: {
        isOpen: '=',
        onClose: '&?',
        hideCloseButton: '<'
    },
    controller: ModalController,
    controllerAs: 'vm',
    templateUrl: 'app/components/modal/modal.html',
    transclude: {
        modalHeader: '?modalHeader',
        modalContent: 'modalContent'
    }
}

app.component('modal', component);