import { IFormController } from 'angular';
import app from "../../../main";
import addressesMatched from '../addressesMatched';
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";
import copyPhysicalAddress from '../copyPhysicalAddress';
import ApplicationStepManager from './applicationStepManager';

export class PersonFormController {
    public static $inject = [];

    constructor() {}

    public applicationSettings: ApplicationSettings;
    public application: Application;
    public applicationStepManager: ApplicationStepManager;
    public addressesMatched: boolean[] = [true];
    public onChangesSubmitted: () => void;

    private checkAddressesMatched() {
        if (!this.application?.people) {
            return;
        }

        for (let i = 0; i < this.application.people.length; i++) {
            const person = this.application.people[i];

            if (person.physicalAddress || person.mailAddress) {
                this.addressesMatched.push(addressesMatched(person));
            } else {
                this.addressesMatched.push(true);
            }
        }
    }

    public physicalAddressChanged(index: number) {
        if (this.addressesMatched[index]) {
            copyPhysicalAddress(this.application.people[index]);
        }
    }

    public addressSameClicked(index: number) {
        if (this.addressesMatched[index]) {
            copyPhysicalAddress(this.application.people[index]);
        }
    }

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.applicationStepManager.moveNextStep();

        if(form.$dirty) {
            this.onChangesSubmitted();
        }
    }

    public $onInit() {
        this.checkAddressesMatched();
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        onChangesSubmitted: '&'
    },
    controller: PersonFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/personForm.html'
}

app.component('personForm', component)