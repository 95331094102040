import app from "../../main";
import { FieldChildBaseController } from "./fieldChildBaseController";

export class FieldValidationMessageController extends FieldChildBaseController {

    public get isVisible() {
        return this.parent.formField.$invalid &&
            (
                this.parent.formField.$touched ||
                this.parent.form.$submitted
            );
    }

    public get errorMessage() {

        if (!this.parent.formField.$invalid) {
            return '';
        }

        if (this.parent.formField.$error.required) {
            return this.parent.requiredMessage;
        } else if (this.parent.formField.$error.email) {
            return this.parent.emailMessage;
        } else if (this.parent.formField.$error.pattern) {
            return this.parent.patternMessage;
        } else if (this.parent.formField.$error.minlength) {
            return this.parent.minLengthMessage;
        } else if (this.parent.formField.$error.maxlength) {
            return this.parent.maxLengthMessage;
        } else if (this.parent.formField.$error.min) {
            return this.parent.minMessage;
        } else if (this.parent.formField.$error.max) {
            return this.parent.maxMessage;
        } else if (this.parent.formField.$error.modelMatches) {
            return this.parent.matchesMessage;
        } else {
            return this.parent.invalidMessage;
        }
    }

    public $onInit() {
    }
}

const component = {
    bindings: {},
    controller: FieldValidationMessageController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/fieldValidationMessage.html',
    require: FieldChildBaseController.require
}

app.component('fieldValidationMessage', component);