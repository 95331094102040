import { Injectables } from "../configuration/injectables";
import app from "../main";

export const filter = () => {
    const escapeRegexp = (queryToEscape: string): string => {

        return (queryToEscape).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    return (matchItem: string, query: string) => {
        
        return query && matchItem ? (matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), '<span class="highlight">$&</span>') : matchItem;
    };
};

app.filter(Injectables.Highlight, filter);

export interface IHighlightFilter {
    (matchItem: string, query: string): string;
}