import { IHttpService } from "angular";
import app from "../main";
import { SystemSettings } from "../configuration/systemSettings";
import { Injectables } from "../configuration/injectables";
import A3ApiResponse from "./a3ApiResponse";
import { CacheStore } from '../utilities/cacheStore/cacheStore';

export class SystemAccountService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.CacheStore
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly cacheStore: CacheStore
    ){}

    public getSystemAccountById(systemAccountId: number, eProducerAccountId?: number) {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetSystemAccount?systemAccountId=${systemAccountId}&eProducerAccountId=${eProducerAccountId}`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get<A3ApiResponse<any>>(url)
                .then(response => response.data.value);
        });
    }

    public getBrandingLogo(systemAccountId: number, eProducerAccountId?: number, size: 'small' | 'large' = 'small') {
        const url = `${this.systemSettings.apiBaseUrl}Download/EProducerBrandingLogo?systemAccountId=${systemAccountId}&eProducerAccountId=${eProducerAccountId}&size=${size == 'small' ? 'sm' : 'lg'}`;

        return this.cacheStore.getData(url, () => {
            return this.$http.get(url)
                .then(response => response.data);
        });
    }
}

app.service(Injectables.SystemAccountService, SystemAccountService);