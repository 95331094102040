import { IPromise, IScope } from "angular";
import app from "../../main";
import { BusyIndicatorTracker, BusyIndicatorTrackerFactory } from "./busyIndicatorTrackerFactory";
import { Injectables } from "../../configuration/injectables";

export class BusyIndicatorController {
    public static $inject = [
        Injectables.BusyIndicatorTrackerFactory,
        Injectables.$scope,
        Injectables.$timeout
    ];

    constructor(
        private readonly busyIndicatorTrackerFactory: BusyIndicatorTrackerFactory,
        private readonly $scope: IScope,
        private readonly $timeout: ng.ITimeoutService
    ) {}

    public promise: IPromise<any>;
    public tracker: BusyIndicatorTracker;
    public isVisible: boolean;
    public hideInitially: boolean;

    private promiseChanged = () => {
        this.$timeout(() => {
            this.isVisible = this.tracker.active();
        });
    }

    public $onInit() {
        this.isVisible = !this.hideInitially;

        this.tracker = this.busyIndicatorTrackerFactory.create();

        this.tracker.onPromiseChanged = this.promiseChanged;
        
        // initialize the tracker with the promise
        if (this.promise) {
            this.tracker.reset(this.promise);
        }

        // watch for changes to promise and reset the tracker
        this.$scope.$watch(() => this.promise, (newValue) => {
            if (this.tracker.promise != newValue) {
                this.tracker.reset(newValue);
            }
        });
    }
}

const component = {
    bindings: {
        promise: '<?',
        message: '@',
        hideMessage: '<?',
        hideBackdrop: '<?',
        hideInitially: '<?',
    },
    templateUrl: 'app/components/busyIndicator/busyIndicator.html',
    controller: BusyIndicatorController,
    controllerAs: 'vm',
};

app.component('busyIndicator', component);