import { IAttributes, IController, IDirectiveFactory, IScope } from "angular"
import app from "../../main";
import formatDate from '../../utilities/formatDate';
import toDate from '../../utilities/toDate';
import { DateFieldController } from '../fields/dateField';

const isDateDirective: IDirectiveFactory = () => {

    const link = (
        scope: IScope,
        elm: JQuery<HTMLInputElement>,
        attrs: IAttributes,
        controllers: IController
    ) => {

        const [ctrl, dateFieldCtrl] = controllers as [IController, DateFieldController];

        attrs.$observe('min', () => {
            ctrl.$validate();
        });

        attrs.$observe('max', () => {
            ctrl.$validate();
        });

        const getCursorPosition = (numbersOnly: string, formattedValue: string): number => {
            let numericIndex = 0;
            let formattedIndex = 0;

            while (numericIndex < numbersOnly.length && formattedIndex < formattedValue.length) {
                if (/\d/.test(formattedValue[formattedIndex])) {
                    if (formattedValue[formattedIndex] === numbersOnly[numericIndex]) {
                        numericIndex++;
                    }
                }

                formattedIndex++;
            }

            if (dateFieldCtrl.lastKeyPressed === '/') {
                formattedIndex++;
            }

            return formattedIndex;
        };

        const isValidDate = (value) => {
            const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
            if (!datePattern.test(value)) {
                return false;
            }

            const [month, day, year] = value.split('/').map(Number);
            const date = new Date(year, month - 1, day);

            return date.getFullYear() === year &&
                date.getMonth() === month - 1 &&
                date.getDate() === day;
        }

        ctrl.$parsers.unshift((viewValue) => {
            if (!viewValue) {
                return null;
            }

            if (dateFieldCtrl.lastKeyPressed === 'Backspace' || dateFieldCtrl.lastKeyPressed === 'Delete') {
                return null;
            }

            let formattedValue = viewValue;

            if (dateFieldCtrl.lastKeyPressed === '/') {
                if (formattedValue.length === 2) {
                    formattedValue = `0${formattedValue}`;
                } else if (formattedValue.length === 5) {
                    formattedValue = formattedValue.slice(0, 3) + '0' + formattedValue.slice(3);
                }
            } else {
                if (formattedValue.length === 2 || formattedValue.length === 5) {
                    formattedValue += '/';
                    dateFieldCtrl.lastKeyPressed = '/';
                }
            }

            if (formattedValue !== viewValue) {
                const cursorPosition = elm[0].selectionStart;
                const precedingNumericChars = viewValue.slice(0, cursorPosition).replace(/\D/g, '');

                elm.val(formattedValue);

                if (elm.is(':focus')) {
                    elm[0].setSelectionRange(getCursorPosition(precedingNumericChars, formattedValue), getCursorPosition(precedingNumericChars, formattedValue));
                }
            }

            return formattedValue;
        });

        ctrl.$formatters.unshift((modelValue) => {
            if (!modelValue) {
                return modelValue;
            }

            // If modelValue is already in correct format, return as is
            if (isValidDate(modelValue)) {
                return modelValue;
            }

            const formattedValue = formatDate(modelValue);

            // Check if modelValue is in "YYYY-MM-DD" format
            if (/^\d{4}-\d{2}-\d{2}$/.test(modelValue)) {
                // Update the view value without calling chnage listeners
                dateFieldCtrl.isProgrammaticChange = true;
                ctrl.$setViewValue(formattedValue);
                ctrl.$render();
                dateFieldCtrl.form.$setPristine();
                dateFieldCtrl.isProgrammaticChange = false;
            }

            return formattedValue;
        });

        ctrl.$validators.invalid = (modelValue) => {
            if (!modelValue) {
                return true;
            }

            return isValidDate(modelValue);
        };

        ctrl.$validators.min = (modelValue) => {
            if (!modelValue) {
                return true;
            }

            if (attrs.min) {
                const dateValue = toDate(modelValue);
                const minDate = toDate(attrs.min);

                if (dateValue && minDate) {
                    return dateValue >= minDate;
                }
            }

            return true;
        };

        ctrl.$validators.max = (modelValue) => {
            if (!modelValue) {
                return true;
            }

            if (attrs.max) {
                const dateValue = toDate(modelValue);
                const maxDate = toDate(attrs.max);

                if (dateValue && maxDate) {
                    return dateValue <= maxDate;
                }
            }

            return true;
        };
    };

    return {
        require: ['ngModel', '^dateField'],
        link: link
    };
};

app.directive('isDate', isDateDirective);