import app from "../../main";

export class LoginModalController {
    public static $inject = [];

    constructor() {}

    public onLogin: () => {}

    public $onInit() {
    }
}

const component = {
    bindings: {
        isOpen: '=',
        onLogin: '&'
    },
    controller: LoginModalController,
    controllerAs: 'vm',
    templateUrl: 'app/components/loginModal/loginModal.html'
}

app.component('loginModal', component);