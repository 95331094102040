import { QuoteForEProducerPurchase } from "../quotes/quoteForEProducerPurchase";
import { PhysicalAndMailAddress } from "./addressesMatched";

export type Application = {
    id: number;
    applicationType: ApplicationType;
    leadId?: number;
    applicationNumber?: number;
    bondState: string;
    bondTypeName: string;
    status: ApplicationStatus;
    customerId: number;
    nameOnBond: string;
    bondTypeId: number;
    bondAmount: number | string;
    desiredEffectiveDate: string;
    bondId: number;
    questionResponses: ApplicationQuestionResponse[];
    company?: Company;
    people: Person[];
    principalType: PrincipalType;
    obligeeId?: number;
    obligee?: Obligee;
    tags?: Tag[];
    broker?: ApplicationBroker;
    isBrokerReferral?: boolean;
}

export type ApplicationBroker = {
    id: number;
    name: string;
    lookupCode: string;
    physicalAddress: string;
    physicalSuiteAptNumber: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
}

export type Lead = {
    id: number;
    bondState: string;
    bondTypeName: string;
    status: ApplicationStatus;
    customerId: number;
    nameOnBond: string;
    bondTypeId: number;
    bondAmount: number | string;
    desiredEffectiveDate: string;
    bondId: number;
    questionResponses: ApplicationQuestionResponse[];
    company?: Company;
    people: Person[];
    principalType: PrincipalType;
    obligeeId?: number;
    obligee?: Obligee;
    tags?: Tag[];
    broker?: ApplicationBroker;
    isBrokerReferral?: boolean;
}

export type ApplicationInformation = {
    id: number;
    status: ApplicationStatus;
    bondTypeId: number;
    bondTypeName: string;
    applicationNumber: number;
    bondAmount: number;
    bondState: string;
    isPolicy: boolean;
    approvedQuotes: QuoteForEProducerPurchase[];
}

export enum PrincipalType {
    Individual = "Individual",
    Company = "Company"
}

export enum ApplicationType {
    BondAccount = "BondAccount",
    SingleBond = "SingleBond",
    SingleBondRenewal = "SingleBondRenewal"
}

export enum ApplicationStatus {
    NotSubmitted = "NotSubmitted",
    Submitted = "Submitted",
    Closed = "Closed",
    Completed = "Completed"
}

export type Company = PhysicalAndMailAddress & {
    id?: number;
    companyType?: CompanyType;
    dateFormed?: string;
    dba?: string;
    email?: string;
    fax?: string;
    fein?: string;
    name?: string;
    numberOfEmployees?: number;
    numberOfOwners?: number;
    phone?: string;
    stateOfIncorporation?: string;
    nmlsNumber?: string;
    websiteUrl?: string;
    fiscalYearEnd?: string;
}

export enum CompanyType {
    Proprietorship = "Proprietorship",
    SCorp = "SCorp",
    CCorp = "CCorp",
    LLC = "LLC",
    Partnership = "Partnership"
}

export type Person = PhysicalAndMailAddress & {
    id?: number
    firstName?: string,
    middleName?: string,
    lastName?: string,
    prefix?: string,
    suffix?: string,
    email?: string,
    yearsExperience?: number,
    cellPhone?: string,
    homePhone?: string,
    fax?: string,
    dateOfBirth?: string,
    ssn?: string,
    employerName?: string,
    employerAddress?: string,
    employerSuiteAptNumber?: string,
    employerState?: string,
    employerCity?: string,
    employerZip?: string,
    employerCounty?: string,
    employerPhone?: string,
    driversLicenseState?: string,
    driversLicenseNumber?: string,
    residenceType?: string,
    dateMovedToResidence?: string,
    maritalStatus?: string,
    spouseFirstName?: string,
    spouseMiddleName?: string,
    spouseLastName?: string,
    spouseSsn?: string,
    spouseEmail?: string,
    spouseDob?: string,
    spouseCellPhone?: string,
    noMiddleName?: boolean,
    ownershipPercent?: number,
    isUSCitizen?: boolean,
}

export type ApplicationQuestionResponse = {
    id?: number;
    responseText?: string;
    questionText: string;
    masterApplicationQuestionId: number;
    originType: AdditionalQuestionResponseType;
}

export enum MaritalStatus {
    Single = "Single",
    Married = "Married",
    Separated = "Separated",
    Divorced = "Divorced",
    Widowed = "Widowed",
    DomPartn = "DomPartn",
    Fiancee = "Fiancee",
    Other = "Other",
    Unknown = "Unknown",
    CivUnion = "CivUnion"
}

export enum ResidenceType {
    Own = "Own",
    Rent = "Rent"
}

export type Obligee = PhysicalAndMailAddress & {
    id?: number;
    comments?: string;
    contact1?: string;
    contact2?: string;
    contact3?: string;
    email?: string;
    fax?: string;
    isActive?: boolean;
    isGeneric?: boolean;
    masterObligeeId?: number;
    name?: string;
    phone?: string;
    requisitioningDepartment?: string;
    requisitioningState?: string;
    websiteUrl?: string;
}

export type AdditionalApplicationQuestion = {
    id: number;
    type: QuestionType;
    question: string;
    requiredText: string;
    parentQuestionId?: number | null;
    showWhenParentValueEquals?: string | null;
    isRequired: boolean;
    isSubQuestion: boolean;
    name: string;
    subQuestions: AdditionalApplicationQuestion[];
    masterApplicationQuestionChoices: MasterApplicationQuestionChoice[];
    originType: AdditionalQuestionResponseType
}

export enum QuestionType {
    TextBox = "TextBox",
    TextArea = "TextArea",
    DropDown = "DropDown",
    Radio = "Radio"
}

export enum AdditionalQuestionResponseType {
    Underwriting = "Underwriting",
    BondType = "BondType"
}

export type MasterApplicationQuestionChoice = {
    id: number;
    text: string;
    sequence?: number;
    masterApplicationQuestionId: number;
}

export type Tag = {
    id: number;
    name: string;
    applicationId?: number;
    leadId?: number;
}