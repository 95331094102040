import { IHttpService, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import { LocalStorageService } from "../utilities/localStorageService";
import app from "../main";
import A3ApiResponse from "./a3ApiResponse";
import { EProducerRootScope } from "../eProducerRootScope";
import { State } from "../states/state";

export class AuthenticationService {
    public static $inject = [
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.LocalStorageService,
        Injectables.$rootScope,
        Injectables.$state
    ];

    constructor(
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly localStorageService: LocalStorageService,
        private readonly $rootScope: EProducerRootScope,
        private readonly $state: State
    ) {}

    public login = (email: string, password: string) => {
        const url = `${this.systemSettings.apiBaseUrl}LoginActions/Login`;

        const request = {
            email: email,
            password: password,
            origin: 'EProducer',
            systemAccountId: this.$rootScope.systemAccount.id
        };

        return this.$http.post<A3ApiResponse<any>>(url, request)
            .then(response => {
                let loginResponse = response.data.value;
                this.localStorageService.setAuthenticationData(loginResponse);
                this.isLoggedIn();
                this.$rootScope.$broadcast('LoginStatusChanged');
            });
    }

    public logout = (returnToCurrentState?: boolean) => {
        this.localStorageService.removeAuthenticationData();
        this.$rootScope.currentUser = null;

        if (returnToCurrentState) {
            this.$state.go('main.login', {returnState: this.$state.current.name});
        } else {
            this.$state.go('main.login');
        }

        this.$rootScope.$broadcast('LoginStatusChanged');
    }

    public requestPasswordReset = (email) => {
        const url = `${this.systemSettings.apiBaseUrl}eProducerActions/RequestPasswordReset`;
        
        var request = {
            email: email,
            systemAccountId: this.$rootScope.systemAccount.id
        };

        return this.$http.post<A3ApiResponse<any>>(url, request)
            .catch(err => {
                return this.$q.reject(err.data.Message);
            });
    };

    public changePassword = (key, password) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/ChangePassword`;

        var req = {
            passwordResetKey: key,
            password: password
        }

        return this.$http.post<A3ApiResponse<any>>(url, req)
            .then(response => {
                return this.login(response.data.value.email, password);
            });
    }

    public isLoggedIn = () => {
        var userToken = this.localStorageService.getAuthenticationData();
        var loggedIn = userToken != undefined;
        this.$rootScope.currentUser = loggedIn ? userToken : null;
        return loggedIn;
    };

    public isAuthorizedForState = (stateName: string) => {
        switch (stateName) {
            case 'main.userDefaultLanding':
                return this.isLoggedIn();

            case 'main.openApplications':
                return this.isLoggedIn() && (this.getUserToken().eProducerAccount.eProducerAccountType !== 'Broker' || this.getUserToken().eProducerAccount.isApproved) && !this.getUserToken().eProducerAccount.isLocked;

            case 'main.purchasedBonds':
                return this.isLoggedIn() && (this.getUserToken().eProducerAccount.eProducerAccountType !== 'Broker' || this.getUserToken().eProducerAccount.isApproved);

            case 'main.documents':
                return this.isLoggedIn();

            case 'main.agencyProfile':
                return (this.isLoggedIn() && this.getUserToken().eProducerAccount.eProducerAccountType === 'Broker');

            case 'main.application':
                // true if any of the following conditions
                // not logged in
                // not a broker
                // isApproved && isNotLocked
                return !this.isLoggedIn() || (
                            this.isLoggedIn() &&
                            (
                                this.getUserToken().eProducerAccount.eProducerAccountType !== 'Broker' ||
                                this.getUserToken().eProducerAccount.isApproved
                            ) &&
                            !this.getUserToken().eProducerAccount.isLocked
                        );

            case 'main.usersDetail':
            case 'main.usersGrid':
                return this.isLoggedIn() && this.getUserToken().eProducerAccount.isApproved && this.getUserToken().eProducerAccount.eProducerAccountType === 'Broker' && !this.getUserToken().eProducerAccount.isLocked;

            default:
                return true;
        }
    }

    public getUserToken = () => {
        this.isLoggedIn();
        return this.$rootScope.currentUser;
    }
}

app.service(Injectables.AuthenticationService, AuthenticationService);
