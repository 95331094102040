import { IFormController, IScope } from 'angular';
import app from "../../main";
import { Injectables } from '../../configuration/injectables';
import getPasswordPattern from '../../utilities/getPasswordPattern';

export class NewPasswordController {
    static $inject = [
        Injectables.$scope
    ];

    constructor($scope: IScope) {}

    model: string;
    confirmPassword: string;
    form: IFormController;
    passwordPattern = getPasswordPattern();
    onChange: () => void;

    public handleChange() {
        if (this.onChange) {
            this.onChange();
        }
    }

    public $onInit() {
    }
}

const component = {
    require: {
        form: '^form'
    },
    bindings: {
        model: '=',
        isRequired: '<?',
        onChange: '&?',
    },
    controller: NewPasswordController,
    controllerAs: 'vm',
    templateUrl: 'app/components/newPassword/newPassword.html'
};

app.component('newPassword', component);
