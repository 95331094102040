import { IRootScopeService } from "angular";

export interface EProducerRootScope extends IRootScopeService {
    systemAccount: SystemAccount;
    application: ApplicationSummary;
    eProducerAccountId?: number;
    currentUser?: CurrentUser;
    currentState?: string;
    branding?: Branding;
}

export type Branding = {
    systemAccountId?: number;
    eProducerAccountId?: number;
    companyName: string;
    phone: string;
    email: string;
}

export type SystemAccount = {
    id: number;
    companyName: string;
    phone: string;
    email: string;
    allowACHPaymentsThroughEProducer: boolean;
    allowACHPaymentsThroughPaymentForm: boolean;
    allowCCPaymentsThroughEProducer: boolean;
    allowCCPaymentsThroughPaymentForm: boolean;
    carrierCount: number;
    noBondTypeRedirectUrl: string;
    expeditedProcessingFee: number;
}

export type ApplicationSummary = {
    applicationNumber?: number;
    bondAmount?: number;
    bondType?: string;
    filteredQuoteCount?: number;
    totalQuoteCount?: number;
    mostExpensiveQuoteCost?: number;
    lowestQuoteCost?: number;
    heading?: string;
    subHeading?: string;
}

export type CurrentUser = {
    token: string,
    user: CurrentUserDetail,
    eProducerAccount: CurrentUserEProducerAccount,
    systemAccount: CurrentUserSystemAccount,
    hasOpenApplications: boolean,
    hasBonds: boolean
}

export type CurrentUserDetail = {
    systemAccountId: number,
    email: string,
    firstName: string,
    lastName: string,
    fullName: string,
    lastLoginDate: string,
    id: number
}

export type CurrentUserEProducerAccount = {
    id: number,
    companyName: string,
    eProducerAccountType: EProducerAccountType,
    allowDeferredPayment: boolean,
    isLocked: boolean,
    isApproved: boolean,
    systemAccountId: number
}

export enum EProducerAccountType {
    Broker = 'Broker',
    Applicant = 'Applicant'
}

export type CurrentUserSystemAccount = {
    companyName: string,
    phone: string
}