import { IFormController } from 'angular';
import app from "../../../main";
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";
import ApplicationStepManager from './applicationStepManager';

export class AdditionalQuestionsFormController {
    public static $inject = [];

    constructor() {}

    public applicationSettings: ApplicationSettings;
    public application: Application;
    public applicationStepManager: ApplicationStepManager;
    public questionResponses: any = {};
    public onChangesSubmitted: () => void;

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.application.questionResponses = Object.values(this.questionResponses);
        this.applicationStepManager.moveNextStep();

        if (form.$dirty) {
            this.onChangesSubmitted();
        }
    }

    public $onInit() {

        this.applicationSettings.additionalApplicationQuestions.forEach(question => {

            // Parent Questions
            const response = this.application.questionResponses
                .find(q => q.masterApplicationQuestionId === question.id);

            this.questionResponses[question.id] = {
                questionText: question.question,
                responseText: response?.responseText ?? null,
                masterApplicationQuestionId: question.id,
                originType: question.originType,
            };

            // Sub Questions
            question.subQuestions.forEach(subQuestion => {
                const subQuestionResponse = this.application.questionResponses
                    .find(q => q.masterApplicationQuestionId === subQuestion.id);

                this.questionResponses[subQuestion.id] = {
                    questionText: subQuestion.question,
                    responseText: subQuestionResponse?.responseText ?? null,
                    masterApplicationQuestionId: subQuestion.id,
                    originType: subQuestion.originType,
                };
            });
        });
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        onChangesSubmitted: '&'
    },
    controller: AdditionalQuestionsFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/additionalQuestionsForm.html'
}

app.component('additionalQuestionsForm', component);