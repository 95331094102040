import { IScope } from "angular";

export interface IPageSlideScope extends IScope {
    psSide;
    psSpeed;
    psSize;
    psZindex;
    psClass;
    psContainer;
    psKeyListener;
    psBodyClass;
    psOpen;
    onopen;
    onclose;
    psAutoClose;
}