import { ITimeoutService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BaseField } from './baseField';

export class RadioFieldController extends BaseField {

    public static $inject = [
        Injectables.$timeout
    ];

    constructor(private readonly $timeout: ITimeoutService) {
        super($timeout);
    }
    
    public $onInit() {
        this.modelOptions = { updateOn: 'default' };
    }
}

const component = {
    bindings: {
        ...BaseField.bindings
    },
    controller: RadioFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/radioField.html',
    transclude: true,
    require: {
        form: '^form'
    }
}

app.component('radioField', component);
