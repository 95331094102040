import { IFormController, INgModelController, INgModelOptions, ITimeoutService } from 'angular';
import isStringDateTime from '../../utilities/isStringDateTime';
import formatDate from '../../utilities/formatDate';
import { twMerge } from 'tailwind-merge';

export abstract class BaseField {

    constructor(private readonly $baseTimeout: ITimeoutService) {}

    public model: string;
    public label: string;
    public name: string;
    public isRequired: boolean;
    public isDisabled: boolean;
    public onChange: () => void;
    public wrapperClass: string;
    public form: IFormController;
    public requiredMessage: string = 'required';
    public invalidMessage: string = 'invalid';
    public emailMessage: string = 'invalid email';
    public patternMessage: string = 'invalid format';
    public matchesMessage: string = 'values do not match';
    public minLengthMessage: string = 'invalid';
    public maxLengthMessage: string = 'invalid';
    public placeholder: string;
    public pattern: string | RegExp;
    public maxLength: number;
    public minLength: number;
    public type: string = 'text';
    public step: string;
    public inputmode: string;
    public modelOptions: INgModelOptions = { updateOn: 'blur'};
    public selectClass: string = '';
    public labelClass: string = '';

    public mergeClasses(baseClass: string, additionalClass: string) {
        return twMerge(baseClass, additionalClass);
    }

    public get invalid(){
        return this.formField.$invalid && (this.formField.$touched || this.form.$submitted)
    }

    public get formField(): INgModelController {
        if (!this.form || !this.form[this.name]) {
            return null;
        }

        return this.form[this.name];
    }

    private _max: string = null;
    public get max(): string {
        return this._max;
    }

    public set max(value: string) {
        this._max = value;
        this.maxMessage = this.buildDefaultMaxMessage();
    }

    private _defaultMaxMessage: string;
    private _maxMessage: string;
    public get maxMessage(): string {
        return this._maxMessage;
    }

    public set maxMessage(value: string) {
        this._maxMessage = value || this._defaultMaxMessage;
    }

    private _min: string = null;
    public get min(): string {
        return this._min;
    }

    public set min(value: string) {
        this._min = value;
        this.minMessage = this.buildDefaultMinMessage();
    }

    private _defaultMinMessage: string;
    private _minMessage: string;
    public get minMessage(): string {
        return this._minMessage;
    }

    public set minMessage(value: string) {
        this._minMessage = value || this._defaultMinMessage;
    }

    private getMinStringValue() {

        if (isStringDateTime(this.min)) {
            return formatDate(this.min);
        }

        return this.min;
    }

    private getMaxStringValue() {

        if (isStringDateTime(this.max)) {
            return formatDate(this.max);
        }

        return this.max;
    }

    private buildDefaultMinMessage() {
        if (this.min && this.max) {
            return `must be between ${this.getMinStringValue()} and ${this.getMaxStringValue()}`;
        } else if (this.min) {
            return `must be greater than ${this.getMinStringValue()}`;
        } else {
            return 'out of range';
        }
    }

    public buildDefaultMaxMessage() {
        if (this.min && this.max) {
            return `must be between ${this.getMinStringValue()} and ${this.getMaxStringValue()}`;
        } else if (this.max) {
            return `must be less than ${this.getMaxStringValue()}`;
        } else {
            return 'out of range';
        }
    }

    public handleChange() {
        if (this.onChange) {
            this.$baseTimeout(() => {
                this.onChange();
            });
        }
    }

    public static bindings = {
        model: '=',
        name: '@',
        type: '@?',
        label: '@',
        isRequired: '<?',
        isDisabled: '<?',
        onChange: '&?',
        wrapperClass: '@?',
        maxLength: '@?',
        minLength: '@?',
        minLengthMessage: '@?',
        maxLengthMessage: '@?',
        requiredMessage: '@?',
        invalidMessage: '@?',
        placeholder: '@?',
        min: '<?',
        max: '<?',
        minMessage: '@?',
        maxMessage: '@?',
        pattern: '<?',
        patternMessage: '@?',
        emailMessage: '@?',
        matchesMessage: '@?',
        step: '@?',
        inputmode: '@?',
        modelOptions: '<?',
        labelClass: '@?',
        selectClass: '@?'
    }
}