import app from '../../main';
import * as jQuery from 'jquery';
import { IJQueryService } from './iJQueryService';
import { Injectables } from '../../configuration/injectables';

export class JQueryProvider {

    public static $inject = [];

    constructor() {}

    private provider: IJQueryService = {
        getElement: (expression: string | Node | HTMLElement, ownerDocument_attributes?: Document | JQuery.PlainObject): JQuery<HTMLElement> => {

            if (expression instanceof HTMLElement) {
                return jQuery(expression) as JQuery<HTMLElement>;
            }

            if (expression instanceof Node) {
                return jQuery(expression) as JQuery<HTMLElement>;
            }

            return jQuery(expression, ownerDocument_attributes);
        }
    };

    public $get = (): IJQueryService => {
        return this.provider;
    }
}

app.provider(Injectables.JQuery, JQueryProvider);
