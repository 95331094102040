import { Injectables } from "../../configuration/injectables";
import app from "../../main"
import { State } from "../../states/state";
import { AuthenticationService } from "../../api/authenticationService";
import { EProducerRootScope } from "../../eProducerRootScope";

export class NavigationController {
    public static $inject = [
        Injectables.$state,
        Injectables.AuthenticationService,
        Injectables.$rootScope
    ]

    constructor(
        private readonly $state: State,
        private readonly authenticationService: AuthenticationService,
        private readonly $rootScope: EProducerRootScope
    ) {}

    public isAuthorized_agencyProfile: boolean;
    public isAuthorized_openApplications: boolean;
    public isAuthorized_portal: boolean;
    public isAuthorized_purchasedBonds: boolean;
    public isAuthorized_usersGrid: boolean;
    public mobileNavOpen: boolean;
    public showNav: boolean;
    public showPendingSpan: boolean;

    public get state() {
        return this.$state.current.name;
    };

    public get currentUser() {
        return this.$rootScope.currentUser;
    }

    public logout() {
        this.authenticationService.logout();
    };

    public setupForUser = () => {
        this.showNav = this.authenticationService.isLoggedIn() && this.state !== 'resetPassword';
        this.showPendingSpan = this.currentUser && !this.currentUser.eProducerAccount.isApproved;

        this.isAuthorized_openApplications = this.authenticationService.isAuthorizedForState('main.openApplications');
        this.isAuthorized_purchasedBonds = this.authenticationService.isAuthorizedForState('main.purchasedBonds');
        this.isAuthorized_portal = this.authenticationService.isAuthorizedForState('main.application');
        this.isAuthorized_agencyProfile = this.authenticationService.isAuthorizedForState('main.agencyProfile');
        this.isAuthorized_usersGrid = this.authenticationService.isAuthorizedForState('main.usersGrid');
    }

    public $onInit() {
        this.setupForUser();

        this.$rootScope.$on('LoginStatusChanged', this.setupForUser);
    }
}

const component = {
    bindings: {},
    controller: NavigationController,
    controllerAs: 'vm',
    templateUrl: 'app/components/navigation/navigation.html',
}

app.component('navigation', component);