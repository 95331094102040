import { IPromise, ITimeoutService } from "angular";
import { Injectables } from "../../configuration/injectables";
import { AttachmentService } from "../../api/attachmentService";
import { UiError } from '../../components/fullPageError/uiError';
import { Table } from '../../utilities/tables/table';
import { SuccessMessage } from '../../components/inlineAlert/inlineAlert';

export class DocumentsController {
    public static $inject = [
        Injectables.AttachmentService,
        Injectables.$timeout
    ];

    constructor(
        private readonly attachmentService: AttachmentService,
        $timeout: ITimeoutService
    ) {
        this.table = new Table($timeout);
        this.table.onChange = this.loadAttachments;
    }

    public error: UiError;
    public table: Table<DocumentListItem>;
    public successMessage: SuccessMessage;
    public loadingPromise: IPromise<any>;

    public uploadSuccess = () => {
        this.successMessage = {
            heading: 'File Uploaded',
            message: 'We received your document and we\'ll review it shortly.'
        };
        this.error = null;
        this.loadAttachments();
    }

    public uploadError = (error: UiError) => {
        this.successMessage = null;
        this.error = error;
    }

    private loadAttachments = () => {
        this.loadingPromise = this.attachmentService.getAllAttachments(this.table.queryOptions)
            .then(response => {
                this.table.setData(response.items, response.totalRecordCount);
            })
            .catch(err => {
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: err?.data?.Message ?? 'Unknown',
                    heading: 'Something went wrong',
                    message: 'An error occurred while loading your documents. Please contact us so that we can resolve this issue.',
                    technicalMessage: err?.data?.MessageDetail
                };
            });
    }

    public $onInit() {
        this.loadAttachments();
    }
}

export type DocumentListItem = {
    id: number;
    filename: string;
    fileSize: number;
    fileId: string;
    downloadUrl: string;
}