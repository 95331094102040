import app from "../../main";

export class TimeRemaining {
    public fieldCount: number;
    public completedFieldCount: number;
    private secondsPerField: number = 5;
    private defaultMinutesRemaining: number = 5;

    public get percent(): number {
        if (!this.fieldCount) {
            return 100;
        }

        return this.completedFieldCount / this.fieldCount * 100;
    }

    public get minutesRemaining(): number {
        const minutesRemaining = Math.max(Math.round(((this.fieldCount - this.completedFieldCount) * this.secondsPerField) / 60), 1);
        return isNaN(minutesRemaining) ? this.defaultMinutesRemaining : minutesRemaining;
    }
}

const component = {
    bindings: {
        fieldCount: '<',
        completedFieldCount: '<'
    },
    controllerAs: 'vm',
    controller: TimeRemaining,
    templateUrl: 'app/components/timeRemaining/timeRemaining.html'
}

app.component('timeRemaining', component);