import { AuthenticationService } from "../../api/authenticationService";
import { IntermediateRouterService } from "../../api/intermediateRouterService";
import { Injectables } from "../../configuration/injectables";
import { IEProducerStateParams } from "../../eProducerStateParams";
import { State } from "../state";

export class LoginController {
    public static $inject = [
        Injectables.AuthenticationService,
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.IntermediateRouterService
    ];

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly $state: State,
        private readonly $stateParams: IEProducerStateParams,
        private readonly intermediateRouterService: IntermediateRouterService
    ) {}

    public redirectToLogin() {
        this.intermediateRouterService.redirectToLogin();
    }

    public checkLoginStatus() {
        if (this.authenticationService.isLoggedIn()) {
            this.$state.go('main.userDefaultLanding');
        }
    }

    public loggedIn() {
        let returnState = this.$stateParams.returnState ? this.$stateParams.returnState : '';

        if (returnState) {
            this.$state.go(returnState);
        } else {
            this.$state.go('main.userDefaultLanding');
        }
    }

    public $onInit() {
        this.checkLoginStatus();
    }
}