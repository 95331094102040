import { IFilterService, IQService } from "angular";
import { BondTypeService } from "../../api/bondTypeService";
import { EProducerService } from "../../api/eProducerService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import getCurrencyValue from "../../utilities/getCurrencyValue";
import { IntermediateRouterService } from "../../api/intermediateRouterService";
import { EProducerRootScope } from "../../eProducerRootScope";

export class SearchableProductSelector {
    public static $inject = [
        Injectables.BondTypeService,
        Injectables.EProducerService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.IntermediateRouterService,
        Injectables.$rootScope
    ];

    constructor(
        private readonly bondTypeService: BondTypeService,
        private readonly eProducerService: EProducerService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly intermediateRouterSevice: IntermediateRouterService,
        private readonly $rootScope: EProducerRootScope
    ){
        setInterval(
            () => {
                this.slide_index++;

                if(this.slide_index > this.slide_max){
                    this.slide_index = 1;
                }

                $('.a-slide').hide();
                $('.a-dot').removeClass('on');
                $('#slide-' + this.slide_index).fadeIn('fast');
                $('#dot-' + this.slide_index).addClass('on');
            },
            this.slide_interval
        );
    }

    public bondAmountType: string;
    public bondType;
    public bondTypeField;
    public isIFrame: boolean;
    public noBondTypeRedirectUrl: string;
    public orientation;
    public selection: {
        bondTypeId?: string | number;
        bondAmount?: string | number;
        state?: string;
        spacer?: string;
        test?: string;
    };

    public slide_index: number = 1;
    public slide_interval: number = 4000;
    public slide_max: number = 3;
    public variableBondAmountField;
    
    public slide(page) {
        $('.a-slide').hide();
        $('.a-dot').removeClass('on');
        $('#slide-' + page).fadeIn('fast');
        $('#dot-' + page).addClass('on');

        this.slide_index = page;
    };

    /***
     *  @description
     *  Takes the user to the applictation page
     */
    public redirectToApplication(invalid) {

        if (invalid) {
            return;
        }

        this.intermediateRouterSevice.redirectToApplication(
            this.selection.bondTypeId as number,
            getCurrencyValue(this.selection.bondAmount as string),
            this.noBondTypeRedirectUrl
        )
    };

    /***
     * @description
     * redirects the user to the login page.
     */
    public redirectToLogin() {
        this.intermediateRouterSevice.redirectToLogin();
    }

    /***
     * @description
     * redirects the user to the terms and conditions page
     */
    public redirectToTermsAndConditions() {
        this.intermediateRouterSevice.redirectToTermsAndConditions();
    }

    public formatSelectionBondAmount() {

        if (!this.selection || !this.selection.bondAmount) {
            return;
        }

        this.selection.bondAmount = this.$filter("currency")(this.selection.bondAmount as number, "$");
    }

    public formatSelectionVariableBondAmounts() {
        for (let i = 0; i < this.variableBondAmountField.options.length; i++) {
            this.variableBondAmountField.options[i].$formattedAmount = this.$filter("currency")(this.variableBondAmountField.options[i].amount, "$");
        }
    }

    /***
     * fires when the state dropdown has been canged
     */
    public stateSelectionChanged() {
        return this.bondTypeService.getBondTypesByState(this.selection.state)
            .then((bondTypes) => {
                delete this.selection.bondTypeId;
                delete this.bondType;
                delete this.selection.bondAmount;

                this.bondTypeField.options = bondTypes;
                this.bondTypeField.isDisabled = false;

                if (this.noBondTypeRedirectUrl) {
                    this.bondTypeField.options.unshift({ id: -1, name: 'All Other Bonds' });
                }

                this.setBondAmountType();
            });
    };

    /***
     * @description
     * fires when the bond Type dropdown has been canged
     */
    public bondTypeSelectionChanged() {

        if (this.selection.bondTypeId as number < 0) {
            return this.$q.when();
        }

        return this.bondTypeService.getBondType(this.selection.bondTypeId as number)
            .then((bondType) => {
                this.bondType = bondType;

                // HACK: Fix bondTypeId being set to undefined to make function bondTypeSelectionChanged work properly.
                // this ensures that the bond type dropdown is set properly.
                this.selection.bondTypeId = undefined;
                this.selection.bondTypeId = bondType.id;

                delete this.selection.bondAmount;

                this.variableBondAmountField.options = bondType.bondTypeVariableBondAmounts;

                if (bondType.bondAmountType === 'Fixed') {
                    this.selection.bondAmount = bondType.bondAmount;
                    this.formatSelectionBondAmount();
                } else if (bondType.bondAmountType === 'Variable') {
                    this.formatSelectionVariableBondAmounts();
                }

                this.setBondAmountType();
        });
    }

    public setBondAmountType() {

        if (this.selection.bondTypeId == -1 || !this.bondType) {
            this.bondAmountType = 'spacer';
        } else if (this.bondType.bondAmountType === 'Fixed') {
            this.bondAmountType = 'fixed';
        } else if (this.bondType.bondAmountType === 'Variable') {
            this.bondAmountType = 'variable';
        } else {
            this.bondAmountType = 'userdefined';
        }
    }

    public onLoaded = () => {
        this.isIFrame = window.parent !== window;
        this.selection = { spacer: "We'll get to this in just a minute" };

        // Orientation options - side, stack, long, auto
        this.orientation = this.eProducerService.getUrlParams().orientation;

        this.bondTypeField = { type: 'bondTypeSearch', label: 'Describe the bond you need', isRequired: true, options: [], optionText: 'name', optionValue: 'id', isDisabled: !this.selection.state };
        this.variableBondAmountField = { type: 'select', label: 'Bond Amount', isRequired: true, options: [], optionText: '$formattedAmount', optionValue: '$formattedAmount' };
        this.setBondAmountType();

        this.noBondTypeRedirectUrl = this.$rootScope.systemAccount.noBondTypeRedirectUrl;
    }

    public $onInit() {
        this.eProducerService.loadPromise.then(this.onLoaded);
    }
}

app.controller('SearchableProductSelectorController', SearchableProductSelector); 