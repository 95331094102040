import { IFilterService, IQService } from "angular";
import { BondTypeService } from "../../api/bondTypeService";
import { EProducerService } from "../../api/eProducerService";
import { SystemAccountService } from "../../api/systemAccountService";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import getCurrencyValue from "../../utilities/getCurrencyValue";
import { EProducerRootScope } from "../../eProducerRootScope";

export class ProductSelectorController {

    public static $inject = [
        Injectables.BondTypeService,
        Injectables.EProducerService,
        Injectables.$q,
        Injectables.$filter,
        Injectables.$rootScope
    ];
    bondType: any;
    bondAmountType: string;

    constructor(
        private readonly bondTypeService: BondTypeService,
        private readonly eProducerService: EProducerService,
        private readonly $q: IQService,
        private readonly $filter: IFilterService,
        private readonly $rootScope: EProducerRootScope
    ) {}

    selection: {
        state?: string;
        bondAmount?: any;
        bondTypeId?: any;
        spacer?: string;
    };
    orientation: any;
    bondTypeField: { type: string; label: string; isRequired: boolean; options: any[]; optionText: string; optionValue: string; isDisabled: boolean; };
    variableBondAmountField: { type: string; label: string; isRequired: boolean; options: any[]; optionText: string; optionValue: string; };
    noBondTypeRedirectUrl: string;


    slide_index: number = 1;
    slide_max: number = 3;
    slide_interval: number = 4000;

    public setInterval(slide_interval) {
        this.slide_index++;

        if(this.slide_index > this.slide_max){
            this.slide_index = 1;
        }

        $('.a-slide').hide();
        $('.a-dot').removeClass('on');

        $('#slide-' + this.slide_index).fadeIn('fast');
        $('#dot-' + this.slide_index).addClass('on');
    };

    public slide(page) {
        $('.a-slide').hide();
        $('.a-dot').removeClass('on');
        $('#slide-' + page).fadeIn('fast');
        $('#dot-' + page).addClass('on');

        this.slide_index = page;
    };

    public redirectToLogin() {
        this.eProducerService.redirectToLogin();
    }

    public redirectToTermsAndConditions() {
        this.eProducerService.redirectToTermsAndConditions();
    }

    public formatSelectionBondAmount() {
        if (!this.selection || !this.selection.bondAmount)
            return;

        this.selection.bondAmount = this.$filter("currency")(this.selection.bondAmount, "$");
    }

    public formatSelectionVariableBondAmounts() {
        for (var i = 0; i < this.variableBondAmountField.options.length; i++) {
            this.variableBondAmountField.options[i].$formattedAmount = this.$filter("currency")(this.variableBondAmountField.options[i].amount, "$");
        }
    }

    public redirectToApplication(invalid) {
        if (invalid)
            return;

        if (this.selection.bondTypeId == '-1')
            this.eProducerService.redirect(this.noBondTypeRedirectUrl);
        else
            this.eProducerService.redirectToApplication(this.selection.bondTypeId, getCurrencyValue(this.selection.bondAmount));

    };

    public stateSelectionChanged() {
        return this.bondTypeService.getBondTypesByState(this.selection.state)
            .then(function (bondTypes) {
                delete this.selection.bondTypeId;
                delete this.bondType;
                delete this.selection.bondAmount;

                this.bondTypeField.options = bondTypes;
                this.bondTypeField.isDisabled = false;

                if (this.noBondTypeRedirectUrl)
                    this.bondTypeField.options.unshift({ id: -1, name: 'All Other Bonds' });

                this.setBondAmountType();
            });
    };

    public bondTypeSelectionChanged() {

        if (this.selection.bondTypeId < 0) {
            return this.$q.when();
        }

        return this.bondTypeService.getBondType(this.selection.bondTypeId)
            .then((bondType) => {
                this.bondType = bondType;

                // HACK: Fix bondTypeId being set to undefined to make function bondTypeSelectionChanged work properly.
                // this ensures that the bond type dropdown is set properly.
                this.selection.bondTypeId = undefined;
                this.selection.bondTypeId = bondType.id;

                delete this.selection.bondAmount;

                this.variableBondAmountField.options = bondType.bondTypeVariableBondAmounts;

                if (bondType.bondAmountType === 'Fixed') {
                    this.selection.bondAmount = bondType.bondAmount;
                    this.formatSelectionBondAmount();
                } else if (bondType.bondAmountType === 'Variable') {
                    this.formatSelectionVariableBondAmounts();
                }

                this.setBondAmountType();
        });
    }

    public setBondAmountType() {
        if (this.selection.bondTypeId == -1 || !this.bondType) {
            this.bondAmountType = 'spacer';
        }
        else if (this.bondType.bondAmountType === 'Fixed') {
            this.bondAmountType = 'fixed';
        }
        else if (this.bondType.bondAmountType === 'Variable') {
            this.bondAmountType = 'variable';
        } else {
            this.bondAmountType = 'userdefined';
        }
    }

    public $onInit(): void {
        this.eProducerService.loadPromise.then(this.onLoaded);
    }

    public onLoaded() {
        this.selection = { spacer: "We'll get to this in just a minute" };

        // Orientation options - side, stack, long, auto
        this.orientation = this.eProducerService.getUrlParams().orientation;

        this.bondTypeField = { type: 'select', label: 'Type of bond required', isRequired: true, options: [], optionText: 'name', optionValue: 'id', isDisabled: !this.selection.state };
        this.variableBondAmountField = { type: 'select', label: 'Bond Amount', isRequired: true, options: [], optionText: '$formattedAmount', optionValue: '$formattedAmount' };
        this.setBondAmountType();
            
        this.noBondTypeRedirectUrl = this.$rootScope.systemAccount.noBondTypeRedirectUrl;    
    }

}

app.controller('ProductSelectorController', ProductSelectorController);