import { IHttpService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import app from "../main";
import A3ApiResponse from "./a3ApiResponse";
import { Application, ApplicationType, Lead } from '../states/application/application';

export class LeadService {
    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http,
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
    ) {}

    public saveLeadApplication = (application: Application) => {
        // do not save lead if application has an id
        if (application.id) {
            return;
        }

        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/SaveLead`;

        const lead: Lead = {
            ...application,
            id: application.leadId
        };

        return this.$http.post<A3ApiResponse<Lead>>(url, lead)
            .then(response => response.data.value);
    }
}

app.service(Injectables.LeadService, LeadService);
