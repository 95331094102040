import { IHttpService, IQService } from "angular";
import {Injectables} from "../configuration/injectables";
import {SystemSettings} from "../configuration/systemSettings";
import { AuthenticationService } from "./authenticationService";
import app from "../main";
import A3ApiResponse from "./a3ApiResponse";
import { TableQueryOptions } from '../utilities/tables/tableQueryOptions';
import { PageResponse } from '../utilities/tables/pageResponse';
import { BondListItem } from '../states/purchasedBonds/purchasedBondsController';
import buildTableQueryString from '../utilities/tables/buildTableQueryString';

export class BondService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ) {}

    public getBonds = (tableQueryOptions: TableQueryOptions) => {
        const url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetPurchasedBonds${buildTableQueryString(tableQueryOptions)}`;

        return this.$http.get<A3ApiResponse<PageResponse<BondListItem>>>(url)
            .then(response => response.data.value);
    }

    public getNewBusinessPacketUrl = (bond) => {
        let newBusinessTranId;

        for (let i = 0; i < bond.bondTransactions.length; i++) {
            if (bond.bondTransactions[i].transactionType === 'NewBusiness') {
                newBusinessTranId = bond.bondTransactions[i].id;
            }
        }

        return this.getPacketUrlByTransactionId(newBusinessTranId);
    }

    public getPacketUrlByTransactionId = (bondTransactionId: number) => {
        return `${this.systemSettings.apiBaseUrl}DataDocument/DownloadTransactionForEProducer?transactionId=${bondTransactionId}`;
    }
}

app.service(Injectables.BondService, BondService);