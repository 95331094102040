import { Injectables } from "../configuration/injectables";
import app from "../main";

/*
 * -- This factory is used to control loading on buttons and other elements.
 * -- Supports multiple buttons in parallel.
 */

const loadFactory = () => {

	const controls = [];

	return {
		start: (selector) => {
			var element = $(selector);

			controls.push({ id: selector, text: element.html() });

			element.addClass('a-load');
			element.html('<i class="fa-spinner fa-spin"></i>');
		},
		stop: (selector) => {
			var element = $(selector),
			text = '';

			controls.forEach(function(control){
				if(control.id === selector){
					text = control.text;
				}
			});

			element.removeClass('a-load');
			element.html(text);
		}
	}
}

loadFactory.$inject = [
    Injectables.$http, 
    Injectables.$compile,
    Injectables.SystemSettings,
    Injectables.DataService
];

app.factory('load', loadFactory);
