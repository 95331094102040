import { IFormController, ISCEService } from 'angular';
import app from "../../../main";
import { AdditionalQuestionResponseType, Application, Person } from "../application";
import { Injectables } from '../../../configuration/injectables';
import { EProducerRootScope } from '../../../eProducerRootScope';

export class ErisaApplicationFormController {
    public static $inject = [
        Injectables.$rootScope,
        Injectables.$sce
    ];

    constructor(
        private readonly $rootScope: EProducerRootScope,
        private readonly $sce: ISCEService
    ) {}

    private bondTypeId = 7;
    private erisaTrusteesMasterApplicationQuestionId = 5;
    private fundsStateMasterApplicationQuestionId = 4;

    public application: Application;
    public person: Person;
    public onChangesSubmitted: () => void;
    public erisaTrustees: string;
    public erisaState: string;
    public minimumEffectiveDate: string;
    public maximumEffectiveDate: string;

    public formSubmitted(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.application.bondTypeId = this.bondTypeId;
        this.application.people = [this.person];
        this.application.questionResponses = [{
            masterApplicationQuestionId: this.erisaTrusteesMasterApplicationQuestionId,
            originType: AdditionalQuestionResponseType.BondType,
            questionText: 'How many trustees are there for the plan?',
            responseText: this.erisaTrustees
        },{
            masterApplicationQuestionId: this.fundsStateMasterApplicationQuestionId,
            originType: AdditionalQuestionResponseType.BondType,
            questionText: 'In what state are the funds located?',
            responseText: this.erisaState
        }];

        if (form.$dirty) {
            this.onChangesSubmitted();
        }
    }

    private getDateStringOffsetByDays(days: number) {
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
    
        // Add 30 days to the current date
        futureDate.setDate(currentDate.getDate() + days);
        
        // Extract the year, month, and day
        const year = futureDate.getFullYear();
        const month = (futureDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const day = futureDate.getDate().toString().padStart(2, '0');
        
        // Format the date as "YYYY-MM-DD"
        return `${year}-${month}-${day}`;
    }

    private setApplicationHeader() {
        this.$rootScope.application = {
            ...this.$rootScope.application,
            applicationNumber: this.application?.applicationNumber,
            //bondAmount: this.application.bondAmount as number,
            heading:  this.$sce.trustAsHtml('<b class="text-base">ERISA Bond Application</b>'),
            subHeading: this.$sce.trustAsHtml('<span>No waiting, no paperwork - <i>just fast a affordable bond in minutes!</i></span>')
        };
    }

    public $onInit() {
        this.minimumEffectiveDate = this.getDateStringOffsetByDays(-30);
        this.maximumEffectiveDate = this.getDateStringOffsetByDays(60);
        this.setApplicationHeader();
    }
}

const component = {
    bindings: {
        application: '<',
        onChangesSubmitted: '&'
    },
    controller: ErisaApplicationFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/customApplicationForms/erisa.html'
}

app.component('erisaApplicationForm', component);