export default (value: string) => {
    let date: Date;

    try {
        let month: number = 0;
        let day: number = 0;
        let year: number = 0;

        if (value.includes('/')) {
            [month, day, year] = value.split('/').map(Number);
        } else if (value.includes('-')) {
            [year, month, day] = value.split('-').map(Number);
        }

        if (month > 12 || month < 1 ||
            day > 31 || day < 1) {
            throw new Error()
        }

        date = new Date(year, month - 1, day);
    } catch {
        return null;
    }

    // Set the time to 00:00:00 in the local timezone
    date.setHours(0, 0, 0, 0);

    return date;
}