import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { BaseField } from './baseField';

export class SsnFieldController extends BaseField {
    public static $inject = [Injectables.$timeout];

    constructor(private $timeout: ng.ITimeoutService) {
        super($timeout);
        this.invalidMessage = 'must be ###-##-#### format';
        this.minLengthMessage = this.invalidMessage;
        this.maxLengthMessage = this.invalidMessage;
    }

    public lastKeyPressed: string;

    public handleKeydown = (event: KeyboardEvent) => {
        const allowedKeys = [
            'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Escape', 'Shift', 'Home', 'End',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-'
        ];

        // if not an allowed key
        if (!allowedKeys.includes(event.key) && !(event.ctrlKey || event.metaKey)) {
            event.preventDefault();
        }

        this.lastKeyPressed = event.key;

        const inputValue = (event.target as HTMLInputElement)?.value;
        const inputNumericValue = inputValue.replace(/\D/g, '');

        if (event.key === '-' && ![3,5].includes(inputNumericValue.length)) {
            event.preventDefault();
        }
    }

    public $onInit() {
        this.modelOptions = { updateOn: 'default' };
    }
}

const component = {
    bindings: {
        ...BaseField.bindings
    },
    controller: SsnFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/ssnField.html',
    require: {
        form: '^form'
    }
}

app.component('ssnField', component);