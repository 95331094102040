import app from "../main";
/*
 * -- This factory is used to scroll page to the top.
 */

const topFactory = () => {

	return {
		top: () => {
			$('html, body, .a-modal-overlay').scrollTop(0);
		},
		scrollTop: () => {
			$('html, body, .a-modal-overlay').animate({ scrollTop: 0 }, 'fast');
		}
	}
}

topFactory.$inject = [];

app.factory('top', topFactory);
