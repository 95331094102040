import { IAttributes, IController, IDirectiveFactory, IScope } from "angular"
import app from "../../main";
import { SsnFieldController } from '../fields/ssnField';

const isSsnDirective: IDirectiveFactory = () => {

    const link = (
        scope: IScope,
        elm: JQuery<HTMLInputElement>,
        attrs: IAttributes,
        controllers: [IController, SsnFieldController]
    ) => {

        const [ctrl, ssnFieldCtrl] = controllers;

        const getCursorPosition = (numbersOnly: string, formattedValue: string): number => {
            let numericIndex = 0;
            let formattedIndex = 0;

            while (numericIndex < numbersOnly.length && formattedIndex < formattedValue.length) {
                if (/\d/.test(formattedValue[formattedIndex])) {
                    if (formattedValue[formattedIndex] === numbersOnly[numericIndex]) {
                        numericIndex++;
                    }
                }

                formattedIndex++;
            }

            if (["-"].includes(ssnFieldCtrl.lastKeyPressed)) {
                formattedIndex++;
            }

            return formattedIndex;
        };

        const isValidSsn = (value: string): boolean => {
            const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
            return ssnPattern.test(value);
        };

        ctrl.$parsers.unshift((viewValue) => {
            if (!viewValue) {
                return null;
            }

            if (ssnFieldCtrl.lastKeyPressed === 'Backspace' || ssnFieldCtrl.lastKeyPressed === 'Delete') {
                return null;
            }

            const cursorPosition = elm[0].selectionStart;
            const precedingNumericChars = viewValue.slice(0, cursorPosition).replace(/\D/g, '');

            const formattingCharacters = [
                precedingNumericChars.length >= 3 ? '-' : '',
                precedingNumericChars.length >= 5 ? '-' : ''
            ];

            let formattedValue = viewValue;

            // update formattedValue with new characters to meet ssn format ###-##-####
            if (precedingNumericChars.length > 0) {
                formattedValue = `${precedingNumericChars.slice(0, 3)}${formattingCharacters[0]}${precedingNumericChars.slice(3, 5)}${formattingCharacters[1]}${precedingNumericChars.slice(5, 9)}`;
            }

            elm.val(formattedValue);

            if (elm.is(':focus')) {
                elm[0].setSelectionRange(getCursorPosition(precedingNumericChars, formattedValue), getCursorPosition(precedingNumericChars, formattedValue));
            }

            if (isValidSsn(formattedValue)) {
                return formattedValue;
            }

            return null;
        });

        ctrl.$validators.invalid = (modelValue) => {
            if (!modelValue) {
                return true;
            }

            return isValidSsn(modelValue);
        };
    };

    return {
        require: ['ngModel', '^ssnField'],
        link: link
    };
};

app.directive('isSsn', isSsnDirective);