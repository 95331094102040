import app from "../../main";
import { UiError } from '../fullPageError/uiError';

export class InlineAlertController {
    constructor() {}

    public successMessage: SuccessMessage;
    public error?: UiError;
}

const component = {
    bindings: {
        successMessage: '<?',
        error: '<?',
        wrapperClass: '@'
    },
    controller: InlineAlertController,
    controllerAs: 'vm',
    templateUrl: 'app/components/inlineAlert/inlineAlert.html'
}

app.component('inlineAlert', component);

export type SuccessMessage = {
    heading?: string;
    message: string;
}