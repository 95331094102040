import { IAttributes, IController, IDirectiveFactory, IScope } from "angular"
import app from "../../main";
import formatMoney from "../../utilities/formatMoney";
import parseMoney from "../../utilities/parseMoney";
import { CurrencyFieldController } from '../fields/currencyField';

const isCurrencyDirective: IDirectiveFactory = () => {

    const link = (
        scope: IScope,
        elm: JQuery<HTMLInputElement>,
        attrs: IAttributes,
        controllers: [IController, CurrencyFieldController]
    ) => {

        const [ctrl, currencyFieldCtrl] = controllers;

        const getCursorPosition = (numbersOnly: string, formattedValue: string): number => {
            let numericIndex = 0;
            let formattedIndex = 0;

            while (numericIndex < numbersOnly.length && formattedIndex < formattedValue.length) {
                if (/\d/.test(formattedValue[formattedIndex])) {
                    if (formattedValue[formattedIndex] === numbersOnly[numericIndex]) {
                        numericIndex++;
                    }
                }

                formattedIndex++;
            }

            if (currencyFieldCtrl.lastKeyPressed === '.') {
                formattedIndex++;
            }

            return formattedIndex;
        };

        ctrl.$parsers.unshift((viewValue: string) => {
            const parsedValue = parseMoney(viewValue);

            if (isNaN(parsedValue)) {
                elm.val('');
                return null;
            }

            const cursorPosition = elm[0].selectionStart;
            const precedingNumericChars = viewValue.slice(0, cursorPosition).replace(/\D/g, '');

            const formattedValue = formatMoney(parsedValue);
            elm.val(formattedValue);

            if (elm.is(':focus')) {
                elm[0].setSelectionRange(getCursorPosition(precedingNumericChars, formattedValue), getCursorPosition(precedingNumericChars, formattedValue));
            }

            return parsedValue;
        });

        ctrl.$formatters.unshift(modelValue => {
            if (!modelValue) {
                return modelValue;
            }

            return formatMoney(modelValue);
        });

        ctrl.$validators.invalid = (modelValue) => {
            if (!modelValue) {
                return true;
            }

            return !isNaN(parseMoney(modelValue.toString()));
        };

        ctrl.$validators.min = (modelValue) => {
            if (!modelValue) {

                if (modelValue === null || modelValue === undefined) {
                    return true;
                }
            }

            if (attrs.min) {
                return parseFloat(modelValue) >= parseFloat(attrs.min);
            }

            return true;
        };

        ctrl.$validators.max = (modelValue) => {
            if (!modelValue) {

                if (modelValue === null || modelValue === undefined) {
                    return true;
                }
            }

            if (attrs.max) {
                return parseFloat(modelValue) <= parseFloat(attrs.max);
            }

            return true;
        };
    };

    return {
        require: ['ngModel', '^currencyField'],
        link: link
    };
};

app.directive('isCurrency', isCurrencyDirective);