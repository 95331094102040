import app from "../../main";

export class CheckListController {
    public wrapperClass?: string;
}

const component = {
    bindings: {
        wrapperClass: '@'
    },
    controller: CheckListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/checkList/checkList.html',
    transclude: true
}

app.component('checkList', component);