import app from "../../main";

export class QuoteAccuracyIndicator {
    public fieldCount: number;
    public completedFieldCount: number;

    public get percent(): number {
        if (!this.fieldCount) {
            return 100;
        }

        return this.completedFieldCount / this.fieldCount * 100;
    }

    public get strengthValue(): number {
        
        if (this.percent > 25) {
            return 1;
        }

        if (this.percent > 50) {
            return 2;
        }

        if (this.percent > 75) {
            return 3;
        }

        return 0;
    }
}

const component = {
    bindings: {
        fieldCount: '<',
        completedFieldCount: '<'
    },
    controllerAs: 'vm',
    controller: QuoteAccuracyIndicator,
    templateUrl: 'app/components/quoteAccuracyIndicator/quoteAccuracyIndicator.html'
}

app.component('quoteAccuracyIndicator', component);