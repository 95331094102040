export type PhysicalAndMailAddress = {
    physicalAddress?: string;
    physicalSuiteAptNumber?: string;
    physicalState?: string;
    physicalCity?: string;
    physicalZip?: string;
    physicalCounty?: string;
    mailAddress?: string;
    mailSuiteAptNumber?: string;
    mailState?: string;
    mailCity?: string;
    mailZip?: string;
    mailCounty?: string;
}

const addressesMatched = (address?: PhysicalAndMailAddress) => {
    let matched = true;

    if (address && (
            ((address.physicalAddress ?? "") != (address.mailAddress ?? "")) ||
            ((address.physicalSuiteAptNumber ?? "") != (address.mailSuiteAptNumber ?? "")) ||
            ((address.physicalState ?? "") != (address.mailState ?? "")) ||
            ((address.physicalCity ?? "") != (address.mailCity ?? "")) ||
            ((address.physicalZip ?? "") != (address.mailZip ?? "")) ||
            ((address.physicalCounty ?? "") != (address.mailCounty ?? ""))
    )) {  
        matched = false;
    }

    return matched;
}

export default addressesMatched;