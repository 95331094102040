import { IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";

export class EProducerService {
    public static $inject = [
        Injectables.$q
    ];

    constructor(
        private readonly $q: IQService
    ) {
        window.parent.postMessage('eProducer_init', '*');
        window.addEventListener("message", this.receiveMessage, false);
    }

    public eProducer: any = {
        urlParams: {
            account: 4,
            bondTypeId: 2,
            bondAmount: 15000
        }
    };

    private loadDefer;
    public currentUser;

    public get loadPromise() {

        if (!this.loadDefer?.promise) {
            this.loadDefer = this.$q.defer();
            this.loadDefer.resolve();
        }

        return this.loadDefer.promise;
    }

    public receiveMessage = (event) => {

        // if message is to report urlParams
        if (event.data && event.data.toString().indexOf('eProducer_urlParams') === 0) {

            this.eProducer = { urlParams: {} };

            // url_params|param1=value&param2=value
            var paramString = event.data.toString().replace('eProducer_urlParams|', '');
            var params = paramString.split('&');

            // loop through each param and set to this._eProducer.urlParams
            for (var paramIndex = 0; paramIndex < params.length; paramIndex++) {
                try {
                    var key = params[paramIndex].split('=')[0];
                    var value = params[paramIndex].split('=')[1];

                    if (key && value)
                        this.eProducer.urlParams[key] = value;
                } catch (e) { }
            }

            this.loadDefer.resolve();
        }
    }

    public redirect = (url: string) => {
        window.parent.postMessage('eProducer_redirect|' + url, '*');
    }

    public redirectToLogin = (returnState?) => {
        let redirectUrl = `${this.eProducer.portalUrl}?state=main.login&account=${this.eProducer.urlParams.account}`;

        if (returnState) {
            redirectUrl += `&returnState=${returnState}`;
        }

        this.redirect(redirectUrl);
    }

    public redirectToTermsAndConditions = () => {
        const redirectUrl = `${this.eProducer.portalUrl}?state=main.termsAndConditions&account=${this.eProducer.urlParams.account}`;

        this.redirect(redirectUrl);
    }

    public redirectToApplication = (bondTypeId, bondAmount) => {
        let redirectUrl = `${this.eProducer.portalUrl}?state=main.application&account=${this.eProducer.urlParams.account}`;

        if (bondTypeId) {
            redirectUrl += `&bondTypeId=${bondTypeId}`;
        }

        if (bondAmount) {
            redirectUrl += `&bondAmount=${bondAmount}`;
        }

        this.redirect(redirectUrl);
    }

    public setPortalUrl = (url: string) => {
        this.eProducer.portalUrl = url;
    };

    public getEProducerAccountIdFromUrl = () => {
        return this.eProducer?.urlParams?.eProducerAccountId ?? null;
    }

    public getbondTypeKeywordsFromUrl = () => {
        return this.eProducer?.urlParams?.keywords ?? null;
    }

    public getTags = () => {
        var tags = [];
        var tagStr = this.eProducer?.urlParams?.tags ?? null;

        if (tagStr) {
            var tagStrArr = tagStr.split('|');

            tagStr.map(tag => {
                return { name: tag };
            });
        }

        return tags;
    }

    public getUrlParams() {
        return this.eProducer.urlParams;
    }
}

app.service(Injectables.EProducerService, EProducerService);