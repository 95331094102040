import { ITimeoutService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BaseField } from './baseField';

export class TextareaFieldController extends BaseField {

    public static $inject = [
        Injectables.$timeout
    ];

    constructor(private readonly $timeout: ITimeoutService) {
        super($timeout);
    }

    public rows: number = 3;

    public $onInit() {
    }
}

const component = {
    bindings: {
        ...BaseField.bindings,
        rows: '<?',
    },
    controller: TextareaFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/textAreaField.html',
    require: {
        form: '^form'
    }
}

app.component('textareaField', component);