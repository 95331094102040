import { IWindowService } from "angular";
import { Injectables } from "../configuration/injectables";
import app from "../main";

export class LocalStorageService {

    public static $inject = [
        Injectables.$window
    ];
    
    private readonly CACHE_KEY: string = 'eProducerCache_';
    private readonly AUTHENTICATION_KEY: string = 'eProducerAuthenticationData';

    constructor (
        private readonly $window: IWindowService
    ) {}

    public getData = (key: string) => {
        const dataString = this.$window.localStorage.getItem(key);

        if (!dataString) {
            return null;
        }

        const item = JSON.parse(dataString);

        if (item.expires) {
            const now = new Date();
            
            if (now.getTime() > item.expires) {
                this.removeData(key);
                return null;
            }
        }
        
        return item?.value;
    }

    public setData = (key: string, obj: object, timeToLive: number = null) => {
        const item = {
            value: obj,
            expires: null
        };

        if (timeToLive) {
            const now = new Date();
            item.expires = now.getTime() + timeToLive;
        }

        const dataString = JSON.stringify(item);

        this.$window.localStorage.setItem(key, dataString);
    }

    public removeData = (key: string) => {
        try {
            this.$window.localStorage.removeItem(key);
        } catch (err) {
            console.log(err);
        }
    }

    public getAuthenticationData = () => {
        return this.getData(this.AUTHENTICATION_KEY);
    }

    public removeAuthenticationData = () => {
        return this.removeData(this.AUTHENTICATION_KEY);
    }

    public setAuthenticationData = (data) => {
        this.setData(this.AUTHENTICATION_KEY, data);
    }

    public setCachedData = (url: string, obj: Object, timeToLive: number = null) => {
        this.setData(this.CACHE_KEY + url, obj, timeToLive);
    }

    public getCachedData = (url: string) => {
        return this.getData(this.CACHE_KEY + url);
    }

    public removeCachedData = (url: string) => {
        return this.removeData(this.CACHE_KEY + url);
    }

    public getAllCacheData = () => {
        let cacheData = [];
        const keys = Object.keys(this.$window.localStorage);

        for (let i = 0; i < keys.length; i++) {
            if (keys[i].includes(this.CACHE_KEY)) {
                const item = this.getData(keys[i]);
                
                if (item) {
                    cacheData.push({
                        key: keys[i].replace(this.CACHE_KEY, ''),
                        data: item
                    });
                }
            }            
        }

        return cacheData;
    }
}

app.service(Injectables.LocalStorageService, LocalStorageService);
