import { IFormController } from 'angular';
import app from "../../../main";
import addressesMatched from '../addressesMatched';
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";
import copyPhysicalAddress from '../copyPhysicalAddress';
import ApplicationStepManager from './applicationStepManager';

export class CompanyFormController {
    public static $inject = [];

    constructor() {}

    public applicationSettings: ApplicationSettings;
    public application: Application;
    public applicationStepManager: ApplicationStepManager;
    public addressesMatched: boolean = true;
    public onChangesSubmitted: () => void;

    public ownerCountChanged() {
        if (this.application.company.numberOfOwners < this.application.people.length) {
            this.application.people = this.application.people.slice(0, this.application.company.numberOfOwners);
        } else {
            while (this.application.people.length < this.application.company.numberOfOwners) {
                this.application.people.push({});
            }
        }
    }

    public physicalAddressChanged() {
        if (this.addressesMatched) {
            copyPhysicalAddress(this.application.company);
        }
    }

    public addressSameClicked() {
        if (this.addressSameClicked) {
            copyPhysicalAddress(this.application.company);
        }
    }

    private checkAddressesMatched() {
        if (this.application?.company?.physicalAddress || this.application?.company?.mailAddress) {
            this.addressesMatched = addressesMatched(this.application.company);
        }
    }

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.applicationStepManager.moveNextStep();

        if (form.$dirty) {
            this.onChangesSubmitted();
        }
    }

    public $onInit() {
        this.checkAddressesMatched();
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        onChangesSubmitted: '&'
    },
    controller: CompanyFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/companyForm.html'
}

app.component('companyForm', component)