import app from "../main";
import { Injectables } from "./injectables";

export class SystemSettings {
    public readonly apiBaseUrl: string = 'https://a3api-qa.azurewebsites.net/api/';
    public readonly odataBaseUrl: string = 'https://a3api-qa.azurewebsites.net/';
    public readonly application_sub_model: string = 'application';
    public readonly authBaseUrl: string = 'https://a3api-qa.azurewebsites.net/';
}

app.constant(Injectables.SystemSettings, new SystemSettings());