import app from "../../main";
import { twMerge } from "tailwind-merge";

export class EmptyStateController {
    public mergedClass: string;
    public wrapperClass: string;

    public $onInit () {
        const initialClass = 'bg-white rounded p-5 shadow';
        this.mergedClass = twMerge(initialClass, this.wrapperClass);
    }
}

const component = {
    bindings: {
        visible: '<',
        heading: '@',
        message: '@',
        wrapperClass: '@'
    },
    controller: EmptyStateController,
    controllerAs: 'vm',
    templateUrl: 'app/components/emptyState/emptyState.html'
}

app.component('emptyState', component);