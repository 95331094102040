import { FieldOption } from "../../../components/fields/fieldOption";
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";

export default (application: Application, applicationSettings: ApplicationSettings) => {

    const nameOnBondOptions: FieldOption<string>[] = [];

    var combinedNames = '';
    for (var i = 0; i < application.people.length; i++) {
        var fullName = application.people[i].firstName + ' ' + (application.people[i].middleName ? application.people[i].middleName + ' ' : '') + application.people[i].lastName;
        var firstLastName = application.people[i].firstName + ' ' + application.people[i].lastName;

        if (applicationSettings.nameOnBondAllowCombinePrincipals && application.people.length > 1) {
            combinedNames += fullName + ' & ';
        }

        if (!application.people[i].firstName || !application.people[i].lastName) { continue; }

        if (applicationSettings.nameOnBondAllowFirstLast) {
            nameOnBondOptions.push({ label: firstLastName, value: firstLastName });
        }

        if (applicationSettings.nameOnBondAllowFirstMiddleLast && (
                application.people[i].middleName || application.people[i].noMiddleName)
            ) {

            nameOnBondOptions.push({ label: fullName, value: fullName });
        }
    }

    if (combinedNames !== '') {
        combinedNames = combinedNames.slice(0, combinedNames.length - 3);
        nameOnBondOptions.push({ label: combinedNames, value: combinedNames });
    }

    if (application.company) {
        if (applicationSettings.nameOnBondAllowCompanyName && application.company.name) {
            nameOnBondOptions.push({ label: application.company.name, value: application.company.name });
        }

        if (applicationSettings.nameOnBondAllowCompanyDBA && application.company.dba) {
            nameOnBondOptions.push({ label: application.company.dba, value: application.company.dba });
        }
    }

    if (applicationSettings.nameOnBondAllowOtherEProducer) {
        nameOnBondOptions.push({ label: 'Other', value: 'other' });
    }

    // Remove duplicate options
    const uniqueNameOnBondOptions = Array.from(new Set(nameOnBondOptions.map(option => JSON.stringify(option))))
        .map(option => JSON.parse(option));

    return uniqueNameOnBondOptions;
}