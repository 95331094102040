import { IFormController } from 'angular';
import { FieldOption } from '../../../components/fields/fieldOption';
import app from "../../../main";
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";
import ApplicationStepManager from './applicationStepManager';
import nameOnBondOptionBuilder from './nameOnBondOptionBuilder';

export class NameOnBondFormController {
    public static $inject = [];

    constructor() {}

    public nameOnBondOptions: FieldOption<string>[] = [];
    public applicationSettings: ApplicationSettings;
    public application: Application;
    public applicationStepManager: ApplicationStepManager;
    public heading: string = 'Name Printed on Bond';
    public dropdownFieldLabel: string = 'Name on Bond';
    public otherFieldLabel: string = 'Name on Bond (other)';
    public nameOnBondOther: string;
    public nameOnBondDropdown: string;
    public onChangesSubmitted: () => void;

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        if (this.nameOnBondDropdown === 'other') {
            this.application.nameOnBond = this.nameOnBondOther;
        } else {
            this.application.nameOnBond = this.nameOnBondDropdown;
        }

        this.applicationStepManager.moveNextStep();

        if (form.$dirty) {
            this.onChangesSubmitted();
        }
    }

    private setFieldValues = () => {
        if (!this.application?.nameOnBond) {
            return;
        }

        // if application.nameOnBond is one of the dropdown options
        if (this.nameOnBondOptions.findIndex(option => option.value === this.application.nameOnBond) >= 0) {
            this.nameOnBondDropdown = this.application.nameOnBond;
            this.nameOnBondOther = null;
            return;
        }

        // if other is allowed and application.nameOnBond is NOT an option in the dropdown
        if (this.nameOnBondOptions.findIndex(option => option.value === 'other') >= 0 &&
            this.nameOnBondOptions.findIndex(option => option.value === this.application.nameOnBond) < 0) {

            this.nameOnBondDropdown = 'other';
            this.nameOnBondOther = this.application.nameOnBond;
            return;
        }
    }

    public $onInit() {

        if (this.applicationSettings.isPolicy) {
            this.dropdownFieldLabel = 'Name of Insured';
            this.otherFieldLabel = 'Name of Insured (other)';
        }

        if (this.applicationSettings.isPolicy) {
            this.heading = 'Name of Insured';
        }

        this.nameOnBondOptions = nameOnBondOptionBuilder(this.application, this.applicationSettings);
        this.setFieldValues();
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        onChangesSubmitted: '&'
    },
    controller: NameOnBondFormController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/nameOnBondForm.html'
}

app.component('nameOnBondForm', component);