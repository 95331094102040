import app from "../../main";

export class ProgressBarController {
    static $inject = [];

    constructor() {}

    public progress: number = 0;

    public $onInit() {}
}

const component = {
    bindings: {
        progress: '<'
    },
    controller: ProgressBarController,
    controllerAs: 'vm',
    templateUrl: 'app/components/progressBar/progressBar.html'
};

app.component('progressBar', component);