import app from '../../main';
import { RadioFieldController } from './radioField';

export class RadioFieldOption {

    public value: string;
    public radioField: RadioFieldController;

    public $onInit() {

    }
}

const optionComponent = {
    bindings: {
        value: '@',
    },
    controller: RadioFieldOption,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/radioOption.html',
    require: {
        radioField: '^radioField',
        form: '^form'
    },
    transclude: true,
}

app.component('radioOption', optionComponent);