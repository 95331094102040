import { IAttributes, IController, IDirectiveFactory, IFormController, INgModelController, IScope } from 'angular';
import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { BaseField } from './baseField';

export class TextFieldController extends BaseField {

    public static $inject = [Injectables.$timeout];

    constructor(private $timeout: ng.ITimeoutService) {
        super($timeout);
    }

    public handleKeydown = (event: KeyboardEvent) => {
        // manually handle maxLength for number fields
        if (this.type === 'number') {
            const inputValue = (event.target as HTMLInputElement)?.value;
            const isKeyNumeric = !isNaN(parseInt(event.key));

            if (this.maxLength && isKeyNumeric && inputValue.length >= this.maxLength) {
                event.preventDefault();
            }
        }
    }

    public $onInit() {

    }
}

const component = {
    bindings: {
        ...BaseField.bindings,
        matches: '<?'
    },
    controller: TextFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/textField.html',
    require: {
        form: '^form'
    }
}

app.component('textField', component);


const modelMatchesDirective: IDirectiveFactory = () => {

    const link =  (
        scope: IScope,
        elem: JQuery,
        attrs: IAttributes,
        ctrl: IController
    ) => {

        if (!elem.closest('text-field[matches]')?.length) {
            return;
        }

        // observe the other value and re-validate on change
        attrs.$observe('modelMatches', (val) => {
            ctrl.$validate();
        });

        ctrl.$validators.modelMatches = (modelValue) => {
            return !modelValue ||
                !attrs.modelMatches ||
                modelValue.toString() === attrs.modelMatches.toString();
        };
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    }
}

app.directive('modelMatches', modelMatchesDirective);