import { IDirectiveFactory } from "angular";
import app from "../../main";
// todo: remove once we have applicationField removed
/*
 * This directive is used to add placeholder badges when user starts typing.
 */

    const field: IDirectiveFactory = ($document) => {
        return {
            link: function(scope, element: JQLite, attr){

                var placeholder = attr.placeholder;

                if(element.is('select')){
                    element.on('change', function(){
                        if(!element.parent().is('.placeholder')){

                            element.wrap('<div class="placeholder"></div>');
                            element.parent().append('<div>' + element.find('option:first').text() + '</div>');
                            element.focus();
                        }
                    });
                } else {
                    element.on('keyup', function(){
                        if(element.val() !== '' && !element.parent().is('.placeholder')){

                            element.wrap('<div class="placeholder"></div>');
                            element.parent().append('<div>' + attr.placeholder + '</div>');
                            element.focus();
                        } else if(element.val() === '' && element.parent().is('.placeholder')) {

                            element.parent().find('div').remove();
                            element.unwrap('.placeholder');
                            element.focus();
                        }
                    });
                }

                // TODO: Replace $watch with less expensive alternative.
                var unbind = scope.$watch(attr.ngModel, function(data){

                    if(element.is('select')){
                        unbind();
                    } else if(data){
                        unbind();

                        element.wrap('<div class="placeholder"></div>');
                        element.parent().append('<div>' + attr.placeholder + '</div>');
                        element.focus();
                    }
                });
            }
        }
    }
    app.directive('field', field);