export interface IEProducerStateParams {
    applicationId:number;
    errorMessage: string;
    id: number;
    iuid: string;
    leadId: number;
    returnState: string;
    submissionError: string;
    successMessage: string;
    quoteId: number;
    eProducerPasswordResetKey: string;
    state: string;
}