import { EProducerUserService } from "../../api/eProducerUserService";
import { UiError } from '../../components/fullPageError/uiError';
import { Injectables } from "../../configuration/injectables";
import { IEProducerStateParams } from "../../eProducerStateParams";
import getPasswordPattern from "../../utilities/getPasswordPattern";
import { State } from "../state";
import { EProducerAccountUser } from "./eProducerAccountUser";

export class UsersDetailController {
    public static $inject = [
        Injectables.$stateParams,
        Injectables.EProducerUserService,
        Injectables.$state
    ]

    constructor(
        private readonly $stateParams: IEProducerStateParams,
        private readonly eProducerUserService: EProducerUserService,
        private readonly $state: State
    ) {}

    public emailExistsError: boolean;
    public error: UiError;
    public isEditMode: boolean;
    public passwordPattern: RegExp;
    public processingPromise;
    public user: EProducerAccountUser;

    public save(invalid) {

        if (invalid) {
            return;
        }

        this.error =  null;

        const promise = this.isEditMode ?
            this.eProducerUserService.updateEProducerUser(this.user):
            this.eProducerUserService.insertEProducerUser(this.user);

        const successMessage = this.isEditMode ? 'The user has been updated' : 'A new user has been created';

        this.processingPromise = promise
            .then(() => {
                this.$state.go('main.usersGrid', { successMessage: successMessage });
            })
            .catch(this.handleError);
    }

    private handleLoadError = (err) => {
        let errorType = err?.data?.errorType;

        if (!errorType && err?.status) {
            errorType = `${err.status} - ${err.statusText}`;
        } else if (!errorType) {
            errorType = 'Unknown';
        }

        this.error = {
            errorDisplayType: 'fullpage',
            errorType: err?.data?.Message ?? 'Unknown',
            heading: 'Something went wrong',
            message: 'An error occurred while loading the user. Please contact us for assistance if you continue to experience problems with the page.',
            technicalMessage: err?.data?.MessageDetail
        };

    }

    private handleError = (err) => {
        let errorType = err?.data?.errorType;

        if (!errorType && err?.status) {
            errorType = `${err.status} - ${err.statusText}`;
        } else if (!errorType) {
            errorType = 'Unknown';
        }

        switch (errorType) {
            case 'EProducerUserEmailExists':
                this.emailExistsError = true;

                this.error = {
                    errorDisplayType: 'inline',
                    errorType: errorType,
                    message: err?.data?.message ?? 'An account with this email already exists.',
                    heading: 'Login already exists',
                };
                break;

            default:
                this.error = {
                    errorDisplayType: 'inline',
                    errorType: errorType,
                    message: err?.data?.MessageDetail ?? `We encountered an error while ${this.isEditMode ? 'updating' : 'adding'} the user. Please contact us for assistance if you continue to experience problems with the page.`,
                    heading: `...Error ${this.isEditMode ? 'Updating' : 'Adding'} the user.`
                }
        }
    }

    public $onInit() {
        this.isEditMode = this.$stateParams.id ? true : false;

        this.passwordPattern = getPasswordPattern();

        if (this.isEditMode) {
            this.processingPromise = this.eProducerUserService.getEProducerUser(this.$stateParams.id)
                .then((user) => {
                    this.user = user;
                })
                .catch((err) => {
                    this.handleLoadError(err);
                })
        } else {
            this.user = { eProducerAccountId: 0 } as EProducerAccountUser;
        }
    }
}