import { IFormController } from 'angular';
import app from "../../../main";
import { Application } from "../application";
import ApplicationSettings from "../applicationSettings";
import ApplicationStepManager from './applicationStepManager';
import { Injectables } from '../../../configuration/injectables';
import { EProducerRootScope } from '../../../eProducerRootScope';

export type NewLogin = {
    email: string;
    password: string;
}

export class ReviewAndSubmitController {
    public static $inject = [Injectables.$rootScope];

    constructor(private readonly $rootScope: EProducerRootScope) {}

    public application: Application;
    public applicationSettings: ApplicationSettings;
    public applicationStepManager: ApplicationStepManager;
    public onChangesSubmitted: () => void;
    public newLogin: NewLogin;
    public createLogin: string;

    public get currentUser() {
        return this.$rootScope.currentUser;
    }

    public nextClicked(form: IFormController) {
        if (form.$invalid) {
            return;
        }

        this.onChangesSubmitted();
    }

    public createLoginChanged() {
        if (this.createLogin !== 'true') {
            this.newLogin = null;
        }
    }

    public emailChanged() {
        if (this.newLogin?.email) {
            this.createLogin = 'true';
        }
    }

    public passwordChanged() {
        if (this.newLogin?.password) {
            this.createLogin = 'true';
        }
    }

    public $onInit() {
        this.createLogin = 'false';
        this.newLogin = null;
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        application: '<',
        applicationStepManager: '<',
        onChangesSubmitted: '&',
        newLogin: '=',
        loginExistsError: '<'
    },
    controller: ReviewAndSubmitController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/panes/reviewAndSubmit.html'
}

app.component('reviewAndSubmit', component);