import { IDirectiveFactory, IFilterService, IScope } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";

const isFloatDirective: IDirectiveFactory = ($filter: IFilterService) => {
    const FLOAT_REGEXP_1 = /^\$?\d+.(\d{3})*(\,\d*)$/; //Numbers like: 1.123,56
    const FLOAT_REGEXP_2 = /^\$?\d+,(\d{3})*(\.\d*)$/; //Numbers like: 1,123.56
    const FLOAT_REGEXP_3 = /^\$?\d+(\.\d*)?$/; //Numbers like: 1123.56
    const FLOAT_REGEXP_4 = /^\$?\d+(\,\d*)?$/; //Numbers like: 1123,56

    // remove all , % _ $ and spaces
    const clean = (value) => {
        return value.toString()
            .replace(/[,%\$ ]/g, '')
            .replace(/_/g, '0');
    };

    const link = (scope: IScope, elm, attrs, ctrl) => {

        if ('isString' in attrs) {
            return;
        }

        ctrl.$parsers.unshift((viewValue) => {
            viewValue = clean(viewValue);

            if (FLOAT_REGEXP_1.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace('.', '').replace(',', '.'));
            } else if (FLOAT_REGEXP_2.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace(',', ''));
            } else if (FLOAT_REGEXP_3.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue);
            } else if (FLOAT_REGEXP_4.test(viewValue)) {
                ctrl.$setValidity('float', true);
                return parseFloat(viewValue.replace(',', '.'));
            } else if (!viewValue) {
                ctrl.$setValidity('float', true);
                return 0;
            } else {
                ctrl.$setValidity('float', false);
                return undefined;
            }
        });

        ctrl.$formatters.unshift((modelValue) => {
            return $filter('number')(parseFloat(modelValue), 2);
        });
    };

    return {
        require: 'ngModel',
        link: link
    };
};

isFloatDirective.$inject = [
    Injectables.$filter
];

app.directive('isFloat', isFloatDirective);