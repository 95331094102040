import app from "../../main";

class TableSorterButtonComponentController {
    public id: string;
    public cleanId: string;

    public $onInit = () => {
    }
}

const tableSorterButtonComponent = {
    bindings: {
        tableSorter: "<",
        sortExpression: "@",
        text: "@"
    },
    templateUrl: "app/components/tableSorter/tableSortButton.html",
    controllerAs: "vm",
    controller: TableSorterButtonComponentController
};

app.component("tableSortButton", tableSorterButtonComponent);
