import { Injectables } from "../configuration/injectables";
import app from "../main";
import { State } from "../states/state";
import { EProducerService } from "./eProducerService";

export class IntermediateRouterService {
    public static $inject = [
        Injectables.EProducerService,
        Injectables.$state
    ]

    constructor(
        private readonly eProducerService: EProducerService,
        private readonly $state: State
    ) {
        this.isIframe = window.parent !== window;
    }

    public isIframe;

    public redirectToApplication(bondTypeId: number,
        bondAmount: number,
        noBondTypeRedirectUrl: string
    ) {

        if (this.isIframe) {

            if (bondTypeId == -1) {
                this.eProducerService.redirect(noBondTypeRedirectUrl);
            } else {
                this.eProducerService.redirectToApplication(bondTypeId, bondAmount);
            }
        } else {

            if (bondTypeId == -1) {
                window.location.href = noBondTypeRedirectUrl;
            } else {
                const applicationParams = {
                    account: this.eProducerService.eProducer.urlParams.account,
                    bondTypeId: bondTypeId ? bondTypeId : null,
                    bondAmount: bondAmount ? bondAmount : null,
                }

                this.$state.go('main.application', applicationParams);
            }
        }
    }

    public redirectToLogin = (returnState?: string) => {

        if (this.isIframe) {
            this.eProducerService.redirectToLogin(returnState);
        } else {
            const loginParams = {
                account: this.eProducerService.eProducer.urlParams.account,
                returnState: returnState
            }

            this.$state.go('main.login', loginParams);
        }
    }

    public redirectToTermsAndConditions = () => {

        if (this.isIframe) {
            this.eProducerService.redirectToTermsAndConditions();
        } else {
            this.$state.go('main.termsAndConditions');
        }
    }
}

app.service(Injectables.IntermediateRouterService, IntermediateRouterService);