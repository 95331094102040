import { Injectables } from "../../configuration/injectables";
import { State } from "../state";
import { AttachmentService } from "../../api/attachmentService";
import { ApplicationService } from "../../api/applicationService";
import { ApplicationInformation, ApplicationStatus } from "../application/application";
import { IndemnityRequirements } from '../application/applicationController';
import { EProducerRootScope } from '../../eProducerRootScope';
import { UiError } from "../../components/fullPageError/uiError";
import { SuccessMessage } from "../../components/inlineAlert/inlineAlert";
import { IPromise } from "angular";

// export interface EProducerDropzoneOptions extends DropzoneOptions {
//     success?: (file: DropzoneFile, response?) => void;
// }

export type ConfirmedQuotesStatePrarams = {
    uniqueId: string;
}

export class ConfirmedQuotesController {
    public static $inject = [
        Injectables.$state,
        Injectables.AttachmentService,
        Injectables.$stateParams,
        Injectables.ApplicationService,
        Injectables.$rootScope
    ]

    constructor(
        private readonly $state: State,
        private readonly attachmentService: AttachmentService,
        private readonly $stateParams: ConfirmedQuotesStatePrarams,
        private readonly applicationService: ApplicationService,
        private readonly $rootScope: EProducerRootScope
    ) {}

    public uniqueId: string;
    public applicationInformation: ApplicationInformation;
    public attachmentError: UiError;
    public error: UiError;
    public attachments: any[] = [];
    public attachmentSuccessMessage: SuccessMessage;
    public attachmentsVisible: boolean;
    public dropzone: any;
    public uploadComplete: boolean;
    public loadingPromise: IPromise<any>;

    public set filteredQuoteCount(quoteCount: number) {
        if (!this.$rootScope.application) {
            return;
        }

        this.$rootScope.application.filteredQuoteCount = quoteCount;
    }

    public set mostExpensiveCost(mostExpensiveQuoteCost: number) {
        if (!this.$rootScope.application) {
            return;
        }

        this.$rootScope.application.mostExpensiveQuoteCost = mostExpensiveQuoteCost;
    }

    public set lowestCost(lowestCost: number) {
        if (!this.$rootScope.application) {
            return;
        }

        this.$rootScope.application.lowestQuoteCost = lowestCost;
    }

    public uploadSuccess = () => {
        this.attachmentSuccessMessage = {
            heading: 'File Uploaded',
            message: 'We received your document and we\'ll review it shortly.'
        };
        
        this.attachmentError = null;
        this.loadAttachments();
    }

    public uploadError = (error: UiError) => {
        this.attachmentSuccessMessage = null;
        this.attachmentError = error;
    }

    public loadAttachments() {
        return this.attachmentService.getApplicationAttachments(this.applicationInformation.id)
            .then(attachments => {
                this.attachments = attachments;
            })
            .catch(err => {
                this.attachmentError = {
                    errorDisplayType: 'inline',
                    heading: 'Unable to load attachments',
                    message: 'We were not able to load attachments for this application.',
                    errorType: 'Unknown'
                };
            });
    }

    public getApplicationInformation = () => {
        this.loadingPromise = this.applicationService.getApplicationForConfirmedQuotes(this.uniqueId)
            .then((applicationInfo) => {
                this.applicationInformation = applicationInfo;

                this.$rootScope.application = {
                    bondAmount: this.applicationInformation.bondAmount,
                    bondType: this.applicationInformation.bondTypeName,
                    applicationNumber: this.applicationInformation.applicationNumber,
                }

                return this.loadAttachments();
            })
            .catch(this.handleError);
    }

    private handleError = (err) => {
        let errorType = err?.data?.errorType;

        if (!errorType && err?.status) {
            errorType = err.status + ' - ' + err.statusText;
        } else if (!errorType) {
            errorType = 'Unknown';
        }

        switch (errorType) {
            case 'ApplicationAlreadyPurchased':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'This application has already been purchased. Please check your email for access to your purchase.',
                    heading: 'Application already purchased',
                };
                break;

            case 'ApplicationClosed':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'This application is now closed. This can happen if the application has expired or if we close it manually.',
                    heading: 'Application not available',
                };
                break;

            case 'ApplicationNotFound':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'We could not find the application you are looking for. Please use a link that was provided to you through email.',
                    heading: 'Application not found',
                };
                break;

            case 'ApplicationLinkInvalid':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'The link you used to access this application is not valid. Please use the link that was provided to you through email.',
                    heading: 'Application link is not valid',
                };
                break;

            case 'ApplicationNotYetSubmitted':
                this.$state.go('main.application', { uniqueId: this.uniqueId });
                break;

            default:
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: err?.data?.MessageDetail ?? 'An error occurred while loading the application. Please use a link that was provided to you through email. Please contact us for assistance if you continue to experience problems.',
                    heading: 'Unable to load application',
                    technicalMessage: err?.data?.Message
                };
        }
    }

    public purchaseQuote(quote) {
        if (quote.indemnityRequirements === IndemnityRequirements.None) {
            this.$state.go('main.payment', { quoteId: quote.id });
        } else {
            this.$state.go('main.indemnitySignatureList', { quoteId: quote.id });
        }
    }

    public $onInit() {
        this.uniqueId = this.$stateParams.uniqueId;

        // reset values to handle back button click
        this.applicationInformation = null;
        this.attachmentError = null;
        this.attachmentSuccessMessage = null;
        this.error = null;
        this.attachments = null;
        this.attachmentsVisible = null;
        this.dropzone = null;
        this.uploadComplete = null;

        if (!this.uniqueId) {
            this.$state.go('main.application');
        }

        this.getApplicationInformation();
    }
}