import app from "../../main";

const component = {
    bindings: {},
    controller: () => {},
    controllerAs: 'vm',
    templateUrl: 'app/components/checkList/checkListItem.html',
    transclude: true
}

app.component('checkListItem', component);