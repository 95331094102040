import app from "../../main";
import { Application } from "./application";
import ApplicationSettings from './applicationSettings';
import ApplicationStepManager from './panes/applicationStepManager';

export class ApplicationFormHeaderController {
    public static $inject = [];

    constructor() {}

    public applicationSettings: ApplicationSettings;
    public applicationStepManager: ApplicationStepManager;
    public application: Application;
    public showSteps: boolean = false;

    public $onInit() {
    }
}

const component = {
    bindings: {
        applicationSettings: '<',
        applicationStepManager: '<',
        application: '<'
    },
    controller: ApplicationFormHeaderController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/applicationFormHeader.html'
}

app.component('applicationFormHeader', component);