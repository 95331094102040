import { ITimeoutService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { BaseField } from './baseField';

export class CheckFieldController extends BaseField {

    public static $inject = [
        Injectables.$timeout
    ];

    constructor(private readonly $timeout: ITimeoutService) {
        super($timeout);
    }

    public requiredMessage: string = 'You must check the box to proceed';

    public $onInit() {
    }
}

const component = {
    bindings: {
        ...BaseField.bindings,
        invalidShake: '<'
    },
    controller: CheckFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/checkField.html',
    require: {
        form: '^form'
    }
}

app.component('checkField', component);

