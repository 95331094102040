import { Injectables } from "../configuration/injectables";
import app from "../main";
import { GuidCreator } from "../utilities/guidCreator";
 
export class TagManagerService {

    public static $inject = [
        Injectables.GuidCreator
    ];

    constructor(
        private readonly guidCreator: GuidCreator
    ){}

    private bondType;
    private paymentAmount;
    private bondNumber;
    public dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];

    private EProducer_ConfirmedQuotesScreen = 'EProducer-ConfirmedQuotesScreen';
    private EProducer_OpenApplicationsScreen = 'EProducer-OpenApplicationsScreen';
    private EProducer_EditBondSelection_NewApplication = 'EProducer-EditBondSelection-NewApplication';
    private EProducer_MissingInformationModal = 'EProducer-MissingInformationModal';
    private EProducer_OneLastThing_ModalReview = 'EProducer-OneLastThing-ModalReview';
    private EProducer_PaymentPage = 'EProducer-PaymentPage';
    private EProducer_ThankYouForYourPurchase = 'EProducer-ThankYouForYourPurchase';
    private EProducer_SendCredentials = 'sendCredentials';
    private EProducer_VisitApplication = 'EProducer-VisitApplication';
    private EProducer_SubmitApplication = 'EProducer-SubmitApplication';
    private EProducer_PurchaseBond = 'EProducer-PurchaseBond';
    private EProducer_GetIndemnity = 'EProducer-GetIndemnity';
    private EProducer_GetCreditConsent = 'EProducer-GetCreditConsent';

    private Individual_Step1 = 'EProducer-Step1-LetsGetStarted';
    private Individual_Step2 = 'EProducer-Step2-PersonalInformation';
    private Company_Step2 = 'EProducer-Step2-CompanyInformation';
    private Individual_Step3 = 'EProducer-Step3-AdditionalQuestions';
    private Company_Step3 = 'EProducer-Step3-Owner';
    private Individual_Step4 = 'EProducer-Step4-BondInformation';
    private Company_Step4 = 'EProducer-Step4-AdditionalQuestions';
    private Company_Step5 = 'EProducer-Step5-BondInformation';

    public confirmedQuotes = (systemAccount) => {
        this.setState(systemAccount, this.EProducer_ConfirmedQuotesScreen);
    }

    public newApplication = (systemAccount) => {
        this.setState(systemAccount, this.EProducer_EditBondSelection_NewApplication);
    }

    public missingInformation = (systemAccount) => {
        this.setState(systemAccount, this.EProducer_MissingInformationModal);
    }

    public review = (systemAccount) => {
        this.setState(systemAccount, this.EProducer_OneLastThing_ModalReview);
    }

    public paymentPage = (systemAccount) => {
        this.setState(systemAccount, this.EProducer_PaymentPage);
    }

    public setStepState = (systemAccount, isIndividual, index) => {
        var state = '';

        switch (index) {
            case 0:
                state = this.Individual_Step1;
                break;
            case 1:
                state = isIndividual ? this.Individual_Step1 : this.Company_Step2;
                break;
            case 2:
                state = isIndividual ? this.Individual_Step2 : this.Company_Step3;
                break;
            case 3:
                state = isIndividual ? this.Individual_Step3 : this.Company_Step4;
                break;
            case 4:
                state = isIndividual ? this.Individual_Step4 : this.Company_Step5;
                break;
            default:
                break;
        }
        
        this.setState(systemAccount, state);
    }

    public setState = (systemAccount, stateName) => {
        this.sendToTagManager(this.buildObject(stateName, systemAccount, null, null, null));
    }

    public sendCredentials = (systemAccount) => {
        this.sendToTagManager(this.buildObject(this.EProducer_SendCredentials, systemAccount, null, null, null));
    }

    public thankYouForYourPurchase = (systemAccount) => {
        this.sendToTagManager(this.buildObject(this.EProducer_ThankYouForYourPurchase, systemAccount, this.bondType, this.paymentAmount, this.bondNumber));
    }

    public getIndemnity = (systemAccount) => {
        this.sendToTagManager(this.buildObject(this.EProducer_GetIndemnity, systemAccount, null, null, null));
    }

    public getCreditConsent = (systemAccount) => {
        this.sendToTagManager(this.buildObject(this.EProducer_GetCreditConsent, systemAccount, null, null, null));
    }

    public visitApplication = (systemAccount) => {
        this.sendToTagManager(this.buildObject(this.EProducer_VisitApplication, systemAccount, null, null, null));
    }

    public openApplications = (systemAccount) => {
        this.sendToTagManager(this.buildObject(this.EProducer_OpenApplicationsScreen, systemAccount, null, null, null));
    }

    public submitApplication = (systemAccount, bondType) => {
        this.sendToTagManager(this.buildObject(this.EProducer_SubmitApplication, systemAccount, bondType, null, null));
    }

    public purchaseBond = (systemAccount, bondType, paymentAmount, bondNumber) => {
        this.bondType = bondType;
        this.paymentAmount = paymentAmount;
        this.bondNumber = bondNumber;

        this.sendToTagManager(this.buildObject(this.EProducer_PurchaseBond, systemAccount, bondType, paymentAmount, bondNumber));
    }

    public downloadPdf = (systemAccount, stateName) => {
        this.sendToTagManager(this.buildObject(stateName, systemAccount, null, null, null));
    }

    public buildObject = (event, systemAccount, bondType, paymentAmount, bondNumber) => {
        if (!systemAccount) {
            // TODO: We should log this so we can determine why
            // it isn't being provided.
            return {};
        }

        if (bondNumber === 'WF_ERROR' || bondNumber === 'PENDING') {
            bondNumber = this.guidCreator.create();
        }

        return {
            'event': '/virtual/' + event,
            'attributes': {
                'systemAccountId': systemAccount.id,
                'bondType': bondType || '',
                'paymentAmount': paymentAmount || '',
                'bondNumber': bondNumber || '',
                'adWordsVisitApplicationConversionId': systemAccount.adWordsVisitApplicationConversionId,
                'adWordsVisitApplicationConversionLabel': systemAccount.adWordsVisitApplicationConversionLabel,
                'adWordsSubmitApplicationConversionId': systemAccount.adWordsSubmitApplicationConversionId,
                'adWordsSubmitApplicationConversionLabel': systemAccount.adWordsSubmitApplicationConversionLabel,
                'adWordsPurchaseBondConversionId': systemAccount.adWordsPurchaseBondConversionId,
                'adWordsPurchaseBondConversionLabel': systemAccount.adWordsPurchaseBondConversionLabel,
                'event': '/virtual/' + event
            }
        };
    }

    public sendToTagManager = (obj) => {
        this.dataLayer.push(obj);
    }
}

app.service(Injectables.TagManagerService, TagManagerService);
