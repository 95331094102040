import { ISCEService } from "angular";
import { CustomerService } from "../../api/customerService";
import { PaymentService } from "../../api/paymentService";
import { Injectables } from "../../configuration/injectables";
import { LocalStorageService } from "../../utilities/localStorageService";
import getCreditCardMonths from "../../utilities/getCreditCardMonths";
import getCreditCardYears from "../../utilities/getCreditCardYears";
import { IEProducerStateParams } from "../../eProducerStateParams";
import { CustomerOpenPayable, SearchCustomerLookupResponse } from "./searchCustomerLookupResponse";
import { FieldOption } from "../../components/fields/fieldOption";
import { SubmitPortalPaymentDto } from "./submitPortalPaymentDto";
import { PaymentMethod } from "../application/payment";
import { EProducerRootScope } from "../../eProducerRootScope";
import { UiError } from '../../components/fullPageError/uiError';

export class PaymentFormController {
    public static $inject = [
        Injectables.PaymentService,
        Injectables.$stateParams,
        Injectables.LocalStorageService,
        Injectables.CustomerService,
        Injectables.$sce,
        Injectables.$rootScope
    ];

    constructor(
        private readonly paymentService: PaymentService,
        private readonly $stateParams: IEProducerStateParams,
        private readonly localStorageService: LocalStorageService,
        private readonly customerService: CustomerService,
        private readonly $sce: ISCEService,
        private readonly $rootScope: EProducerRootScope
    ) {}

    private CUSTOMER_FOUND_FORM = 'CUSTOMER_FOUND_FORM';
    private CUSTOMER_LOOKUP_FORM = 'CUSTOMER_LOOKUP_FORM';
    private CUSTOMER_SEARCH_FORM = 'CUSTOMER_SEARCH_FORM';
    private PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION';
    private PAYMENT_FORM = 'PAYMENT_FORM';
    private REVIEW_PAYMENT = 'REVIEW_PAYMENT';

    public achAccountTypeOptions: FieldOption<string>[];
    public activePane: string;
    public address: string;
    public agreedToTerms: boolean;
    public agreeToTerms: boolean;
    public city: string;
    public confirmAccountNumber: string;
    public creditCardExpirationMonthOptions;
    public creditCardExpirationYearOptions;
    public customerSearchBondNumber: string;
    public customerSearchEmail: string;
    public customerSearchFormSubmitting: boolean;
    public customerSearchPhone: string;
    public dto: SubmitPortalPaymentDto;
    public error: UiError;
    public lookupCode;
    public lookupZip;
    public name: string;
    public otherPayment: boolean;
    public otherPaymentAmount: string;
    public otherPaymentAmountIsRequired: boolean;
    public otherPaymentReferenceNumber: string;
    public otherPaymentReferenceNumberIsRequired: boolean;
    public payables: CustomerOpenPayable[];
    public paymentDetails: string;
    public paymentMethodOptions: FieldOption<string>[];
    public paymentSubmitting: boolean;
    public phone: string;
    public processingPromise;
    public saveBillingInformation: boolean;
    public saveLookupCode: boolean;
    public searchingLookup: boolean;
    public searchMatch: SearchCustomerLookupResponse;
    public state: string;
    public suiteAptNumber: string;
    public totalPaymentAmount;
    public transaction;
    public zip: string;


    public showLookupCodeSearch() {
        this.activePane = this.CUSTOMER_SEARCH_FORM;
    }

    public submitCustomerSearch() {
        this.customerSearchFormSubmitting = true;
        this.error = null;

        const request = {
            bondNumber: this.customerSearchBondNumber ? this.customerSearchBondNumber : null,
            phone: this.customerSearchPhone ? this.customerSearchPhone : null,
            email: this.customerSearchEmail ? this.customerSearchEmail : null
        };

        this.searchMatch = null;

        this.customerService.searchLookupCode(request, this.$rootScope.systemAccount.id)
            .then((searchMatch) => {
                this.customerSearchFormSubmitting = false;
                this.searchMatch = searchMatch;
                this.activePane = this.CUSTOMER_FOUND_FORM;
            })
            .catch(() => {
                let messageText = request.bondNumber ?
                    `We couldn't find a matching account using the information you provided. Please review your entries and try again. If you continue to have trouble please contact us at ${this.$rootScope.branding.phone}.` :
                    `We couldn't find any matching account using the information you provided. Try searching the bond number if it's known, otherwise, please review your entries and try again. If you continue to have trouble please contact us at ${this.$rootScope.branding.phone}.`

                this.customerSearchFormSubmitting = false;
                
                this.error = {
                    errorDisplayType: 'inline',
                    heading: 'Not Found',
                    message: messageText
                };
            });
    }

    public customerFoundSubmitted() {
        this.lookupZip = this.searchMatch.zip;
        this.lookupCode = this.searchMatch.lookupCode;
        this.saveLookupCode = true;
        this.activePane = this.CUSTOMER_LOOKUP_FORM;
        this.searchCustomerPayables();
    }

    public searchCustomerPayables() {
        if ((!this.lookupCode || !this.lookupZip) && !this.$rootScope.currentUser) {
            return;
        }

        this.searchingLookup = true;

        this.customerService.getOpenPayablesByLookup(this.lookupCode, this.lookupZip)
            .then((result) => {

                this.searchingLookup = false;
                this.setCustomerInfo(result);

                if (result === null) {
                    this.error = {
                        errorDisplayType: 'inline',
                        heading: 'Not Found',
                        message: `We couldn't find any matching customer records using the account number you provided. Please check the number and try again. If you continue to have trouble please contact us at ${this.$rootScope.branding.phone}.`,
                        errorType: 'Unknown'
                    };
                } else {
                    this.error = null;
                    this.payables = result.payables;

                    if (!this.payables.length) {
                        this.otherPayment = true;
                        this.otherPaymentReferenceNumberIsRequired = true;
                        this.otherPaymentAmountIsRequired = true;
                    }

                    for (let i = 0; i < this.payables.length; i++) {
                        for(let j = 0; j < this.payables[i].lineItems.length; j++) {
                            this.payables[i].lineItems[j].parsedDescription = this.$sce.trustAsHtml(this.payables[i].lineItems[j].description.replace(/\|/g, '<br/>'));
                        }
                    }

                    this.activePane = this.PAYMENT_FORM;

                    for(let i=0; i < this.payables.length; i++){
                        this.payables[i].selected = true;
                    }

                    this.calculatePaymentAmount();
                }

                this.searchingLookup = false;
            });

        if (this.saveLookupCode) {
            this.storelookupCode();
        } else {
            this.forgetlookupCode();
        }
    }

    public setCustomerInfo(result) {
        if (!result) {
            this.dto.payment.customerId = null;
            this.dto.payment.eProducerAccountId = null;
            this.name = '';
            this.address = '';
            this.suiteAptNumber = '';
            this.city = '';
            this.state = '';
            this.zip = '';
            this.phone = '';
        } else {
            this.dto.payment.customerId = result.customerId;
            this.dto.payment.eProducerAccountId = result.eProducerAccountId;
            this.name = result.name;
            this.address = result.address;
            this.suiteAptNumber = result.suiteAptNumber;
            this.city = result.city;
            this.state = result.state;
            this.zip = result.zip;
            this.phone = result.phone;
        }
    }

    public showError(error) {
        this.error = {
            errorDisplayType: 'inline',
            errorType: 'Unknown',
            heading: '',
            message: error
        };
    }

    public calculatePaymentAmount() {
        this.dto.payment.amount = 0;

        for(let i = 0; i < this.payables.length; i++){
            if (this.payables[i].selected) {
                this.dto.payment.amount += this.payables[i].totalAmountDue;
            }
        }

        if (this.otherPayment) {
            this.dto.payment.amount += this.otherPaymentAmount ? parseFloat(this.otherPaymentAmount.toString().replace(/\$|,/g, "")) : 0;
            this.otherPaymentReferenceNumberIsRequired = true;
            this.otherPaymentAmountIsRequired = true;
        } else {
            this.otherPaymentReferenceNumberIsRequired = false;
            this.otherPaymentAmountIsRequired = false;
        }
    }

    public submitPaymentForm(invalid) {
        if (invalid) {
            return;
        }

        if (this.payables.length && (
                !this.dto.payment.amount ||
                this.dto.payment.amount <= 0)
        ) {
            this.showError('You must select a payable item to be processed.');
            return;
        }

        this.error = null;

        for(var i = 0; i < this.payables.length; i++) {
            if (this.payables[i].selected) {
                this.dto.payables.push(this.payables[i]);
            }
        }

        if (this.saveBillingInformation) {
            this.storeBillingInformation();
        } else {
            this.forgetBillingInformation();
        }

        if (this.dto.payment.paymentMethod === 'CreditCard') {
            const cardNumber = this.dto.payment.cardNumber + '';
            this.paymentDetails = `Card number ending in ${cardNumber.substr(cardNumber.length - 4)}`;
        } else {
            const acctNumber = this.dto.payment.accountNumber + '';
            this.paymentDetails = `ACH from account number ending in ${acctNumber.substr(acctNumber.length - 4)}`;
        }

        this.processingPromise = this.paymentService.getFees(this.dto)
            .then((response) => {
                this.dto.payment.processingFee = response.fee;
                this.totalPaymentAmount = this.dto.payment.amount + this.dto.payment.processingFee;
            });

        this.agreeToTerms = false;
        this.activePane = this.REVIEW_PAYMENT;
    }

    public loadLookupCode() {
        try {
            const savedLookupCode = this.localStorageService.getData('lookupCode');
            const savedLookupZip = this.localStorageService.getData('lookupZip');

            if (savedLookupCode) {
                this.lookupCode = savedLookupCode;
                this.saveLookupCode = true;
            }

            if (savedLookupZip) {
                this.lookupZip = savedLookupZip;
                this.saveLookupCode = true;
            }
        } catch (e) { }
    }

    public storelookupCode() {
        try {
            this.localStorageService.setData('lookupCode', this.lookupCode);
            this.localStorageService.setData('lookupZip', this.lookupZip);
        } catch (e) {}
    }

    public forgetlookupCode() {
        try {
            this.localStorageService.removeData('lookupCode');
            this.localStorageService.removeData('lookupZip');
        } catch (e) {}
    }

    public loadBillingInformation() {
        try {
            const savedBillingInformation = this.localStorageService.getData('billingInformation');

            if (savedBillingInformation) {
                this.dto.payment = {
                    firstName: savedBillingInformation.firstName,
                    lastName: savedBillingInformation.lastName,
                    address: savedBillingInformation.address,
                    suiteAptNumber: savedBillingInformation.suiteAptNumber,
                    city: savedBillingInformation.city,
                    state: savedBillingInformation.state,
                    zip: savedBillingInformation.zip,
                    phone: savedBillingInformation.phone,
                    email: savedBillingInformation.email,
                    systemAccountId: this.$rootScope.systemAccount.id,
                };

                this.dto.payment.companyName = savedBillingInformation.companyName;
                this.saveBillingInformation = true;
            }
        } catch (e) { }
    }

    public storeBillingInformation() {
        try {
            this.localStorageService.setData(
                'billingInformation',
                {
                    firstName: this.dto.payment.firstName,
                    lastName: this.dto.payment.lastName,
                    address: this.dto.payment.address,
                    suiteAptNumber: this.dto.payment.suiteAptNumber,
                    city: this.dto.payment.city,
                    state: this.dto.payment.state,
                    zip: this.dto.payment.zip,
                    phone: this.dto.payment.phone,
                    email: this.dto.payment.email,
                    companyName: this.dto.payment.companyName
                }
            );
        } catch (e) {}
    }

    public forgetBillingInformation() {
        try {
            this.localStorageService.removeData('billingInformation');
        } catch (e) {}
    }

    public accountKeyPress($event) {
        if ($event.keyCode > 47 && $event.keyCode < 58) {
            return;
        }

        $event.preventDefault();
    }

    public submitReviewPaymentForm(form) {
        if (form.$invalid) {
            return;
        }

        this.paymentSubmitting = true;

        if (this.otherPayment) {
            this.dto.payment.comments = this.otherPaymentReferenceNumber;
        }

        this.dto.payment.paymentOrigin = 'PaymentPortal';

        this.processingPromise = this.paymentService.submitPayment(this.dto)
            .then((response) => {
                if (response.success) {
                    this.activePane = this.PAYMENT_CONFIRMATION;
                    this.transaction = response.paymentTransaction.transactionId;
                    this.error = null;
                    this.paymentSubmitting = false;
                } else {
                    this.showError(response.message);
                }
            })
            .catch(err => {
                const errorType = err?.data?.errorType;
                const errorMessage = err?.data?.message;

                if (errorType == 'PaymentDeclined') {
                    this.error = {
                        errorDisplayType: 'inline',
                        errorType: errorType,
                        heading: 'Payment Declined',
                        message: errorMessage,
                    }
                } else if (errorType == 'PaymentError') {
                    this.error = {
                        errorDisplayType: 'inline',
                        errorType: errorType,
                        heading: 'Payment Error',
                        message: errorMessage
                    };
                } else {
                    this.error = {
                        errorDisplayType: 'fullpage',
                        errorType: 'Unknown',
                        heading: 'Something went wrong',
                        message: 'An error occurred while processing your payment. Please contact us for more information.'
                    };
                }
            })
            .finally(() => {
                this.paymentSubmitting = false;
            });
    }

    public submitPaymentConfirmationForm(invalid) {
        if (invalid) {
            return;
        }

        this.dto.payables = [];
        this.dto.amount = 0;
        this.dto.processingFee = 0;

        this.searchCustomerPayables();
    }

    public get allowCCPayments() {
        return this.$rootScope.systemAccount.allowCCPaymentsThroughPaymentForm;
    }

    public get allowACHPayments() {
        return this.$rootScope.systemAccount.allowACHPaymentsThroughPaymentForm;
    }

    public $onInit() {
        if (this.$rootScope.currentUser) {
           this.searchCustomerPayables();
        } else {
           this.activePane = this.CUSTOMER_LOOKUP_FORM;           
        }

        this.dto = {
            payables: [],
            payment: {
                paymentMethod: PaymentMethod.CreditCard,
                processingFee: 0,
                amount: 0,
                referenceNumber: ''
            }
        };
        
        this.paymentMethodOptions = [];

        if (this.allowACHPayments) {
            this.paymentMethodOptions.push({
                label: 'ACH',
                value: 'ACH'
            });

            this.achAccountTypeOptions = [
                { label: 'Checking', value: '0' },
                { label: 'Savings', value: '1' },
                { label: 'Corporate Checking', value: '2' },
                { label: 'Corporate Savings', value: '3' }
            ]
        }

        if (this.allowCCPayments) {
            this.creditCardExpirationMonthOptions = getCreditCardMonths();
            this.creditCardExpirationYearOptions = getCreditCardYears();

            this.paymentMethodOptions.push({
                label: 'Credit Card',
                value: 'CreditCard'
            });
        }

        this.otherPaymentReferenceNumberIsRequired = false;
        this.otherPaymentAmountIsRequired = false;

        if (this.$stateParams.errorMessage) {
            this.showError(this.$stateParams.errorMessage);
        }

        this.loadLookupCode();
        this.loadBillingInformation();
    }
}