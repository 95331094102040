import { ApplicationService } from "../../api/applicationService";
import { Injectables } from "../../configuration/injectables";
import ApplicationSettings from './applicationSettings';
import { Application, Tag } from './application';
import ApplicationStepManager from './panes/applicationStepManager';
import { EProducerRootScope } from '../../eProducerRootScope';
import { LeadService } from '../../api/leadService';
import { IStateService } from '../../../typeDefinitions/uiRouter';
import { IIntervalService } from 'angular';
import { NewLogin } from './panes/reviewAndSubmit';
import { AuthenticationService } from '../../api/authenticationService';
import { UiError } from '../../components/fullPageError/uiError';

export type CustomApplicationType = 'erisa';

export type ApplicationParams = {
    uniqueId?: string,
    bondTypeId?: number,
    bondAmount?: number,
    tags?: string,
    applicationType?: CustomApplicationType,
}

export class ApplicationController {
    public static $inject = [
        Injectables.ApplicationService,
        Injectables.$stateParams,
        Injectables.ApplicationStepManager,
        Injectables.$rootScope,
        Injectables.LeadService,
        Injectables.$state,
        Injectables.$interval,
        Injectables.AuthenticationService
    ]

    constructor(
        private readonly applicationService: ApplicationService,
        private readonly $stateParams: ApplicationParams,
        public readonly applicationStepManager: ApplicationStepManager,
        private readonly $rootScope: EProducerRootScope,
        private readonly leadService: LeadService,
        private readonly $state: IStateService,
        private readonly $interval: IIntervalService,
        private readonly authenticationService: AuthenticationService
    ) {}

    public customApplicationType: CustomApplicationType;
    public applicationSettings: ApplicationSettings;
    public application: Application;
    public filterCount: number;
    public prequalifiedQuotes: any[];
    public filtersVisible: boolean;
    public error: UiError;
    public isSubmitting: boolean;
    public submissionProgressPercent: number;
    public newLogin: NewLogin;

    public applicationSettingsChanged(applicationSettings: ApplicationSettings) {
        this.applicationSettings = applicationSettings;
    }

    public set filteredQuoteCount(quoteCount) {
        if (!this.$rootScope.application) {
            return;
        }

        this.$rootScope.application.filteredQuoteCount = quoteCount;
    }

    public set mostExpensiveCost(mostExpensiveQuoteCost) {
        if (!this.$rootScope.application) {
            return;
        }

        this.$rootScope.application.mostExpensiveQuoteCost = mostExpensiveQuoteCost;
    }

    public set lowestCost(lowestCost) {
        if (!this.$rootScope.application) {
            return;
        }

        this.$rootScope.application.lowestQuoteCost = lowestCost;
    }

    public handleProductSelectionFormError = (error: UiError) => {
        this.error = error;
    }

    public productSelectionFormSubmitted(hasChanges) {
        if (hasChanges) {
            this.save();
        }

        if (hasChanges || this.prequalifiedQuotes == null) {
            this.loadPrequalifiedQuotes();
        }
    }

    public save = () => {
        if (!this.application.id) {
            this.leadService.saveLeadApplication(this.application)
                .then(leadId => {
                    this.application.leadId = leadId.id;
                })
                .catch(() => { /* swallow error so not to disrupt application flow for lead generation */ });
        }
    }

    public sumbitApplication = () => {
        this.isSubmitting = true;

        this.applicationService.submitApplication(this.application, this.newLogin)
            .then((uniqueId) => {
                this.$state.go('main.confirmedQuotes', { uniqueId: uniqueId });

                if (this.newLogin?.email && this.newLogin?.password) {
                    this.authenticationService.login(this.newLogin.email, this.newLogin.password);
                }
            })
            .catch(this.handleError)
            .finally(() => {
                this.isSubmitting = false;
            });

        this.submissionProgressPercent = 0;
        this.$interval(() => {
            const remainingPercent = 100 - this.submissionProgressPercent;
            this.submissionProgressPercent += remainingPercent * .025;
        }, 100, 150);
    }

    private loadPrequalifiedQuotes = () => {
        this.applicationService.getPrequalifiedQuotes(this.$rootScope.systemAccount.id, this.applicationSettings.bondTypeId, this.application.bondAmount as number)
            .then(prequalifiedQuotes => {
                this.prequalifiedQuotes = prequalifiedQuotes;
                this.$rootScope.application.totalQuoteCount = this.prequalifiedQuotes?.length;
            });
    }

    private loadApplication = () => {
        this.applicationService.loadApplication(this.$stateParams.uniqueId)
            .then(application => {
                this.setApplication(application);
                this.applicationStepManager.showStepProgress = true;
            })
            .catch(this.handleError);
    }

    private handleError = (err) => {
        let errorType = err?.data?.errorType;

        if (!errorType && err?.status) {
            errorType = err.status + ' - ' + err.statusText;
        } else if (!errorType) {
            errorType = 'Unknown';
        }

        switch (errorType) {
            case 'ApplicationAlreadySubmitted':
                this.$state.go('main.confirmedQuotes', { uniqueId: this.$stateParams.uniqueId });
                break;

            case 'ApplicationAlreadyPurchased':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'This application has already been purchased. Please check your email for access to your purchase.',
                    heading: 'Application already purchased',
                };
                break;

            case 'ApplicationClosed':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'This application is now closed. This can happen if the application has expired or if we close it manually.',
                    heading: 'Application not available',
                };
                break;

            case 'ApplicationNotFound':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'We could not find the application you are looking for. Please use a link that was provided to you through email.',
                    heading: 'Application not found',
                };
                break;

            case 'ApplicationLinkInvalid':
                this.error = {
                    errorDisplayType: 'fullpage',
                    errorType: errorType,
                    message: 'The link you used to access this application is not valid. Please use the link that was provided to you through email.',
                    heading: 'Application link is not valid',
                };
                break;

            case 'EProducerUserEmailExists':
                this.error = {
                    errorDisplayType: 'inline',
                    errorType: errorType,
                    message: err?.data?.message ?? 'An account with this email already exists.',
                    heading: 'Login already exists',
                };
                break;

            default:
                if (err.config.url.includes('SubmitApplication')) {
                    this.error = {
                        errorDisplayType: 'fullpage',
                        errorType: err?.data?.Message ?? 'Unknown',
                        message: 'An error occurred while submitting the application. Please contact us for assistance if you continue to experience problems',
                        heading: 'Something went wrong',
                        technicalMessage: err?.data?.MessageDetail
                    };
                } else {
                    this.error = {
                        errorDisplayType: 'fullpage',
                        errorType: errorType,
                        message: err?.data?.MessageDetail ?? 'An error occurred while loading the application. Please use a link that was provided to you through email. Please contact us for assistance if you continue to experience problems.',
                        heading: 'Unable to load application',
                        technicalMessage: err?.data?.Message
                    };
                }
        }
    }

    private setApplication = (application: Application) => {
        this.application = {
            ...application,
            people: application.people?.length ? application.people : [{}],
            company: application.company || null,
            obligee: application.obligee || null,
            questionResponses: application.questionResponses?.length ? application.questionResponses : [],
            tags: application.tags?.length ? application.tags : [],
        };

        if (application.id && !application.broker) {
            application.isBrokerReferral = false;
        }

        // Add tags from $stateParams that don't already exist
        this.$stateParams.tags
            ?.split(',')
            .forEach(tag => {
                if (!this.application.tags.some(existingTag => existingTag.name === tag)) {
                    this.application.tags.push({
                        name: tag,
                        leadId: this.application.leadId,
                        applicationId: this.application.id
                    } as Tag);
                }
            });
    }

    public $onInit() {
        this.customApplicationType = this.$stateParams.applicationType?.toLowerCase() as CustomApplicationType;

        // reset values to handle back button click
        this.applicationSettings = null;
        this.application = null;
        this.filterCount = 0;
        this.prequalifiedQuotes = null;
        this.filtersVisible = false;
        this.error = null;
        this.isSubmitting = false;
        this.submissionProgressPercent = 0;
        this.$rootScope.application = null;
        this.applicationStepManager.reset();

        if (this.$stateParams.uniqueId) {
            this.loadApplication();
        } else {
            this.setApplication({
                bondTypeId: this.$stateParams?.bondTypeId,
                bondAmount: this.$stateParams?.bondAmount
            } as Application);
        }
    }
}

export enum IndemnityRequirements {
    None = 'None',
    ApplicantOnly = 'ApplicantOnly',
    ApplicantSpouse = 'ApplicantSpouse',
    ApplicantSpouseCorporate = 'ApplicantSpouseCorporate',
    ApplicantCorporate = 'ApplicantCorporate'
}