import { IHttpProvider } from "angular";
import app from "../main";
import { Injectables } from "./injectables";
import { EProducerService } from "../api/eProducerService";

const systemAccountInterceptor = (eProducerService: EProducerService) => {

    return {
        request: (request) => {
            if (request.url.indexOf('/login') !== -1) {
                return request;
            }

            request.headers = request.headers || {};

            var urlParams = eProducerService.getUrlParams();
            
            if (urlParams && urlParams.account) {
                request.headers.SystemAccountId = urlParams.account;
            }

            return request;
        },
        response: (response) => {
            return response;
        }
    }
}

systemAccountInterceptor.$inject = [
    Injectables.EProducerService,
];

app.factory(Injectables.systemAccountInterceptor, systemAccountInterceptor);

app.config([Injectables.$httpProvider, ($httpProvider: IHttpProvider) => {
    $httpProvider.interceptors.push(Injectables.systemAccountInterceptor);
}]);
