import { IQService, ISCEService } from "angular";
import { Injectables } from "../../configuration/injectables";
import { EProducerService } from "../../api/eProducerService";
import { IndemnityService } from "../../api/indemnityService";
import { ApplicationService } from "../../api/applicationService";
import { SystemAccountService } from "../../api/systemAccountService";
import { TagManagerService } from "../../api/tagManagerService";
import { EProducerRootScope } from "../../eProducerRootScope";
import { State } from "../state";
import { IEProducerStateParams } from "../../eProducerStateParams";
import { IndemnitySignatureListItem } from "./indemnitySignatureListItem";
import { IndemnityStatus } from "./indemnityStatus";

export class IndemnitySignatureListController {
    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.IndemnityService,
        Injectables.$q,
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: IEProducerStateParams,
        private readonly indemnityService: IndemnityService,
        private readonly $q: IQService,
    ) {}

    public processingPromise;
    public indemnitySignatures: IndemnitySignatureListItem[];
    public indemnitySignaturesSentCount: number;
    public indemnitySignaturesUnsignedCount: number;
    public pane: 'review' | 'send';
    public quoteId: number;

    public sendIndemnityRequests(isValid) {

        if (!isValid) {
            return this.$q.reject();
        }

        const indemnitySignatureIds = [];

        for(let i = 0; i < this.indemnitySignatures.length; i++) {
            indemnitySignatureIds.push({
                id: this.indemnitySignatures[i].id,
                emailedToAddress: this.indemnitySignatures[i].emailedToAddress
            });
        }

        this.processingPromise = this.indemnityService.sendSignatureRequests(indemnitySignatureIds)
            .then(() => {
                return this.loadIndemnitySignatures();
            })
            .then(() => {
                this.pane = 'review';
            });
    }

    public loadIndemnitySignatures() {
        return this.indemnityService.loadIndemnitySignatures(this.quoteId)
            .then((signatures) => {
                this.indemnitySignatures = signatures;
                
                if (this.indemnitySignatures.length) {
                    this.indemnitySignaturesSentCount = this.indemnitySignatures.filter(signature => (signature.status == IndemnityStatus.Sent || signature.status == IndemnityStatus.Signed)).length;
                    this.indemnitySignaturesUnsignedCount = this.indemnitySignatures.filter(signature => signature.status != IndemnityStatus.Signed).length;
                }
            });
    }

    public $onInit () {
        this.quoteId = this.$stateParams.quoteId;;

        if (!this.quoteId) {
            this.$state.go('main.application');
        }

        this.processingPromise = this.loadIndemnitySignatures()
            .then(() => {

                if (!this.indemnitySignatures.length) {
                    this.$state.go('main.payment', { quoteId: this.quoteId });
                }

                if (this.indemnitySignaturesSentCount === 0) {
                    this.pane = 'send';
                } else {
                    this.pane = 'review';
                }
            });
    }
}