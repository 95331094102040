import { IHttpService } from "angular";
import app from "../main";
import { Injectables } from "../configuration/injectables";
import A3ApiResponse from "./a3ApiResponse";
import { SystemSettings } from "../configuration/systemSettings";
import { EProducerService } from "./eProducerService";

export class BondTypeService {

    public static $inject = [
        Injectables.SystemSettings,
        Injectables.$http,
        Injectables.EProducerService
    ];

    constructor(
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService,
        private readonly eProducerService: EProducerService
    ){}

    public getBondTypesByState = (stateAbbr) => {

        let url = `${this.systemSettings.apiBaseUrl}EProducerActions/GetBondTypesByState?state=${stateAbbr}`;

        const eProducerAccountId = this.eProducerService.getEProducerAccountIdFromUrl();

        if (eProducerAccountId) {
            url += `&eProducerAccountId=${eProducerAccountId}`;
        }

        const bondTypeKeywords = this.eProducerService.getbondTypeKeywordsFromUrl();
        if (bondTypeKeywords) {
            url += `&bondTypeKeywords=${bondTypeKeywords}`;
        }

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }


    public getBondType = (id: number) => {
        const query = `?$filter=id eq ${id}&$expand=bondTypeVariableBondAmounts,obligee`;
        const url = `${this.systemSettings.odataBaseUrl}BondType${query}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value[0]);
    }
}

app.service(Injectables.BondTypeService, BondTypeService);
