import { IQService, ISCEService } from "angular";
import { Injectables } from "../../configuration/injectables";
import { EProducerService } from "../../api/eProducerService";
import { IndemnityService } from "../../api/indemnityService";
import { ApplicationService } from "../../api/applicationService";
import { SystemAccountService } from "../../api/systemAccountService";
import { TagManagerService } from "../../api/tagManagerService";
import { EProducerRootScope } from "../../eProducerRootScope";
import { State } from "../state";
import { IEProducerStateParams } from "../../eProducerStateParams";

export class IndemnitySignatureSignController {
    public static $inject = [
        Injectables.$state,
        Injectables.$stateParams,
        Injectables.$sce,
        Injectables.IndemnityService,
        Injectables.$q,
    ];

    constructor(
        private readonly $state: State,
        private readonly $stateParams: IEProducerStateParams,
        private readonly $sce: ISCEService,
        private readonly indemnityService: IndemnityService,
        private readonly $q: IQService,
    ) {}

    public indemnityLanguage;
    public indemnitySignature;
    public indemnityUniqueId: string;
    public invalidSignature: boolean;
    public quoteId: number;

    public loadIndemnity(uniqueId) {
        return this.indemnityService.getByUniqueId(uniqueId)
            .then((response) => {
                this.indemnitySignature = response;

                if (this.indemnitySignature) {
                    this.indemnityLanguage = this.$sce.trustAsHtml(this.indemnitySignature.indemnityLanguage);
                }
            });
    };

    public signIndemnity(invalid: boolean) {

        if (invalid) {
            return this.$q.reject();
        }

        this.indemnitySignature.signature = this.indemnitySignature.signature.trim();

        if (this.indemnitySignature.printedName !== this.indemnitySignature.signature) {
            this.invalidSignature = true;
            return this.$q.reject();
        }

        return this.indemnityService.signIndemnity(this.indemnitySignature.id, this.indemnitySignature.signature)
            .then(() => {
                this.goToIndemnityList(this.indemnitySignature.quoteId);
            });
    }

    private goToIndemnityList = (quoteId: number) => {
        this.$state.go('main.indemnitySignatureList', { quoteId: quoteId });
    }

    public $onInit() {
        this.indemnityUniqueId = this.$stateParams.iuid;
        this.quoteId = this.$stateParams.quoteId;

        if (!this.indemnityUniqueId) {

            if (this.quoteId) {
                this.goToIndemnityList(this.quoteId);
            } else {
                this.$state.go('main.application');
            }
        } else {
            this.loadIndemnity(this.indemnityUniqueId)
            .then(() => {

                if (this.indemnitySignature && this.indemnitySignature.status === 'Signed') {
                    this.goToIndemnityList(this.indemnitySignature.quoteId);
                }
            });
        }
    }
}