import { IQService } from "angular";
import app from "../main";
import { LocalStorageService } from "../utilities/localStorageService";
import { Injectables } from "./injectables";
import { SystemSettings } from "./systemSettings";
import { EProducerRootScope } from "../eProducerRootScope";

// http://bitoftech.net/2014/06/09/angularjs-token-authentication-using-asp-net-web-api-2-owin-asp-net-identity/
const authInterceptorService = (
        $q: IQService,  
        $injector: any,
        localStorageService: LocalStorageService,
        systemSettings: SystemSettings,
        $rootScope: EProducerRootScope
    ) => {
    
    return {
        request: function(config) {
            config.headers = config.headers || {};

                // if login request then don't add auth header
                if (config.url && 
                    config.url.indexOf(systemSettings.apiBaseUrl + 'LoginActions/Login') === 0) {
                    return config;
                }

                // if not requesting through A3 api then don't add auth header
                if (config.url && 
                    config.url.indexOf(systemSettings.apiBaseUrl + 'LoginActions/Login') !== 0 && 
                    config.url.indexOf(systemSettings.apiBaseUrl) !== 0 && 
                    config.url.indexOf(systemSettings.odataBaseUrl) !== 0) {
                    return config;
                }

                // get auth token and add to Authorization header
                var token = localStorageService.getAuthenticationData();
                if (token) { 
                    config.headers.Authorization = 'Bearer ' + token.token;
                }
                
                return config;
        },
        responseError: function(rejection) {
            if (rejection.status === 401) {
                localStorageService.removeAuthenticationData();
                $rootScope.currentUser = null;

                const $state = $injector.get('$state');

                $state.go('main.login', {returnState: $state.current.name});
                $rootScope.$broadcast('LoginStatusChanged');
            }
            return $q.reject(rejection);
        }
    };
}

authInterceptorService.$inject = [
    Injectables.$q, 
    Injectables.$injector,
    Injectables.LocalStorageService,
    Injectables.SystemSettings,
    Injectables.$rootScope
];

app.factory('authInterceptorService', authInterceptorService);

app.config(['$httpProvider', ($httpProvider) => {
    $httpProvider.interceptors.push('authInterceptorService');
}]);