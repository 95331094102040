export default () => {
    var date = new Date().getFullYear();
    var creditCardYearOptions = [];
    var length = date + 13;

    for (var i = date; i < length; i++) {
        creditCardYearOptions.push({ value: '' + i, label: '' + i });
    }

    return creditCardYearOptions;
}