import { IHttpService, IRootScopeService } from "angular";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { TagManagerService } from "../../api/tagManagerService";
import { saveAs } from "file-saver";

const pdfDownloadDirective = () => {
    return {
        restrict: 'E',
        templateUrl: 'app/components/pdfDownload/pdfDownload.html',
        scope: {},
        bindToController: {
            processingPromise: '=?',
            buttonClass: '@?',
            systemAccount: '=?',
            virtualPageName: '=?',
            url: '=?',
            fileName: '=?',
            tooltip: '@?',
            buttonText: '@?',
            showIcon: '=?'
        },
        controllerAs: 'vm',
        controller: pdfDownloadController
    }
}

app.directive('pdfDownload', pdfDownloadDirective);

export class pdfDownloadController {
    public static $inject = [
        Injectables.TagManagerService,
        Injectables.$rootScope,
        Injectables.$http
    ]

    constructor(
        private readonly tagManagerService: TagManagerService,
        private readonly $rootScope: IRootScopeService,
        private readonly $http: IHttpService
    ) {}

    public buttonText: string;
    public downloading: boolean;
    public processingPromise;
    public statusMessage: string;
    public systemAccount;
    public url: string;
    public virtualPageName;
    
    public tellTagManager() {
        this.tagManagerService.downloadPdf(this.systemAccount, this.virtualPageName);
    }

    public processError(response) {
        this.downloading = false;

        if (response && response.Message) {
            this.$rootScope.$broadcast('dangerMessage', response.Message);
        } else {
            this.$rootScope.$broadcast('dangerMessage', 'An error occurred trying to download the document');
        }
    }

    public processResponse(response) {
        const headers = response.headers();
        const dispositionHeader = headers['content-disposition'].replace(/['"]+/g, '');
        const filename = dispositionHeader.substring(dispositionHeader.indexOf('filename=') + 9);

        const blob = new Blob([response.data], {
            type: headers['content-type']
        });

        //uses FileSave.js
        //http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js
        saveAs(blob, filename);

        this.downloading = false;
    }

    public downloadPdf() {

        if (this.downloading) {
            return;
        }

        this.downloading = true;

        this.processingPromise = this.$http.get(this.url, {
            responseType: 'arraybuffer'
        })
            .then((response) => {
                this.processResponse(response);
                this.tellTagManager();
            })
            .catch((response) => {
                this.processError(response);
            });
    }

    public $onInit() {
        this.downloading = false;
        this.statusMessage = '';
        this.buttonText = this.buttonText || 'Download';
    }
}