import app from "../../main";
import { IComponentOptions, IController, IScope, ITimeoutService } from 'angular';
import { DropzoneFile, DropzoneOptions } from 'dropzone';
import angular from 'angular';
import Dropzone from 'dropzone';
import { SystemSettings } from '../../configuration/systemSettings';
import { LocalStorageService } from '../../utilities/localStorageService';
import { AttachmentService } from '../../api/attachmentService';
import { UiError } from '../fullPageError/uiError';
import { Injectables } from '../../configuration/injectables';

class DropzoneController implements IController {
    public static $inject = [
        Injectables.$scope,
        Injectables.SystemSettings,
        Injectables.LocalStorageService,
        Injectables.AttachmentService,
        Injectables.$timeout
    ];

    constructor(
        private readonly $scope: IScope,
        private readonly systemSettings: SystemSettings,
        private readonly localStorageService: LocalStorageService,
        private readonly attachmentService: AttachmentService,
        private readonly $timeout: ITimeoutService
    ) {}

    public dropzoneId: string = 'dropzone';
    public options: EProducerDropzoneOptions = {};
    public uploadProgress: number = 0;
    public onSuccess: () => void;
    public onError: ({error}: { error: UiError }) => void;
    public applicationId?: number;

    public initDropzone() {
        // get auth token and add to Authorization header
        const token = this.localStorageService.getAuthenticationData();
        let tokenString = '';

        if (token) {
            tokenString = `Bearer ${token.token}`;
        }

        const dropzone = new Dropzone(`div#${this.dropzoneId}`,
            {
                url: "will be set onProcessing",
                headers: { "Authorization": tokenString },
                createImageThumbnails: this.options.createImageThumbnails ??false,
                maxFilesize: this.options.maxFilesize ?? 10, // 10 Mb,
                maxFiles: this.options.maxFiles ?? 1,
                uploadMultiple: this.options.uploadMultiple ?? false,
                acceptedFiles: this.options.acceptedFiles ?? "application/pdf,image/jpeg,image/png,image/gif,text/txt,application/msword,application/excel",
                autoProcessQueue: this.options.autoProcessQueue ??true,
                parallelUploads: this.options.parallelUploads ??1,
                dictFileTooBig: this.options.dictFileTooBig ?? "You cannot upload files larger than {{maxFilesize}}Mb.",
                dictInvalidFileType: this.options.dictInvalidFileType ?? "You cannot upload files of this type. Please upload a PDF, image, text, Word or Excel file.",
                clickable: '.dz-click-area',
                accept: (file, done) => {
                    // done()    is a function that adds document to queue.
                    // done(err) doesn't add file to queue because of error
                    done();
                },
                drop: (event) => {
                    angular.element("#dropzone").removeClass("a-ds-dragover");
                },
                dropstart: (event) => {},
                dragstart: (event) => {},
                dragend: (event) => {},
                dragenter: (event) => {
                    angular.element("#dropzone").addClass("a-ds-dragover");
                },
                dragover: (event) => {},
                dragleave: (event) => {
                    angular.element("#dropzone").removeClass("a-ds-dragover");
                },
                addedfile: (file) => {},
                removedfile: (file) => {},
                thumbnail: (file) => {},
                error: (file, response, xhr) => {
                    if (this.onError) {
                        this.onError({
                            error: {
                                errorDisplayType: 'inline',
                                errorType: 'Unknown',
                                heading: xhr ? 'Upload Failed' : 'Invalid File',
                                message: xhr ? 'An error occurred trying to upload this document. Please contact us for assistance.' : response.toString(),
                                technicalMessage: ''
                            }
                        });
                    }

                    dropzone.removeAllFiles();
                    this.uploadProgress = 0;
                    this.$scope.$apply();
                },
                processing: (file) => {
                    dropzone.options.url = this.systemSettings.apiBaseUrl + "upload/UploadFiles";
                },
                uploadprogress: (file, progress) => {
                    this.uploadProgress = progress;
                    this.$scope.$apply();
                },
                sending: (file) => {},
                success: (file, response) => {

                    dropzone.removeAllFiles();
                    this.uploadProgress = 0;

                    const attachment = {
                        filename: file.name,
                        fileSize: file.size,
                        fileId: response[0],
                        applicationId: this.applicationId
                    };

                    this.attachmentService.uploadAttachment(attachment)
                        .then(() => {
                            if (this.onSuccess) {
                                this.onSuccess();
                            }
                        })
                        .catch(err => {
                            if (this.onError) {
                                this.onError({
                                    error: {
                                        errorDisplayType: 'inline',
                                        errorType: 'Unknown',
                                        heading: 'Something went wrong',
                                        message: 'An error occurred while uploading your document. Please contact us so that we can resolve this issue.'
                                    }
                                });
                            }
                        });
                },
                complete: (file) => {
                    dropzone.removeAllFiles();
                },
                canceled: (file) => {},
                maxfilesreached: (file) => {},
                maxfilesexceeded: (file) => {},
                addFilesStart: (files, proceed) => { proceed(); },
                addedfiles: (files) => {},
                totaluploadprogress: (upload) => {},
                reset: () => {},
                queuecomplete: () => {},
            } as EProducerDropzoneOptions);
    }

    public $onInit() {
        this.$timeout(() => {
            this.initDropzone();
        });
    }
}

const component: IComponentOptions = {
    bindings: {
        onSuccess: '&',
        onError: '&',
        options: '<?',
        dropzoneId: '<?',
        applicationId: '<?'
    },
    controller: DropzoneController,
    controllerAs: 'vm',
    templateUrl: 'app/components/dropzone/dropzone.html'
};

app.component('dropzone', component);

export interface EProducerDropzoneOptions extends DropzoneOptions {
    success?: (file: DropzoneFile, response?) => void;
}