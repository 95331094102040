import { Injectables } from '../../../configuration/injectables';
import app from '../../../main';
import { Application } from '../application';
import ApplicationSettings from '../applicationSettings';
import { ApplicationPanes } from './applicationPanes';

export default class ApplicationStepManager {
    public static $inject = [];

    constructor() {}

    public activePane: ApplicationPanes;
    public panes: ApplicationPanes[];
    public showStepProgress: boolean = false;
    public currentPaneNumber: number = 1;

    public setPaneOptions = (application: Application, applicationSettings: ApplicationSettings) => {
        const panes: ApplicationPanes[] = ['ProductSelectionStep'];

        if (application.principalType == 'Company') {
            panes.push('CompanyInfo');
        }

        panes.push('PersonalInfo');

        if (applicationSettings.additionalApplicationQuestions?.length) {
            panes.push('AdditionalQuestions');
        }

        if (applicationSettings.isGenericObligee) {
            panes.push('Obligee');
        }

        panes.push('NameOnBond');
        panes.push('Review');

        this.activePane = 'ProductSelectionStep';
        this.panes = panes;
    }

    public get totalPaneCount() {
        return this.panes?.length ?? 0;
    }

    public moveNextStep() {
        this.currentPaneNumber++;

        switch (this.activePane) {
            case 'ProductSelectionStep':
                this.activePane = 'CompanyInfo';
                break;
            case 'CompanyInfo':
                this.activePane = 'PersonalInfo';
                break;
            case 'PersonalInfo':
                this.activePane = 'AdditionalQuestions';
                break;
            case 'AdditionalQuestions':
                this.activePane = 'Obligee';
                break;
            case 'Obligee':
                this.activePane = 'NameOnBond';
                break;
            case 'NameOnBond':
                this.activePane = 'Review';
                break;
            case 'Review':
                break;
            default:
                this.activePane = 'ProductSelectionStep';
                this.currentPaneNumber = 1;
                break;
        }

        if (!this.panes.includes(this.activePane)) {
            this.currentPaneNumber--;
            this.moveNextStep();
        }else {
            this.showStepProgress = true;
        }
    }

    public movePrevStep() {
        this.currentPaneNumber--;

        switch (this.activePane) {
            case 'ProductSelectionStep':
                break;
            case 'CompanyInfo':
                this.activePane = 'ProductSelectionStep';
                break;
            case 'PersonalInfo':
                this.activePane = 'CompanyInfo';
                break;
            case 'AdditionalQuestions':
                this.activePane = 'PersonalInfo';
                break;
            case 'Obligee':
                this.activePane = 'AdditionalQuestions';
                break;
            case 'NameOnBond':
                this.activePane = 'Obligee';
                break;
            case 'Review':
                this.activePane = 'NameOnBond';
                break;
            default:
                this.activePane = 'ProductSelectionStep';
                this.currentPaneNumber = 1;
                break;
        }

        if (!this.panes.includes(this.activePane)) {
            this.currentPaneNumber++;
            this.movePrevStep();
        }
    }

    public reset() {
        this.activePane = null;
        this.panes = null;
        this.showStepProgress = false;
        this.currentPaneNumber = 1;
    }
}

app.factory(Injectables.ApplicationStepManager, ApplicationStepManager);