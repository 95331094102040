import app from "../../main";
import { FieldChildBaseController } from "./fieldChildBaseController";

export class FieldWrapperController extends FieldChildBaseController {

}

const component = {
    bindings: {
    },
    controller: FieldWrapperController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/fieldWrapper.html',
    transclude: true,
    require: FieldChildBaseController.require
}

app.component('fieldWrapper', component);