import * as luxon from "luxon";
import app from "../main";
import { DateTime } from "luxon";

export const dateOnlyFilter = () => {

    return (date: Date | string) => {
        if (!date) { 
            return ''; 
        }

        let dateTime:DateTime = null;

        if (date instanceof Date) {
            dateTime = luxon.DateTime.fromJSDate(date as Date);
        } else {
            dateTime = luxon.DateTime.fromISO(date as string);
        }
        

        if (!dateTime.isValid) {
            console.error('Unable to parse local date time filter because', dateTime.invalidExplanation);
            return '';
        }
        
        if (dateTime.toUTC().year == 1) {
            return '';
        }

        return dateTime.toFormat('mm/dd/yyyy');
    };
}

app.filter('dateOnly', dateOnlyFilter);

export interface IDateOnlyFilter {
    (date: any): string;
}