export default (amountString: string) => {
    if (!amountString) {
        return null;
    }

    return parseFloat(amountString
        .toString()
        .replace(/,/g, '')
        .replace(/\$/g, '')
    );
}