export enum Injectables {
    $filter = "$filter",
    $http = "$http",
    $injector = "$injector",
    $interval = "$interval",
    $q = "$q",
    $compile = "$compile",
    $rootScope = "$rootScope",
    $sce = "$sce",
    $scope = "$scope",
    $state = "$state",
    $stateParams = "$stateParams",
    $timeout = "$timeout",
    $element = "$element",
    $attrs = "$attrs",
    $window = "$window",
    $location = '$location',
    $urlRouterProvider = '$urlRouterProvider',
    $stateProvider = '$stateProvider',
    GuidCreator = 'GuidCreator',
    LocalStorageService = 'LocalStorageService',
    SystemSettings = 'SystemSettings',
    EProducerService = 'eProducerService',
    DataService = 'dataSvc',
    ApplicationService = 'ApplicationService',
    AuthenticationService = 'AuthenticationService',
    SystemAccountService = 'SystemAccountService',
    LeadService = 'LeadService',
    EProducerAccountService = 'EProducerAccountService',
    AttachmentService = 'attachmentService',
    BondService = 'BondService',
    BondTypeService = 'BondTypeService',
    CustomerService = 'CustomerService',
    EProducerUserService = 'EProducerUserService',
    TagManagerService = 'TagManagerService',
    PaymentService = 'PaymentService',
    IndemnityService = 'IndemnityService',
    DateOnly = 'dateOnly',
    FileSize = 'fileSize',
    Highlight = 'highlight',
    PerfectScrollbarService = "PerfectScrollbarService",
    $document = "$document",
    IntermediateRouterService = "IntermediateRouterService",
    $parse = "$parse",
    ApplicationStepManager = "ApplicationStepManager",
    CacheStore = "CacheStore",
    JQueryService = "JQueryService",
    JQuery = "JQuery",
    LocalDateTime = "localDateTime",
    BusyIndicatorTrackerFactory = "BusyIndicatorTrackerFactory",
    eProducerTemplateCache = "eProducerTemplateCache",
    UiRouter = "ui.router",
    ngAnimate = "ngAnimate",
    $provide = "$provide",
    $locationProvider = "$locationProvider",
    $httpProvider = "$httpProvider",
    jsonFormatterInterceptor = "jsonFormatterInterceptor",
    systemAccountInterceptor = "systemAccountInterceptor",
    systemTypeInterceptor = "systemTypeInterceptor"
}
