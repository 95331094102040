import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { BaseField } from './baseField';

export class CurrencyFieldController extends BaseField {
    public static $inject = [Injectables.$timeout];

    constructor(private $timeout: ng.ITimeoutService) {
        super($timeout);
    }

    public lastKeyPressed: string;

    public handleChange = () => {
        this.$timeout(() => {
            if (this.onChange) {
                this.onChange();
            }
        });
    }

    public handleKeydown = (event: KeyboardEvent) => {
        const allowedKeys = [
            'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Escape', 'Shift', 'Home', 'End',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'
        ];

        if (!allowedKeys.includes(event.key) && !(event.ctrlKey || event.metaKey)) {
            event.preventDefault();
        }

        this.lastKeyPressed = event.key;
    }

    public $onInit() {
    }
}

const component = {
    bindings: {
        ...BaseField.bindings
    },
    controller: CurrencyFieldController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/currencyField.html',
    require: {
        form: '^form'
    }
}

app.component('currencyField', component);