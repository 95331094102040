import { IHttpProvider, IQService } from "angular";
import app from "../main";
import { Injectables } from "./injectables";

const systemTypeInterceptor = ($q: IQService) => {
    return {
        request: (request) => {
            if (request.url.indexOf('/login') !== -1) {
                return request;
            }

                request.headers = request.headers || {};
                
                request.headers.SystemType = 'eproducer';
                
                return request;
        },
        responseError: (rejection) => {
            return $q.reject(rejection);
        }
    }
}

systemTypeInterceptor.$inject = [
    Injectables.$q,
];

app.factory(Injectables.systemTypeInterceptor, systemTypeInterceptor);

app.config([Injectables.$httpProvider, ($httpProvider: IHttpProvider) => {
    $httpProvider.interceptors.push(Injectables.systemTypeInterceptor);
}]);