import { Injectables } from "../../configuration/injectables";
import { EProducerRootScope } from "../../eProducerRootScope";
import app from "../../main";

export class ApplicationFormFooterController {
    public static $inject = [Injectables.$rootScope];

    constructor(private readonly $rootScope: EProducerRootScope) {}

    public onNextClick: () => void;
    public onBackClick: () => void;
    public hideBackButton: boolean;
    public nextButtonText: string = 'Next';

    public get hasQuotes(): boolean {
        return !!this.$rootScope.application?.totalQuoteCount;
    }

    public $onInit() {
    }
}

const component = {
    bindings: {
        onNextClick: '&?',
        onBackClick: '&?',
        hideBackButton: '@?',
        nextButtonText: '@?',
    },
    controller: ApplicationFormFooterController,
    controllerAs: 'vm',
    templateUrl: 'app/states/application/applicationFormFooter.html'
}

app.component('applicationFormFooter', component);