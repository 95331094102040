import { AuthenticationService } from "../api/authenticationService";
import { Injectables } from "../configuration/injectables";
import { SystemAccountService } from "../api/systemAccountService";
import { State } from "./state";
import { EProducerRootScope } from '../eProducerRootScope';

export type MainStateParams = {
    eProducerAccountId?: number;
}

export class MainController {
    public static $inject = [
        Injectables.AuthenticationService,
        Injectables.SystemAccountService,
        Injectables.$state,
        Injectables.$rootScope,
        Injectables.$stateParams
    ];

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly systemAccountService: SystemAccountService,
        private readonly $state: State,
        private readonly $rootScope: EProducerRootScope,
        private readonly $stateParams: MainStateParams
    ) {}

    public get allowCCPayments() {
        return this.$rootScope.systemAccount.allowCCPaymentsThroughEProducer;
    }

    showNav: boolean;
    productType?: 'policy' | 'bond';

    private handleLoginStatus = () => {
        this.authenticationService.isLoggedIn();
    }

    public get state() {
        return this.$rootScope.currentState;
    }

    public get systemAccount() {
        return this.$rootScope.systemAccount;
    }

    public get application() {
        return this.$rootScope.application;
    }

    public get currentUser() {
        return this.$rootScope.currentUser;
    }

    public get branding() {
        return this.$rootScope.branding;
    }

    public $onInit() {
        // todo: remove this when we have a better way to determine the system account
        const systemAccountId = 4;
        // todo: decide how to get eProducerAccountId and remove the hard coding
        //this.$rootScope.eProducerAccountId = 1; //this.$stateParams.eProducerAccountId;

        this.$rootScope.currentState = this.$state.current.name;

        this.systemAccountService.getSystemAccountById(systemAccountId, this.$rootScope.eProducerAccountId)
            .then(systemAccount => {
                this.$rootScope.systemAccount = systemAccount;

                if (systemAccount.eProducerAccountBranding) {
                    this.$rootScope.branding = {
                        systemAccountId: systemAccount.id,
                        eProducerAccountId: systemAccount.eProducerAccountBranding.id,
                        companyName: systemAccount.eProducerAccountBranding.companyName,
                        phone: systemAccount.eProducerAccountBranding.phone,
                        email: systemAccount.eProducerAccountBranding.email
                    };
                } else {
                    this.$rootScope.branding = {
                        systemAccountId: systemAccount.id,
                        companyName: systemAccount.companyName,
                        phone: systemAccount.phone,
                        email: systemAccount.email
                    };
                }
            });

        this.$rootScope.$on("$stateChangeStart", (event, toState, fromState) => {
            if (!this.authenticationService.isAuthorizedForState(toState.name)) {
                this.$state.go('main.login', {returnState: toState.name});
                event.preventDefault();
            }
        });

        this.$rootScope.$on('$stateChangeSuccess', (event, toState, fromState) => {
            this.$rootScope.currentState = toState.name;

            if (this.state !== 'main.application') {
                this.$rootScope.application = null;
            }
        });

        this.handleLoginStatus();
        this.$rootScope.$on('LoginStatusChanged', this.handleLoginStatus);

        this.showNav = this.authenticationService.isLoggedIn() && this.state !== 'resetPassword';
    }
}