import { IHttpService, IQService } from "angular";
import { Injectables } from "../configuration/injectables";
import { SystemSettings } from "../configuration/systemSettings";
import A3ApiResponse from "./a3ApiResponse";
import app from "../main";

export class CustomerService {

    public static $inject = [
        Injectables.$q,
        Injectables.SystemSettings,
        Injectables.$http
    ];

    constructor(
        private readonly $q: IQService,
        private readonly systemSettings: SystemSettings,
        private readonly $http: IHttpService
    ) {}

    public getOpenPayablesByLookup = (lookupCode: string, lookupZip: string) => {
        lookupCode = lookupCode?.replace(/&/g, "%26");
        const url = `${this.systemSettings.apiBaseUrl}CustomerActions/OpenPayables?lookupCode=${lookupCode}&lookupZip=${lookupZip}`;

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => response.data.value);
    }

    public searchLookupCode = (searchRequest, systemAccountId: number) => {
        var url = `${this.systemSettings.apiBaseUrl}CustomerActions/SearchLookupCode?systemAccountId=${systemAccountId}`;

        if (searchRequest.bondNumber) {
            url += `&bondNumber=${searchRequest.bondNumber}`;
        }

        if (searchRequest.phone) {
            url += `&phone=${searchRequest.phone}`;
        }

        if (searchRequest.email) {
            url += `&email=${searchRequest.email}`;
        }

        return this.$http.get<A3ApiResponse<any>>(url)
            .then(response => {
                if (!response.data.value) {
                    throw new Error('Record not found');
                }

                return response.data.value;
            });
    }
}

app.service(Injectables.CustomerService, CustomerService);
