import { FieldOption } from '../fields/fieldOption';

export class TablePager {

    constructor() {
        this.recordsPerPageOptions = [
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 50, label: '50' }
        ];

        this.recordsPerPage = 10;
        this.currentPage = 1;
        this.pageButtonCount = 2;
    }

    public recordsPerPageOptions: FieldOption<number>[];
    public recordsPerPage: number;
    public currentPage: number;
    public onPageChange: () => void;
    public onRecordsPerPageChanged: () => void;
    public pageButtonCount: number;
    public pageButtonOptions: number[] = [];

    private _totalRecordCount: number;
    public get totalRecordCount(): number {
        return this._totalRecordCount;
    }
    public set totalRecordCount(value: number) {
        this._totalRecordCount = value;
        this.buildPageButtonOptions();
    }

    public get totalRecordsCountFormatted(): string {
        if (this.totalRecordCount !== null && this.totalRecordCount !== undefined) {
            return this.totalRecordCount.toLocaleString();
        } else {
            return '';
        }
    }

    public buildPageButtonOptions() {
        this.pageButtonOptions = [];

        if (this.totalPages <= this.pageButtonCount) {
            for (let i = 1; i <= this.totalPages; i++) {
                this.pageButtonOptions.push(i);
            }
        } else {
            let start = this.currentPage - Math.floor(this.pageButtonCount / 2);
            let end = this.currentPage + Math.floor(this.pageButtonCount / 2);

            if (start < 1) {
                start = 1;
                end = this.pageButtonCount;
            }

            if (end > this.totalPages) {
                end = this.totalPages;
                start = this.totalPages - this.pageButtonCount + 1;
            }

            for (let i = start; i <= end; i++) {
                this.pageButtonOptions.push(i);
            }
        }
    }

    public get isVisible(): boolean {
        return this.totalRecordCount > 0;
    }

    public recordsPerPageChanged = () => {
        this.currentPage = 1;
        this.buildPageButtonOptions();

        if (this.onRecordsPerPageChanged instanceof Function) {
            this.onRecordsPerPageChanged();
        }
    }

    public setRecordPerPage(selectedOption) {
        this.recordsPerPage = selectedOption.value;
        this.recordsPerPageChanged();
    }

    public currentPageChanged() {
        this.buildPageButtonOptions();

        if (typeof this.onPageChange === 'function') {
            this.onPageChange();
        }
    }

    public goToNextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.currentPageChanged();
        }
    }

    public goToPreviousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.currentPageChanged();
        }
    }

    public goToPage(pageNumber: number) {
        if (pageNumber >= 1 && pageNumber <= this.totalPages) {
            this.currentPage = pageNumber;
            this.currentPageChanged();
        }
    }

    public goToFirstPage() {
        this.currentPage = 1;
        this.currentPageChanged();
    }

    public goToLastPage() {
        this.currentPage = this.totalPages;
        this.currentPageChanged();
    }

    public get totalPages(): number {
        return Math.ceil(this.totalRecordCount / this.recordsPerPage);
    }
}

