import app from "../../main";
import { FieldChildBaseController } from "./fieldChildBaseController";

export class FieldLabelController extends FieldChildBaseController {

}

const component = {
    bindings: {
    },
    controller: FieldLabelController,
    controllerAs: 'vm',
    templateUrl: 'app/components/fields/fieldLabel.html',
    transclude: true,
    require: FieldChildBaseController.require
}

app.component('fieldLabel', component);