import { Injectables } from '../../configuration/injectables';
import app from "../../main";
import { IndemnityRequirements } from '../../states/application/applicationController';

export class QuoteListController {
    public static $inject = [
        Injectables.$scope
    ];

    constructor(
        private readonly $scope: ng.IScope
    ) {}

    public filtersVisible: boolean = false;
    public filterCount: number = 0;
    public filteredQuoteCount: number;
    public filters: {
        term?: '1'|'2'|'3'|'4'|'5';
        noIndemnity?: boolean;
        noSpouseIndemnity?: boolean;
        noCorporateIndemnity?: boolean;
        noCredit?: boolean;
        noReview?: boolean;
        noDocuments?: boolean;
    } = {};
    public quotes: any[] = [];
    public filteredQuotes: any[] = [];
    public isPolicy: boolean;
    public isPurchasable: boolean;
    public mostExpensiveCost: number;
    public lowestCost: number;

    public resetFilters = () => {
        this.filters = {};
        this.applyQuoteFilters();
    }

    public applyQuoteFilters = () => {
        this.filterCount = 0;

        if (this.filters.term) {
            this.filterCount++;
        }

        if (this.filters.noIndemnity) {
            this.filterCount++;
        } else if (this.filters.noSpouseIndemnity) {
            this.filterCount++;
        } else if (this.filters.noCorporateIndemnity) {
            this.filterCount++;
        }

        if (this.filters.noCredit) {
            this.filterCount++;
        }

        if (this.filters.noReview) {
            this.filterCount++;
        }

        if (this.filters.noDocuments) {
            this.filterCount++;
        }

        this.filteredQuotes = this.quotes.filter(this.handleQuoteFiltering);
        this.filteredQuoteCount = this.filteredQuotes.length;
        this.mostExpensiveCost = null;
        this.lowestCost = null;

        // set highest pre qual quote price
        for (let i = 0; i < this.filteredQuotes.length; i++) {
            if (!this.mostExpensiveCost || this.filteredQuotes[i].totalAmountDue > this.mostExpensiveCost) {
                this.mostExpensiveCost = this.filteredQuotes[i].totalAmountDue;
            }
        }

        // set lowest pre qual quote price
        for (var i = 0; i < this.filteredQuotes.length; i++) {
            if (!this.lowestCost || this.filteredQuotes[i].totalAmountDue < this.lowestCost) {
                this.lowestCost = this.filteredQuotes[i].totalAmountDue;
            }
        }
    }

    public handleQuoteFiltering = (quote) => {
        let showQuote = true;
        switch (this.filters.term?.toString()) {
            case '1':
                showQuote = showQuote && quote.term < 2;
                break;

            case '2':
                showQuote = showQuote && quote.term >= 2 && quote.term < 3;
                break;

            case '3':
                showQuote = showQuote && quote.term >= 3 && quote.term < 4;
                break;

            case '4':
                showQuote = showQuote && quote.term >= 4 && quote.term < 5;
                break;

            case '5':
                showQuote = showQuote && quote.term >= 5;
                break;

            default:
        }

        if (this.filters.noIndemnity) {
            showQuote = showQuote && quote.indemnityRequirements === IndemnityRequirements.None;
        } else if (this.filters.noSpouseIndemnity) {
            showQuote = showQuote && quote.indemnityRequirements !== IndemnityRequirements.ApplicantSpouse && quote.indemnityRequirements !== IndemnityRequirements.ApplicantSpouseCorporate;
        } else if (this.filters.noCorporateIndemnity) {
            showQuote = showQuote && quote.indemnityRequirements !== IndemnityRequirements.ApplicantCorporate && quote.indemnityRequirements !== IndemnityRequirements.ApplicantSpouseCorporate;
        }

        if (this.filters.noCredit) {
            showQuote = showQuote && !quote.requiresCredit;
        }

        if (this.filters.noReview) {
            showQuote = showQuote && !quote.requiresManualReview;
        }

        if (this.filters.noDocuments) {
            showQuote = showQuote && !quote.requiresAdditionalDocuments;
        }

        return showQuote;
    }

    public $onInit() {
        this.$scope.$watch(() => this.quotes, this.applyQuoteFilters);
    }
}

const component = {
    bindings: {
        quotes: '<',
        filterCount: '=',
        filteredQuoteCount: '=',
        lowestCost: '=',
        mostExpensiveCost: '=',
        isPolicy: '<?',
        isPurchasable: '<?',
        filtersVisible: '='
    },
    controller: QuoteListController,
    controllerAs: 'vm',
    templateUrl: 'app/components/quoteList/quoteList.html'
};

app.component('quoteList', component);

